
import OpportunityReportPage from "./OpportunityReportPage.vue";
import { ExtendedOpportunityRow, OpportunityReportPageResult } from "./opportunityReportPage";
import {
    getOpportunityRowDefaultKeys,
    mapOpportunityRowKeyToRowLabel,
    splitWithOpportunityTeamReceiverId,
    WithOpportunityTeamReceiverId,
    withOpportunityTeamReceiverIdSetToNull,
} from "./opportunityRowUtils";
import { OpportunityStatus } from "@/api/opportunities";
import { SentimentType } from "@/api/opportunitySearch";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import ReportingLineChartCard from "@/app/pages/reporting/ReportingLineChartCard.vue";
import { getReportingTableByReportingLineChart } from "@/app/pages/reporting/chartUtils";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import {
    hasNonZeroSeries,
    isNonZeroSeries,
    ReportingLineChartData,
} from "@/app/pages/reporting/charts/reportingLineChart";
import { getOpportunitySentimentTypeColor, getOpportunityStatusColor, TOTAL_COLOR } from "@/app/pages/reporting/colors";
import {
    isWithProcessingTimeIndividualDurationSlotErrorless,
    PotentiallyErroneous,
    PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS,
    splitWithAssignee,
    WithAssignee,
    withAssigneeSetToNull,
    WithCloser,
    WithCreatedRecurringTimeSlot,
    WithCreatorActorType,
    WithProcessingTimeIndividualDurationSlot,
    WithSentimentType,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData, transpose } from "@/app/pages/reporting/table/reportingTable";
import {
    getRecurringTimeSlotKeys,
    getRecurringTimeSlotLabel,
    IndividualDurationInterval,
    RecurringTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type PrecomputedProperties = WithCloser &
    WithCreatedRecurringTimeSlot &
    WithCreatorActorType &
    WithProcessingTimeIndividualDurationSlot<PotentiallyErroneous> &
    WithSentimentType;

type ComputedOpportunityRow = ExtendedOpportunityRow<PrecomputedProperties> &
    WithAssignee &
    WithOpportunityTeamReceiverId;

type ComputedOpportunityRowGroupBy = keyof Pick<
    ComputedOpportunityRow,
    | "assigneeId"
    | "channel"
    | "closer"
    | "creatorActorType"
    | "dealerId"
    | "opportunityStatus"
    | "opportunityTeamReceiverId"
    | "outcome"
    | "processingTimeIndividualDurationSlot"
    | "sentimentType"
    | "sourceId"
>;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedOpportunityRowGroupBy;
}

export default Vue.extend({
    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
            evaluationType: EvaluationType.PERCENTAGE,
            groupBy: "channel" as ComputedOpportunityRowGroupBy,
            recurringTimeInterval: RecurringTimeInterval.HOUR_OF_DAY as RecurringTimeInterval,
            RecurringTimeInterval,
            result: null as OpportunityReportPageResult<PrecomputedProperties> | null,
        };
    },

    computed: {
        chart(): ReportingLineChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const slots = getRecurringTimeSlotKeys(this.recurringTimeInterval);
            const rowsBySlot = new Map<number, ComputedOpportunityRow[]>();

            for (const slot of slots) {
                rowsBySlot.set(
                    slot,
                    this.filteredComputedOpportunityRows.filter((r) => r.createdRecurringTimeSlot === slot)
                );
            }

            const chart: ReportingLineChartData = {
                title: this.$t("Stoßzeiten") as string,
                categories: slots.map(
                    (slot) =>
                        getRecurringTimeSlotLabel(slot, this.recurringTimeInterval) || (this.$t("Unbekannt") as string)
                ),
                series: [
                    {
                        id: "total",
                        name: this.$t("Gesamt") as string,
                        selected: false,
                        data: {
                            values: slots.map((slot) => {
                                const slotRows = rowsBySlot.get(slot)!;

                                return {
                                    value: slotRows.length,
                                    onClick: this.showBottomSheetOnClick(slotRows.map((r) => r.id)),
                                };
                            }),
                        },
                        color: TOTAL_COLOR,
                    },
                    ...[...this.rowGroups]
                        .sort((a, b) => b.rows.length - a.rows.length)
                        .map((rowGroup, index) => ({
                            id: `group-${index}`,
                            name: rowGroup.title,
                            data: {
                                values: slots.map((slot) => {
                                    const slotRows = rowGroup.rows.filter((r) => r.createdRecurringTimeSlot === slot);

                                    return {
                                        value:
                                            this.evaluationType === EvaluationType.PERCENTAGE
                                                ? rate(slotRows.length, rowsBySlot.get(slot)!.length)
                                                : slotRows.length,
                                        onClick: this.showBottomSheetOnClick(slotRows.map((r) => r.id)),
                                    };
                                }),
                                isPercentage: this.evaluationType === EvaluationType.PERCENTAGE,
                            },
                            additionalTooltipData: [
                                {
                                    values: slots.map((slot) => {
                                        const slotRows = rowGroup.rows.filter(
                                            (r) => r.createdRecurringTimeSlot === slot
                                        );

                                        return {
                                            value:
                                                this.evaluationType === EvaluationType.PERCENTAGE
                                                    ? slotRows.length
                                                    : rate(slotRows.length, rowsBySlot.get(slot)!.length),
                                        };
                                    }),
                                    isPercentage: this.evaluationType !== EvaluationType.PERCENTAGE,
                                },
                            ],
                            color:
                                this.groupBy === "opportunityStatus"
                                    ? getOpportunityStatusColor(rowGroup.key as OpportunityStatus)
                                    : this.groupBy === "sentimentType"
                                    ? getOpportunitySentimentTypeColor(rowGroup.key as SentimentType)
                                    : undefined,
                            colorPalette:
                                this.groupBy === "outcome"
                                    ? rowGroup.key === true
                                        ? ReportingChartColorPalette.POSITIVE
                                        : rowGroup.key === false
                                        ? ReportingChartColorPalette.NEGATIVE
                                        : undefined
                                    : undefined,
                        })),
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return {
                ...chart,
                series: chart.series.map((s) => ({
                    ...s,
                    selected: s.selected ?? isNonZeroSeries(s),
                })),
            };
        },

        defaultKeys(): RowKey[] {
            return getOpportunityRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        evaluationTypeOptions(): SelectOption[] {
            return [
                {
                    text: this.$t("Absolut"),
                    value: EvaluationType.ABSOLUTE,
                },
                {
                    text: this.$t("Prozentual"),
                    value: EvaluationType.PERCENTAGE,
                },
            ];
        },

        filteredComputedOpportunityRows(): readonly ComputedOpportunityRow[] {
            let filteredRows: readonly ComputedOpportunityRow[] = (this.result?.rows ?? [])
                .filter((row) => this.groupBy !== "closer" || row.opportunityStatus === OpportunityStatus.CLOSED)
                .map(withAssigneeSetToNull)
                .map(withOpportunityTeamReceiverIdSetToNull);

            if (this.groupBy === "assigneeId") {
                filteredRows = splitWithAssignee(filteredRows);
            } else if (this.groupBy === "opportunityTeamReceiverId") {
                filteredRows = splitWithOpportunityTeamReceiverId(filteredRows);
            }

            return filteredRows;
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "creatorActorType",
                    text: this.$t("Art des Erstellers"),
                },
                {
                    value: "processingTimeIndividualDurationSlot",
                    text: this.$t("Bearbeitungsdauer"),
                },
                {
                    value: "channel",
                    text: this.$t("Kanal"),
                },
                {
                    value: "sourceId",
                    text: this.$t("Quelle"),
                },
                {
                    value: "outcome",
                    text: this.$t("Resultat"),
                },
                {
                    value: "closer",
                    text: this.$t("Schließer"),
                },
                {
                    value: "opportunityStatus",
                    text: this.$t("Status"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
                {
                    value: "sentimentType",
                    text: this.$t("Stimmung"),
                },
                {
                    value: "assigneeId",
                    text: this.$t("Verkäufer"),
                },
                {
                    value: "opportunityTeamReceiverId",
                    text: this.$t("Verkäuferteam"),
                },
            ];
        },

        groupByText(): string | null {
            return (this.groupByOptions.find((o) => o.value === this.groupBy)?.text ?? null) as string | null;
        },

        processingTimeSlots(): IndividualDurationInterval[] {
            return [...PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS];
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedOpportunityRow>[] {
            const filteredRows =
                this.groupBy === "processingTimeIndividualDurationSlot"
                    ? this.filteredComputedOpportunityRows.filter(isWithProcessingTimeIndividualDurationSlotErrorless)
                    : this.filteredComputedOpportunityRows;

            const rowGroups = groupRowsBy(filteredRows, (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapOpportunityRowKeyToRowLabel(rowGroup.key, this.groupBy, {
                    ...(this.result?.mapKeyToLabelContext ?? {}),
                    recurringTimeInterval: this.recurringTimeInterval,
                });
                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            const table = transpose(getReportingTableByReportingLineChart(this.chart));

            return {
                headerGroups: table.headerGroups,
                items: table.items.slice(1),
                totals: table.items[0],
                groupByHeaderText: this.groupByText ?? undefined,
            };
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        OpportunityReportPage,
        ReportingLineChartCard,
    },
});
