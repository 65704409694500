
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { durationFormatter, getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import {
    Errorless,
    isWithAssigneeWaitingTimeUntilFirstReactionErrorless,
    splitWithAssignee,
    WithAssignee,
    WithAssigneeWaitingTimeUntilFirstReaction,
    WithIsAssigneeFirstReactingAssignee,
    withIsAssigneeFirstReactingAssignee,
} from "@/app/pages/reporting/issueRowUtils";
import { withAssigneeWaitingTimeUntilFirstReaction } from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { avg, median } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import Vue from "vue";

type ComputedOpportunityRow = OpportunityRow &
    WithAssignee &
    WithAssigneeWaitingTimeUntilFirstReaction<Errorless> &
    WithIsAssigneeFirstReactingAssignee;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => OpportunityRow[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    assigneeId: rowGroup.key!,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    firstReactionRows: rowGroup.rows,
                    waitingTimeUntilFirstReactionAvg: avg(
                        rowGroup.rows.map((r) => r.assigneeWaitingTimeUntilFirstReaction!)
                    ),
                    waitingTimeUntilFirstReactionMedian: median(
                        rowGroup.rows.map((r) => r.assigneeWaitingTimeUntilFirstReaction!)
                    ),
                }))
                .map((c) => ({
                    ...c,
                    sortValue: c.waitingTimeUntilFirstReactionAvg,
                }))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "waiting-time-until-first-reaction-median",
                        name: this.$t("Median") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.waitingTimeUntilFirstReactionMedian,
                                onClick: this.showBottomSheetOnClick(c.firstReactionRows.map((r) => r.id)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                    {
                        id: "waiting-time-until-first-reaction-avg",
                        name: this.$t("Durchschnitt") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.waitingTimeUntilFirstReactionAvg,
                                onClick: this.showBottomSheetOnClick(c.firstReactionRows.map((r) => r.id)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        filteredComputedOpportunityRows(): readonly ComputedOpportunityRow[] {
            return [this.rows]
                .map(splitWithAssignee)
                .map((rows) => rows.filter((r) => r.assigneeId))
                .map(withAssigneeWaitingTimeUntilFirstReaction)
                .pop()!
                .filter(isWithAssigneeWaitingTimeUntilFirstReactionErrorless)
                .filter((r) => r.assigneeWaitingTimeUntilFirstReaction !== null)
                .map(withIsAssigneeFirstReactingAssignee)
                .filter((r) => r.isAssigneeFirstReactingAssignee);
        },

        rowGroups(): readonly TitledRowGroup<string | null, ComputedOpportunityRow>[] {
            return groupRowsBy(this.filteredComputedOpportunityRows, (r) => r.assigneeId).map((rowGroup) => {
                const user = rowGroup.key ? usersStore.getUserById(rowGroup.key) : null;

                return {
                    ...rowGroup,
                    title: getFullName(user) || (this.$t("Unbekannter Benutzer") as string),
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText: this.$t("Verkäufer") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardBarTile,
    },
});
