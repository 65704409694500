import { $t } from "@/app/i18n";
import { RowKey, RowLabel } from "@/app/pages/reporting/rowUtils";
import {
    getOngoingTimeSlotKey,
    getOngoingTimeSlotLabelByKey,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";

export interface WithBeginOngoingTimeSlot {
    readonly beginOngoingTimeSlot: string;
}

export function withBeginOngoingTimeSlot<T extends { begin: Date }>(
    row: T,
    ongoingTimeInterval: OngoingTimeInterval
): T & WithBeginOngoingTimeSlot {
    return {
        ...row,
        beginOngoingTimeSlot: getOngoingTimeSlotKey(row.begin, ongoingTimeInterval, userSession.timeZone)!,
    };
}

export interface MapExternalBdcCdrRowKeyToLabelContext {
    ongoingTimeInterval?: OngoingTimeInterval;
}

export function mapExternalBdcCdrRowKeyToRowLabel(
    key: RowKey,
    groupBy: string,
    context?: MapExternalBdcCdrRowKeyToLabelContext
): RowLabel {
    const { ongoingTimeInterval } = context ?? {};

    if (groupBy === "beginOngoingTimeSlot" && typeof key === "string" && ongoingTimeInterval) {
        return { label: getOngoingTimeSlotLabelByKey(key, ongoingTimeInterval, userSession.timeZone, "L") || "" };
    } else if (groupBy === "dealerId") {
        if (key === null) {
            return { label: $t("Ohne Standort") as string };
        } else if (typeof key === "string") {
            return { label: dealersStore.dealerById(key)?.name || ($t("Unbekannter Standort") as string) };
        }
    }

    return { label: $t("Unbekannt") as string };
}

export interface GetExternalBdcCdrRowDefaultKeysContext {
    readonly visibleDealerIds?: readonly string[];
}

export function getExternalBdcCdrRowDefaultKeys(
    groupBy: string,
    context?: GetExternalBdcCdrRowDefaultKeysContext
): RowKey[] {
    const { visibleDealerIds } = context ?? {};

    if (groupBy === "beginOngoingTimeSlot") {
        return [];
    } else if (groupBy === "dealerId" && visibleDealerIds) {
        return dealersStore.dealers.map((u) => u.id).filter((id) => visibleDealerIds?.includes(id));
    }

    return [];
}
