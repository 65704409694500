
import {
    calculateAxisMaxValue,
    EChartsDatazoomEvent,
    EChartsLegendSelectChangedEvent,
    EChartsMouseEvent,
    formatAxisValueLabel,
    formatTooltipLabel,
    getDatazoomStartIndex,
    getSliderWindow,
} from "./eChartsUtils";
import { getColoredChartSeries } from "./reportingChart";
import { renderTooltip, ReportingChartTooltipCache } from "./reportingChartTooltip";
import {
    getDataPoints,
    ReportingTimeSeriesChartSeries,
    ReportingTimeSeriesChartSeriesData,
    ReportingTimeSeriesChartSeriesDataPoint,
    ReportingTimeSeriesChartSeriesValueFormatter,
    ReportingTimeSeriesDateFormatter,
} from "./reportingTimeSeriesChart";
import { userSession } from "@/store/userSession";
import { formatLocalDate, getDate } from "@/util/dateTimeUtils";
import {
    DefaultLabelFormatterCallbackParams,
    EChartsOption,
    LineSeriesOption,
    XAXisComponentOption as XAXisOption,
    YAXisComponentOption as YAXisOption,
} from "echarts";
import Vue from "vue";

type AxisOption = XAXisOption | YAXisOption;

interface DateAxisSlider {
    startIndex?: number;
}

interface LineSeriesOptionDataValue {
    readonly value: number | undefined;
    readonly isPercentage: boolean;
    readonly onClick?: () => Promise<void> | void;
    readonly formatter?: ReportingTimeSeriesChartSeriesValueFormatter;
}
const STACK_MARKER = "MAIN_STACK";

export default Vue.extend({
    props: {
        absoluteMax: {
            type: Number,
            required: false,
        },
        dateAxisScroll: {
            type: Boolean,
            default: false,
        },
        dateAxisScrollWindowPosition: {
            type: String as () => "START" | "CENTER" | "END",
            default: "START",
        },
        dateAxisScrollWindowSize: {
            type: Number,
            required: false,
        },
        dateFormatter: {
            type: Function,
            required: false,
        },
        fillArea: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            required: false,
        },
        hideLegend: {
            type: Boolean,
            default: false,
        },
        hideValueAxis: {
            type: Boolean,
            default: false,
        },
        percentageMax: {
            type: Number,
            required: false,
        },
        series: {
            type: Array as () => ReportingTimeSeriesChartSeries[],
            required: true,
        },
        seriesAdditionalTooltipDataTooltipHeaders: {
            type: Array as () => string[],
            required: false,
        },
        seriesDataTooltipHeader: {
            type: String,
            required: false,
        },
        stacked: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dateAxisSlider: {} as DateAxisSlider, // enforce non-reactive attributes
            eChartGridLeftMargin: 0,
            eChartGridRightMargin: 20,
            eChartGridTopMargin: 25,
            selected: this.series.filter((s) => s.selected !== false).map((s) => s.name),
            tooltipCache: {} as ReportingChartTooltipCache,
            width: 0,
        };
    },

    computed: {
        dateAxisScrollStart(): number {
            if (this.dateAxisScrollWindowPosition === "START") {
                return 0;
            } else if (this.dateAxisScrollWindowPosition === "CENTER") {
                return Math.max(0, Math.floor((this.dataPoints.length - this.eChartDateAxisScrollWindowSize) / 2));
            } else if (this.dateAxisScrollWindowPosition === "END") {
                return Math.max(0, this.dataPoints.length - this.eChartDateAxisScrollWindowSize);
            } else {
                return 0;
            }
        },

        dataPoints(): Date[] {
            return getDataPoints(this.series);
        },

        eChartDateAxisScrollWindowSize(): number {
            if (this.dateAxisScrollWindowSize !== undefined) {
                return Math.max(2, this.dateAxisScrollWindowSize);
            }

            return Math.max(2, this.eChartGridWidth * 5);
        },

        eChartHeight(): number {
            if (this.height) {
                return this.height;
            } else {
                return 500;
            }
        },

        eChartGridBottomMargin(): number {
            return this.showDateAxisSlider ? (this.hideLegend ? 30 : 50) : this.hideLegend ? 0 : 30;
        },

        eChartGridWidth(): number {
            return Math.max(0, this.width - this.eChartGridLeftMargin - this.eChartGridRightMargin);
        },

        eChartsOption(): EChartsOption {
            const options: EChartsOption = {
                animation: false,
                grid: {
                    top: `${this.eChartGridTopMargin}px`,
                    left: `${this.eChartGridLeftMargin}px`,
                    right: `${this.eChartGridRightMargin}px`,
                    bottom: `${this.eChartGridBottomMargin}px`,
                    containLabel: true,
                },
                xAxis: this.eChartXAXisOptions,
                yAxis: this.eChartYAXisOptions,
                tooltip: {
                    trigger: "axis",
                    formatter: (params: any) => {
                        return this.renderTooltip(params as DefaultLabelFormatterCallbackParams[]) ?? "";
                    },
                },
                axisPointer: {},
                legend: {
                    show: !this.hideLegend,
                    type: "scroll",
                    top: "bottom",
                    height: "30px",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "11px",
                    },
                    selected: this.series.reduce((selected, s) => {
                        selected[s.name] = this.isSeriesSelected(s.name);
                        return selected;
                    }, {} as { [key: string]: boolean }),
                    tooltip: {
                        show: true,
                        align: "center",
                        formatter: (params: any) => this.series.find((s) => s.name === params.name)?.tooltip ?? "",
                    },
                },
                series: this.eChartSeries,
            };

            if (this.showDateAxisSlider) {
                const sliderWindow = getSliderWindow(
                    this.dateAxisSlider.startIndex ?? this.dateAxisScrollStart,
                    this.dataPoints.length,
                    this.eChartDateAxisScrollWindowSize
                );

                options.dataZoom = {
                    name: "dateAxisSlider",
                    type: "slider",
                    dataBackground: {
                        lineStyle: { opacity: 0, width: 0 },
                        areaStyle: { opacity: 0 },
                    },
                    selectedDataBackground: {
                        lineStyle: { opacity: 0, width: 0 },
                        areaStyle: { opacity: 0 },
                    },
                    handleStyle: { opacity: 0 },
                    labelFormatter: "",
                    filterMode: "filter",
                    startValue: sliderWindow.startIndex,
                    endValue: sliderWindow.endIndex,
                    zoomLock: true,
                    rangeMode: ["value", "value"],
                    brushSelect: false,
                    height: 15,
                    bottom: this.hideLegend ? 5 : 30,
                };
            }

            return options;
        },

        eChartSeries(): LineSeriesOption[] {
            return getColoredChartSeries(this.series).map((series: ReportingTimeSeriesChartSeries) => {
                const lineSeriesOption: LineSeriesOption = {
                    name: series.name,
                    type: "line",
                    yAxisId: series.data.isPercentage ? "percentage-axis" : "absolute-axis",
                    silent: !series.data.values.some((v) => v.onClick),
                    data: this.dataPoints.map((date) => {
                        const v = series.data.values.find((v) => v.date.getTime() === date.getTime());

                        return {
                            ...this.getLineSeriesOptionDataValue(v, series.data),
                            additionalTooltipValues: (series.additionalTooltipData ?? []).map((adtData) =>
                                this.getLineSeriesOptionDataValue(
                                    adtData.values.find((v) => v.date.getTime() === date.getTime()),
                                    adtData
                                )
                            ),
                            label: {
                                show: !!v?.value,
                            },
                        };
                    }),
                    label: {
                        show: true,
                        color: "#ffffff",
                        backgroundColor: series.color + "AA",
                        borderRadius: 2,
                        padding: [2, 4, 2, 4],
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontSize: "12px",
                        fontWeight: "bold",
                        textBorderColor: "#999999",
                        textBorderWidth: 2,
                        formatter: (params: any) => {
                            const value = params?.value;

                            if (typeof value !== "number") {
                                return "";
                            }

                            const customLabel = series.data.formatter
                                ? series.data.formatter(value, !!series.data.isPercentage, "LABEL")
                                : null;

                            return customLabel ?? formatTooltipLabel(value, !!series.data.isPercentage);
                        },
                    },
                    color: series.color,
                    stack: this.stacked ? STACK_MARKER : undefined,
                };

                if (this.fillArea) {
                    lineSeriesOption.areaStyle = {
                        color: {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                { offset: 0, color: series.color! + "88" },
                                { offset: 1, color: series.color! + "22" },
                            ],
                            global: false,
                        },
                    };
                }

                return lineSeriesOption;
            });
        },

        eChartXAXisOptions(): XAXisOption {
            return {
                type: "category",
                data: this.dataPoints.map((date) => this.formatDate(date, true)),
                boundaryGap: false,
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "11px",
                },
            };
        },

        eChartYAXisOptions(): YAXisOption[] {
            const axis: YAXisOption[] = [];

            // has absolute series
            if (this.series.some((series) => !series.data.isPercentage)) {
                axis.push(this.getValueAxisOption(false) as YAXisOption);
            }

            // has percentage series
            if (this.series.some((series) => !!series.data.isPercentage)) {
                axis.push(this.getValueAxisOption(true) as YAXisOption);
            }

            return axis.map((a, index) => ({
                ...a,
                alignTicks: index === 0 || undefined,
            }));
        },

        showDateAxisSlider(): boolean {
            return this.dateAxisScroll && this.eChartDateAxisScrollWindowSize < this.dataPoints.length;
        },
    },

    methods: {
        formatDate(date: Date, short: boolean): string {
            if (this.dateFormatter) {
                return (this.dateFormatter as ReportingTimeSeriesDateFormatter)(date, short);
            } else {
                return formatLocalDate(getDate(date, userSession.timeZone), userSession.locale, short ? "S" : "L");
            }
        },

        getChartAsPngDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsPngDataUrl();
        },

        getChartAsSvgDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsSvgDataUrl();
        },

        getLineSeriesOptionDataValue(
            v: ReportingTimeSeriesChartSeriesDataPoint | undefined,
            data: ReportingTimeSeriesChartSeriesData
        ): LineSeriesOptionDataValue {
            const roundFactor = data.isPercentage ? 10000 : 100;

            return {
                value: v?.value === undefined ? null! : Math.round(roundFactor * v.value) / roundFactor,
                isPercentage: !!data.isPercentage,
                onClick: v?.onClick,
                formatter: data.formatter,
            };
        },

        getValueAxisOption(isPercentage: boolean): AxisOption {
            return {
                id: isPercentage ? "percentage-axis" : "absolute-axis",
                show: !this.hideValueAxis,
                type: "value",
                axisLabel: {
                    formatter: (value: number) => formatAxisValueLabel(value, isPercentage),
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "11px",
                },
                max: (extent: { min: number; max: number }) =>
                    calculateAxisMaxValue(
                        extent,
                        isPercentage ? this.percentageMax : this.absoluteMax,
                        isPercentage
                    ) as number, // type is incorrect, prop accepts null
            };
        },

        isSeriesSelected(seriesName: string): boolean {
            return this.selected.includes(seriesName);
        },

        onDatazoom(e: EChartsDatazoomEvent) {
            this.dateAxisSlider.startIndex = getDatazoomStartIndex(e, this.dataPoints.length);
        },

        onLegendSelectChanged(e: EChartsLegendSelectChangedEvent) {
            this.selected = Object.keys(e.selected).filter((name) => e.selected[name]);
        },

        async onValueClick(e: EChartsMouseEvent<LineSeriesOptionDataValue>) {
            if (e.componentType !== "series" || e.seriesType !== "line" || !e.data.onClick) {
                return;
            }

            await e.data.onClick();
        },

        renderTooltip(series: DefaultLabelFormatterCallbackParams[]): string | null {
            if (!series.length) {
                return null;
            }

            const tooltipSeries = series.map((s) => {
                const dataValue = s.data as LineSeriesOptionDataValue;
                const additionalTooltipValues = (s.data as any).additionalTooltipValues as LineSeriesOptionDataValue[];
                const stack = (s.data as any).stack as string | null;

                return {
                    name: s.seriesName!,
                    marker: s.marker! as string,
                    value: {
                        value: dataValue.value,
                        isPercentage: dataValue.isPercentage,
                        formatter: (value: number | undefined, isPercentage: boolean) =>
                            dataValue.formatter ? dataValue.formatter(value, isPercentage, "TOOLTIP") : null,
                    },
                    additionalTooltipValues: additionalTooltipValues.map((adtValue) => ({
                        value: adtValue.value,
                        isPercentage: adtValue.isPercentage,
                        formatter: (value: number | undefined, isPercentage: boolean) =>
                            adtValue.formatter ? adtValue.formatter(value, isPercentage, "TOOLTIP") : null,
                    })),
                    group: stack !== STACK_MARKER ? stack : null,
                };
            });

            return renderTooltip(
                {
                    additionalValueHeaders: this.seriesAdditionalTooltipDataTooltipHeaders,
                    series: tooltipSeries,
                    title: this.formatDate(this.dataPoints[series[0].dataIndex], false),
                    valueHeader: this.seriesDataTooltipHeader,
                },
                this.tooltipCache,
                this.$i18n
            );
        },

        resize(width: number) {
            this.width = width;
        },
    },

    watch: {
        dataPoints() {
            this.dateAxisSlider.startIndex = this.dateAxisScrollStart;
        },

        series(__, oldSeries: ReportingTimeSeriesChartSeries[]) {
            const oldSeriesNames = oldSeries.map((s) => s.name);

            this.selected = this.series
                .filter(
                    (s) => this.selected.includes(s.name) || (s.selected !== false && !oldSeriesNames.includes(s.name))
                )
                .map((s) => s.name);
        },
    },

    components: {
        LazyEChart: () => import("./LazyEChart.vue"),
    },
});
