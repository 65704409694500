import { SentimentType as CaseSentimentType } from "@/api/caseSearch";
import { CaseStatus } from "@/api/cases";
import { OpportunityStatus } from "@/api/opportunities";
import { SentimentType as OpportunitySentimentType } from "@/api/opportunitySearch";

export const ISSUE_STATUS_OPEN_COLOR = "#ff5252";
export const ISSUE_STATUS_ASSIGNED_COLOR = "#fb8c00";
export const ISSUE_STATUS_POSTPONED_COLOR = "#2196f3";
export const ISSUE_STATUS_CLOSED_COLOR = "#c0c0c0";
export const ISSUE_STATUS_TOTAL_COLOR = "#666666";

export const ISSUE_OUTCOME_WON_COLOR = "#4caf50";
export const ISSUE_OUTCOME_LOST_COLOR = "#c0c0c0";

export const CALL_COLOR = "#2E93fA";
export const EMAIL_COLOR = "#C0CA33";
export const SMS_COLOR = "#546E7A";
export const WHATSAPP_MESSAGE_COLOR = "#4caf50";

export const SENTIMENT_POSITIVE = "#4caf50";
export const SENTIMENT_NEUTRAL = "#C0CA33";
export const SENTIMENT_NEGATIVE = "#ff5252";
export const SENTIMENT_UNKNOWN = "#c0c0c0";

export const OTHER_COLOR = "#666666";
export const TOTAL_COLOR = "#666666";

export function getCaseSentimentTypeColor(sentimentType: CaseSentimentType): string | undefined {
    return sentimentType === CaseSentimentType.POSITIVE
        ? SENTIMENT_POSITIVE
        : sentimentType === CaseSentimentType.NEUTRAL
        ? SENTIMENT_NEUTRAL
        : sentimentType === CaseSentimentType.NEGATIVE
        ? SENTIMENT_NEGATIVE
        : sentimentType === CaseSentimentType.UNKNOWN
        ? SENTIMENT_UNKNOWN
        : undefined;
}

export function getCaseStatusColor(caseStatus: CaseStatus | null): string | undefined {
    return caseStatus === CaseStatus.OPEN
        ? ISSUE_STATUS_OPEN_COLOR
        : caseStatus === CaseStatus.ASSIGNED
        ? ISSUE_STATUS_ASSIGNED_COLOR
        : caseStatus === CaseStatus.POSTPONED
        ? ISSUE_STATUS_POSTPONED_COLOR
        : caseStatus === CaseStatus.CLOSED
        ? ISSUE_STATUS_CLOSED_COLOR
        : undefined;
}

export function getOpportunitySentimentTypeColor(sentimentType: OpportunitySentimentType | null): string | undefined {
    return sentimentType === OpportunitySentimentType.POSITIVE
        ? SENTIMENT_POSITIVE
        : sentimentType === OpportunitySentimentType.NEUTRAL
        ? SENTIMENT_NEUTRAL
        : sentimentType === OpportunitySentimentType.NEGATIVE
        ? SENTIMENT_NEGATIVE
        : sentimentType === OpportunitySentimentType.UNKNOWN
        ? SENTIMENT_UNKNOWN
        : undefined;
}

export function getOpportunityStatusColor(
    opportunityStatus: OpportunityStatus | null,
    outcome?: boolean | null
): string | undefined {
    return opportunityStatus === OpportunityStatus.OPEN
        ? ISSUE_STATUS_OPEN_COLOR
        : opportunityStatus === OpportunityStatus.ASSIGNED
        ? ISSUE_STATUS_ASSIGNED_COLOR
        : opportunityStatus === OpportunityStatus.POSTPONED
        ? ISSUE_STATUS_POSTPONED_COLOR
        : opportunityStatus === OpportunityStatus.CLOSED
        ? outcome === undefined || outcome === null
            ? ISSUE_STATUS_CLOSED_COLOR
            : outcome
            ? ISSUE_OUTCOME_WON_COLOR
            : ISSUE_OUTCOME_LOST_COLOR
        : undefined;
}
