import { OpportunityChannel, OpportunityStatus } from "@/api/opportunities";
import { IssueActivitySummary, IssueAssignee } from "@/api/reporting";
import { $t } from "@/app/i18n";
import { getSentimentType } from "@/app/pages/opportunities/opportunitySearchUtils";
import {
    getIssueRowDefaultKeys,
    GetIssueRowDefaultKeysContext,
    mapIssueRowKeyToLabel,
    MapIssueRowKeyToLabelContext,
    PotentiallyErroneous,
    WithAssignee,
    WithAssigneeWaitingTimeUntilFirstReaction,
    withAssigneeWaitingTimeUntilFirstReaction as withAssigneeWaitingTimeUntilFirstReactionByIssueRow,
    WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally,
    withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally as withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyByIssueRow,
    WithSentimentType,
    withWaitingTimeUntilFirstReaction as withWaitingTimeUntilFirstReactionByIssueRow,
    WithWaitingTimeUntilFirstReaction,
    withWaitingTimeUntilFirstReactionAsPerceivedExternally as withWaitingTimeUntilFirstReactionAsPerceivedExternallyByIssueRow,
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "@/app/pages/reporting/issueRowUtils";
import { RowKey, RowLabel } from "@/app/pages/reporting/rowUtils";
import { dealersStore } from "@/store/dealers";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { reportingSettingsStore } from "@/store/reportingSettingsStore";

/*
 * helper
 */

export function filterConsiderOpportunityInReports(row: { outcomeReasonId: string | null }): boolean {
    return (
        row.outcomeReasonId === null ||
        !reportingSettingsStore.reportingSettings.ignoreOpportunityOutcomeReasonIds.includes(row.outcomeReasonId)
    );
}

/*
 * with assignee waiting time until first reaction
 */

export function withAssigneeWaitingTimeUntilFirstReaction<
    T extends { dealerId: string; activitySummary: IssueActivitySummary | null } & WithAssignee
>(rows: readonly T[]): readonly (T & WithAssigneeWaitingTimeUntilFirstReaction<PotentiallyErroneous>)[] {
    return withAssigneeWaitingTimeUntilFirstReactionByIssueRow(
        rows,
        (settings) => settings.opportunityReactionTimeOfficeHoursId
    );
}

/*
 * with assignee waiting time until first reaction as perceived externally
 */

export function withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<
    T extends { dealerId: string; created: Date } & WithAssignee
>(
    rows: readonly T[]
): readonly (T & WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>)[] {
    return withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyByIssueRow(
        rows,
        (settings) => settings.opportunityReactionTimeAsPerceivedExternallyOfficeHoursId
    );
}

/*
 * with opportunity status
 */

export interface WithOpportunityStatus {
    readonly opportunityStatus: OpportunityStatus;
}

export function withOpportunityStatus<
    T extends { created: Date; closed: Date | null; postponedUntil: Date | null; assignees: IssueAssignee[] }
>(row: T): T & WithOpportunityStatus {
    return {
        ...row,
        opportunityStatus: row.closed
            ? OpportunityStatus.CLOSED
            : row.postponedUntil
            ? OpportunityStatus.POSTPONED
            : row.assignees.length
            ? OpportunityStatus.ASSIGNED
            : OpportunityStatus.OPEN,
    };
}

/*
 * with opportunity team receiver
 */

export interface WithOpportunityTeamReceiverId {
    readonly opportunityTeamReceiverId: string | null;
}

export function withOpportunityTeamReceiverIdSetToNull<T extends { opportunityTeamReceivers: string[] }>(
    row: T
): T & WithOpportunityTeamReceiverId {
    return { ...row, opportunityTeamReceiverId: null };
}

export function splitWithOpportunityTeamReceiverId<T extends { opportunityTeamReceivers: string[] }>(
    rows: readonly T[]
): readonly (T & WithOpportunityTeamReceiverId)[] {
    return rows
        .map((r) =>
            (!!r.opportunityTeamReceivers.length ? r.opportunityTeamReceivers : ([null] as (string | null)[])).map(
                (opportunityTeamReceiverId) => ({
                    ...r,
                    opportunityTeamReceiverId,
                })
            )
        )
        .reduce((prev, cur) => prev.concat(cur), []);
}

/*
 * with sentiment type
 */

export function withSentimentType<T extends { sentiment: number | null }>(row: T): T & WithSentimentType {
    return {
        ...row,
        sentimentType: getSentimentType(row.sentiment),
    };
}

/*
 * with waiting time until first reaction
 */

export function withWaitingTimeUntilFirstReaction<
    T extends { dealerId: string; activitySummary: IssueActivitySummary | null }
>(rows: readonly T[]): readonly (T & WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>)[] {
    return withWaitingTimeUntilFirstReactionByIssueRow(
        rows,
        (settings) => settings.opportunityReactionTimeOfficeHoursId
    );
}

/*
 * with waiting time until first reaction as perceived externally
 */

export function withWaitingTimeUntilFirstReactionAsPerceivedExternally<
    T extends { dealerId: string; created: Date; activitySummary: IssueActivitySummary | null }
>(rows: readonly T[]): readonly (T & WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>)[] {
    return withWaitingTimeUntilFirstReactionAsPerceivedExternallyByIssueRow(
        rows,
        (settings) => settings.opportunityReactionTimeAsPerceivedExternallyOfficeHoursId
    );
}

/*
 * misc
 */

export type MapOpportunityRowKeyToLabelContext = MapIssueRowKeyToLabelContext;

export function mapOpportunityRowKeyToRowLabel(
    key: RowKey,
    groupBy: string,
    context?: MapOpportunityRowKeyToLabelContext
): RowLabel {
    if (groupBy === "channel" && typeof key === "string") {
        return { label: $t(`enum.OpportunityChannel.${key}`) as string };
    } else if (groupBy === "opportunityStatus" && typeof key === "string") {
        return { label: $t(`enum.OpportunityStatus.${key}`) as string };
    } else if (groupBy === "opportunityTeamReceiverId") {
        if (key === null) {
            return { label: $t("Ohne Verkäuferteam") as string };
        } else if (typeof key === "string") {
            const opportunityTeam = opportunityTeamsStore.getOpportunityTeamById(key);

            if (!opportunityTeam) {
                return { label: $t("Unbekanntes Verkäuferteam") as string };
            }

            const dealer = dealersStore.dealerById(opportunityTeam.dealerId);

            if (!dealer) {
                return { label: opportunityTeam.name };
            }

            return { label: opportunityTeam.name, sublabel: dealer.name };
        }
    } else if (groupBy === "outcome") {
        if (key === null) {
            return { label: $t("Ungeschlossene Verkaufschance") as string };
        } else if (key === true) {
            return { label: $t("Erfolgreiche Verkaufschance") as string };
        } else if (key === false) {
            return { label: $t("Erfolglose Verkaufschance") as string };
        }
    } else if (groupBy === "sourceId" && typeof key === "string") {
        return { label: opportunitySourcesStore.getOpportunitySourceById(key)?.name || "" };
    }

    return mapIssueRowKeyToLabel(key, groupBy, context);
}

export type GetOpportunityRowDefaultKeysContext = GetIssueRowDefaultKeysContext & {
    readonly visibleChannels?: readonly string[];
};

export function getOpportunityRowDefaultKeys(groupBy: string, context?: GetOpportunityRowDefaultKeysContext): RowKey[] {
    const { visibleChannels, visibleDealerIds } = context ?? {};

    if (groupBy === "channel" && visibleChannels) {
        return Object.keys(OpportunityChannel).filter((c) => visibleChannels.includes(c));
    } else if (groupBy === "opportunityStatus") {
        return Object.keys(OpportunityStatus);
    } else if (groupBy === "opportunityTeamReceiverId") {
        return opportunityTeamsStore.opportunityTeams
            .filter((t) => visibleDealerIds?.includes(t.dealerId))
            .map((t) => t.id);
    } else if (groupBy === "outcome") {
        return [true, false];
    } else if (groupBy === "sourceId") {
        return opportunitySourcesStore.opportunitySources.map((s) => s.id);
    }

    return getIssueRowDefaultKeys(groupBy, context);
}
