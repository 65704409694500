import { cloneObject } from "@/util/cloneUtils";
import { DeepMutable } from "@/util/types";

export interface RowGroup<K, T> {
    readonly key: K;
    readonly rows: readonly T[];
}

function keyToString<K>(key: K): string {
    return JSON.stringify(key);
}

export function groupRowsBy<K, T>(rows: readonly T[], keyMapper: (r: T) => K): RowGroup<K, T>[] {
    const map = new Map<string, DeepMutable<RowGroup<K, T>>>();

    for (const row of rows) {
        const key = keyMapper(row);
        const keyAsString = keyToString(key);

        if (map.has(keyAsString)) {
            map.get(keyAsString)!.rows.push(row);
        } else {
            map.set(keyAsString, {
                key: typeof key === "object" ? Object.freeze(cloneObject(key)) : key,
                rows: [row],
            });
        }
    }

    return [...map.values()].map(
        (rowGroup) =>
            ({
                ...rowGroup,
                rows: Object.freeze(rowGroup.rows),
            } as RowGroup<K, T>)
    );
}

export function count<A, K, T, V>(rowGroups: readonly RowGroup<K, T>[], predicate: (row: T) => boolean): number[] {
    return rowGroups.map((rowGroup) => rowGroup.rows.filter((r) => predicate(r)).length);
}

export function applyKeySort<G extends RowGroup<K, T>, T, K>(rowGroups: readonly G[], sortedKeys: readonly K[]): G[] {
    const sortedKeyStrings = new Set(sortedKeys.map(keyToString));

    return [
        ...[...sortedKeyStrings]
            .map((keyString) => rowGroups.find((rowGroup) => keyToString(rowGroup.key) === keyString))
            .filter((rowGroup): rowGroup is G => !!rowGroup),
        ...rowGroups.filter((rowGroup) => !sortedKeyStrings.has(keyToString(rowGroup.key))),
    ];
}

export function addMissingRowGroups<T, K>(
    rowGroups: readonly RowGroup<K, T>[],
    requiredKeys: readonly K[]
): RowGroup<K, T>[] {
    const existingKeys = new Set(rowGroups.map((rowGroup) => keyToString(rowGroup.key)));
    const missingKeys = requiredKeys.filter((key) => !existingKeys.has(keyToString(key)));

    return [...rowGroups, ...missingKeys.map((key) => ({ key, rows: Object.freeze([]) }))];
}
