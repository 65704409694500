
import {
    ExtendedIncomingCallRow,
    IncomingCallReportPageFilter,
    IncomingCallReportPageResult,
} from "./incomingCallReportPage";
import { EMPTY_INCOMING_CALL_ROW_SEARCH_REQUEST } from "./incomingCallRowSearchUtils";
import { withLastIntermediateInternalIncomingPhoneNumberId, withWasAccepted } from "./incomingCallRowUtils";
import { incomingPhoneNumbersApi, InternalIncomingPhoneNumber } from "@/api/incomingPhoneNumbers";
import { IncomingCallRow, IssueType, reportingApi } from "@/api/reporting";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import DealerPicker from "@/app/pages/DealerPicker.vue";
import ReportPage from "@/app/pages/reporting/ReportPage.vue";
import {
    withCreatedOngoingTimeSlot,
    withCreatedRecurringTimeSlot,
    withIssueType,
} from "@/app/pages/reporting/activityRowUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { canReportAllDealers, getReportableDealerIds } from "@/app/pages/reporting/reportingPermissionUtils";
import { OngoingTimeInterval, RecurringTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        evaluationType: {
            type: Number,
            required: false,
        },
        evaluationTypeOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        groupBy: {
            type: String,
            required: false,
        },
        groupByOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        loadForwarding: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        noData: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: Object,
            required: false,
        },
        sortByOptions: {
            type: Array as () => SelectOptions,
            required: false,
            default: () => [],
        },
        timeInterval: {
            type: String as () => OngoingTimeInterval | RecurringTimeInterval,
            required: false,
        },
        timeIntervals: {
            type: Array as () => OngoingTimeInterval[] | RecurringTimeInterval[],
            required: false,
            default: () => [],
        },
        timeRange: {
            type: Object as () => TimeRange,
            required: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
        withCreatedOngoingTimeSlot: {
            type: String as () => OngoingTimeInterval | null,
            default: null,
        },
        withCreatedRecurringTimeSlot: {
            type: String as () => RecurringTimeInterval | null,
            default: null,
        },
        withWasAccepted: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        const ts = now();

        return {
            internalIncomingPhoneNumbers: [] as readonly InternalIncomingPhoneNumber[],
            IssueType,
            loadFilter: {
                dealerIds: [] as string[],
                createdRange: {
                    from: this.timeRange ? this.timeRange.from : getDate(ts, this.timeZone, -6),
                    to: this.timeRange ? this.timeRange.to : getDate(ts, this.timeZone),
                } as DateRange,
            },
            loadingRows: false,
            loadLimiter: new ActionLimiter(true),
            localFilter: {
                issueTypes: [] as IssueType[],
                acceptorUserIds: [] as string[],
                internalIncomingPhoneNumberIds: [] as string[],
            },
            nowAtLoadRows: null as Date | null,
            rows: [] as readonly IncomingCallRow[],
            searchId: 0,
        };
    },

    computed: {
        acceptorUserOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        canReportAllDealers(): boolean {
            return canReportAllDealers();
        },

        computedRows(): readonly ExtendedIncomingCallRow[] {
            return this.rows
                .map((r) =>
                    this.withCreatedOngoingTimeSlot
                        ? withCreatedOngoingTimeSlot(r, this.withCreatedOngoingTimeSlot, this.timeZone)
                        : r
                )
                .map((r) =>
                    this.withCreatedRecurringTimeSlot
                        ? withCreatedRecurringTimeSlot(r, this.withCreatedRecurringTimeSlot, this.timeZone)
                        : r
                )
                .map((r) =>
                    this.withWasAccepted ? withWasAccepted(withLastIntermediateInternalIncomingPhoneNumberId(r)) : r
                )
                .map(withIssueType);
        },

        currentDate(): string | null {
            return this.nowAtLoadRows ? getDate(this.nowAtLoadRows, this.timeZone) : null;
        },

        filter(): IncomingCallReportPageFilter {
            return {
                ...{ ...this.loadFilter, createdRange: undefined },
                createdFrom: this.loadFilter.createdRange.from,
                createdTo: this.loadFilter.createdRange.to,
                ...this.localFilter,
            };
        },

        filteredRows(): readonly ExtendedIncomingCallRow[] {
            return this.computedRows
                .filter(
                    (row) => !this.localFilter.issueTypes.length || this.localFilter.issueTypes.includes(row.issueType)
                )
                .filter(
                    (row) =>
                        !this.localFilter.acceptorUserIds.length ||
                        (row.acceptorUserId !== null && this.localFilter.acceptorUserIds.includes(row.acceptorUserId))
                )
                .filter(
                    (row) =>
                        !this.localFilter.internalIncomingPhoneNumberIds.length ||
                        this.localFilter.internalIncomingPhoneNumberIds.includes(row.internalIncomingPhoneNumberId)
                );
        },

        internalIncomingPhoneNumberOptions(): SelectOption[] {
            return this.internalIncomingPhoneNumbers
                .map((number) => {
                    const formattedNumber = parseAndFormatNumber(number.number, "INTERNATIONAL");

                    return {
                        value: number.id,
                        text:
                            !!formattedNumber && !!number.name
                                ? `${formattedNumber} (${number.name})`
                                : formattedNumber ?? number.name ?? "",
                    };
                })
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        reportableDealerIds(): string[] {
            return getReportableDealerIds();
        },
    },

    methods: {
        async loadRows() {
            this.nowAtLoadRows = now();
            this.rows = [];
            const searchId = ++this.searchId;
            this.loadingRows = true;

            await this.loadLimiter.execute(async () => {
                try {
                    const createdFrom = toDateObject(this.timeZone, this.loadFilter.createdRange.from);
                    const createdTo = toDateObject(this.timeZone, this.loadFilter.createdRange.to, 1);

                    const [internalIncomingPhoneNumbers, rows] = await Promise.all([
                        incomingPhoneNumbersApi.getAllInternal(),
                        reportingApi.incomingCallRows({
                            ...EMPTY_INCOMING_CALL_ROW_SEARCH_REQUEST,
                            dealerIds: this.loadFilter.dealerIds,
                            createdFrom,
                            createdTo,
                            includeForwarding: this.loadForwarding,
                        }),
                    ]);

                    const sortedRows = rows.sort((a, b) => b.created.getTime() - a.created.getTime());

                    if (searchId === this.searchId) {
                        this.internalIncomingPhoneNumbers = Object.freeze(internalIncomingPhoneNumbers);
                        this.rows = Object.freeze(sortedRows);
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loadingRows = false;
                    }
                }
            });
        },

        updateTimeRange(timeRange: TimeRange): void {
            this.loadFilter.createdRange = { from: timeRange.from, to: timeRange.to };
        },
    },

    watch: {
        filteredRows() {
            this.$emit("loaded", {
                filter: { ...this.filter },
                rows: [...this.filteredRows],
                nowAtLoadRows: this.nowAtLoadRows,
                defaultKeysContext: {
                    visibleAcceptorUserIds: this.filter.acceptorUserIds.length
                        ? this.filter.acceptorUserIds
                        : usersStore.users.map((u) => u.id),
                    visibleDealerIds: this.filter.dealerIds.length
                        ? this.filter.dealerIds
                        : dealersStore.dealers.map((d) => d.id),
                    visibleIssueTypes: this.filter.issueTypes.length ? this.filter.issueTypes : Object.keys(IssueType),
                },
                mapKeyToLabelContext: {
                    internalIncomingPhoneNumbers: this.internalIncomingPhoneNumbers,
                },
            } as IncomingCallReportPageResult);
        },

        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadRows();
    },

    components: {
        DAutocomplete,
        DateRangePicker,
        DealerPicker,
        EnumField,
        ReportPage,
    },
});
