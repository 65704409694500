
import CaseReportPage from "./CaseReportPage.vue";
import { CaseReportPageResult, ExtendedCaseRow } from "./caseReportPage";
import {
    getCaseRowDefaultKeys,
    mapCaseRowKeyToRowLabel,
    splitWithCaseEscalationGroupReceiverId,
    WithCaseEscalationGroupReceiverId,
    withCaseEscalationGroupReceiverIdSetToNull,
    WithCaseTypeGroup,
} from "./caseRowUtils";
import { SentimentType } from "@/api/caseSearch";
import { CaseStatus } from "@/api/cases";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import ReportingBarChartCard from "@/app/pages/reporting/ReportingBarChartCard.vue";
import { BarChartSeriesOptions, getReportingBarChartForHeaderGroup } from "@/app/pages/reporting/chartUtils";
import { ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { getCaseSentimentTypeColor } from "@/app/pages/reporting/colors";
import {
    isWithProcessingTimeIndividualDurationSlotErrorless,
    PotentiallyErroneous,
    PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS,
    splitWithAssignee,
    WithAssignee,
    withAssigneeSetToNull,
    WithCloser,
    WithCreatorActorType,
    WithProcessingTimeIndividualDurationSlot,
    WithSentimentType,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import {
    getSortByOptions,
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnEntries,
    ReportingTableItemColumnGroup,
    ReportingTableSortByOptions,
    ReportingTableSortByOptionValue,
    sort,
} from "@/app/pages/reporting/table/reportingTable";
import { IndividualDurationInterval } from "@/app/pages/reporting/timeInterval";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type PrecomputedProperties = WithCaseTypeGroup &
    WithCloser &
    WithCreatorActorType &
    WithProcessingTimeIndividualDurationSlot<PotentiallyErroneous> &
    WithSentimentType;

type ComputedCaseRow = ExtendedCaseRow<PrecomputedProperties> & WithAssignee & WithCaseEscalationGroupReceiverId;

type ComputedCaseRowGroupBy = keyof Pick<
    ComputedCaseRow,
    | "assigneeId"
    | "caseStatus"
    | "caseType"
    | "caseTypeGroup"
    | "channel"
    | "closer"
    | "creatorActorType"
    | "escalationGroupReceiverId"
    | "dealerId"
    | "processingTimeIndividualDurationSlot"
>;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedCaseRowGroupBy;
}

type ReportingTableHeaderGroupWithColumnGroupComputer<Options> = ReportingTableHeaderGroup<Options> & {
    readonly toColumnGroup: (
        rowGroupRows: readonly ComputedCaseRow[],
        totalRows: readonly ComputedCaseRow[]
    ) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
            evaluationType: EvaluationType.ABSOLUTE as EvaluationType,
            EvaluationType,
            groupBy: "channel" as ComputedCaseRowGroupBy,
            result: null as CaseReportPageResult<PrecomputedProperties> | null,
            sortBy: {
                groupIndex: 0,
                columnIndex: 0,
                direction: "DESC",
            } as ReportingTableSortByOptionValue,
        };
    },

    computed: {
        defaultKeys(): RowKey[] {
            return getCaseRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        filteredComputedCaseRows(): readonly ComputedCaseRow[] {
            let filteredRows: readonly ComputedCaseRow[] = (this.result?.rows ?? [])
                .filter((row) => this.groupBy !== "closer" || row.caseStatus === CaseStatus.CLOSED)
                .map(withAssigneeSetToNull)
                .map(withCaseEscalationGroupReceiverIdSetToNull);

            if (this.groupBy === "assigneeId") {
                filteredRows = splitWithAssignee(filteredRows);
            } else if (this.groupBy === "escalationGroupReceiverId") {
                filteredRows = splitWithCaseEscalationGroupReceiverId(filteredRows);
            }

            return filteredRows;
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "creatorActorType",
                    text: this.$t("Art des Erstellers"),
                },
                {
                    value: "assigneeId",
                    text: this.$t("Bearbeiter"),
                },
                {
                    value: "processingTimeIndividualDurationSlot",
                    text: this.$t("Bearbeitungsdauer"),
                },
                {
                    value: "escalationGroupReceiverId",
                    text: this.$t("Eskalationsgruppe"),
                },
                {
                    value: "caseTypeGroup",
                    text: this.$t("Fall-Kategorie"),
                },
                {
                    value: "caseType",
                    text: this.$t("Fall-Typ"),
                },
                {
                    value: "channel",
                    text: this.$t("Kanal"),
                },
                {
                    value: "closer",
                    text: this.$t("Schließer"),
                },
                {
                    value: "caseStatus",
                    text: this.$t("Status"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
            ];
        },

        groupByText(): string | null {
            return (this.groupByOptions.find((o) => o.value === this.groupBy)?.text ?? null) as string | null;
        },

        processingTimeSlots(): IndividualDurationInterval[] {
            return [...PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS];
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedCaseRow>[] {
            const filteredRows =
                this.groupBy === "processingTimeIndividualDurationSlot"
                    ? this.filteredComputedCaseRows.filter(isWithProcessingTimeIndividualDurationSlotErrorless)
                    : this.filteredComputedCaseRows;

            const rowGroups = groupRowsBy(filteredRows, (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapCaseRowKeyToRowLabel(rowGroup.key, this.groupBy, {
                    ...(this.result?.mapKeyToLabelContext ?? {}),
                    processingTimeSlots: this.processingTimeSlots,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        sentimentTypeChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 1);
        },

        sortByOptions(): ReportingTableSortByOptions {
            return getSortByOptions(this.tableHeaderGroups);
        },

        table(): ReportingTableData<BarChartSeriesOptions> | null {
            if (!this.tableData) {
                return null;
            }

            return {
                ...this.tableData,
                items: sort(this.tableData.items, this.sortBy),
            };
        },

        tableData(): ReportingTableData<BarChartSeriesOptions> | null {
            const allRows = this.filteredComputedCaseRows;

            if (!allRows.length) {
                return null;
            }

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => ({
                    title: rowGroup.title,
                    subtitle: rowGroup.subtitle,
                    groups: this.tableHeaderGroups.map((headerGroup) =>
                        headerGroup.toColumnGroup(rowGroup.rows, allRows)
                    ),
                })),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) => headerGroup.toColumnGroup(allRows, allRows)),
                },
                groupByHeaderText: this.groupByText ?? undefined,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer<BarChartSeriesOptions>[] {
            const sentimentTypes = [
                SentimentType.POSITIVE,
                SentimentType.NEUTRAL,
                SentimentType.NEGATIVE,
                SentimentType.UNKNOWN,
            ];

            return [
                {
                    text: null,
                    headers: [{ text: this.$t("Gesamt") as string }],
                    toColumnGroup: (rowGroupRows, allRows) => ({
                        columns: [
                            {
                                entries: this.toCountShareValueEntries(rowGroupRows, allRows),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Stimmung") as string,
                    headers: sentimentTypes.map((sentimentType) => ({
                        text: this.$t(`enum.SentimentType.${sentimentType}`) as string,
                        options: {
                            color: getCaseSentimentTypeColor(sentimentType),
                        },
                    })),
                    toColumnGroup: (rowGroupRows) => ({
                        columns: sentimentTypes.map((sentimentType) => ({
                            entries: this.toCountShareValueEntries(
                                rowGroupRows.filter((r) => r.sentimentType === sentimentType),
                                rowGroupRows
                            ),
                        })),
                    }),
                },
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },

        toCountShareValueEntries(
            countRows: readonly ComputedCaseRow[],
            totalRows: readonly ComputedCaseRow[]
        ): ReportingTableItemColumnEntries {
            const absoluteValue = { value: countRows.length };
            const percentageValue = {
                value: rate(countRows.length, totalRows.length),
                isPercentage: true,
            };

            const onClick = this.showBottomSheetOnClick(countRows.map((r) => r.id));

            return this.evaluationType === EvaluationType.PERCENTAGE
                ? [{ ...percentageValue, onClick }, absoluteValue]
                : [{ ...absoluteValue, onClick }, percentageValue];
        },
    },

    components: {
        CaseReportPage,
        CasesBottomSheet,
        ReportingBarChartCard,
        ReportTableCard,
    },
});
