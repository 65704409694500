
import { CaseStatus } from "@/api/cases";
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import { WithCaseStatus } from "@/app/pages/reporting/cases/caseRowUtils";
import ReportingDashboardTile from "@/app/pages/reporting/dashboard/ReportingDashboardTile.vue";
import { rate, renderPercentage } from "@/app/pages/reporting/reportingUtils";
import Vue from "vue";

type ComputedCaseRow = CaseRow & WithCaseStatus;

export default Vue.extend({
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        rows: {
            type: Array as () => ComputedCaseRow[],
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
            renderPercentage,
        };
    },

    computed: {
        closedRate(): number | undefined {
            return rate(this.closedRows.length, this.total);
        },

        closedRows(): ComputedCaseRow[] {
            return this.rows.filter((r) => r.caseStatus === CaseStatus.CLOSED);
        },

        showBottomSheetOnClick(): (() => void) | undefined {
            return () => {
                this.bottomSheetCaseIds = this.closedRows.map((r) => r.id);
                this.bottomSheetVisible = true;
            };
        },

        total(): number {
            return this.rows.length;
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardTile,
    },
});
