
import { OpportunityChannel, OpportunityStatus } from "@/api/opportunities";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { getColoredChartSeries } from "@/app/pages/reporting/charts/reportingChart";
import { OTHER_COLOR } from "@/app/pages/reporting/colors";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { WithOpportunityStatus } from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import { dealersStore } from "@/store/dealers";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import Vue from "vue";

type ComputedOpportunityRow = OpportunityRow & WithOpportunityStatus;

interface GroupByFilter {
    readonly id: string;
    readonly name: string | null;
    readonly predicate: (r: ComputedOpportunityRow) => boolean;
    readonly color?: string;
}

export default Vue.extend({
    props: {
        channels: {
            type: Array as () => OpportunityChannel[],
            default: () => [],
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        dealerIds: {
            type: Array as () => string[],
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        maxChannelsCount: {
            type: Number,
            default: 10,
        },
        maxSourcesCount: {
            type: Number,
            default: 10,
        },
        noChart: {
            type: Boolean,
            default: false,
        },
        rows: {
            type: Array as () => ComputedOpportunityRow[],
            required: true,
        },
        seriesGroupBy: {
            type: String as () => "CHANNEL" | "SOURCE",
            required: false,
        },
        seriesType: {
            type: String as () => "CHANNEL" | "SOURCE",
            required: true,
        },
        sourceIds: {
            type: Array as () => string[],
            default: () => [],
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        channelColors() {
            return getColoredChartSeries(
                Object.keys(OpportunityChannel).map((channel) => ({
                    id: channel,
                    name: channel,
                    color: undefined,
                }))
            );
        },

        chart(): ReportingBarChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    rows: rowGroup.rows,
                }))
                .map((c) => ({
                    ...c,
                    sortValue: c.rows.length,
                }))
                .filter((c) => c.sortValue || this.dealerIds.includes(c.categoryId))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: this.getGroupByFilters(this.seriesGroupBy)
                    .map((group) =>
                        this.getGroupByFilters(this.seriesType).map((type) => {
                            const basePredicate = (r: ComputedOpportunityRow) =>
                                group.predicate(r) && type.predicate(r);

                            const data = topCategories.map((c) => {
                                const allRows = c.rows.filter(basePredicate);

                                return {
                                    allRows,
                                    wonRows: allRows.filter((r) => r.outcome),
                                    lostRows: allRows.filter((r) => !r.outcome),
                                };
                            });

                            return {
                                id: `${group.id}-${type.id}`,
                                name: type.name ?? (this.$t("Unbekannt") as string),
                                data: {
                                    values: data.map((c) => ({
                                        value: c.wonRows.length,
                                        onClick: this.showBottomSheetOnClick(c.wonRows.map((r) => r.id)),
                                    })),
                                },
                                additionalTooltipData: [
                                    {
                                        values: data.map((c) => ({
                                            value: c.lostRows.length,
                                            onClick: this.showBottomSheetOnClick(c.lostRows.map((r) => r.id)),
                                        })),
                                    },
                                    {
                                        values: data.map((c) => ({
                                            value: rate(c.wonRows.length, c.allRows.length),
                                        })),
                                        isPercentage: true,
                                    },
                                ],
                                color: type.color,
                                group: group.name ?? undefined,
                            };
                        })
                    )
                    .reduce((prev, cur) => prev.concat(cur), []),
                seriesDataTooltipHeader: this.$t("Erfolgreich") as string,
                seriesAdditionalTooltipDataTooltipHeaders: [
                    this.$t("Erfolglos") as string,
                    this.$t("Conversion Rate") as string,
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        rowGroups(): readonly TitledRowGroup<string, ComputedOpportunityRow>[] {
            const filteredRows = this.rows.filter((row) => row.opportunityStatus === OpportunityStatus.CLOSED);

            const rowGroups = groupRowsBy(filteredRows, (r) => r.dealerId);

            return addMissingRowGroups(rowGroups, this.dealerIds).map((rowGroup) => ({
                ...rowGroup,
                title: dealersStore.dealerById(rowGroup.key)?.name || (this.$t("Unbekannter Standort") as string),
            }));
        },

        sourceColors() {
            return getColoredChartSeries(
                opportunitySourcesStore.opportunitySources.map((source) => ({
                    id: source.id,
                    name: source.name,
                    color: undefined,
                }))
            );
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart, "success--text", ["error--text"]),
                groupByHeaderText: this.$t("Standort") as string,
            };
        },

        topChannels(): OpportunityChannel[] {
            return [
                OpportunityChannel.EMAIL,
                OpportunityChannel.PHONE_INTERNAL,
                OpportunityChannel.PHONE_BDC,
                OpportunityChannel.WEBSITE,
                ...(Object.keys(OpportunityChannel) as OpportunityChannel[]),
            ]
                .filter((channel, index, array) => array.indexOf(channel) === index)
                .filter((channel) => this.channels.includes(channel));
        },

        topSourceIds(): string[] {
            const rows = this.rowGroups.reduce(
                (rows, rowGroup) => rows.concat(rowGroup.rows),
                [] as ComputedOpportunityRow[]
            );

            return this.sourceIds
                .map((sourceId) => ({
                    sourceId,
                    value: rows.filter((r) => r.sourceId === sourceId).length,
                }))
                .sort((a, b) => (b.value ?? 0) - (a.value ?? 0))
                .map((s) => s.sourceId);
        },
    },

    methods: {
        getGroupByFilters(groupBy: "CHANNEL" | "SOURCE" | undefined): GroupByFilter[] {
            let filters: GroupByFilter[] = [];
            let maxCount = 0;

            if (groupBy === "CHANNEL") {
                maxCount = this.maxChannelsCount;

                filters = this.topChannels.map((channel) => ({
                    id: `channel-${channel}`,
                    name: this.$t(`enum.OpportunityChannel.${channel}`) as string,
                    predicate: (r: ComputedOpportunityRow) => r.channel === channel,
                    color: this.channelColors.find((r) => r.id === channel)?.color,
                }));
            } else if (groupBy === "SOURCE") {
                maxCount = this.maxSourcesCount;

                filters = this.topSourceIds.map((sourceId) => ({
                    id: `source-${sourceId}`,
                    name:
                        opportunitySourcesStore.getOpportunitySourceById(sourceId)?.name ||
                        (this.$t("Unbekannte Quelle") as string),
                    predicate: (r: ComputedOpportunityRow) => r.sourceId === sourceId,
                    color: this.sourceColors.find((r) => r.id === sourceId)?.color,
                }));
            } else {
                return [{ id: "none", name: null, predicate: () => true }];
            }

            if (filters.length <= maxCount + 1) {
                return filters;
            }

            const others = filters.slice(maxCount);

            return [
                ...filters.slice(0, maxCount),
                {
                    id: "other",
                    name: this.$t("Sonstige") as string,
                    predicate: (r: ComputedOpportunityRow) => others.some((o) => o.predicate(r)),
                    color: OTHER_COLOR,
                },
            ];
        },

        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardBarTile,
    },
});
