
import CaseReportPage from "./CaseReportPage.vue";
import { CaseReportPageResult, ExtendedCaseRow } from "./caseReportPage";
import { SentimentType } from "@/api/caseSearch";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import ReportingTimeSeriesChartCard from "@/app/pages/reporting/ReportingTimeSeriesChartCard.vue";
import { ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { getCaseSentimentTypeColor } from "@/app/pages/reporting/colors";
import { WithCreatedOngoingTimeSlot, WithSentimentType } from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { RowKey } from "@/app/pages/reporting/rowUtils";
import { getOngoingTimeSlotLabel, OngoingTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getWeek } from "@/util/dateTimeUtils";
import Vue from "vue";

type PrecomputedProperties = WithCreatedOngoingTimeSlot & WithSentimentType;

type ComputedCaseRow = ExtendedCaseRow<PrecomputedProperties>;

export default Vue.extend({
    data() {
        const ts = now();

        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
            ongoingTimeInterval: OngoingTimeInterval.WEEK,
            OngoingTimeInterval,
            result: null as CaseReportPageResult<PrecomputedProperties> | null,
            timeRange: {
                from: getWeek(ts, -6, userSession.timeZone, userSession.locale).begin,
                to: getWeek(ts, 0, userSession.timeZone, userSession.locale).end,
            } as TimeRange,
        };
    },

    computed: {
        chart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            return {
                title: this.$t("Stimmung") as string,
                series: [
                    SentimentType.POSITIVE,
                    SentimentType.NEUTRAL,
                    SentimentType.NEGATIVE,
                    SentimentType.UNKNOWN,
                ].map((sentimentType) => ({
                    id: `sentiment-type-${sentimentType}`,
                    name: this.$t(`enum.SentimentType.${sentimentType}`) as string,
                    data: {
                        values: this.rowGroups.map((rowGroup) => {
                            const sentimentTypeRows = rowGroup.rows.filter((r) => r.sentimentType === sentimentType);

                            return {
                                date: new Date(rowGroup.key as string),
                                value: rate(sentimentTypeRows.length, rowGroup.rows.length),
                                onClick: this.showBottomSheetOnClick(sentimentTypeRows.map((r) => r.id)),
                            };
                        }),
                        isPercentage: true,
                    },
                    color: getCaseSentimentTypeColor(sentimentType),
                    additionalTooltipData: [
                        {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: rowGroup.rows.filter((r) => r.sentimentType === sentimentType).length,
                            })),
                        },
                    ],
                })),
                dateFormatter: this.dateFormatter,
                seriesDataTooltipHeader: this.$t("Anteil") as string,
                seriesAdditionalTooltipDataTooltipHeaders: [this.$t("Fälle") as string],
            };
        },

        defaultKeys(): RowKey[] {
            return getTimeSeriesDefaultKeys(
                this.result?.rows ?? [],
                (r: ComputedCaseRow) => r.created,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null
            );
        },

        rowGroups(): readonly RowGroup<RowKey, ComputedCaseRow>[] {
            const rowGroups = groupRowsBy(this.result?.rows ?? [], (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },

        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CaseReportPage,
        CasesBottomSheet,
        ReportingTimeSeriesChartCard,
    },
});
