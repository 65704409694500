
import IncomingCallReportPage from "./IncomingCallReportPage.vue";
import { ExtendedIncomingCallRow, IncomingCallReportPageResult } from "./incomingCallReportPage";
import { IssueType } from "@/api/reporting";
import ReportingTimeSeriesChartCard from "@/app/pages/reporting/ReportingTimeSeriesChartCard.vue";
import { WithCreatedOngoingTimeSlot } from "@/app/pages/reporting/activityRowUtils";
import { durationFormatter } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { avg, median, sum } from "@/app/pages/reporting/reportingUtils";
import { RowKey } from "@/app/pages/reporting/rowUtils";
import { getOngoingTimeSlotLabel, OngoingTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getWeek } from "@/util/dateTimeUtils";
import Vue from "vue";

type PrecomputedProperties = WithCreatedOngoingTimeSlot;

type ComputedIncomingCallRow = ExtendedIncomingCallRow<PrecomputedProperties>;

export default Vue.extend({
    data() {
        const ts = now();

        return {
            ongoingTimeInterval: OngoingTimeInterval.WEEK,
            OngoingTimeInterval,
            result: null as IncomingCallReportPageResult<PrecomputedProperties> | null,
            timeRange: {
                from: getWeek(ts, -6, userSession.timeZone, userSession.locale).begin,
                to: getWeek(ts, 0, userSession.timeZone, userSession.locale).end,
            } as TimeRange,
        };
    },

    computed: {
        callVolumeTimeSeriesChart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const chart: ReportingTimeSeriesChartData = {
                title: this.$t("Anrufvolumen") as string,
                series: [IssueType.UNKNOWN, IssueType.CASE, IssueType.OPPORTUNITY].map((issueType, index) => ({
                    id: `issue-type-${index}`,
                    name: this.$t(`enum.IssueType.${issueType}`) as string,
                    data: {
                        values: this.rowGroups.map((rowGroup) => ({
                            date: new Date(rowGroup.key as string),
                            value: rowGroup.rows.filter((r) => r.issueType === issueType).length,
                        })),
                    },
                })),
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        defaultKeys(): RowKey[] {
            return getTimeSeriesDefaultKeys(
                this.result?.rows ?? [],
                (r: ComputedIncomingCallRow) => r.created,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null
            );
        },

        durationTimeSeriesChart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const chart: ReportingTimeSeriesChartData = {
                title: this.$t("Gesprächsdauer") as string,
                series: [
                    {
                        id: "total",
                        name: this.$t("Gesamtdauer") as string,
                        selected: false,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: sum(rowGroup.rows.map((r) => r.duration)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                    {
                        id: "duration-median",
                        name: this.$t("Median") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: median(rowGroup.rows.map((r) => r.duration)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                    {
                        id: "duration-average",
                        name: this.$t("Durchschnitt") as string,
                        selected: false,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: avg(rowGroup.rows.map((r) => r.duration)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                ],
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        rowGroups(): readonly RowGroup<RowKey, ComputedIncomingCallRow>[] {
            const rowGroups = groupRowsBy(this.result?.rows ?? [], (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        waitTimeTimeSeriesChart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const chart: ReportingTimeSeriesChartData = {
                title: this.$t("Wartezeit") as string,
                series: [
                    {
                        id: "wait-time-median",
                        name: this.$t("Median") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: median(rowGroup.rows.map((r) => r.waited)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                    {
                        id: "wait-time-average",
                        name: this.$t("Durchschnitt") as string,
                        selected: false,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: avg(rowGroup.rows.map((r) => r.waited)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                ],
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },
    },

    components: {
        IncomingCallReportPage,
        ReportingTimeSeriesChartCard,
    },
});
