
import CasesDataTableRow from "./CasesDataTableRow.vue";
import { CaseResult, casesApi } from "@/api/cases";
import { Permission } from "@/api/userSession";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { userSession } from "@/store/userSession";
import { PickMutable } from "@/util/types";
import Vue from "vue";

interface Item {
    readonly caseId: string;
    readonly caseResult: CaseResult | null;
}

export default Vue.extend({
    props: {
        caseIds: {
            type: Array as () => string[],
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: 25,
        },
        title: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            items: [] as Item[],
            loading: true,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                totalSize: this.caseIds.length,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchId: 1,
        };
    },

    computed: {
        canViewAllCases(): boolean {
            return userSession.hasPermission(Permission.VIEW_ALL_CASES);
        },

        canViewCases(): boolean {
            return this.canViewAllCases || userSession.hasPermission(Permission.MANAGE_OWN_CASES);
        },

        headers(): DataTableHeader[] {
            return [{ width: "30%" }, { width: "35%" }, { width: "35%" }];
        },

        pageCaseIds(): string[] {
            const start = (this.paging.page - 1) * this.itemsPerPage;

            return this.caseIds.slice(start, start + this.itemsPerPage);
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            const pageCaseIds = this.pageCaseIds;
            const searchId = ++this.searchId;

            try {
                const caseResults = this.canViewCases ? await casesApi.getByIds(pageCaseIds) : [];

                const items: Item[] = pageCaseIds.map((caseId) => ({
                    caseId,
                    caseResult: caseResults.find((cr) => cr.caseObj.id === caseId) ?? null,
                }));

                if (searchId === this.searchId) {
                    this.items = items;
                    this.paging.totalSize = this.caseIds.length;
                    this.loading = false;
                }
            } catch (e) {
                if (searchId === this.searchId) {
                    this.loading = false;
                    this.paging.page = 1;
                }
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },
    },

    watch: {
        async caseIds() {
            this.paging.page = 1;
            this.paging.totalSize = this.caseIds.length;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        CasesDataTableRow,
        DataTable,
    },
});
