import { CaseChannel, CaseStatus } from "./cases";
import { OpportunityChannel, OpportunityStatus } from "./opportunities";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IssueType {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    EMERGENCY_INCIDENT = "EMERGENCY_INCIDENT",
    UNKNOWN = "UNKNOWN",
}

export interface CaseRow {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly closed: Date | null;
    readonly closerUserId: string | null;
    readonly closerCtUserId: string | null;
    readonly outcomeReasonId: string | null;
    readonly postponedUntil: Date | null;
    readonly assignees: IssueAssignee[];
    readonly dealerId: string;
    readonly channel: CaseChannel;
    readonly caseType: string;
    readonly sentiment: number | null;
    readonly escalationGroupReceivers: string[];
    readonly activitySummary: IssueActivitySummary | null;
}

export interface CaseRowSearchRequest {
    readonly dealerIds: string[];
    readonly status: CaseStatus[];
    readonly outcomeReasonIds: string[];
    readonly assigneeIds: string[];
    readonly escalationGroupReceiverIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly closedFrom: Date | null;
    readonly closedTo: Date | null;
    readonly postponedUntilFrom: Date | null;
    readonly postponedUntilTo: Date | null;
    readonly includeFirstAssigned: boolean;
    readonly includeFirstIncomingActivities: boolean;
    readonly includeFirstOutgoingActivities: boolean;
    readonly includeLastIncomingActivities: boolean;
    readonly includeLastOutgoingActivities: boolean;
    readonly includeAssignees: boolean;
    readonly includeAssigneeFirstAssigned: boolean;
    readonly includeAssigneeFirstOutgoingActivities: boolean;
    readonly includeAssigneeLastOutgoingActivities: boolean;
    readonly includeEscalationGroupReceivers: boolean;
}

export interface ExternalBdcRevenue {
    readonly billingMonth: string;
    readonly dealerId: string;
    readonly fixedBdcRevenue: number;
    readonly variableBdcRevenue: number;
}

export interface ExternalBdcCdrRow {
    readonly callee: string;
    readonly begin: Date;
    readonly billedMinutes: number;
    readonly dealerId: string;
}

export interface ExternalBdcCdrRowExtension {
    readonly waited: number;
    readonly duration: number | null;
    readonly billedMinutes: number | null;
}

export interface IncomingActivitySummary {
    readonly call: Date | null;
    readonly email: Date | null;
    readonly sms: Date | null;
    readonly whatsAppMessage: Date | null;
}

export interface IncomingCallRow {
    readonly created: Date;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string;
    readonly internalIncomingPhoneNumberId: string;
    readonly waited: number | null;
    readonly acceptorUserId: string | null;
    readonly duration: number | null;
    readonly forwardedToExternalBdc: boolean;
    readonly forwardedToCustomPhoneNumber: boolean;
    readonly forwardedFromInternalIncomingPhoneNumberIds: string[];
}

export interface IncomingCallRowSearchRequest {
    readonly issueTypes: IssueType[];
    readonly dealerIds: string[];
    readonly acceptorUserIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly includeForwarding: boolean;
}

export interface IncomingEmailRow {
    readonly created: Date;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string | null;
}

export interface IncomingEmailRowSearchRequest {
    readonly issueTypes: IssueType[];
    readonly dealerIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
}

export interface IncomingSmsRow {
    readonly created: Date;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string | null;
    readonly toNumber: string;
}

export interface IncomingSmsRowSearchRequest {
    readonly issueTypes: IssueType[];
    readonly dealerIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
}

export interface IncomingWhatsAppMessageRow {
    readonly created: Date;
    readonly read: Date | null;
    readonly deleted: Date | null;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string | null;
    readonly toNumber: string;
}

export interface IncomingWhatsAppMessageRowSearchRequest {
    readonly issueTypes: IssueType[];
    readonly dealerIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
}

export interface IssueActivitySummary {
    readonly firstAssigned: Date | null;
    readonly firstIncoming: IncomingActivitySummary | null;
    readonly firstOutgoing: OutgoingActivitySummary | null;
    readonly lastIncoming: IncomingActivitySummary | null;
    readonly lastOutgoing: OutgoingActivitySummary | null;
}

export interface IssueAssignee {
    readonly id: string;
    readonly firstAssigned: Date | null;
    readonly firstOutgoing: OutgoingActivitySummary | null;
    readonly lastOutgoing: OutgoingActivitySummary | null;
}

export interface OpportunityRow {
    readonly id: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly creatorCtUserId: string | null;
    readonly closed: Date | null;
    readonly closerUserId: string | null;
    readonly closerCtUserId: string | null;
    readonly outcome: boolean | null;
    readonly outcomeReasonId: string | null;
    readonly postponedUntil: Date | null;
    readonly assignees: IssueAssignee[];
    readonly dealerId: string;
    readonly channel: OpportunityChannel;
    readonly sourceId: string;
    readonly sentiment: number | null;
    readonly opportunityTeamReceivers: string[];
    readonly activitySummary: IssueActivitySummary | null;
}

export interface OpportunityRowSearchRequest {
    readonly dealerIds: string[];
    readonly sourceIds: string[];
    readonly status: OpportunityStatus[];
    readonly outcomeReasonIds: string[];
    readonly assigneeIds: string[];
    readonly opportunityTeamReceiverIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly closedFrom: Date | null;
    readonly closedTo: Date | null;
    readonly postponedUntilFrom: Date | null;
    readonly postponedUntilTo: Date | null;
    readonly includeFirstAssigned: boolean;
    readonly includeFirstIncomingActivities: boolean;
    readonly includeFirstOutgoingActivities: boolean;
    readonly includeLastIncomingActivities: boolean;
    readonly includeLastOutgoingActivities: boolean;
    readonly includeAssignees: boolean;
    readonly includeAssigneeFirstAssigned: boolean;
    readonly includeAssigneeFirstOutgoingActivities: boolean;
    readonly includeAssigneeLastOutgoingActivities: boolean;
    readonly includeOpportunityTeamReceivers: boolean;
}

export interface OutgoingActivitySummary {
    readonly call: Date | null;
    readonly email: Date | null;
    readonly sms: Date | null;
    readonly whatsAppMessage: Date | null;
}

export interface OutgoingCallRow {
    readonly created: Date;
    readonly initiatorUserId: string;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string;
    readonly waited: number;
    readonly duration: number | null;
}

export interface OutgoingCallRowSearchRequest {
    readonly issueTypes: IssueType[];
    readonly dealerIds: string[];
    readonly initiatorUserIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
}

export interface OutgoingEmailRow {
    readonly created: Date;
    readonly initiatorUserId: string;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string | null;
}

export interface OutgoingEmailRowSearchRequest {
    readonly issueTypes: IssueType[];
    readonly dealerIds: string[];
    readonly initiatorUserIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
}

export interface OutgoingSmsRow {
    readonly created: Date;
    readonly initiatorUserId: string;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string | null;
}

export interface OutgoingSmsRowSearchRequest {
    readonly issueTypes: IssueType[];
    readonly dealerIds: string[];
    readonly initiatorUserIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
}

export interface OutgoingWhatsAppMessageRow {
    readonly created: Date;
    readonly delivered: Date | null;
    readonly read: Date | null;
    readonly failed: Date | null;
    readonly initiatorUserId: string;
    readonly caseId: string | null;
    readonly opportunityId: string | null;
    readonly dealerId: string | null;
}

export interface OutgoingWhatsAppMessageRowSearchRequest {
    readonly issueTypes: IssueType[];
    readonly dealerIds: string[];
    readonly initiatorUserIds: string[];
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
}

export interface UserOpportunityStatus {
    readonly userId: string;
    readonly createdOpportunities: number;
    readonly activeUnassignedOpportunities: number;
    readonly activeUnassignedOpportunitiesWithUnreadActivities: number;
    readonly activeAssignedOpportunities: number;
    readonly activeAssignedOpportunitiesWithUnreadActivities: number;
    readonly postponedOpportunities: number;
    readonly postponedOpportunitiesWithUnreadActivities: number;
    readonly closedOpportunities: number;
    readonly closedCreatedOpportunities: number;
    readonly closedAssignedOpportunities: number;
    readonly closedCloserOpportunities: number;
    readonly wonAssignedOpportunities: number;
    readonly lostAssignedOpportunities: number;
}

interface ReportingApi {
    caseRows(caseRowSearchRequest: CaseRowSearchRequest): Promise<CaseRow[]>;
    externalBdcRevenues(
        dealerIds: string[],
        createdFrom: Date | null,
        createdTo: Date | null
    ): Promise<ExternalBdcRevenue[]>;
    externalBdcCdrRows(
        dealerIds: string[],
        createdFrom: Date | null,
        createdTo: Date | null,
        extended: boolean
    ): Promise<ExternalBdcCdrRow[] | (ExternalBdcCdrRow & ExternalBdcCdrRowExtension)[]>;
    incomingCallRows(incomingCallRowSearchRequest: IncomingCallRowSearchRequest): Promise<IncomingCallRow[]>;
    incomingEmailRows(incomingEmailRowSearchRequest: IncomingEmailRowSearchRequest): Promise<IncomingEmailRow[]>;
    incomingSmsRows(incomingSmsRowSearchRequest: IncomingSmsRowSearchRequest): Promise<IncomingSmsRow[]>;
    incomingWhatsAppMessageRows(
        incomingWhatsAppMessageRowSearchRequest: IncomingWhatsAppMessageRowSearchRequest
    ): Promise<IncomingWhatsAppMessageRow[]>;
    opportunityRows(opportunityRowSearchRequest: OpportunityRowSearchRequest): Promise<OpportunityRow[]>;
    outgoingCallRows(outgoingCallRowSearchRequest: OutgoingCallRowSearchRequest): Promise<OutgoingCallRow[]>;
    outgoingEmailRows(outgoingEmailRowSearchRequest: OutgoingEmailRowSearchRequest): Promise<OutgoingEmailRow[]>;
    outgoingSmsRows(outgoingSmsRowSearchRequest: OutgoingSmsRowSearchRequest): Promise<OutgoingSmsRow[]>;
    outgoingWhatsAppMessageRows(
        outgoingWhatsAppMessageRowSearchRequest: OutgoingWhatsAppMessageRowSearchRequest
    ): Promise<OutgoingWhatsAppMessageRow[]>;
    userOpportunityStatuses(
        dealerIds: string[],
        userIds: string[],
        createdFrom: Date | null,
        createdTo: Date | null
    ): Promise<UserOpportunityStatus[]>;
}

function getParams(dealerIds: string[], userIds: string[], createdFrom: Date | null, createdTo: Date | null) {
    const params = new URLSearchParams(dealerIds.map((i) => `dealerId=${i}`).join("&"));

    for (const userId of userIds) {
        params.append("userId", userId);
    }

    if (createdFrom) {
        params.append("createdFrom", createdFrom.toJSON());
    }

    if (createdTo) {
        params.append("createdTo", createdTo.toJSON());
    }

    return params;
}

export const reportingApi: ReportingApi = {
    async caseRows(caseRowSearchRequest) {
        return cloneObject((await axios.post("/api/reporting/cases/_search", caseRowSearchRequest)).data);
    },

    async externalBdcRevenues(dealerIds, createdFrom, createdTo) {
        const params = getParams(dealerIds, [], createdFrom, createdTo);

        return cloneObject((await axios.get("/api/reporting/external-bdc-revenues", { params })).data);
    },

    async externalBdcCdrRows(dealerIds, createdFrom, createdTo, extended) {
        const params = getParams(dealerIds, [], createdFrom, createdTo);

        params.append("extended", String(extended));

        return cloneObject((await axios.get("/api/reporting/external-bdc-cdrs", { params })).data);
    },

    async incomingCallRows(incomingCallRowSearchRequest) {
        return cloneObject(
            (await axios.post("/api/reporting/incoming-calls/_search", incomingCallRowSearchRequest)).data
        );
    },

    async incomingEmailRows(incomingEmailRowSearchRequest) {
        return cloneObject(
            (await axios.post("/api/reporting/incoming-emails/_search", incomingEmailRowSearchRequest)).data
        );
    },

    async incomingSmsRows(incomingSmsRowSearchRequest: IncomingSmsRowSearchRequest) {
        return cloneObject((await axios.post("/api/reporting/incoming-sms/_search", incomingSmsRowSearchRequest)).data);
    },

    async incomingWhatsAppMessageRows(incomingWhatsAppMessageRowSearchRequest) {
        return cloneObject(
            (
                await axios.post(
                    "/api/reporting/incoming-whatsapp-messages/_search",
                    incomingWhatsAppMessageRowSearchRequest
                )
            ).data
        );
    },

    async opportunityRows(opportunityRowSearchRequest) {
        return cloneObject(
            (await axios.post("/api/reporting/opportunities/_search", opportunityRowSearchRequest)).data
        );
    },

    async outgoingCallRows(outgoingCallRowSearchRequest) {
        return cloneObject(
            (await axios.post("/api/reporting/outgoing-calls/_search", outgoingCallRowSearchRequest)).data
        );
    },

    async outgoingEmailRows(outgoingEmailRowSearchRequest) {
        return cloneObject(
            (await axios.post("/api/reporting/outgoing-emails/_search", outgoingEmailRowSearchRequest)).data
        );
    },

    async outgoingSmsRows(outgoingSmsRowSearchRequest) {
        return cloneObject((await axios.post("/api/reporting/outgoing-sms/_search", outgoingSmsRowSearchRequest)).data);
    },

    async outgoingWhatsAppMessageRows(outgoingWhatsAppMessageRowSearchRequest) {
        return cloneObject(
            (
                await axios.post(
                    "/api/reporting/outgoing-whatsapp-messages/_search",
                    outgoingWhatsAppMessageRowSearchRequest
                )
            ).data
        );
    },

    async userOpportunityStatuses(dealerIds, userIds, createdFrom, createdTo) {
        const params = getParams(dealerIds, userIds, createdFrom, createdTo);

        return cloneObject((await axios.get("/api/reporting/user-opportunity-statuses", { params })).data);
    },
};
