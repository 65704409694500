
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { durationFormatter, getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { ISSUE_STATUS_ASSIGNED_COLOR } from "@/app/pages/reporting/colors";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import {
    Errorless,
    isWithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless,
    splitWithAssignee,
    WithAssignee,
    WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally,
    WithIsAssigneeFirstReactingAssignee,
    withIsAssigneeFirstReactingAssignee,
} from "@/app/pages/reporting/issueRowUtils";
import { withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally } from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { avg } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import Vue from "vue";

type ComputedOpportunityRow = OpportunityRow &
    WithAssignee &
    WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<Errorless> &
    WithIsAssigneeFirstReactingAssignee;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => OpportunityRow[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => {
                    const waitingTimeUntilFirstReactionAsPerceivedExternallyRows = rowGroup.rows.filter(
                        (r) => r.assigneeWaitingTimeUntilFirstReactionAsPerceivedExternally !== null
                    );

                    const waitingTimeUntilFirstReactionAsPerceivedExternallyAvg = avg(
                        waitingTimeUntilFirstReactionAsPerceivedExternallyRows.map(
                            (r) => r.assigneeWaitingTimeUntilFirstReactionAsPerceivedExternally
                        )
                    );

                    const waitingTimeUntilFirstAssignmentAsPerceivedExternallyRows = waitingTimeUntilFirstReactionAsPerceivedExternallyRows.filter(
                        (r) => r.assigneeWaitingTimeUntilFirstAssignmentAsPerceivedExternally !== null
                    );

                    const waitingTimeUntilFirstAssignmentAsPerceivedExternallyAvg = avg(
                        waitingTimeUntilFirstAssignmentAsPerceivedExternallyRows.map(
                            (r) => r.assigneeWaitingTimeUntilFirstAssignmentAsPerceivedExternally
                        )
                    );

                    return {
                        assigneeId: rowGroup.key!,
                        name: rowGroup.title,
                        description: rowGroup.subtitle,
                        waitingTimeUntilFirstReactionAsPerceivedExternallyRows,
                        waitingTimeUntilFirstAssignmentAsPerceivedExternallyRows,
                        waitingTimeUntilFirstReactionAsPerceivedExternallyAvg,
                        waitingTimeUntilFirstAssignmentAsPerceivedExternallyAvg,
                        sortValue: waitingTimeUntilFirstReactionAsPerceivedExternallyAvg,
                    };
                })
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "assignee-first-assigned-time",
                        name: this.$t("Dauer von Erstellung bis zur Zuteilung (Durchschnitt)") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.waitingTimeUntilFirstAssignmentAsPerceivedExternallyAvg,
                                onClick: this.showBottomSheetOnClick(
                                    c.waitingTimeUntilFirstAssignmentAsPerceivedExternallyRows.map((r) => r.id)
                                ),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                        color: ISSUE_STATUS_ASSIGNED_COLOR,
                    },
                    {
                        id: "assignee-waiting-time-until-first-reaction-as-perceived-externally",
                        name: this.$t("Dauer von Erstellung bis zur ersten Kontaktaufnahme (Durchschnitt)") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.waitingTimeUntilFirstReactionAsPerceivedExternallyAvg,
                                onClick: this.showBottomSheetOnClick(
                                    c.waitingTimeUntilFirstReactionAsPerceivedExternallyRows.map((r) => r.id)
                                ),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        filteredComputedOpportunityRows(): readonly ComputedOpportunityRow[] {
            return [this.rows]
                .map(splitWithAssignee)
                .map((rows) => rows.filter((r) => r.assigneeId))
                .map(withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally)
                .pop()!
                .filter(isWithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless)
                .map(withIsAssigneeFirstReactingAssignee)
                .filter((r) => r.isAssigneeFirstReactingAssignee);
        },

        rowGroups(): readonly TitledRowGroup<string | null, ComputedOpportunityRow>[] {
            return groupRowsBy(this.filteredComputedOpportunityRows, (r) => r.assigneeId).map((rowGroup) => {
                const user = rowGroup.key ? usersStore.getUserById(rowGroup.key) : null;

                return {
                    ...rowGroup,
                    title: getFullName(user) || (this.$t("Unbekannter Benutzer") as string),
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText: this.$t("Verkäufer") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardBarTile,
    },
});
