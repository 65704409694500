import { CaseChannel, CaseStatus } from "@/api/cases";
import { IssueActivitySummary, IssueAssignee } from "@/api/reporting";
import { $t } from "@/app/i18n";
import { getSentimentType } from "@/app/pages/cases/caseSearchUtils";
import {
    getIssueRowDefaultKeys,
    GetIssueRowDefaultKeysContext,
    mapIssueRowKeyToLabel,
    MapIssueRowKeyToLabelContext,
    PotentiallyErroneous,
    WithAssignee,
    withAssigneeWaitingTimeUntilFirstReaction as withAssigneeWaitingTimeUntilFirstReactionByIssueRow,
    WithAssigneeWaitingTimeUntilFirstReaction,
    withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally as withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyByIssueRow,
    WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally,
    WithSentimentType,
    withWaitingTimeUntilFirstReaction as withWaitingTimeUntilFirstReactionByIssueRow,
    WithWaitingTimeUntilFirstReaction,
    withWaitingTimeUntilFirstReactionAsPerceivedExternally as withWaitingTimeUntilFirstReactionAsPerceivedExternallyByIssueRow,
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "@/app/pages/reporting/issueRowUtils";
import { RowKey, RowLabel } from "@/app/pages/reporting/rowUtils";
import { caseTypesStore } from "@/store/caseTypes";
import { dealersStore } from "@/store/dealers";
import { escalationGroupsStore } from "@/store/escalationGroups";
import { reportingSettingsStore } from "@/store/reportingSettingsStore";

/*
 * helper
 */

export function filterConsiderCaseInReports(row: { outcomeReasonId: string | null }): boolean {
    return (
        row.outcomeReasonId === null ||
        !reportingSettingsStore.reportingSettings.ignoreCaseOutcomeReasonIds.includes(row.outcomeReasonId)
    );
}

/*
 * with assignee waiting time until first reaction
 */

export function withAssigneeWaitingTimeUntilFirstReaction<
    T extends { dealerId: string; activitySummary: IssueActivitySummary | null } & WithAssignee
>(rows: readonly T[]): readonly (T & WithAssigneeWaitingTimeUntilFirstReaction<PotentiallyErroneous>)[] {
    return withAssigneeWaitingTimeUntilFirstReactionByIssueRow(
        rows,
        (settings) => settings.caseReactionTimeOfficeHoursId
    );
}

/*
 * with assignee waiting time until first reaction as perceived externally
 */

export function withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<
    T extends { dealerId: string; created: Date } & WithAssignee
>(
    rows: readonly T[]
): readonly (T & WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>)[] {
    return withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyByIssueRow(
        rows,
        (settings) => settings.caseReactionTimeAsPerceivedExternallyOfficeHoursId
    );
}

/*
 * with case escalation group receiver id
 */

export interface WithCaseEscalationGroupReceiverId {
    readonly escalationGroupReceiverId: string | null;
}

export function withCaseEscalationGroupReceiverIdSetToNull<T extends { escalationGroupReceivers: string[] }>(
    row: T
): T & WithCaseEscalationGroupReceiverId {
    return { ...row, escalationGroupReceiverId: null };
}

export function splitWithCaseEscalationGroupReceiverId<T extends { escalationGroupReceivers: string[] }>(
    rows: readonly T[]
): readonly (T & WithCaseEscalationGroupReceiverId)[] {
    return rows
        .map((r) =>
            (!!r.escalationGroupReceivers.length ? r.escalationGroupReceivers : ([null] as (string | null)[])).map(
                (escalationGroupReceiverId) => ({
                    ...r,
                    escalationGroupReceiverId,
                })
            )
        )
        .reduce((prev, cur) => prev.concat(cur), []);
}

/*
 * with case status
 */

export interface WithCaseStatus {
    readonly caseStatus: CaseStatus;
}

export function withCaseStatus<
    T extends { created: Date; closed: Date | null; postponedUntil: Date | null; assignees: IssueAssignee[] }
>(row: T): T & WithCaseStatus {
    return {
        ...row,
        caseStatus: row.closed
            ? CaseStatus.CLOSED
            : row.postponedUntil
            ? CaseStatus.POSTPONED
            : row.assignees.length
            ? CaseStatus.ASSIGNED
            : CaseStatus.OPEN,
    };
}

/*
 * with case type group
 */

export interface WithCaseTypeGroup {
    readonly caseTypeGroup: string;
}

export function withCaseTypeGroup<T extends { caseType: string }>(row: T): T & WithCaseTypeGroup {
    const caseTypeGroups = caseTypesStore.caseTypeGroups;

    return {
        ...row,
        caseTypeGroup: caseTypeGroups.find((g) => g.caseTypes.includes(row.caseType))!.name,
    };
}

/*
 * with sentiment type
 */

export function withSentimentType<T extends { sentiment: number | null }>(row: T): T & WithSentimentType {
    return {
        ...row,
        sentimentType: getSentimentType(row.sentiment),
    };
}

/*
 * with waiting time until first reaction
 */

export function withWaitingTimeUntilFirstReaction<
    T extends { dealerId: string; activitySummary: IssueActivitySummary | null }
>(rows: readonly T[]): readonly (T & WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>)[] {
    return withWaitingTimeUntilFirstReactionByIssueRow(rows, (settings) => settings.caseReactionTimeOfficeHoursId);
}

/*
 * with waiting time until first reaction as perceived externally
 */

export function withWaitingTimeUntilFirstReactionAsPerceivedExternally<
    T extends { dealerId: string; created: Date; activitySummary: IssueActivitySummary | null }
>(rows: readonly T[]): readonly (T & WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>)[] {
    return withWaitingTimeUntilFirstReactionAsPerceivedExternallyByIssueRow(
        rows,
        (settings) => settings.caseReactionTimeAsPerceivedExternallyOfficeHoursId
    );
}

/*
 * misc
 */

export type MapCaseRowKeyToLabelContext = MapIssueRowKeyToLabelContext;

export function mapCaseRowKeyToRowLabel(key: RowKey, groupBy: string, context?: MapCaseRowKeyToLabelContext): RowLabel {
    if (groupBy === "caseStatus" && typeof key === "string") {
        return { label: $t(`enum.CaseStatus.${key}`) as string };
    } else if (groupBy === "caseType" && typeof key === "string") {
        return { label: $t(`enum.CaseType.${key}`) as string };
    } else if (groupBy === "caseTypeGroup" && typeof key === "string") {
        return { label: $t(`enum.CaseTypeGroup.${key}`) as string };
    } else if (groupBy === "channel" && typeof key === "string") {
        return { label: $t(`enum.CaseChannel.${key}`) as string };
    } else if (groupBy === "escalationGroupReceiverId") {
        if (key === null) {
            return { label: $t("Ohne Eskalationsgruppe") as string };
        } else if (typeof key === "string") {
            const escalationGroup = escalationGroupsStore.escalationGroupById(key);

            if (!escalationGroup) {
                return { label: $t("Unbekannte Eskalationsgruppe") as string };
            }

            const dealer = dealersStore.dealerById(escalationGroup.dealerId);

            if (!dealer) {
                return { label: escalationGroup.name };
            }

            return { label: escalationGroup.name, sublabel: dealer.name };
        }
    }

    return mapIssueRowKeyToLabel(key, groupBy, context);
}

export type GetCaseRowDefaultKeysContext = GetIssueRowDefaultKeysContext & {
    readonly visibleCaseTypes?: string[];
    readonly visibleChannels?: string[];
};

export function getCaseRowDefaultKeys(groupBy: string, context?: GetCaseRowDefaultKeysContext): RowKey[] {
    const { visibleCaseTypes, visibleChannels, visibleDealerIds } = context ?? {};

    if (groupBy === "caseStatus") {
        return Object.keys(CaseStatus);
    } else if (groupBy === "caseType") {
        return caseTypesStore.caseTypeGroups
            .map((cg) => cg.caseTypes)
            .reduce((prev, cur) => prev.concat(cur), [])
            .filter((caseType) => visibleCaseTypes?.includes(caseType));
    } else if (groupBy === "caseTypeGroup") {
        return caseTypesStore.caseTypeGroups.map((cg) => cg.name);
    } else if (groupBy === "channel" && visibleChannels) {
        return Object.keys(CaseChannel).filter((c) => visibleChannels.includes(c));
    } else if (groupBy === "escalationGroupReceiverId") {
        return escalationGroupsStore.escalationGroups
            .filter((e) => visibleDealerIds?.includes(e.dealerId))
            .map((e) => e.id);
    }

    return getIssueRowDefaultKeys(groupBy, context);
}
