
import ExternalBdcReportPage from "./ExternalBdcReportPage.vue";
import { WithBeginOngoingTimeSlot } from "./externalBdcCdrRowUtils";
import { ExternalBdcReportPageResult, ExternalBdcReportPageRow } from "./externalBdcReportPage";
import ReportingTimeSeriesChartCard from "@/app/pages/reporting/ReportingTimeSeriesChartCard.vue";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { hasNonZeroSeries, ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { sum } from "@/app/pages/reporting/reportingUtils";
import { RowKey } from "@/app/pages/reporting/rowUtils";
import { getOngoingTimeSlotLabel, OngoingTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { userSession } from "@/store/userSession";
import Vue from "vue";

type PrecomputedProperties = WithBeginOngoingTimeSlot;

type ComputedExternalBdcCdrRow = ExternalBdcReportPageRow<PrecomputedProperties>;

export default Vue.extend({
    data() {
        return {
            ongoingTimeInterval: OngoingTimeInterval.DATE,
            OngoingTimeInterval,
            result: null as ExternalBdcReportPageResult<PrecomputedProperties> | null,
        };
    },

    computed: {
        chart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const chart: ReportingTimeSeriesChartData = {
                title: this.$t("Anrufvolumen") as string,
                series: [
                    {
                        id: "accepted",
                        name: this.$t("Angenommen") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: rowGroup.rows.length || undefined,
                            })),
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                    {
                        id: "billed-minutes",
                        name: this.$t("Abgerechnete Minuten") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: sum(rowGroup.rows.map((r) => r.billedMinutes)),
                            })),
                        },
                    },
                ],
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        defaultKeys(): RowKey[] {
            return getTimeSeriesDefaultKeys(
                this.result?.rows ?? [],
                (r: ComputedExternalBdcCdrRow) => r.begin,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null
            );
        },

        rowGroups(): readonly RowGroup<RowKey, ComputedExternalBdcCdrRow>[] {
            const rowGroups = groupRowsBy(this.result?.rows ?? [], (r) => r.beginOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },
    },

    components: {
        ExternalBdcReportPage,
        ReportingTimeSeriesChartCard,
    },
});
