
import IncomingCallReportPage from "./IncomingCallReportPage.vue";
import { ExtendedIncomingCallRow, IncomingCallReportPageResult } from "./incomingCallReportPage";
import {
    getIncomingCallRowDefaultKeys,
    mapIncomingCallRowKeyToRowLabel,
    splitWithIntermediateInternalIncomingPhoneNumberId,
    withWasAccepted,
    WithWasAccepted,
} from "./incomingCallRowUtils";
import ReportingLineChartCard from "@/app/pages/reporting/ReportingLineChartCard.vue";
import { WithCreatedRecurringTimeSlot } from "@/app/pages/reporting/activityRowUtils";
import { getReportingTableByReportingLineChart } from "@/app/pages/reporting/chartUtils";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { hasNonZeroSeries, ReportingLineChartData } from "@/app/pages/reporting/charts/reportingLineChart";
import { TOTAL_COLOR } from "@/app/pages/reporting/colors";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData, transpose } from "@/app/pages/reporting/table/reportingTable";
import {
    getRecurringTimeSlotKeys,
    getRecurringTimeSlotLabel,
    RecurringTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type PrecomputedProperties = WithCreatedRecurringTimeSlot & WithWasAccepted;

type ComputedIncomingCallRow = ExtendedIncomingCallRow<PrecomputedProperties>;

type ComputedIncomingCallRowGroupBy = keyof Pick<
    ComputedIncomingCallRow,
    "acceptorUserId" | "dealerId" | "issueType" | "wasAccepted"
>;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedIncomingCallRowGroupBy;
}

export default Vue.extend({
    data() {
        return {
            evaluationType: EvaluationType.PERCENTAGE,
            groupBy: "wasAccepted" as ComputedIncomingCallRowGroupBy,
            recurringTimeInterval: RecurringTimeInterval.HOUR_OF_DAY as RecurringTimeInterval,
            RecurringTimeInterval,
            result: null as IncomingCallReportPageResult<PrecomputedProperties> | null,
        };
    },

    computed: {
        chart(): ReportingLineChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const allRows = this.rowGroups.reduce(
                (prev, rowGroup) => prev.concat(rowGroup.rows),
                [] as ComputedIncomingCallRow[]
            );

            const rowsBySlot = this.recurringTimeIntervalSlotKeys.reduce((map, slot) => {
                map.set(
                    slot,
                    allRows.filter((r) => r.createdRecurringTimeSlot === slot)
                );

                return map;
            }, new Map<number, ComputedIncomingCallRow[]>());

            const chart: ReportingLineChartData = {
                title: this.$t("Stoßzeiten") as string,
                categories: this.recurringTimeIntervalSlotKeys
                    .map((slot) => getRecurringTimeSlotLabel(slot, this.recurringTimeInterval))
                    .map((category) => category || (this.$t("Unbekannt") as string)),
                series: [
                    {
                        id: "total",
                        name: this.$t("Gesamt") as string,
                        selected: false,
                        data: {
                            values: this.recurringTimeIntervalSlotKeys.map((slot) => ({
                                value: rowsBySlot.get(slot)!.length,
                            })),
                        },
                        color: TOTAL_COLOR,
                    },
                    ...[...this.rowGroups]
                        .sort((a, b) => b.rows.length - a.rows.length)
                        .map((rowGroup, index) => ({
                            id: `group-${index}`,
                            name: rowGroup.title,
                            data: {
                                values: this.recurringTimeIntervalSlotKeys.map((slot) => {
                                    const slotRows = rowGroup.rows.filter((r) => r.createdRecurringTimeSlot === slot);

                                    return {
                                        value:
                                            this.evaluationType === EvaluationType.PERCENTAGE
                                                ? rate(slotRows.length, rowsBySlot.get(slot)!.length)
                                                : slotRows.length,
                                    };
                                }),
                                isPercentage: this.evaluationType === EvaluationType.PERCENTAGE,
                            },
                            additionalTooltipData: [
                                {
                                    values: this.recurringTimeIntervalSlotKeys.map((slot) => {
                                        const slotRows = rowGroup.rows.filter(
                                            (r) => r.createdRecurringTimeSlot === slot
                                        );

                                        return {
                                            value:
                                                this.evaluationType === EvaluationType.PERCENTAGE
                                                    ? slotRows.length
                                                    : rate(slotRows.length, rowsBySlot.get(slot)!.length),
                                        };
                                    }),
                                    isPercentage: this.evaluationType !== EvaluationType.PERCENTAGE,
                                },
                            ],
                            colorPalette:
                                this.groupBy === "wasAccepted" && rowGroup.key === true
                                    ? ReportingChartColorPalette.POSITIVE
                                    : this.groupBy === "wasAccepted" && rowGroup.key === false
                                    ? ReportingChartColorPalette.NEGATIVE
                                    : undefined,
                        })),
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        computedIncomingCallRows(): readonly ComputedIncomingCallRow[] {
            return this.result?.rows ?? [];
        },

        defaultKeys(): RowKey[] {
            return getIncomingCallRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        evaluationTypeOptions(): SelectOption[] {
            return [
                {
                    text: this.$t("Absolut"),
                    value: EvaluationType.ABSOLUTE,
                },
                {
                    text: this.$t("Prozentual"),
                    value: EvaluationType.PERCENTAGE,
                },
            ];
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "wasAccepted",
                    text: this.$t("Angenommen"),
                },
                {
                    value: "acceptorUserId",
                    text: this.$t("Annehmer"),
                },
                {
                    value: "issueType",
                    text: this.$t("Art des Vorgangs"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
            ];
        },

        groupByText(): string | null {
            return (this.groupByOptions.find((o) => o.value === this.groupBy)?.text ?? null) as string | null;
        },

        recurringTimeIntervalSlotKeys(): number[] {
            return getRecurringTimeSlotKeys(this.recurringTimeInterval);
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedIncomingCallRow>[] {
            let computedRows = this.computedIncomingCallRows;

            if (this.groupBy !== "acceptorUserId") {
                computedRows = computedRows
                    .map(splitWithIntermediateInternalIncomingPhoneNumberId)
                    .reduce((prev, cur) => prev.concat(cur), [])
                    .map(withWasAccepted);
            }

            const rowGroups = groupRowsBy(computedRows, (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapIncomingCallRowKeyToRowLabel(rowGroup.key, this.groupBy, {
                    ...(this.result?.mapKeyToLabelContext ?? {}),
                    recurringTimeInterval: this.recurringTimeInterval,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            const table = transpose(getReportingTableByReportingLineChart(this.chart));

            return {
                headerGroups: table.headerGroups,
                items: table.items.slice(1),
                totals: table.items[0],
                groupByHeaderText: this.groupByText ?? undefined,
            };
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    components: {
        IncomingCallReportPage,
        ReportingLineChartCard,
    },
});
