import { RowGroup } from "./pivotUtils";

export const UNDETERMINED_ERROR: Error = Object.freeze({ error: "UNDETERMINED" });

export type Marker = { marker: string };
export type Error = { error: string };
export type Undetermined = typeof UNDETERMINED_ERROR;

export type RowKey = string | number | boolean | null | Marker | Error;

export interface RowLabel {
    readonly label: string;
    readonly sublabel?: string;
}

export interface TitledRowGroup<K, T> extends RowGroup<K, T> {
    readonly title: string;
    readonly subtitle?: string;
}

export function isError(key: RowKey): key is Error {
    return key !== null && typeof key === "object" && typeof (key as any)?.error === "string";
}

export function isSameError<E extends Error>(key: RowKey, e: E): key is E {
    return isError(key) && key.error === e.error;
}

export function isSameMarker<M extends Marker>(key: RowKey, m: M): key is M {
    return key !== null && typeof key === "object" && (key as any)?.marker === m.marker;
}
