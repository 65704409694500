
import { OutgoingEmailRow } from "@/api/reporting";
import { WithIssueType } from "@/app/pages/reporting/activityRowUtils";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { EMAIL_COLOR } from "@/app/pages/reporting/colors";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { mapOutgoingEmailRowKeyToRowLabel } from "@/app/pages/reporting/outgoingemails/outgoingEmailRowUtils";
import { addMissingRowGroups, count, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedOutgoingEmailRow = OutgoingEmailRow & WithIssueType;

type ComputedOutgoingEmailRowGroupBy = keyof Pick<
    ComputedOutgoingEmailRow,
    "dealerId" | "initiatorUserId" | "issueType"
>;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "ISSUE_TYPE" | "DEALER" | "INITIATOR",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => ComputedOutgoingEmailRow[],
            default: () => [],
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const emails = count(this.rowGroups, () => true);

            const topCategories = this.rowGroups
                .map((rowGroup, index) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    index,
                    value: rowGroup.rows.length,
                }))
                .filter((v) => v.value || (v.categoryId && this.defaultGroupIds.includes(v.categoryId)))
                .sort((a, b) => b.value - a.value);

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "send",
                        name: this.$t("Gesendet") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: emails[c.index],
                            })),
                        },
                        color: EMAIL_COLOR,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        groupByField(): ComputedOutgoingEmailRowGroupBy | null {
            if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "INITIATOR") {
                return "initiatorUserId";
            } else if (this.groupBy === "ISSUE_TYPE") {
                return "issueType";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string | null, ComputedOutgoingEmailRow>[] {
            const rowGroups = this.groupByField ? groupRowsBy(this.rows, (r) => r[this.groupByField!]) : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapOutgoingEmailRowKeyToRowLabel(rowGroup.key, this.groupByField!);

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "INITIATOR"
                        ? (this.$t("Absender") as string)
                        : this.groupBy === "ISSUE_TYPE"
                        ? (this.$t("Art des Vorgangs") as string)
                        : undefined,
            };
        },
    },

    components: {
        ReportingDashboardBarTile,
    },
});
