
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import { mapCaseRowKeyToRowLabel, WithCaseTypeGroup } from "@/app/pages/reporting/cases/caseRowUtils";
import { getReportingTableByReportingPunchCardChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingPunchCardChartData } from "@/app/pages/reporting/charts/reportingPunchCardChart";
import ReportingDashboardPunchCardTile from "@/app/pages/reporting/dashboard/ReportingDashboardPunchCardTile.vue";
import {
    Errorless,
    isWithWaitingTimeUntilFirstReactionErrorless,
    isWithWaitingTimeUntilFirstReactionIndividualDurationSlotErrorless,
    PotentiallyErroneous,
    WAITING_TIME_UNTIL_FIRST_REACTION_INDIVIDUAL_DURATION_SLOTS,
    WithWaitingTimeUntilFirstReaction,
    withWaitingTimeUntilFirstReactionIndividualDurationSlot,
    WithWaitingTimeUntilFirstReactionIndividualDurationSlot,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import { getIndividualDurationIntervalLabel, IndividualDurationInterval } from "@/app/pages/reporting/timeInterval";
import Vue from "vue";

type ComputedCaseRow = CaseRow &
    WithCaseTypeGroup &
    WithWaitingTimeUntilFirstReaction<Errorless> &
    WithWaitingTimeUntilFirstReactionIndividualDurationSlot<Errorless>;

type ComputedCaseRowGroupBy = keyof Pick<ComputedCaseRow, "dealerId" | "channel" | "caseType" | "caseTypeGroup">;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "DEALER" | "CHANNEL" | "CASE_TYPE" | "CASE_TYPE_GROUP",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => (CaseRow &
                WithCaseTypeGroup &
                WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>)[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingPunchCardChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    rows: rowGroup.rows,
                }))
                .map((c) => ({
                    ...c,
                    sortValue: c.rows.length,
                }))
                .filter((c) => c.sortValue || this.defaultGroupIds.includes(c.categoryId))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingPunchCardChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: this.waitingTimeUntilFirstReactionSlots.map((s) => ({
                    id: `waiting-time-until-first-reaction-slot-${s.key}`,
                    name: getIndividualDurationIntervalLabel(s),
                    data: {
                        values: topCategories.map((c) => {
                            const reactedRows = c.rows.filter(
                                (r) => r.waitingTimeUntilFirstReactionIndividualDurationSlot === s.key
                            );

                            return {
                                value: reactedRows.length,
                                onClick: this.showBottomSheetOnClick(reactedRows.map((r) => r.id)),
                            };
                        }),
                    },
                })),
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        groupByField(): ComputedCaseRowGroupBy | null {
            if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "CHANNEL") {
                return "channel";
            } else if (this.groupBy === "CASE_TYPE") {
                return "caseType";
            } else if (this.groupBy === "CASE_TYPE_GROUP") {
                return "caseTypeGroup";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string, ComputedCaseRow>[] {
            const computedRows: ComputedCaseRow[] = this.rows
                .filter(isWithWaitingTimeUntilFirstReactionErrorless)
                .map((r) =>
                    withWaitingTimeUntilFirstReactionIndividualDurationSlot(r, this.waitingTimeUntilFirstReactionSlots)
                )
                .filter(isWithWaitingTimeUntilFirstReactionIndividualDurationSlotErrorless);

            const rowGroups = this.groupByField ? groupRowsBy(computedRows, (r) => r[this.groupByField!]) : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapCaseRowKeyToRowLabel(rowGroup.key, this.groupByField!, {});

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingPunchCardChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "CHANNEL"
                        ? (this.$t("Kanal") as string)
                        : this.groupBy === "CASE_TYPE"
                        ? (this.$t("Fall-Typ") as string)
                        : this.groupBy === "CASE_TYPE_GROUP"
                        ? (this.$t("Fall-Kategorie") as string)
                        : undefined,
            };
        },

        waitingTimeUntilFirstReactionSlots(): IndividualDurationInterval[] {
            return [...WAITING_TIME_UNTIL_FIRST_REACTION_INDIVIDUAL_DURATION_SLOTS];
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardPunchCardTile,
    },
});
