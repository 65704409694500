
import { SentimentType } from "@/api/opportunitySearch";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import {
    SENTIMENT_NEGATIVE,
    SENTIMENT_NEUTRAL,
    SENTIMENT_POSITIVE,
    SENTIMENT_UNKNOWN,
} from "@/app/pages/reporting/colors";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { WithSentimentType } from "@/app/pages/reporting/issueRowUtils";
import { mapOpportunityRowKeyToRowLabel } from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedOpportunityRow = OpportunityRow & WithSentimentType;

type ComputedOpportunityRowGroupBy = keyof Pick<ComputedOpportunityRow, "dealerId" | "channel" | "sourceId">;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "DEALER" | "CHANNEL" | "SOURCE",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => ComputedOpportunityRow[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    positiveRows: rowGroup.rows.filter((r) => r.sentimentType === SentimentType.POSITIVE),
                    neutralRows: rowGroup.rows.filter((r) => r.sentimentType === SentimentType.NEUTRAL),
                    negativeRows: rowGroup.rows.filter((r) => r.sentimentType === SentimentType.NEGATIVE),
                    unknownRows: rowGroup.rows.filter((r) => r.sentimentType === SentimentType.UNKNOWN),
                    total: rowGroup.rows.length,
                }))
                .map((c) => ({
                    ...c,
                    sortValue: c.total,
                }))
                .filter((c) => c.sortValue || this.defaultGroupIds.includes(c.categoryId))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "sentiment-positive",
                        name: this.$t(`enum.SentimentType.${SentimentType.POSITIVE}`) as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: rate(c.positiveRows.length, c.total),
                                onClick: this.showBottomSheetOnClick(c.positiveRows.map((r) => r.id)),
                            })),
                            isPercentage: true,
                        },
                        color: SENTIMENT_POSITIVE,
                        additionalTooltipData: [
                            {
                                values: topCategories.map((c) => ({
                                    value: c.positiveRows.length,
                                })),
                            },
                        ],
                    },
                    {
                        id: "sentiment-neutral",
                        name: this.$t(`enum.SentimentType.${SentimentType.NEUTRAL}`) as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: rate(c.neutralRows.length, c.total),
                                onClick: this.showBottomSheetOnClick(c.neutralRows.map((r) => r.id)),
                            })),
                            isPercentage: true,
                        },
                        color: SENTIMENT_NEUTRAL,
                        additionalTooltipData: [
                            {
                                values: topCategories.map((c) => ({
                                    value: c.neutralRows.length,
                                })),
                            },
                        ],
                    },
                    {
                        id: "sentiment-negative",
                        name: this.$t(`enum.SentimentType.${SentimentType.NEGATIVE}`) as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: rate(c.negativeRows.length, c.total),
                                onClick: this.showBottomSheetOnClick(c.negativeRows.map((r) => r.id)),
                            })),
                            isPercentage: true,
                        },
                        color: SENTIMENT_NEGATIVE,
                        additionalTooltipData: [
                            {
                                values: topCategories.map((c) => ({
                                    value: c.negativeRows.length,
                                })),
                            },
                        ],
                    },
                    {
                        id: "sentiment-unknown",
                        name: this.$t(`enum.SentimentType.${SentimentType.UNKNOWN}`) as string,
                        selected: false,
                        data: {
                            values: topCategories.map((c) => ({
                                value: rate(c.unknownRows.length, c.total),
                                onClick: this.showBottomSheetOnClick(c.unknownRows.map((r) => r.id)),
                            })),
                            isPercentage: true,
                        },
                        color: SENTIMENT_UNKNOWN,
                        additionalTooltipData: [
                            {
                                values: topCategories.map((c) => ({
                                    value: c.unknownRows.length,
                                })),
                            },
                        ],
                    },
                ],
                seriesDataTooltipHeader: this.$t("Anteil") as string,
                seriesAdditionalTooltipDataTooltipHeaders: [this.$t("Verkaufschancen") as string],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        groupByField(): ComputedOpportunityRowGroupBy | null {
            if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "CHANNEL") {
                return "channel";
            } else if (this.groupBy === "SOURCE") {
                return "sourceId";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string, ComputedOpportunityRow>[] {
            const rowGroups = this.groupByField ? groupRowsBy(this.rows, (r) => r[this.groupByField!]) : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapOpportunityRowKeyToRowLabel(rowGroup.key, this.groupByField!, {});

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "CHANNEL"
                        ? (this.$t("Kanal") as string)
                        : this.groupBy === "SOURCE"
                        ? (this.$t("Quelle") as string)
                        : undefined,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardBarTile,
    },
});
