import { RowKey, RowLabel } from "./rowUtils";
import {
    getOngoingTimeSlotKey,
    getOngoingTimeSlotLabelByKey,
    getRecurringTimeSlotKey,
    getRecurringTimeSlotLabel,
    OngoingTimeInterval,
    RecurringTimeInterval,
} from "./timeInterval";
import { IssueType } from "@/api/reporting";
import { $t } from "@/app/i18n";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";

/*
 * with created ongoing time slot
 */

export interface WithCreatedOngoingTimeSlot {
    readonly createdOngoingTimeSlot: string;
}

export function withCreatedOngoingTimeSlot<T extends { created: Date }>(
    row: T,
    ongoingTimeInterval: OngoingTimeInterval,
    timeZone: string
): T & WithCreatedOngoingTimeSlot {
    return {
        ...row,
        createdOngoingTimeSlot: getOngoingTimeSlotKey(row.created, ongoingTimeInterval, timeZone)!,
    };
}

/*
 * with created recurring time slot
 */

export interface WithCreatedRecurringTimeSlot {
    readonly createdRecurringTimeSlot: number;
}

export function withCreatedRecurringTimeSlot<T extends { created: Date }>(
    row: T,
    recurringTimeInterval: RecurringTimeInterval,
    timeZone: string
): T & WithCreatedRecurringTimeSlot {
    return {
        ...row,
        createdRecurringTimeSlot: getRecurringTimeSlotKey(row.created, recurringTimeInterval, timeZone)!,
    };
}

/*
 * with issue type
 */

export interface WithIssueType {
    readonly issueType: IssueType;
}

export function withIssueType<
    T extends { caseId?: string | null; opportunityId?: string | null; emergencyIncidentId?: string | null }
>(row: T): T & WithIssueType {
    return {
        ...row,
        issueType: row.caseId
            ? IssueType.CASE
            : row.opportunityId
            ? IssueType.OPPORTUNITY
            : row.emergencyIncidentId
            ? IssueType.EMERGENCY_INCIDENT
            : IssueType.UNKNOWN,
    };
}

/*
 * misc
 */

export interface MapActivityRowKeyToLabelContext {
    readonly ongoingTimeInterval?: OngoingTimeInterval;
    readonly recurringTimeInterval?: RecurringTimeInterval;
}

export function mapActivityRowKeyToLabel(
    key: RowKey,
    groupBy: string,
    context?: MapActivityRowKeyToLabelContext
): RowLabel {
    const { ongoingTimeInterval, recurringTimeInterval } = context ?? {};

    if (groupBy === "createdOngoingTimeSlot" && typeof key === "string" && ongoingTimeInterval) {
        return {
            label: getOngoingTimeSlotLabelByKey(key, ongoingTimeInterval, userSession.timeZone, "L") || "",
        };
    } else if (groupBy === "createdRecurringTimeSlot" && typeof key === "number" && recurringTimeInterval) {
        return { label: getRecurringTimeSlotLabel(key, recurringTimeInterval) || "" };
    } else if (groupBy === "dealerId") {
        if (key === null) {
            return { label: $t("Ohne Standort") as string };
        } else if (typeof key === "string") {
            return { label: dealersStore.dealerById(key)?.name || ($t("Unbekannter Standort") as string) };
        }
    }
    if (groupBy === "issueType" && typeof key === "string") {
        return { label: $t(`enum.IssueType.${key}`) as string };
    }

    return { label: $t("Unbekannt") as string };
}

export interface GetActivityRowDefaultKeysContext {
    readonly visibleDealerIds?: readonly string[];
    readonly visibleIssueTypes?: readonly string[];
}

export function getActivityRowDefaultKeys(groupBy: string, context?: GetActivityRowDefaultKeysContext): RowKey[] {
    const { visibleDealerIds, visibleIssueTypes } = context ?? {};

    if (groupBy === "createdOngoingTimeSlot") {
        return [];
    } else if (groupBy === "dealerId" && visibleDealerIds) {
        return dealersStore.dealers.map((u) => u.id).filter((id) => visibleDealerIds.includes(id));
    } else if (groupBy === "issueType" && visibleIssueTypes) {
        return Object.keys(IssueType).filter((t) => visibleIssueTypes.includes(t));
    }

    return [];
}
