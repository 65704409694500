import { InternalIncomingPhoneNumber } from "@/api/incomingPhoneNumbers";
import { $t } from "@/app/i18n";
import {
    getActivityRowDefaultKeys,
    GetActivityRowDefaultKeysContext,
    mapActivityRowKeyToLabel,
    MapActivityRowKeyToLabelContext,
} from "@/app/pages/reporting/activityRowUtils";
import { isSameMarker, Marker, RowKey, RowLabel } from "@/app/pages/reporting/rowUtils";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";

export const FORWARDED_MARKER: Marker = Object.freeze({ marker: "FORWARDED" });

export type ForwardedMarker = typeof FORWARDED_MARKER;

/*
 * with intermediate internal incoming phone number id
 */

export interface WithIntermediateInternalIncomingPhoneNumberId {
    readonly intermediateInternalIncomingPhoneNumberId: string;
    readonly isCallBeganAtThisIntermediateInternalIncomingPhoneNumberId: boolean;
    readonly isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId: boolean;
}

function getIntermediateInternalIncomingPhoneNumberIds<
    T extends {
        internalIncomingPhoneNumberId: string;
        forwardedToExternalBdc: boolean;
        forwardedFromInternalIncomingPhoneNumberIds: string[];
    }
>(r: T): string[] {
    return [
        ...r.forwardedFromInternalIncomingPhoneNumberIds.filter(
            (n, index, array) => n !== r.internalIncomingPhoneNumberId && array.indexOf(n) === index
        ),
        r.internalIncomingPhoneNumberId,
    ];
}

export function withLastIntermediateInternalIncomingPhoneNumberId<
    T extends {
        internalIncomingPhoneNumberId: string;
        forwardedToExternalBdc: boolean;
        forwardedToCustomPhoneNumber: boolean;
        forwardedFromInternalIncomingPhoneNumberIds: string[];
    }
>(r: T): T & WithIntermediateInternalIncomingPhoneNumberId {
    const intermediateNumberIds = getIntermediateInternalIncomingPhoneNumberIds(r);

    return {
        ...r,
        intermediateInternalIncomingPhoneNumberId: r.internalIncomingPhoneNumberId,
        isCallBeganAtThisIntermediateInternalIncomingPhoneNumberId:
            intermediateNumberIds[0] === r.internalIncomingPhoneNumberId,
        isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId:
            !r.forwardedToExternalBdc &&
            !r.forwardedToCustomPhoneNumber &&
            intermediateNumberIds[intermediateNumberIds.length - 1] === r.internalIncomingPhoneNumberId,
    };
}

export function splitWithIntermediateInternalIncomingPhoneNumberId<
    T extends {
        internalIncomingPhoneNumberId: string;
        forwardedToExternalBdc: boolean;
        forwardedToCustomPhoneNumber: boolean;
        forwardedFromInternalIncomingPhoneNumberIds: string[];
    }
>(r: T): readonly (T & WithIntermediateInternalIncomingPhoneNumberId)[] {
    return getIntermediateInternalIncomingPhoneNumberIds(r).map((n, index) => ({
        ...r,
        intermediateInternalIncomingPhoneNumberId: n,
        isCallBeganAtThisIntermediateInternalIncomingPhoneNumberId: index === 0,
        isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId:
            !r.forwardedToExternalBdc && !r.forwardedToCustomPhoneNumber && n === r.internalIncomingPhoneNumberId,
    }));
}

/*
 * with is accepted
 */

export interface WithWasAccepted {
    readonly wasAccepted: boolean | ForwardedMarker;
}

export function withWasAccepted<
    T extends {
        duration: number | null;
        isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId: boolean;
    }
>(row: T): T & WithWasAccepted {
    return {
        ...row,
        wasAccepted: row.isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId
            ? row.duration !== null
            : FORWARDED_MARKER,
    };
}

/*
 * misc
 */

export type MapIncomingCallRowKeyToLabelContext = MapActivityRowKeyToLabelContext & {
    readonly internalIncomingPhoneNumbers?: readonly InternalIncomingPhoneNumber[];
};

export function mapIncomingCallRowKeyToRowLabel(
    key: RowKey,
    groupBy: string,
    context?: MapIncomingCallRowKeyToLabelContext
): RowLabel {
    const { internalIncomingPhoneNumbers } = context ?? {};

    if (groupBy === "acceptorUserId") {
        if (key === null) {
            return { label: $t("Ohne Benutzer") as string };
        } else if (typeof key === "string") {
            const user = usersStore.getUserById(key);

            if (!user) {
                return { label: $t("Gelöschter Benutzer") as string };
            }

            return { label: getFullName(user) };
        }
    } else if (
        (groupBy === "internalIncomingPhoneNumberId" || groupBy === "intermediateInternalIncomingPhoneNumberId") &&
        typeof key === "string"
    ) {
        const internalIncomingPhoneNumber = (internalIncomingPhoneNumbers || []).find((p) => p.id === key);

        if (!internalIncomingPhoneNumber) {
            return { label: $t("Gelöschte Rufnummer") as string };
        }

        const formattedNumber = parseAndFormatNumber(internalIncomingPhoneNumber.number, "INTERNATIONAL") || key;

        if (!internalIncomingPhoneNumber.name) {
            return { label: formattedNumber };
        }

        return { label: formattedNumber, sublabel: internalIncomingPhoneNumber.name };
    } else if (groupBy === "wasAccepted") {
        if (key === true) {
            return { label: $t("Angenommen") as string };
        } else if (key === false) {
            return { label: $t("Nicht angenommen") as string };
        } else if (isSameMarker(key, FORWARDED_MARKER)) {
            return { label: $t("Weitergeleitet") as string };
        }
    }

    return mapActivityRowKeyToLabel(key, groupBy, context ?? {});
}

export type GetIncomingCallRowDefaultKeysContext = GetActivityRowDefaultKeysContext & {
    readonly visibleAcceptorUserIds?: readonly string[];
};

export function getIncomingCallRowDefaultKeys(
    groupBy: string,
    context?: GetIncomingCallRowDefaultKeysContext
): RowKey[] {
    return getActivityRowDefaultKeys(groupBy, context);
}
