
import { SentimentType } from "@/api/caseSearch";
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import { getReportingTableByReportingTimeSeriesChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import {
    SENTIMENT_NEGATIVE,
    SENTIMENT_NEUTRAL,
    SENTIMENT_POSITIVE,
    SENTIMENT_UNKNOWN,
} from "@/app/pages/reporting/colors";
import ReportingDashboardTimeSeriesTile from "@/app/pages/reporting/dashboard/ReportingDashboardTimeSeriesTile.vue";
import {
    WithCreatedOngoingTimeSlot,
    withCreatedOngoingTimeSlot,
    WithSentimentType,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import {
    getOngoingTimeSlotKeys,
    getOngoingTimeSlotLabel,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { userSession } from "@/store/userSession";
import Vue from "vue";

type ComputedCaseRow = CaseRow & WithCreatedOngoingTimeSlot & WithSentimentType;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        chartTimeInterval: {
            type: String as () => OngoingTimeInterval,
            default: OngoingTimeInterval.WEEK,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => ComputedCaseRow[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        timeSeriesFrom: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        timeSeriesTo: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingTimeSeriesChartData | undefined {
            const data = this.rowGroups.map((rowGroup) => ({
                key: rowGroup.key,
                positiveRows: rowGroup.rows.filter((r) => r.sentimentType === SentimentType.POSITIVE),
                neutralRows: rowGroup.rows.filter((r) => r.sentimentType === SentimentType.NEUTRAL),
                negativeRows: rowGroup.rows.filter((r) => r.sentimentType === SentimentType.NEGATIVE),
                unknownRows: rowGroup.rows.filter((r) => r.sentimentType === SentimentType.UNKNOWN),
                total: rowGroup.rows.length,
            }));

            const chart: ReportingTimeSeriesChartData = {
                title: "",
                series: [
                    {
                        id: "sentiment-positive",
                        name: this.$t(`enum.SentimentType.${SentimentType.POSITIVE}`) as string,
                        data: {
                            values: data.map((d) => ({
                                date: new Date(d.key),
                                value: rate(d.positiveRows.length, d.total),
                                onClick: this.showBottomSheetOnClick(d.positiveRows.map((r) => r.id)),
                            })),
                            isPercentage: true,
                        },
                        color: SENTIMENT_POSITIVE,
                        additionalTooltipData: [
                            {
                                values: data.map((d) => ({
                                    date: new Date(d.key),
                                    value: d.positiveRows.length,
                                })),
                            },
                        ],
                    },
                    {
                        id: "sentiment-neutral",
                        name: this.$t(`enum.SentimentType.${SentimentType.NEUTRAL}`) as string,
                        data: {
                            values: data.map((d) => ({
                                date: new Date(d.key),
                                value: rate(d.neutralRows.length, d.total),
                                onClick: this.showBottomSheetOnClick(d.neutralRows.map((r) => r.id)),
                            })),
                            isPercentage: true,
                        },
                        color: SENTIMENT_NEUTRAL,
                        additionalTooltipData: [
                            {
                                values: data.map((d) => ({
                                    date: new Date(d.key),
                                    value: d.neutralRows.length,
                                })),
                            },
                        ],
                    },
                    {
                        id: "sentiment-negative",
                        name: this.$t(`enum.SentimentType.${SentimentType.NEGATIVE}`) as string,
                        data: {
                            values: data.map((d) => ({
                                date: new Date(d.key),
                                value: rate(d.negativeRows.length, d.total),
                                onClick: this.showBottomSheetOnClick(d.negativeRows.map((r) => r.id)),
                            })),
                            isPercentage: true,
                        },
                        color: SENTIMENT_NEGATIVE,
                        additionalTooltipData: [
                            {
                                values: data.map((d) => ({
                                    date: new Date(d.key),
                                    value: d.negativeRows.length,
                                })),
                            },
                        ],
                    },
                    {
                        id: "sentiment-unknown",
                        name: this.$t(`enum.SentimentType.${SentimentType.UNKNOWN}`) as string,
                        selected: false,
                        data: {
                            values: data.map((d) => ({
                                date: new Date(d.key),
                                value: rate(d.unknownRows.length, d.total),
                                onClick: this.showBottomSheetOnClick(d.unknownRows.map((r) => r.id)),
                            })),
                            isPercentage: true,
                        },
                        color: SENTIMENT_UNKNOWN,
                        additionalTooltipData: [
                            {
                                values: data.map((d) => ({
                                    date: new Date(d.key),
                                    value: d.unknownRows.length,
                                })),
                            },
                        ],
                    },
                ],
                dateFormatter: this.dateFormatter,
                seriesDataTooltipHeader: this.$t("Anteil") as string,
                seriesAdditionalTooltipDataTooltipHeaders: [this.$t("Fälle") as string],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        defaultKeys(): string[] {
            return getOngoingTimeSlotKeys(
                this.timeSeriesFrom,
                this.timeSeriesTo,
                this.chartTimeInterval,
                userSession.timeZone,
                true
            );
        },

        rowGroups(): RowGroup<string, ComputedCaseRow>[] {
            const filteredRows = this.rows
                .filter(
                    (row) =>
                        this.timeSeriesFrom.getTime() <= row.created.getTime() &&
                        row.created.getTime() <= this.timeSeriesTo.getTime()
                )
                .map((row) => withCreatedOngoingTimeSlot(row, this.chartTimeInterval));

            const rowGroups = groupRowsBy(filteredRows, (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingTimeSeriesChart(this.chart),
                groupByHeaderText: this.$t("Zeitraum") as string,
            };
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.chartTimeInterval, userSession.timeZone, short ? "S" : "L") || "";
        },

        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardTimeSeriesTile,
    },
});
