
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import {
    mapCaseRowKeyToRowLabel,
    splitWithCaseEscalationGroupReceiverId,
    WithCaseEscalationGroupReceiverId,
    withCaseEscalationGroupReceiverIdSetToNull,
} from "@/app/pages/reporting/cases/caseRowUtils";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { ColorableReportingChartSeries, getColoredChartSeries } from "@/app/pages/reporting/charts/reportingChart";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { splitWithAssignee, WithAssignee, withAssigneeSetToNull } from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import { caseTypesStore } from "@/store/caseTypes";
import Vue from "vue";

type ComputedCaseRow = CaseRow & WithAssignee & WithCaseEscalationGroupReceiverId;

type ComputedCaseRowGroupBy = keyof Pick<
    ComputedCaseRow,
    "assigneeId" | "channel" | "dealerId" | "escalationGroupReceiverId"
>;

export default Vue.extend({
    props: {
        caseTypes: {
            type: Array as () => string[],
            required: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "ASSIGNEE" | "CHANNEL" | "DEALER" | "ESCALATION_GROUP_RECEIVER",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => CaseRow[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        caseTypeGroupColors(): ColorableReportingChartSeries[] {
            return getColoredChartSeries(
                caseTypesStore.caseTypeGroups.map((caseTypeGroup) => ({
                    name: this.$t(`enum.CaseTypeGroup.${caseTypeGroup.name}`) as string,
                    color: undefined,
                }))
            );
        },

        chart(): ReportingBarChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    rows: rowGroup.rows,
                    sortValue: rowGroup.rows.length,
                }))
                .filter((c) => c.sortValue || (!!c.categoryId && this.defaultGroupIds.includes(c.categoryId)))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: caseTypesStore.caseTypeGroups
                    .filter((caseTypeGroup) =>
                        caseTypeGroup.caseTypes.some((caseType) => this.caseTypes.includes(caseType))
                    )
                    .map((caseTypeGroup) => ({
                        id: caseTypeGroup.name,
                        name: this.$t(`enum.CaseTypeGroup.${caseTypeGroup.name}`) as string,
                        data: {
                            values: topCategories.map((c) => {
                                const caseTypeRows = c.rows.filter((r) => caseTypeGroup.caseTypes.includes(r.caseType));

                                return {
                                    value: caseTypeRows.length,
                                    onClick: this.showBottomSheetOnClick(caseTypeRows.map((r) => r.id)),
                                };
                            }),
                        },
                    }))
                    .map((s) => ({
                        ...s,
                        color: this.caseTypeGroupColors.find((cs) => cs.name === s.name)?.color,
                    })),
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        groupByField(): ComputedCaseRowGroupBy | null {
            if (this.groupBy === "ASSIGNEE") {
                return "assigneeId";
            } else if (this.groupBy === "CHANNEL") {
                return "channel";
            } else if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "ESCALATION_GROUP_RECEIVER") {
                return "escalationGroupReceiverId";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string | null, ComputedCaseRow>[] {
            let computedCaseRows: readonly ComputedCaseRow[] = this.rows
                .map(withAssigneeSetToNull)
                .map(withCaseEscalationGroupReceiverIdSetToNull);

            if (this.groupBy === "ASSIGNEE") {
                computedCaseRows = splitWithAssignee(computedCaseRows);
            } else if (this.groupBy === "ESCALATION_GROUP_RECEIVER") {
                computedCaseRows = splitWithCaseEscalationGroupReceiverId(computedCaseRows);
            }

            const rowGroups = this.groupByField ? groupRowsBy(computedCaseRows, (r) => r[this.groupByField!]) : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapCaseRowKeyToRowLabel(rowGroup.key, this.groupByField!, {});

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "ASSIGNEE"
                        ? "Bearbeiter"
                        : this.groupBy === "CHANNEL"
                        ? (this.$t("Kanal") as string)
                        : this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "ESCALATION_GROUP_RECEIVER"
                        ? (this.$t("Eskalationsgruppe") as string)
                        : undefined,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardBarTile,
    },
});
