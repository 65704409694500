
import { OpportunityStatus } from "@/api/opportunities";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { ISSUE_STATUS_CLOSED_COLOR } from "@/app/pages/reporting/colors";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { splitWithAssignee, WithAssignee, withAssigneeSetToNull } from "@/app/pages/reporting/issueRowUtils";
import {
    mapOpportunityRowKeyToRowLabel,
    splitWithOpportunityTeamReceiverId,
    WithOpportunityStatus,
    WithOpportunityTeamReceiverId,
    withOpportunityTeamReceiverIdSetToNull,
} from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedOpportunityRow = OpportunityRow & WithAssignee & WithOpportunityStatus & WithOpportunityTeamReceiverId;

type ComputedOpportunityRowGroupBy = keyof Pick<
    ComputedOpportunityRow,
    "assigneeId" | "channel" | "dealerId" | "sourceId" | "opportunityTeamReceiverId"
>;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "ASSIGNEE" | "CHANNEL" | "DEALER" | "SOURCE" | "OPPORTUNITY_TEAM_RECEIVER",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => (OpportunityRow & WithOpportunityStatus)[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => {
                    const closedRows = rowGroup.rows.filter((r) => r.opportunityStatus === OpportunityStatus.CLOSED);

                    return {
                        categoryId: rowGroup.key,
                        name: rowGroup.title,
                        description: rowGroup.subtitle,
                        closedRows,
                        wonRows: closedRows.filter((r) => r.outcome),
                        total: rowGroup.rows.length,
                    };
                })
                .map((c) => ({
                    ...c,
                    sortValue: c.total,
                }))
                .filter((c) => c.sortValue || (!!c.categoryId && this.defaultGroupIds.includes(c.categoryId)))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "closed",
                        name: this.$t("Geschlossene Verkaufschancen") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.closedRows.length,
                                onClick: this.showBottomSheetOnClick(c.closedRows.map((r) => r.id)),
                            })),
                        },
                        color: ISSUE_STATUS_CLOSED_COLOR,
                    },
                    {
                        id: "conversion-rate",
                        name: this.$t("Conversion Rate") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: rate(c.wonRows.length, c.closedRows.length),
                                onClick: this.showBottomSheetOnClick(c.wonRows.map((r) => r.id)),
                            })),
                            isPercentage: true,
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        groupByField(): ComputedOpportunityRowGroupBy | null {
            if (this.groupBy === "ASSIGNEE") {
                return "assigneeId";
            } else if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "CHANNEL") {
                return "channel";
            } else if (this.groupBy === "SOURCE") {
                return "sourceId";
            } else if (this.groupBy === "OPPORTUNITY_TEAM_RECEIVER") {
                return "opportunityTeamReceiverId";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string | null, ComputedOpportunityRow>[] {
            if (!this.groupByField) {
                return [];
            }

            let computedOpportunityRows: readonly ComputedOpportunityRow[] = this.rows
                .map(withAssigneeSetToNull)
                .map(withOpportunityTeamReceiverIdSetToNull);

            if (this.groupBy === "ASSIGNEE") {
                computedOpportunityRows = splitWithAssignee(computedOpportunityRows);
            } else if (this.groupBy === "OPPORTUNITY_TEAM_RECEIVER") {
                computedOpportunityRows = splitWithOpportunityTeamReceiverId(computedOpportunityRows);
            }

            const rowGroups = this.groupByField
                ? groupRowsBy(computedOpportunityRows, (r) => r[this.groupByField!])
                : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapOpportunityRowKeyToRowLabel(rowGroup.key, this.groupByField!, {});

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "ASSIGNEE"
                        ? (this.$t("Verkäufer") as string)
                        : this.groupBy === "CHANNEL"
                        ? (this.$t("Kanal") as string)
                        : this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "SOURCE"
                        ? (this.$t("Quelle") as string)
                        : this.groupBy === "OPPORTUNITY_TEAM_RECEIVER"
                        ? (this.$t("Verkäuferteam") as string)
                        : undefined,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardBarTile,
    },
});
