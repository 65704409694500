
import { CaseStatus } from "@/api/cases";
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import { WithCaseStatus } from "@/app/pages/reporting/cases/caseRowUtils";
import { getReportingTableItemsByReportingDonutChartSeries } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingDonutChartData } from "@/app/pages/reporting/charts/reportingDonutChart";
import { getCaseStatusColor } from "@/app/pages/reporting/colors";
import ReportingDashboardDonutTile from "@/app/pages/reporting/dashboard/ReportingDashboardDonutTile.vue";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedCaseRow = CaseRow & WithCaseStatus;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        rows: {
            type: Array as () => ComputedCaseRow[],
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingDonutChartData | undefined {
            const chart: ReportingDonutChartData = {
                title: "",
                series: [CaseStatus.CLOSED, CaseStatus.POSTPONED, CaseStatus.ASSIGNED, CaseStatus.OPEN].map(
                    (caseStatus) => {
                        const statusRows = this.rows.filter((r) => r.caseStatus === caseStatus);

                        return {
                            id: `status-${caseStatus}`,
                            name: this.$t(`enum.CaseStatus.${caseStatus}`) as string,
                            data: {
                                value: {
                                    value: statusRows.length,
                                    onClick: this.showBottomSheetOnClick(statusRows.map((r) => r.id)),
                                },
                            },
                            color: getCaseStatusColor(caseStatus),
                        };
                    }
                ),
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }
            return {
                headerGroups: [
                    {
                        text: this.$t("Fälle") as string,
                        headers: [{ text: this.$t("Fälle") as string }],
                    },
                ],
                items: getReportingTableItemsByReportingDonutChartSeries(this.chart.series),
                groupByHeaderText: this.$t("Status") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardDonutTile,
    },
});
