
import { CaseStatus } from "@/api/cases";
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import {
    mapCaseRowKeyToRowLabel,
    splitWithCaseEscalationGroupReceiverId,
    WithCaseEscalationGroupReceiverId,
    withCaseEscalationGroupReceiverIdSetToNull,
    WithCaseStatus,
    WithCaseTypeGroup,
} from "@/app/pages/reporting/cases/caseRowUtils";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import {
    ISSUE_STATUS_ASSIGNED_COLOR,
    ISSUE_STATUS_CLOSED_COLOR,
    ISSUE_STATUS_OPEN_COLOR,
    ISSUE_STATUS_POSTPONED_COLOR,
} from "@/app/pages/reporting/colors";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { splitWithAssignee, WithAssignee, withAssigneeSetToNull } from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedCaseRow = CaseRow & WithAssignee & WithCaseEscalationGroupReceiverId & WithCaseStatus & WithCaseTypeGroup;

type ComputedCaseRowGroupBy = keyof Pick<
    ComputedCaseRow,
    "assigneeId" | "caseType" | "caseTypeGroup" | "channel" | "dealerId" | "escalationGroupReceiverId"
>;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () =>
                | "ASSIGNEE"
                | "CASE_TYPE"
                | "CASE_TYPE_GROUP"
                | "CHANNEL"
                | "DEALER"
                | "ESCALATION_GROUP_RECEIVER",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => ComputedCaseRow[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    openRows: rowGroup.rows.filter((r) => r.caseStatus === CaseStatus.OPEN),
                    assignedRows: rowGroup.rows.filter((r) => r.caseStatus === CaseStatus.ASSIGNED),
                    postponedRows: rowGroup.rows.filter((r) => r.caseStatus === CaseStatus.POSTPONED),
                    closedRows: rowGroup.rows.filter((r) => r.caseStatus === CaseStatus.CLOSED),
                }))
                .map((c) => ({
                    ...c,
                    sortValue: c.openRows.length + c.assignedRows.length + c.postponedRows.length + c.closedRows.length,
                }))
                .filter((c) => c.sortValue || (!!c.categoryId && this.defaultGroupIds.includes(c.categoryId)))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "closed",
                        name: this.$t("Geschlossen") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.closedRows.length,
                                onClick: this.showBottomSheetOnClick(c.closedRows.map((r) => r.id)),
                            })),
                        },
                        color: ISSUE_STATUS_CLOSED_COLOR,
                    },
                    {
                        id: "postponed",
                        name: this.$t("Zurückgestellt") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.postponedRows.length,
                                onClick: this.showBottomSheetOnClick(c.postponedRows.map((r) => r.id)),
                            })),
                        },
                        color: ISSUE_STATUS_POSTPONED_COLOR,
                    },
                    {
                        id: "assigned",
                        name: this.$t("Zugeteilt") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.assignedRows.length,
                                onClick: this.showBottomSheetOnClick(c.assignedRows.map((r) => r.id)),
                            })),
                        },
                        color: ISSUE_STATUS_ASSIGNED_COLOR,
                    },
                    {
                        id: "open",
                        name: this.$t("Offen") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.openRows.length,
                                onClick: this.showBottomSheetOnClick(c.openRows.map((r) => r.id)),
                            })),
                        },
                        color: ISSUE_STATUS_OPEN_COLOR,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        groupByField(): ComputedCaseRowGroupBy | null {
            if (this.groupBy === "ASSIGNEE") {
                return "assigneeId";
            } else if (this.groupBy === "CASE_TYPE") {
                return "caseType";
            } else if (this.groupBy === "CASE_TYPE_GROUP") {
                return "caseTypeGroup";
            } else if (this.groupBy === "CHANNEL") {
                return "channel";
            } else if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "ESCALATION_GROUP_RECEIVER") {
                return "escalationGroupReceiverId";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string | null, ComputedCaseRow>[] {
            let computedCaseRows: readonly ComputedCaseRow[] = this.rows
                .map(withAssigneeSetToNull)
                .map(withCaseEscalationGroupReceiverIdSetToNull);

            if (this.groupBy === "ASSIGNEE") {
                computedCaseRows = splitWithAssignee(computedCaseRows);
            } else if (this.groupBy === "ESCALATION_GROUP_RECEIVER") {
                computedCaseRows = splitWithCaseEscalationGroupReceiverId(computedCaseRows);
            }

            const rowGroups = this.groupByField ? groupRowsBy(computedCaseRows, (r) => r[this.groupByField!]) : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapCaseRowKeyToRowLabel(rowGroup.key, this.groupByField!, {});

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "ASSIGNEE"
                        ? "Bearbeiter"
                        : this.groupBy === "CASE_TYPE"
                        ? (this.$t("Fall-Typ") as string)
                        : this.groupBy === "CASE_TYPE_GROUP"
                        ? (this.$t("Fall-Kategorie") as string)
                        : this.groupBy === "CHANNEL"
                        ? (this.$t("Kanal") as string)
                        : this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "ESCALATION_GROUP_RECEIVER"
                        ? (this.$t("Eskalationsgruppe") as string)
                        : undefined,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardBarTile,
    },
});
