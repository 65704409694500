
import OpportunityReportPage from "./OpportunityReportPage.vue";
import { ExtendedOpportunityRow, OpportunityReportPageResult } from "./opportunityReportPage";
import {
    getOpportunityRowDefaultKeys,
    mapOpportunityRowKeyToRowLabel,
    splitWithOpportunityTeamReceiverId,
    WithOpportunityTeamReceiverId,
    withOpportunityTeamReceiverIdSetToNull,
} from "./opportunityRowUtils";
import { OpportunityStatus } from "@/api/opportunities";
import { SentimentType } from "@/api/opportunitySearch";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import ReportingBarChartCard from "@/app/pages/reporting/ReportingBarChartCard.vue";
import { BarChartSeriesOptions, getReportingBarChartForHeaderGroup } from "@/app/pages/reporting/chartUtils";
import { ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { getOpportunitySentimentTypeColor } from "@/app/pages/reporting/colors";
import {
    isWithProcessingTimeIndividualDurationSlotErrorless,
    PotentiallyErroneous,
    PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS,
    splitWithAssignee,
    WithAssignee,
    withAssigneeSetToNull,
    WithCloser,
    WithCreatorActorType,
    WithProcessingTimeIndividualDurationSlot,
    WithSentimentType,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import {
    getSortByOptions,
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnEntries,
    ReportingTableItemColumnGroup,
    ReportingTableSortByOptions,
    ReportingTableSortByOptionValue,
    sort,
} from "@/app/pages/reporting/table/reportingTable";
import { IndividualDurationInterval } from "@/app/pages/reporting/timeInterval";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type PrecomputedProperties = WithCreatorActorType &
    WithCloser &
    WithProcessingTimeIndividualDurationSlot<PotentiallyErroneous> &
    WithSentimentType;

type ComputedOpportunityRow = ExtendedOpportunityRow<PrecomputedProperties> &
    WithAssignee &
    WithOpportunityTeamReceiverId;

type ComputedOpportunityRowGroupBy = keyof Pick<
    ComputedOpportunityRow,
    | "assigneeId"
    | "channel"
    | "closer"
    | "creatorActorType"
    | "dealerId"
    | "opportunityStatus"
    | "opportunityTeamReceiverId"
    | "outcome"
    | "processingTimeIndividualDurationSlot"
    | "sourceId"
>;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedOpportunityRowGroupBy;
}

type ReportingTableHeaderGroupWithColumnGroupComputer<Options> = ReportingTableHeaderGroup<Options> & {
    readonly toColumnGroup: (
        rowGroupRows: readonly ComputedOpportunityRow[],
        totalRows: readonly ComputedOpportunityRow[]
    ) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
            evaluationType: EvaluationType.ABSOLUTE as EvaluationType,
            EvaluationType,
            groupBy: "sourceId" as ComputedOpportunityRowGroupBy,
            result: null as OpportunityReportPageResult<PrecomputedProperties> | null,
            sortBy: {
                groupIndex: 0,
                columnIndex: 0,
                direction: "DESC",
            } as ReportingTableSortByOptionValue,
        };
    },

    computed: {
        defaultKeys(): RowKey[] {
            if (this.groupBy === "sourceId") {
                return [];
            }

            return getOpportunityRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        filteredComputedOpportunityRows(): readonly ComputedOpportunityRow[] {
            let filteredRows: readonly ComputedOpportunityRow[] = (this.result?.rows ?? [])
                .filter((row) => this.groupBy !== "closer" || row.opportunityStatus === OpportunityStatus.CLOSED)
                .map(withAssigneeSetToNull)
                .map(withOpportunityTeamReceiverIdSetToNull);

            if (this.groupBy === "assigneeId") {
                filteredRows = splitWithAssignee(filteredRows);
            } else if (this.groupBy === "opportunityTeamReceiverId") {
                filteredRows = splitWithOpportunityTeamReceiverId(filteredRows);
            }

            return filteredRows;
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "creatorActorType",
                    text: this.$t("Art des Erstellers"),
                },
                {
                    value: "processingTimeIndividualDurationSlot",
                    text: this.$t("Bearbeitungsdauer"),
                },
                {
                    value: "channel",
                    text: this.$t("Kanal"),
                },
                {
                    value: "sourceId",
                    text: this.$t("Quelle"),
                },
                {
                    value: "outcome",
                    text: this.$t("Resultat"),
                },
                {
                    value: "closer",
                    text: this.$t("Schließer"),
                },
                {
                    value: "opportunityStatus",
                    text: this.$t("Status"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
                {
                    value: "assigneeId",
                    text: this.$t("Verkäufer"),
                },
                {
                    value: "opportunityTeamReceiverId",
                    text: this.$t("Verkäuferteam"),
                },
            ];
        },

        groupByText(): string | null {
            return (this.groupByOptions.find((o) => o.value === this.groupBy)?.text ?? null) as string | null;
        },

        processingTimeSlots(): IndividualDurationInterval[] {
            return [...PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS];
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedOpportunityRow>[] {
            const filteredRows =
                this.groupBy === "processingTimeIndividualDurationSlot"
                    ? this.filteredComputedOpportunityRows.filter(isWithProcessingTimeIndividualDurationSlotErrorless)
                    : this.filteredComputedOpportunityRows;

            const rowGroups = groupRowsBy(filteredRows, (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapOpportunityRowKeyToRowLabel(rowGroup.key, this.groupBy, {
                    ...(this.result?.mapKeyToLabelContext ?? {}),
                    processingTimeSlots: this.processingTimeSlots,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        sentimentTypeChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 1);
        },

        sortByOptions(): ReportingTableSortByOptions {
            return getSortByOptions(this.tableHeaderGroups);
        },

        table(): ReportingTableData<BarChartSeriesOptions> | null {
            if (!this.tableData) {
                return null;
            }

            return {
                ...this.tableData,
                items: sort(this.tableData.items, this.sortBy),
            };
        },

        tableData(): ReportingTableData<BarChartSeriesOptions> | null {
            const allRows = this.filteredComputedOpportunityRows;

            if (!allRows.length) {
                return null;
            }

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => ({
                    title: rowGroup.title,
                    subtitle: rowGroup.subtitle,
                    groups: this.tableHeaderGroups.map((headerGroup) =>
                        headerGroup.toColumnGroup(rowGroup.rows, allRows)
                    ),
                })),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) => headerGroup.toColumnGroup(allRows, allRows)),
                },
                groupByHeaderText: this.groupByText ?? undefined,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer<BarChartSeriesOptions>[] {
            const sentimentTypes = [
                SentimentType.POSITIVE,
                SentimentType.NEUTRAL,
                SentimentType.NEGATIVE,
                SentimentType.UNKNOWN,
            ];

            return [
                {
                    text: null,
                    headers: [{ text: this.$t("Gesamt") as string }],
                    toColumnGroup: (rowGroupRows, allRows) => ({
                        columns: [
                            {
                                entries: this.toCountShareValueEntries(rowGroupRows, allRows),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Stimmung") as string,
                    headers: sentimentTypes.map((sentimentType) => ({
                        text: this.$t(`enum.SentimentType.${sentimentType}`) as string,
                        options: {
                            color: getOpportunitySentimentTypeColor(sentimentType),
                        },
                    })),
                    toColumnGroup: (rowGroupRows) => ({
                        columns: sentimentTypes.map((sentimentType) => ({
                            entries: this.toCountShareValueEntries(
                                rowGroupRows.filter((r) => r.sentimentType === sentimentType),
                                rowGroupRows
                            ),
                        })),
                    }),
                },
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },

        toCountShareValueEntries(
            countRows: readonly ComputedOpportunityRow[],
            totalRows: readonly ComputedOpportunityRow[]
        ): ReportingTableItemColumnEntries {
            const absoluteValue = { value: countRows.length };
            const percentageValue = {
                value: rate(countRows.length, totalRows.length),
                isPercentage: true,
            };

            const onClick = this.showBottomSheetOnClick(countRows.map((r) => r.id));

            return this.evaluationType === EvaluationType.PERCENTAGE
                ? [{ ...percentageValue, onClick }, absoluteValue]
                : [{ ...absoluteValue, onClick }, percentageValue];
        },
    },

    components: {
        OpportunitiesBottomSheet,
        OpportunityReportPage,
        ReportingBarChartCard,
        ReportTableCard,
    },
});
