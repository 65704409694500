
import OpportunityReportPage from "./OpportunityReportPage.vue";
import { ExtendedOpportunityRow, OpportunityReportPageResult } from "./opportunityReportPage";
import { OpportunityStatus } from "@/api/opportunities";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import ReportingTimeSeriesChartCard from "@/app/pages/reporting/ReportingTimeSeriesChartCard.vue";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { hasNonZeroSeries, ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { WithCreatedOngoingTimeSlot } from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { getOngoingTimeSlotLabel, OngoingTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getWeek } from "@/util/dateTimeUtils";
import Vue from "vue";

type PrecomputedProperties = WithCreatedOngoingTimeSlot;

type ComputedOpportunityRow = ExtendedOpportunityRow<PrecomputedProperties>;

export default Vue.extend({
    data() {
        const ts = now();

        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
            ongoingTimeInterval: OngoingTimeInterval.WEEK as OngoingTimeInterval,
            OngoingTimeInterval,
            result: null as OpportunityReportPageResult<PrecomputedProperties> | null,
            timeRange: {
                from: getWeek(ts, -6, userSession.timeZone, userSession.locale).begin,
                to: getWeek(ts, 0, userSession.timeZone, userSession.locale).end,
            } as TimeRange,
        };
    },

    computed: {
        chart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const chart: ReportingTimeSeriesChartData = {
                title: this.$t("Conversion Rate") as string,
                series: [
                    {
                        id: "conversion-rate",
                        name: this.$t("Conversion Rate") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => {
                                const closedRows = rowGroup.rows.filter(
                                    (r) => r.opportunityStatus === OpportunityStatus.CLOSED
                                );
                                const wonRows = closedRows.filter((r) => r.outcome);

                                return {
                                    date: new Date(rowGroup.key as string),
                                    value: rate(wonRows.length, closedRows.length),
                                    onClick: this.showBottomSheetOnClick(wonRows.map((r) => r.id)),
                                };
                            }),
                            isPercentage: true,
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                ],
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        defaultKeys(): string[] {
            return getTimeSeriesDefaultKeys(
                this.result?.rows ?? [],
                (r: OpportunityRow) => r.created,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null
            );
        },

        rowGroups(): RowGroup<string, ComputedOpportunityRow>[] {
            const rowGroups = groupRowsBy(this.result?.rows ?? [], (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },

        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        OpportunityReportPage,
        ReportingTimeSeriesChartCard,
    },
});
