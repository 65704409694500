
import OutgoingActivityCountByGroupTile from "./compound/OutgoingActivityCountByGroupTile.vue";
import IncomingCallCountByGroupTile from "./incomingcalls/IncomingCallCountByGroupTile.vue";
import OpportunityChannelOfFirstReactionByGroupTile from "./opportunities/OpportunityChannelOfFirstReactionByGroupTile.vue";
import OpportunityConversionRateByGroupTile from "./opportunities/OpportunityConversionRateByGroupTile.vue";
import OpportunityConversionRateTile from "./opportunities/OpportunityConversionRateTile.vue";
import OpportunityCountTile from "./opportunities/OpportunityCountTile.vue";
import OpportunityOutcomeOfChannelSourceByDealerTile from "./opportunities/OpportunityOutcomeOfChannelSourceByDealerTile.vue";
import OpportunitySentimentByGroupTile from "./opportunities/OpportunitySentimentByGroupTile.vue";
import OpportunitySentimentTile from "./opportunities/OpportunitySentimentTile.vue";
import OpportunityStatusByGroupTile from "./opportunities/OpportunityStatusByGroupTile.vue";
import OpportunityStatusTile from "./opportunities/OpportunityStatusTile.vue";
import OpportunityWaitingTimeUntilFirstReactionAsPerceivedExternallyByGroupTile from "./opportunities/OpportunityWaitingTimeUntilFirstReactionAsPerceivedExternallyByGroupTile.vue";
import OpportunityWaitingTimeUntilFirstReactionAsPerceivedExternallyDistributionByGroupTile from "./opportunities/OpportunityWaitingTimeUntilFirstReactionAsPerceivedExternallyDistributionByGroupTile.vue";
import OpportunityWaitingTimeUntilFirstReactionAsPerceivedExternallyTile from "./opportunities/OpportunityWaitingTimeUntilFirstReactionAsPerceivedExternallyTile.vue";
import OpportunityWaitingTimeUntilFirstReactionByGroupTile from "./opportunities/OpportunityWaitingTimeUntilFirstReactionByGroupTile.vue";
import OpportunityWaitingTimeUntilFirstReactionDistributionByGroupTile from "./opportunities/OpportunityWaitingTimeUntilFirstReactionDistributionByGroupTile.vue";
import OpportunityWaitingTimeUntilFirstReactionTile from "./opportunities/OpportunityWaitingTimeUntilFirstReactionTile.vue";
import { opportunityLocationDashboardState } from "./opportunityLocationDashboard";
import OutgoingCallStatusByGroupTile from "./outgoingcalls/OutgoingCallStatusByGroupTile.vue";
import OutgoingEmailCountByGroupTile from "./outgoingemails/OutgoingEmailCountByGroupTile.vue";
import { OpportunityChannel } from "@/api/opportunities";
import {
    IncomingCallRow,
    IssueType,
    OpportunityRow,
    OutgoingCallRow,
    OutgoingEmailRow,
    OutgoingSmsRow,
    OutgoingWhatsAppMessageRow,
    reportingApi,
} from "@/api/reporting";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import DealerPicker from "@/app/pages/DealerPicker.vue";
import { withIssueType, WithIssueType } from "@/app/pages/reporting/activityRowUtils";
import { EMPTY_INCOMING_CALL_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/incomingcalls/incomingCallRowSearchUtils";
import {
    PotentiallyErroneous,
    withCloser,
    WithCloser,
    withCreatorActorType,
    WithCreatorActorType,
    WithFirstAssignedTime,
    withFirstAssignedTime,
    withLastCommunicationDate,
    WithSentimentType,
    WithWaitingTimeUntilFirstReaction,
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "@/app/pages/reporting/issueRowUtils";
import { EMPTY_OPPORTUNITY_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/opportunities/opportunityRowSearchUtils";
import {
    filterConsiderOpportunityInReports,
    withOpportunityStatus,
    WithOpportunityStatus,
    withSentimentType,
    withWaitingTimeUntilFirstReaction,
    withWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { EMPTY_OUTGOING_CALL_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/outgoingcalls/outgoingCallRowSearchUtils";
import { EMPTY_OUTGOING_EMAIL_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/outgoingemails/outgoingEmailRowSearchUtils";
import { EMPTY_OUTGOING_SMS_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/outgoingsms/outgoingSmsRowSearchUtils";
import { EMPTY_OUTGOING_WHATSAPP_MESSAGE_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/outgoingwhatsappmessages/outgoingWhatsAppMessageRowSearchUtils";
import { canReportAllDealers, getReportableDealerIds } from "@/app/pages/reporting/reportingPermissionUtils";
import {
    getEndOfRelativeTimeSlot,
    getStartOfRelativeTimeSlot,
    RelativeTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { formatLocalDate, getDate } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { SelectOption } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

type ComputedOpportunityRow = OpportunityRow &
    WithCloser &
    WithCreatorActorType &
    WithFirstAssignedTime<PotentiallyErroneous> &
    WithOpportunityStatus &
    WithSentimentType &
    WithWaitingTimeUntilFirstReaction<PotentiallyErroneous> &
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>;

type ComputedIncomingCallRow = IncomingCallRow & WithIssueType;
type ComputedOutgoingCallRow = OutgoingCallRow & WithIssueType;
type ComputedOutgoingEmailRow = OutgoingEmailRow & WithIssueType;
type ComputedOutgoingSmsRow = OutgoingSmsRow & WithIssueType;
type ComputedOutgoingWhatsAppMessageRow = OutgoingWhatsAppMessageRow & WithIssueType;

interface QuickFilter {
    readonly label: TranslateResult;
    readonly from: string;
    readonly to: string;
}

export default Vue.extend({
    data() {
        return {
            incomingCallRows: [] as readonly IncomingCallRow[],
            loading: false,
            loadLimiter: new ActionLimiter(true),
            nowAtLoadRows: now() as Date,
            OpportunityChannel,
            opportunityLocationDashboardState,
            opportunityRows: [] as readonly OpportunityRow[],
            outgoingCallRows: [] as readonly OutgoingCallRow[],
            outgoingEmailRows: [] as readonly OutgoingEmailRow[],
            outgoingSmsRows: [] as readonly OutgoingSmsRow[],
            outgoingWhatsAppMessageRows: [] as readonly OutgoingWhatsAppMessageRow[],
            searchId: 0,
        };
    },

    computed: {
        canReportAllDealers(): boolean {
            return canReportAllDealers();
        },

        computedOpportunityRows(): readonly ComputedOpportunityRow[] {
            const opportunityRows = [...this.opportunityRows]
                .map(withLastCommunicationDate)
                .sort(
                    (a, b) =>
                        (b.lastCommunicationDate?.getTime() ?? 0) - (a.lastCommunicationDate?.getTime() ?? 0) ||
                        b.created.getTime() - a.created.getTime()
                )
                .map(withCloser)
                .map(withCreatorActorType)
                .map(withFirstAssignedTime)
                .map(withOpportunityStatus)
                .map(withSentimentType);

            return [opportunityRows]
                .map((rows) => withWaitingTimeUntilFirstReaction(rows))
                .map((rows) => withWaitingTimeUntilFirstReactionAsPerceivedExternally(rows))
                .pop()!;
        },

        filteredIncomingCallRows(): readonly ComputedIncomingCallRow[] {
            return this.incomingCallRows
                .filter(
                    (row) =>
                        !this.opportunityLocationDashboardState.opportunityTeamReceivers.length ||
                        (row.acceptorUserId !== null &&
                            this.opportunityTeamReceiverFilterMemberIds.includes(row.acceptorUserId))
                )
                .filter((row) => row.opportunityId && this.filteredOpportunityIds.includes(row.opportunityId))
                .map(withIssueType);
        },

        filteredOpportunities(): readonly ComputedOpportunityRow[] {
            return this.computedOpportunityRows
                .filter(filterConsiderOpportunityInReports)
                .filter((row) => this.reportedChannels.includes(row.channel))
                .filter((row) => this.reportedSourceIds.includes(row.sourceId))
                .filter(
                    (row) =>
                        !this.opportunityLocationDashboardState.opportunityTeamReceivers.length ||
                        this.opportunityLocationDashboardState.opportunityTeamReceivers.some((id) =>
                            row.opportunityTeamReceivers.includes(id)
                        )
                );
        },

        filteredOpportunityIds(): string[] {
            return this.filteredOpportunities.map((r) => r.id);
        },

        filteredOutgoingCallRows(): readonly ComputedOutgoingCallRow[] {
            return this.outgoingCallRows
                .filter(
                    (row) =>
                        !this.opportunityLocationDashboardState.opportunityTeamReceivers.length ||
                        this.opportunityTeamReceiverFilterMemberIds.includes(row.initiatorUserId)
                )
                .filter((row) => row.opportunityId && this.filteredOpportunityIds.includes(row.opportunityId))
                .map(withIssueType);
        },

        filteredOutgoingEmailRows(): readonly ComputedOutgoingEmailRow[] {
            return this.outgoingEmailRows
                .filter(
                    (row) =>
                        !this.opportunityLocationDashboardState.opportunityTeamReceivers.length ||
                        this.opportunityTeamReceiverFilterMemberIds.includes(row.initiatorUserId)
                )
                .filter((row) => row.opportunityId && this.filteredOpportunityIds.includes(row.opportunityId))
                .map(withIssueType);
        },

        filteredOutgoingSmsRows(): readonly ComputedOutgoingSmsRow[] {
            return this.outgoingSmsRows
                .filter(
                    (row) =>
                        !this.opportunityLocationDashboardState.opportunityTeamReceivers.length ||
                        this.opportunityTeamReceiverFilterMemberIds.includes(row.initiatorUserId)
                )
                .filter((row) => row.opportunityId && this.filteredOpportunityIds.includes(row.opportunityId))
                .map(withIssueType);
        },

        filteredOutgoingWhatsAppMessageRows(): readonly ComputedOutgoingWhatsAppMessageRow[] {
            return this.outgoingWhatsAppMessageRows
                .filter(
                    (row) =>
                        !this.opportunityLocationDashboardState.opportunityTeamReceivers.length ||
                        this.opportunityTeamReceiverFilterMemberIds.includes(row.initiatorUserId)
                )
                .filter((row) => row.opportunityId && this.filteredOpportunityIds.includes(row.opportunityId))
                .map(withIssueType);
        },

        opportunitiesWithoutOpportunityTeamReceiver(): readonly ComputedOpportunityRow[] {
            return this.filteredOpportunities.filter((r) => !r.opportunityTeamReceivers.length);
        },

        opportunityTeamOptions(): SelectOption[] {
            return opportunityTeamsStore.opportunityTeams.map((t) => ({
                value: t.id,
                text: `${t.name} (${dealersStore.dealerById(t.dealerId)!.name})`,
            }));
        },

        opportunityTeamReceiverFilterMemberIds(): string[] {
            return this.opportunityLocationDashboardState.opportunityTeamReceivers
                .map((id) => opportunityTeamsStore.getOpportunityTeamById(id)?.memberUserIds ?? [])
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        quickFilters(): QuickFilter[] {
            const ts = this.nowAtLoadRows;

            return [
                { label: this.$t("Heute"), interval: RelativeTimeInterval.TODAY },
                { label: this.$t("Letzten 7 Tage"), interval: RelativeTimeInterval.LAST_SEVEN_DAYS },
                { label: this.$t("Aktuelle Woche"), interval: RelativeTimeInterval.CURRENT_WEEK },
                { label: this.$t("Letzte Woche"), interval: RelativeTimeInterval.PREVIOUS_WEEK },
                { label: this.$t("Aktueller Monat"), interval: RelativeTimeInterval.CURRENT_MONTH },
                { label: this.$t("Letzter Monat"), interval: RelativeTimeInterval.PREVIOUS_MONTH },
                { label: this.$t("Aktuelles Quartal"), interval: RelativeTimeInterval.CURRENT_QUARTER },
                { label: this.$t("Letztes Quartal"), interval: RelativeTimeInterval.PREVIOUS_QUARTER },
            ].map((i) => ({
                label: i.label,
                from: getDate(getStartOfRelativeTimeSlot(ts, i.interval)!, this.timeZone),
                to: getDate(getEndOfRelativeTimeSlot(ts, i.interval)!, this.timeZone),
            }));
        },

        reportableDealerIds(): string[] {
            return getReportableDealerIds();
        },

        reportedChannels(): OpportunityChannel[] {
            return (Object.keys(OpportunityChannel) as OpportunityChannel[]).filter(
                (channel) =>
                    !this.opportunityLocationDashboardState.channels.length ||
                    this.opportunityLocationDashboardState.channels.includes(channel)
            );
        },

        reportedDealerIds(): string[] {
            if (!this.opportunityLocationDashboardState.dealerIds.length) {
                return this.reportableDealerIds;
            }

            return this.opportunityLocationDashboardState.dealerIds;
        },

        reportedSourceIds(): string[] {
            return opportunitySourcesStore.opportunitySources
                .filter(
                    (source) =>
                        !this.opportunityLocationDashboardState.sourceIds.length ||
                        this.opportunityLocationDashboardState.sourceIds.includes(source.id)
                )
                .map((source) => source.id);
        },

        sourceOptions(): SelectOption[] {
            return opportunitySourcesStore.opportunitySources.map((s) => ({ value: s.id, text: s.name }));
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        uncontactedOpportunities(): readonly ComputedOpportunityRow[] {
            return this.filteredOpportunities.filter(
                (r) => r.waitingTimeUntilFirstReactionAsPerceivedExternally === null
            );
        },
    },

    methods: {
        getLocalDate(date: string): string {
            return formatLocalDate(date, userSession.locale, "L");
        },

        isTimeRangeSelected(quickFilter: QuickFilter) {
            return (
                this.opportunityLocationDashboardState.createdRange.from === quickFilter.from &&
                this.opportunityLocationDashboardState.createdRange.to === quickFilter.to
            );
        },

        async loadRows() {
            this.nowAtLoadRows = now();
            this.incomingCallRows = [];
            this.opportunityRows = [];
            this.outgoingCallRows = [];
            this.outgoingEmailRows = [];
            this.outgoingSmsRows = [];
            this.outgoingWhatsAppMessageRows = [];
            this.loading = true;
            const searchId = ++this.searchId;

            await this.loadLimiter.execute(async () => {
                try {
                    const createdFrom = this.opportunityLocationDashboardState.searchRequest.createdFrom;
                    const createdTo = this.opportunityLocationDashboardState.searchRequest.createdTo;

                    const [
                        incomingCallRows,
                        opportunityRows,
                        outgoingCallRows,
                        outgoingEmailRows,
                        outgoingSmsRows,
                        outgoingWhatsAppMessageRows,
                    ] = await Promise.all([
                        reportingApi.incomingCallRows({
                            ...EMPTY_INCOMING_CALL_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.OPPORTUNITY],
                            dealerIds: this.opportunityLocationDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                        reportingApi.opportunityRows({
                            ...EMPTY_OPPORTUNITY_ROW_SEARCH_REQUEST,
                            ...this.opportunityLocationDashboardState.searchRequest,
                            includeFirstAssigned: true,
                            includeFirstOutgoingActivities: true,
                            includeAssignees: true,
                            includeAssigneeFirstAssigned: true,
                            includeAssigneeFirstOutgoingActivities: true,
                            includeOpportunityTeamReceivers: true,
                        }),
                        reportingApi.outgoingCallRows({
                            ...EMPTY_OUTGOING_CALL_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.OPPORTUNITY],
                            dealerIds: this.opportunityLocationDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                        reportingApi.outgoingEmailRows({
                            ...EMPTY_OUTGOING_EMAIL_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.OPPORTUNITY],
                            dealerIds: this.opportunityLocationDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                        reportingApi.outgoingSmsRows({
                            ...EMPTY_OUTGOING_SMS_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.OPPORTUNITY],
                            dealerIds: this.opportunityLocationDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                        reportingApi.outgoingWhatsAppMessageRows({
                            ...EMPTY_OUTGOING_WHATSAPP_MESSAGE_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.OPPORTUNITY],
                            dealerIds: this.opportunityLocationDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                    ]);

                    if (searchId === this.searchId) {
                        this.incomingCallRows = Object.freeze(incomingCallRows);
                        this.opportunityRows = Object.freeze(opportunityRows);
                        this.outgoingCallRows = Object.freeze(outgoingCallRows as OutgoingCallRow[]);
                        this.outgoingEmailRows = Object.freeze(outgoingEmailRows as OutgoingEmailRow[]);
                        this.outgoingSmsRows = Object.freeze(outgoingSmsRows as OutgoingSmsRow[]);
                        this.outgoingWhatsAppMessageRows = Object.freeze(
                            outgoingWhatsAppMessageRows as OutgoingWhatsAppMessageRow[]
                        );
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loading = false;
                    }
                }
            });
        },

        refresh() {
            this.opportunityLocationDashboardState.refresh();
        },

        selectTimeRange(quickFilter: QuickFilter): void {
            this.opportunityLocationDashboardState.createdRange = { from: quickFilter.from, to: quickFilter.to };
        },
    },

    watch: {
        "opportunityLocationDashboardState.searchRequest": {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadRows();
    },

    components: {
        DateRangePicker,
        DealerPicker,
        EnumField,
        IncomingCallCountByGroupTile,
        OpportunityChannelOfFirstReactionByGroupTile,
        OpportunityConversionRateByGroupTile,
        OpportunityConversionRateTile,
        OpportunityCountTile,
        OpportunityOutcomeOfChannelSourceByDealerTile,
        OpportunitySentimentByGroupTile,
        OpportunitySentimentTile,
        OpportunityStatusByGroupTile,
        OpportunityStatusTile,
        OpportunityWaitingTimeUntilFirstReactionAsPerceivedExternallyByGroupTile,
        OpportunityWaitingTimeUntilFirstReactionAsPerceivedExternallyDistributionByGroupTile,
        OpportunityWaitingTimeUntilFirstReactionAsPerceivedExternallyTile,
        OpportunityWaitingTimeUntilFirstReactionByGroupTile,
        OpportunityWaitingTimeUntilFirstReactionDistributionByGroupTile,
        OpportunityWaitingTimeUntilFirstReactionTile,
        OutgoingActivityCountByGroupTile,
        OutgoingCallStatusByGroupTile,
        OutgoingEmailCountByGroupTile,
    },
});
