
import OpportunityReportPage from "./OpportunityReportPage.vue";
import { ExtendedOpportunityRow, OpportunityReportPageResult } from "./opportunityReportPage";
import { OpportunityStatus } from "@/api/opportunities";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import ReportingTimeSeriesChartCard from "@/app/pages/reporting/ReportingTimeSeriesChartCard.vue";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { WithCreatedOngoingTimeSlot } from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { RowKey } from "@/app/pages/reporting/rowUtils";
import { getOngoingTimeSlotLabel, OngoingTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { now } from "@/store/now";
import { opportunityOutcomeReasonsStore } from "@/store/opportunityOutcomeReasons";
import { reportingSettingsStore } from "@/store/reportingSettingsStore";
import { userSession } from "@/store/userSession";
import { getWeek } from "@/util/dateTimeUtils";
import Vue from "vue";

type PrecomputedProperties = WithCreatedOngoingTimeSlot;

type ComputedOpportunityRow = ExtendedOpportunityRow<PrecomputedProperties>;

export default Vue.extend({
    data() {
        const ts = now();

        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
            ongoingTimeInterval: OngoingTimeInterval.WEEK as OngoingTimeInterval,
            OngoingTimeInterval,
            result: null as OpportunityReportPageResult<PrecomputedProperties> | null,
            timeRange: {
                from: getWeek(ts, -6, userSession.timeZone, userSession.locale).begin,
                to: getWeek(ts, 0, userSession.timeZone, userSession.locale).end,
            } as TimeRange,
        };
    },

    computed: {
        chart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const wonOutcomeReasons = opportunityOutcomeReasonsStore.opportunityOutcomeReasons.filter(
                (r) =>
                    r.won && !reportingSettingsStore.reportingSettings.ignoreOpportunityOutcomeReasonIds.includes(r.id)
            );

            const lostOutcomeReasons = opportunityOutcomeReasonsStore.opportunityOutcomeReasons.filter(
                (r) =>
                    !r.won && !reportingSettingsStore.reportingSettings.ignoreOpportunityOutcomeReasonIds.includes(r.id)
            );

            return {
                title: this.$t("Resultat") as string,
                series: [
                    ...[...wonOutcomeReasons, null].map((outcomeReason, index) => {
                        const outcomeReasonName = outcomeReason
                            ? outcomeReason.name
                            : (this.$t("Kein Grund angegeben") as string);

                        const name = wonOutcomeReasons.length
                            ? `${this.$t("Erfolgreich")} (${outcomeReasonName})`
                            : (this.$t("Erfolgreich") as string);

                        return {
                            id: `won-${index}`,
                            name,
                            data: {
                                values: this.rowGroups.map((rowGroup) => {
                                    const wonRows = rowGroup.rows.filter(
                                        (r: ComputedOpportunityRow) =>
                                            r.outcome && r.outcomeReasonId === (outcomeReason?.id ?? null)
                                    );

                                    return {
                                        date: new Date(rowGroup.key as string),
                                        value: wonRows.length,
                                        onClick: this.showBottomSheetOnClick(wonRows.map((r) => r.id)),
                                    };
                                }),
                            },
                            colorPalette: ReportingChartColorPalette.POSITIVE,
                        };
                    }),
                    ...[...lostOutcomeReasons, null].map((outcomeReason, index) => {
                        const outcomeReasonName = outcomeReason
                            ? outcomeReason.name
                            : (this.$t("Kein Grund angegeben") as string);

                        const name = lostOutcomeReasons.length
                            ? `${this.$t("Erfolglos")} (${outcomeReasonName})`
                            : (this.$t("Erfolglos") as string);

                        return {
                            id: `lost-${index}`,
                            name,
                            data: {
                                values: this.rowGroups.map((rowGroup) => {
                                    const lostRows = rowGroup.rows.filter(
                                        (r: ComputedOpportunityRow) =>
                                            !r.outcome && r.outcomeReasonId === (outcomeReason?.id ?? null)
                                    );

                                    return {
                                        date: new Date(rowGroup.key as string),
                                        value: lostRows.length,
                                        onClick: this.showBottomSheetOnClick(lostRows.map((r) => r.id)),
                                    };
                                }),
                            },
                            colorPalette: ReportingChartColorPalette.NEGATIVE,
                        };
                    }),
                ],
                dateFormatter: this.dateFormatter,
            };
        },

        defaultKeys(): RowKey[] {
            return getTimeSeriesDefaultKeys(
                this.result?.rows ?? [],
                (r: OpportunityRow) => r.created,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null
            );
        },

        rowGroups(): readonly RowGroup<RowKey, ComputedOpportunityRow>[] {
            const filteredRows: readonly ComputedOpportunityRow[] = (this.result?.rows ?? []).filter(
                (row) => row.opportunityStatus === OpportunityStatus.CLOSED
            );

            const rowGroups = groupRowsBy(filteredRows, (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },

        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        OpportunityReportPage,
        ReportingTimeSeriesChartCard,
    },
});
