var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('case-report-page',{attrs:{"no-data":!_vm.processingTimeMedianChart && !_vm.processingTimeIndividualDurationSlotChart,"time-interval":_vm.ongoingTimeInterval,"time-intervals":[
        _vm.OngoingTimeInterval.THIRTY_MINUTES,
        _vm.OngoingTimeInterval.SIXTY_MINUTES,
        _vm.OngoingTimeInterval.DATE,
        _vm.OngoingTimeInterval.WEEK,
        _vm.OngoingTimeInterval.MONTH,
        _vm.OngoingTimeInterval.QUARTER,
        _vm.OngoingTimeInterval.YEAR,
    ],"time-range":_vm.timeRange,"time-zone":_vm.timeZone,"with-created-ongoing-time-slot":_vm.ongoingTimeInterval,"with-processing-time":"","with-processing-time-individual-duration-slot":_vm.processingTimeSlots},on:{"update:timeInterval":function($event){_vm.ongoingTimeInterval=$event},"update:time-interval":function($event){_vm.ongoingTimeInterval=$event},"loaded":(v) => (_vm.result = v)},scopedSlots:_vm._u([{key:"default",fn:function({ pageTitle }){return [(_vm.processingTimeMedianChart)?_c('reporting-time-series-chart-card',{staticClass:"mb-2",attrs:{"chart":_vm.processingTimeMedianChart,"chart-hide-value-axis":"","filename-prefix":pageTitle,"group-by-header-text":_vm.$t('Zeitraum')}}):_vm._e(),(_vm.processingTimeIndividualDurationSlotChart)?_c('reporting-time-series-chart-card',{staticClass:"mb-2",attrs:{"chart":_vm.processingTimeIndividualDurationSlotChart,"filename-prefix":pageTitle,"group-by-header-text":_vm.$t('Zeitraum')}}):_vm._e(),(_vm.bottomSheetVisible)?_c('cases-bottom-sheet',{attrs:{"case-ids":_vm.bottomSheetCaseIds},on:{"close":_vm.hideBottomSheet}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }