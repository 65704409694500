import { DEFAULT_INDIVIDUAL_DURATION_INTERVALS } from "./reportingUtils";
import {
    Error,
    isError,
    isSameError,
    isSameMarker,
    Marker,
    RowKey,
    RowLabel,
    Undetermined,
    UNDETERMINED_ERROR,
} from "./rowUtils";
import {
    getEndOfOngoingTimeSlot,
    getIndividualDurationIntervalKeyByDuration,
    getIndividualDurationIntervalLabelByKey,
    getNextOngoingTimeSlot,
    getOngoingTimeSlotKey,
    getOngoingTimeSlotLabelByKey,
    getRecurringTimeSlotKey,
    getRecurringTimeSlotLabel,
    getStartOfRelativeTimeSlot,
    IndividualDurationInterval,
    OngoingTimeInterval,
    RecurringTimeInterval,
    RelativeTimeInterval,
} from "./timeInterval";
import { OfficeHours } from "@/api/officeHours";
import { IssueActivitySummary, IssueAssignee } from "@/api/reporting";
import { ReportingDealerSettings } from "@/api/reportingSettings";
import { $t } from "@/app/i18n";
import { getOfficeHoursEntries, isExceptionalDay } from "@/app/officeHoursUtils";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { officeHoursStore } from "@/store/officeHours";
import { reportingSettingsStore } from "@/store/reportingSettingsStore";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { getDate, getWeekDayOfDate } from "@/util/dateTimeUtils";

export const PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS: IndividualDurationInterval[] = [
    ...DEFAULT_INDIVIDUAL_DURATION_INTERVALS,
];

export const WAITING_TIME_UNTIL_FIRST_REACTION_INDIVIDUAL_DURATION_SLOTS: IndividualDurationInterval[] = [
    ...DEFAULT_INDIVIDUAL_DURATION_INTERVALS,
];

/*
 * marker
 */

export const EXTERNAL_AGENT_MARKER: Marker = Object.freeze({ marker: "EXTERNAL_AGENT" });
export const SYSTEM_MARKER: Marker = Object.freeze({ marker: "SYSTEM" });
export const USER_MARKER: Marker = Object.freeze({ marker: "USER" });

export type ExternalAgentMarker = typeof EXTERNAL_AGENT_MARKER;
export type SystemMarker = typeof SYSTEM_MARKER;
export type UserMarker = typeof USER_MARKER;

/*
 * types
 */

const ERRORLESS = "ERRORLESS";
const POTENTIALLY_ERRONEOUS = "POTENTIALLY_ERRONEOUS";

export type Errorless = typeof ERRORLESS;
export type PotentiallyErroneous = typeof POTENTIALLY_ERRONEOUS;

// elapsed time types

export type ElapsedTime =
    | number // number of full seconds
    | null; // there is no end;

export const END_PRECEDES_START_ERROR: Error = Object.freeze({ error: "END_PRECEDES_START" });
export const END_WITHOUT_START_ERROR: Error = Object.freeze({ error: "END_WITHOUT_START" });

export type EndPrecedesStart = typeof END_PRECEDES_START_ERROR;
export type EndWithoutStart = typeof END_WITHOUT_START_ERROR;

export type ElapsedTimeError = EndPrecedesStart | EndWithoutStart | Undetermined;

export type ElapsedTimeOrError = ElapsedTime | ElapsedTimeError;

export function isElapsedTime(elapsedTime: ElapsedTimeOrError): elapsedTime is ElapsedTime {
    return elapsedTime === null || typeof elapsedTime === "number";
}

// duration slot types

export type IndividualDurationSlot =
    | string // slot key
    | null; // no elapsed time given

export const INDIVIDUAL_DURATION_SLOT_MISSING_ERROR: Error = Object.freeze({
    error: "INDIVIDUAL_DURATION_SLOT_MISSING",
});
export const INDIVIDUAL_DURATION_SLOT_ELAPSED_TIME_ERRONEOUS_ERROR: Error = Object.freeze({
    error: "INDIVIDUAL_DURATION_SLOT_ELAPSED_TIME_ERRONEOUS",
});

export type IndividualDurationSlotMissing = typeof INDIVIDUAL_DURATION_SLOT_MISSING_ERROR;
export type IndividualDurationSlotElapsedTimeErroneous = typeof INDIVIDUAL_DURATION_SLOT_ELAPSED_TIME_ERRONEOUS_ERROR;

export type IndividualDurationSlotError =
    | IndividualDurationSlotMissing // no suitable duration slot given
    | IndividualDurationSlotElapsedTimeErroneous
    | Undetermined;

export type IndividualDurationSlotOrError = IndividualDurationSlot | IndividualDurationSlotError;

export function isIndividualDurationSlot<T>(slot: IndividualDurationSlotOrError): slot is IndividualDurationSlot {
    return slot === null || typeof slot === "string";
}

/*
 * helper
 */

function getEarliestDate(...dates: (Date | undefined | null)[]): Date | null {
    return dates
        .filter((d) => d)
        .map((d) => d as Date)
        .reduce((prev, cur) => {
            if (prev === null) {
                return cur;
            }

            return cur.getTime() < prev.getTime() ? cur : prev;
        }, null as Date | null);
}

function getLatestDate(...dates: (Date | undefined | null)[]): Date | null {
    return dates
        .filter((d) => d)
        .map((d) => d as Date)
        .reduce((prev, cur) => {
            if (prev === null) {
                return cur;
            }

            return prev.getTime() < cur.getTime() ? cur : prev;
        }, null as Date | null);
}

function toFullSeconds(time: number | null): number | null {
    if (time === null) {
        return null;
    }

    const seconds = time / 1000;

    return seconds < 0 ? Math.floor(seconds) : Math.ceil(seconds);
}

function getElapsedTime(from: Date | undefined | null, to: Date | undefined | null): ElapsedTimeOrError {
    if (!to) {
        return null;
    }

    if (!from) {
        return END_WITHOUT_START_ERROR;
    }

    if (to.getTime() < from.getTime()) {
        return END_PRECEDES_START_ERROR;
    }

    const seconds = toFullSeconds(to.getTime())! - toFullSeconds(from.getTime())!;

    return seconds >= 0 ? seconds : END_PRECEDES_START_ERROR;
}

function min(...values: (number | undefined | null)[]): number | null {
    return values
        .filter((v) => v !== undefined && v !== null)
        .map((v) => v as number)
        .reduce((prev, cur) => {
            if (prev === null) {
                return cur;
            }

            return Math.min(prev, cur);
        }, null as number | null);
}

interface ReportingOfficeHoursDay {
    readonly totalWorkingHoursInSeconds: number;
    readonly entries: ReportingOfficeHoursEntryTimeSpan[];
}

interface ReportingOfficeHoursEntryTimeSpan {
    readonly fromInSeconds: number;
    readonly toInSeconds: number;
}

export function getSecondsSinceStartOfDay(ts: Date, timeZone: string): number {
    const startOfDay = getStartOfRelativeTimeSlot(ts, RelativeTimeInterval.TODAY, timeZone)!;

    return getElapsedTime(startOfDay, ts)! as number;
}

type OfficeHoursCache = Map<string, Map<number, ReportingOfficeHoursDay>>;

function getOfficeHoursDay(officeHours: OfficeHours, ts: Date, cache: OfficeHoursCache): ReportingOfficeHoursDay {
    if (!cache.has(officeHours.id)) {
        cache.set(officeHours.id, new Map<number, ReportingOfficeHoursDay>());
    }

    const timeZone = dealersStore.dealerById(officeHours.dealerId)!.timeZone;
    const date = getDate(ts, timeZone);

    const dayOfWeek = getWeekDayOfDate(date);
    const isException = isExceptionalDay(officeHours, ts);

    if (!isException && cache.get(officeHours.id)!.has(dayOfWeek)) {
        return cache.get(officeHours.id)!.get(dayOfWeek)!;
    }

    const entries: ReportingOfficeHoursEntryTimeSpan[] = getOfficeHoursEntries(officeHours, ts)
        .map((entry) => ({
            fromInSeconds: getSecondsSinceStartOfDay(entry.from, timeZone),
            toInSeconds: getSecondsSinceStartOfDay(entry.to, timeZone),
        }))
        .filter((entry) => entry.fromInSeconds <= entry.toInSeconds)
        .sort((a, b) => a.fromInSeconds - b.fromInSeconds)
        .reduce((prev, cur) => {
            if (!prev.length) {
                return [cur];
            }

            const previous = prev[prev.length - 1];

            if (previous.fromInSeconds <= cur.fromInSeconds && cur.fromInSeconds <= previous.toInSeconds) {
                // merge overlapping intervals

                prev[prev.length - 1] = {
                    fromInSeconds: previous.fromInSeconds,
                    toInSeconds: Math.max(previous.toInSeconds, cur.toInSeconds),
                };

                return prev;
            }

            return [...prev, cur];
        }, [] as ReportingOfficeHoursEntryTimeSpan[]);

    const reportingOfficeHoursDay: ReportingOfficeHoursDay = {
        totalWorkingHoursInSeconds: entries.reduce((prev, cur) => prev + (cur.toInSeconds - cur.fromInSeconds), 0),
        entries,
    };

    if (!isException) {
        cache.get(officeHours.id)!.set(dayOfWeek, reportingOfficeHoursDay);
    }

    return reportingOfficeHoursDay;
}

function getElapsedTimeInOfficeHours(
    from: Date | undefined | null,
    to: Date | undefined | null,
    officeHours: OfficeHours | null,
    cache: OfficeHoursCache
): ElapsedTimeOrError {
    if (!to) {
        return null;
    }

    if (!from) {
        return END_WITHOUT_START_ERROR;
    }

    if (to.getTime() < from.getTime()) {
        return END_PRECEDES_START_ERROR;
    }

    if (!officeHours) {
        return getElapsedTime(from, to);
    }

    const timeZone = dealersStore.dealerById(officeHours.dealerId)!.timeZone;

    let seconds = 0;

    for (
        let start = new Date(from);
        start.getTime() < to.getTime();
        start = getNextOngoingTimeSlot(start, OngoingTimeInterval.DATE, timeZone)!
    ) {
        const end = getEarliestDate(to, getEndOfOngoingTimeSlot(start, OngoingTimeInterval.DATE, timeZone)!)!;

        const startInSeconds = getSecondsSinceStartOfDay(start, timeZone);
        const endInSeconds = getSecondsSinceStartOfDay(end, timeZone);

        const duration = Math.max(0, endInSeconds - startInSeconds);

        if (!duration) {
            continue;
        }

        const officeHoursDay = getOfficeHoursDay(officeHours, start, cache);

        // TODO: handle time change...

        if (duration === 86400 || officeHoursDay.totalWorkingHoursInSeconds === 0) {
            seconds += officeHoursDay.totalWorkingHoursInSeconds;
            continue;
        }

        seconds += officeHoursDay.entries
            .map((entry) => {
                const latestStartInSeconds = Math.max(entry.fromInSeconds, startInSeconds);
                const earliestEndInSeconds = Math.min(entry.toInSeconds, endInSeconds);

                // negative if range does not overlap with entry
                return Math.max(0, earliestEndInSeconds - latestStartInSeconds);
            })
            .reduce((prev, cur) => prev + cur, 0);
    }

    return seconds;
}

export type getOfficeHoursId = (settings: ReportingDealerSettings) => string | null;

function getOfficeHoursByDealerId(
    rows: readonly { dealerId: string }[],
    getOfficeHoursIdFn: getOfficeHoursId
): Map<string, OfficeHours | null> {
    const officeHoursByDealerId = new Map<string, OfficeHours | null>();

    for (const row of rows) {
        if (officeHoursByDealerId.has(row.dealerId)) {
            continue;
        }

        const settings = reportingSettingsStore.getReportingDealerSettingsByDealer(row.dealerId);
        const officeHoursId = getOfficeHoursIdFn(settings);

        officeHoursByDealerId.set(
            row.dealerId,
            officeHoursId ? officeHoursStore.getOfficeHoursById(officeHoursId) : null
        );
    }

    return officeHoursByDealerId;
}

/*
 * with assignee
 */

export interface WithAssignee {
    readonly assignee: IssueAssignee | null;
    readonly assigneeId: string | null;
}

export function withAssigneeSetToNull<T>(row: T): T & WithAssignee {
    return { ...row, assignee: null, assigneeId: null };
}

export function splitWithAssignee<T extends { assignees: IssueAssignee[] }>(
    rows: readonly T[]
): readonly (T & WithAssignee)[] {
    return rows
        .map((r) =>
            (!!r.assignees.length ? r.assignees : ([null] as (IssueAssignee | null)[])).map((assignee) => ({
                ...r,
                assignee,
                assigneeId: assignee?.id ?? null,
            }))
        )
        .reduce((prev, cur) => prev.concat(cur), []);
}

/*
 * with assignee first assigned time
 */

export interface WithAssigneeFirstAssignedTime<E extends Errorless | PotentiallyErroneous> {
    readonly assigneeFirstAssignedTime: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
}

export function isWithAssigneeFirstAssignedTimeErrorless<T extends WithAssigneeFirstAssignedTime<PotentiallyErroneous>>(
    row: T
): row is T & WithAssigneeFirstAssignedTime<Errorless> {
    return isElapsedTime(row.assigneeFirstAssignedTime);
}

export function withAssigneeFirstAssignedTime<T extends { created: Date } & WithAssignee>(
    row: T
): T & WithAssigneeFirstAssignedTime<PotentiallyErroneous> {
    return {
        ...row,
        assigneeFirstAssignedTime: getElapsedTime(row.created, row.assignee?.firstAssigned),
    };
}

/*
 * with assignee waiting time until first reaction
 */

export interface WithAssigneeWaitingTimeUntilFirstReaction<E extends Errorless | PotentiallyErroneous> {
    readonly assigneeWaitingTimeUntilFirstReaction: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
    readonly assigneeWaitingTimeUntilFirstOutgoingCall: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
    readonly assigneeWaitingTimeUntilFirstOutgoingEmail: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
    readonly assigneeWaitingTimeUntilFirstOutgoingSms: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
    readonly assigneeWaitingTimeUntilFirstOutgoingWhatsApp: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
}

export function isWithAssigneeWaitingTimeUntilFirstReactionErrorless<
    T extends WithAssigneeWaitingTimeUntilFirstReaction<PotentiallyErroneous>
>(row: T): row is T & WithAssigneeWaitingTimeUntilFirstReaction<Errorless> {
    return isElapsedTime(row.assigneeWaitingTimeUntilFirstReaction);
}

export function withAssigneeWaitingTimeUntilFirstReactionSetToUndetermined<T>(
    row: T
): T & WithAssigneeWaitingTimeUntilFirstReaction<PotentiallyErroneous> {
    return {
        ...row,
        assigneeWaitingTimeUntilFirstReaction: UNDETERMINED_ERROR,
        assigneeWaitingTimeUntilFirstOutgoingCall: UNDETERMINED_ERROR,
        assigneeWaitingTimeUntilFirstOutgoingEmail: UNDETERMINED_ERROR,
        assigneeWaitingTimeUntilFirstOutgoingSms: UNDETERMINED_ERROR,
        assigneeWaitingTimeUntilFirstOutgoingWhatsApp: UNDETERMINED_ERROR,
    };
}

export function withAssigneeWaitingTimeUntilFirstReaction<
    T extends { dealerId: string; activitySummary: IssueActivitySummary | null } & WithAssignee
>(
    rows: readonly T[],
    getOfficeHoursIdFn: getOfficeHoursId
): readonly (T & WithAssigneeWaitingTimeUntilFirstReaction<PotentiallyErroneous>)[] {
    const cache: OfficeHoursCache = new Map<string, Map<number, ReportingOfficeHoursDay>>();
    const reactionTimeOfficeHoursByDealerId: Map<string, OfficeHours | null> = getOfficeHoursByDealerId(
        rows,
        getOfficeHoursIdFn
    );

    return rows.map((row) => {
        const reactionTimeOfficeHours = reactionTimeOfficeHoursByDealerId.get(row.dealerId) ?? null;

        const getWaitingTimeSinceFn = (activity: Date | null | undefined): ElapsedTimeOrError =>
            getElapsedTimeInOfficeHours(row.assignee?.firstAssigned, activity, reactionTimeOfficeHours, cache);

        const result: T & WithAssigneeWaitingTimeUntilFirstReaction<PotentiallyErroneous> = {
            ...row,
            assigneeWaitingTimeUntilFirstReaction: UNDETERMINED_ERROR,
            assigneeWaitingTimeUntilFirstOutgoingCall: getWaitingTimeSinceFn(row.assignee?.firstOutgoing?.call),
            assigneeWaitingTimeUntilFirstOutgoingEmail: getWaitingTimeSinceFn(row.assignee?.firstOutgoing?.email),
            assigneeWaitingTimeUntilFirstOutgoingSms: getWaitingTimeSinceFn(row.assignee?.firstOutgoing?.sms),
            assigneeWaitingTimeUntilFirstOutgoingWhatsApp: getWaitingTimeSinceFn(
                row.assignee?.firstOutgoing?.whatsAppMessage
            ),
        };

        const waitingTimes = [
            result.assigneeWaitingTimeUntilFirstOutgoingCall,
            result.assigneeWaitingTimeUntilFirstOutgoingEmail,
            result.assigneeWaitingTimeUntilFirstOutgoingSms,
            result.assigneeWaitingTimeUntilFirstOutgoingWhatsApp,
        ];

        return {
            ...result,
            assigneeWaitingTimeUntilFirstReaction:
                waitingTimes.find((elapsedTime) => !isElapsedTime(elapsedTime)) ??
                min(...(waitingTimes as ElapsedTime[])),
        };
    });
}

/*
 * with assignee waiting time until first reaction individual duration slot
 */

export interface WithAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot<
    E extends Errorless | PotentiallyErroneous
> {
    readonly assigneeWaitingTimeUntilFirstReactionIndividualDurationSlot: E extends Errorless
        ? IndividualDurationSlot
        : IndividualDurationSlotOrError;
}

export function isWithAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlotErrorless<
    T extends WithAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot<PotentiallyErroneous>
>(row: T): row is T & WithAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot<Errorless> {
    return isIndividualDurationSlot(row.assigneeWaitingTimeUntilFirstReactionIndividualDurationSlot);
}

export function withAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlotSetToUndetermined<
    T extends WithAssigneeWaitingTimeUntilFirstReaction<PotentiallyErroneous>
>(row: T): T & WithAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot<PotentiallyErroneous> {
    return {
        ...row,
        assigneeWaitingTimeUntilFirstReactionIndividualDurationSlot: UNDETERMINED_ERROR,
    };
}

export function withAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot<
    T extends WithAssigneeWaitingTimeUntilFirstReaction<PotentiallyErroneous>
>(
    row: T,
    intervals: IndividualDurationInterval[]
): T & WithAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot<PotentiallyErroneous> {
    return {
        ...row,
        assigneeWaitingTimeUntilFirstReactionIndividualDurationSlot: !isElapsedTime(
            row.assigneeWaitingTimeUntilFirstReaction
        )
            ? INDIVIDUAL_DURATION_SLOT_ELAPSED_TIME_ERRONEOUS_ERROR
            : row.assigneeWaitingTimeUntilFirstReaction !== null
            ? getIndividualDurationIntervalKeyByDuration(row.assigneeWaitingTimeUntilFirstReaction, intervals) ??
              INDIVIDUAL_DURATION_SLOT_MISSING_ERROR
            : null,
    };
}

/*
 * with assignee waiting time until first reaction as perceived externally
 */

export interface WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<
    E extends Errorless | PotentiallyErroneous
> {
    readonly assigneeWaitingTimeUntilFirstAssignmentAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
    readonly assigneeWaitingTimeUntilFirstReactionAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
    readonly assigneeWaitingTimeUntilFirstOutgoingCallAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
    readonly assigneeWaitingTimeUntilFirstOutgoingEmailAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
    readonly assigneeWaitingTimeUntilFirstOutgoingSmsAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
    readonly assigneeWaitingTimeUntilFirstOutgoingWhatsAppAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
}

export function isWithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless<
    T extends WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>
>(row: T): row is T & WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<Errorless> {
    return (
        isElapsedTime(row.assigneeWaitingTimeUntilFirstAssignmentAsPerceivedExternally) &&
        isElapsedTime(row.assigneeWaitingTimeUntilFirstReactionAsPerceivedExternally)
    );
}

export function withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<
    T extends { dealerId: string; created: Date } & WithAssignee
>(
    rows: readonly T[],
    getOfficeHoursIdFn: getOfficeHoursId
): readonly (T & WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>)[] {
    const cache: OfficeHoursCache = new Map<string, Map<number, ReportingOfficeHoursDay>>();
    const reactionTimeOfficeHoursByDealerId: Map<string, OfficeHours | null> = getOfficeHoursByDealerId(
        rows,
        getOfficeHoursIdFn
    );

    return rows.map((row) => {
        const reactionTimeOfficeHours = reactionTimeOfficeHoursByDealerId.get(row.dealerId) ?? null;

        const getWaitingTimeSinceFn = (activity: Date | null | undefined): ElapsedTimeOrError =>
            getElapsedTimeInOfficeHours(row.created, activity, reactionTimeOfficeHours, cache);

        const result: T & WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous> = {
            ...row,
            assigneeWaitingTimeUntilFirstAssignmentAsPerceivedExternally: getWaitingTimeSinceFn(
                row.assignee?.firstAssigned
            ),
            assigneeWaitingTimeUntilFirstReactionAsPerceivedExternally: UNDETERMINED_ERROR,
            assigneeWaitingTimeUntilFirstOutgoingCallAsPerceivedExternally: getWaitingTimeSinceFn(
                row.assignee?.firstOutgoing?.call
            ),
            assigneeWaitingTimeUntilFirstOutgoingEmailAsPerceivedExternally: getWaitingTimeSinceFn(
                row.assignee?.firstOutgoing?.email
            ),
            assigneeWaitingTimeUntilFirstOutgoingSmsAsPerceivedExternally: getWaitingTimeSinceFn(
                row.assignee?.firstOutgoing?.sms
            ),
            assigneeWaitingTimeUntilFirstOutgoingWhatsAppAsPerceivedExternally: getWaitingTimeSinceFn(
                row.assignee?.firstOutgoing?.whatsAppMessage
            ),
        };

        const waitingTimes = [
            result.assigneeWaitingTimeUntilFirstOutgoingCallAsPerceivedExternally,
            result.assigneeWaitingTimeUntilFirstOutgoingEmailAsPerceivedExternally,
            result.assigneeWaitingTimeUntilFirstOutgoingSmsAsPerceivedExternally,
            result.assigneeWaitingTimeUntilFirstOutgoingWhatsAppAsPerceivedExternally,
        ];

        return {
            ...result,
            assigneeWaitingTimeUntilFirstReactionAsPerceivedExternally:
                waitingTimes.find((elapsedTime) => !isElapsedTime(elapsedTime)) ??
                min(...(waitingTimes as ElapsedTime[])),
        };
    });
}

/*
 * with assignee waiting time until first reaction as perceived externally individual duration slot
 */

export interface WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<
    E extends Errorless | PotentiallyErroneous
> {
    readonly assigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot: E extends Errorless
        ? IndividualDurationSlot
        : IndividualDurationSlotOrError;
}

export function isWithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlotErrorless<
    T extends WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<PotentiallyErroneous>
>(row: T): row is T & WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<Errorless> {
    return isIndividualDurationSlot(
        row.assigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot
    );
}

export function withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<
    T extends WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>
>(
    row: T,
    intervals: IndividualDurationInterval[]
): T & WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<PotentiallyErroneous> {
    return {
        ...row,
        assigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot: !isElapsedTime(
            row.assigneeWaitingTimeUntilFirstReactionAsPerceivedExternally
        )
            ? INDIVIDUAL_DURATION_SLOT_ELAPSED_TIME_ERRONEOUS_ERROR
            : row.assigneeWaitingTimeUntilFirstReactionAsPerceivedExternally !== null
            ? getIndividualDurationIntervalKeyByDuration(
                  row.assigneeWaitingTimeUntilFirstReactionAsPerceivedExternally,
                  intervals
              ) ?? INDIVIDUAL_DURATION_SLOT_MISSING_ERROR
            : null,
    };
}

/*
 * with closer
 */

export interface WithCloser {
    readonly closer: string | ExternalAgentMarker | SystemMarker | null;
}

export function withCloser<
    T extends { closed: Date | null; closerUserId: string | null; closerCtUserId: string | null }
>(row: T): T & WithCloser {
    return {
        ...row,
        closer:
            row.closed === null
                ? null
                : row.closerUserId
                ? row.closerUserId
                : row.closerCtUserId
                ? EXTERNAL_AGENT_MARKER
                : SYSTEM_MARKER,
    };
}

/*
 * with created ongoing time slot
 */

export interface WithCreatedOngoingTimeSlot {
    readonly createdOngoingTimeSlot: string;
}

export function withCreatedOngoingTimeSlot<T extends { created: Date }>(
    row: T,
    ongoingTimeInterval: OngoingTimeInterval
): T & WithCreatedOngoingTimeSlot {
    return {
        ...row,
        createdOngoingTimeSlot: getOngoingTimeSlotKey(row.created, ongoingTimeInterval, userSession.timeZone)!,
    };
}

/*
 * with created recurring time slot
 */

export interface WithCreatedRecurringTimeSlot {
    readonly createdRecurringTimeSlot: number;
}

export function withCreatedRecurringTimeSlot<T extends { created: Date }>(
    row: T,
    recurringTimeInterval: RecurringTimeInterval
): T & WithCreatedRecurringTimeSlot {
    return {
        ...row,
        createdRecurringTimeSlot: getRecurringTimeSlotKey(row.created, recurringTimeInterval, userSession.timeZone)!,
    };
}

/*
 * with creator actor type
 */

export interface WithCreatorActorType {
    readonly creatorActorType: UserMarker | ExternalAgentMarker | SystemMarker;
}

export function withCreatorActorType<T extends { creatorUserId: string | null; creatorCtUserId: string | null }>(
    row: T
): T & WithCreatorActorType {
    return {
        ...row,
        creatorActorType: row.creatorUserId ? USER_MARKER : row.creatorCtUserId ? EXTERNAL_AGENT_MARKER : SYSTEM_MARKER,
    };
}

/*
 * with first assigned time
 */

export interface WithFirstAssignedTime<E extends Errorless | PotentiallyErroneous> {
    readonly firstAssignedTime: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
}

export function isWithFirstAssignedTimeErrorless<T extends WithFirstAssignedTime<PotentiallyErroneous>>(
    row: T
): row is T & WithFirstAssignedTime<Errorless> {
    return isElapsedTime(row.firstAssignedTime);
}

export function withFirstAssignedTime<T extends { created: Date; activitySummary: IssueActivitySummary | null }>(
    row: T
): T & WithFirstAssignedTime<PotentiallyErroneous> {
    return {
        ...row,
        firstAssignedTime: getElapsedTime(row.created, row.activitySummary?.firstAssigned),
    };
}

/*
 * with is assignee first reacting assignee
 */

export interface WithIsAssigneeFirstReactingAssignee {
    readonly isAssigneeFirstReactingAssignee: boolean;
}

export function withIsAssigneeFirstReactingAssignee<
    T extends { activitySummary: IssueActivitySummary | null } & WithAssignee
>(row: T): T & WithIsAssigneeFirstReactingAssignee {
    const firstOutgoingCommunication = getEarliestDate(
        row.activitySummary?.firstOutgoing?.call,
        row.activitySummary?.firstOutgoing?.email,
        row.activitySummary?.firstOutgoing?.sms,
        row.activitySummary?.firstOutgoing?.whatsAppMessage
    );

    const assigneeFirstOutgoingCommunication = getEarliestDate(
        row.assignee?.firstOutgoing?.call,
        row.assignee?.firstOutgoing?.email,
        row.assignee?.firstOutgoing?.sms,
        row.assignee?.firstOutgoing?.whatsAppMessage
    );

    return {
        ...row,
        isAssigneeFirstReactingAssignee:
            firstOutgoingCommunication !== null &&
            assigneeFirstOutgoingCommunication !== null &&
            firstOutgoingCommunication.getTime() === assigneeFirstOutgoingCommunication.getTime(),
    };
}

/*
 * with last communication date
 */

export interface WithLastCommunicationDate {
    readonly lastCommunicationDate: Date | null;
}

export function withLastCommunicationDate<T extends { activitySummary: IssueActivitySummary | null }>(
    row: T
): T & WithLastCommunicationDate {
    return {
        ...row,
        lastCommunicationDate: getLatestDate(
            row.activitySummary?.lastIncoming?.call,
            row.activitySummary?.lastIncoming?.email,
            row.activitySummary?.lastIncoming?.sms,
            row.activitySummary?.lastIncoming?.whatsAppMessage,
            row.activitySummary?.lastOutgoing?.call,
            row.activitySummary?.lastOutgoing?.email,
            row.activitySummary?.lastOutgoing?.sms,
            row.activitySummary?.lastOutgoing?.whatsAppMessage
        ),
    };
}

/*
 * with processing time
 */

export interface WithProcessingTime<E extends Errorless | PotentiallyErroneous> {
    readonly processingTime: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
}

export function isWithProcessingTimeErrorless<T extends WithProcessingTime<PotentiallyErroneous>>(
    row: T
): row is T & WithProcessingTime<Errorless> {
    return isElapsedTime(row.processingTime);
}

export function withProcessingTime<T extends { created: Date; closed: Date | null }>(
    row: T,
    fallbackProcessedUntil: Date | null
): T & WithProcessingTime<PotentiallyErroneous> {
    return {
        ...row,
        processingTime: getElapsedTime(row.created, row.closed ?? fallbackProcessedUntil),
    };
}

/*
 * with processing time individual duration slot
 */

export interface WithProcessingTimeIndividualDurationSlot<E extends Errorless | PotentiallyErroneous> {
    readonly processingTimeIndividualDurationSlot: E extends Errorless
        ? IndividualDurationSlot
        : IndividualDurationSlotOrError;
}

export function isWithProcessingTimeIndividualDurationSlotErrorless<
    T extends WithProcessingTimeIndividualDurationSlot<PotentiallyErroneous>
>(row: T): row is T & WithProcessingTimeIndividualDurationSlot<Errorless> {
    return isIndividualDurationSlot(row.processingTimeIndividualDurationSlot);
}

export function withProcessingTimeIndividualDurationSlot<T extends WithProcessingTime<PotentiallyErroneous>>(
    row: T,
    intervals: IndividualDurationInterval[]
): T & WithProcessingTimeIndividualDurationSlot<PotentiallyErroneous> {
    return {
        ...row,
        processingTimeIndividualDurationSlot: !isElapsedTime(row.processingTime)
            ? INDIVIDUAL_DURATION_SLOT_ELAPSED_TIME_ERRONEOUS_ERROR
            : row.processingTime !== null
            ? getIndividualDurationIntervalKeyByDuration(row.processingTime, intervals) ??
              INDIVIDUAL_DURATION_SLOT_MISSING_ERROR
            : null,
    };
}

/*
 * with sentiment type
 */

export interface WithSentimentType {
    readonly sentimentType: string;
}

/*
 * with waiting time until first reaction
 */

export interface WithWaitingTimeUntilFirstReaction<E extends Errorless | PotentiallyErroneous> {
    readonly waitingTimeUntilFirstReaction: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
    readonly waitingTimeUntilFirstOutgoingCall: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
    readonly waitingTimeUntilFirstOutgoingEmail: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
    readonly waitingTimeUntilFirstOutgoingSms: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
    readonly waitingTimeUntilFirstOutgoingWhatsApp: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
}

export function isWithWaitingTimeUntilFirstReactionErrorless<
    T extends WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>
>(row: T): row is T & WithWaitingTimeUntilFirstReaction<Errorless> {
    return isElapsedTime(row.waitingTimeUntilFirstReaction);
}

export function withWaitingTimeUntilFirstReaction<
    T extends { dealerId: string; activitySummary: IssueActivitySummary | null }
>(
    rows: readonly T[],
    getOfficeHoursIdFn: getOfficeHoursId
): readonly (T & WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>)[] {
    const cache: OfficeHoursCache = new Map<string, Map<number, ReportingOfficeHoursDay>>();
    const reactionTimeOfficeHoursByDealerId: Map<string, OfficeHours | null> = getOfficeHoursByDealerId(
        rows,
        getOfficeHoursIdFn
    );

    return rows.map((row) => {
        const reactionTimeOfficeHours = reactionTimeOfficeHoursByDealerId.get(row.dealerId) ?? null;

        const getWaitingTimeSinceFn = (activity: Date | null | undefined): ElapsedTimeOrError =>
            getElapsedTimeInOfficeHours(row.activitySummary?.firstAssigned, activity, reactionTimeOfficeHours, cache);

        const result: T & WithWaitingTimeUntilFirstReaction<PotentiallyErroneous> = {
            ...row,
            waitingTimeUntilFirstReaction: UNDETERMINED_ERROR,
            waitingTimeUntilFirstOutgoingCall: getWaitingTimeSinceFn(row.activitySummary?.firstOutgoing?.call),
            waitingTimeUntilFirstOutgoingEmail: getWaitingTimeSinceFn(row.activitySummary?.firstOutgoing?.email),
            waitingTimeUntilFirstOutgoingSms: getWaitingTimeSinceFn(row.activitySummary?.firstOutgoing?.sms),
            waitingTimeUntilFirstOutgoingWhatsApp: getWaitingTimeSinceFn(
                row.activitySummary?.firstOutgoing?.whatsAppMessage
            ),
        };

        const waitingTimes = [
            result.waitingTimeUntilFirstOutgoingCall,
            result.waitingTimeUntilFirstOutgoingEmail,
            result.waitingTimeUntilFirstOutgoingSms,
            result.waitingTimeUntilFirstOutgoingWhatsApp,
        ];

        return {
            ...result,
            waitingTimeUntilFirstReaction:
                waitingTimes.find((elapsedTime) => !isElapsedTime(elapsedTime)) ??
                min(...(waitingTimes as ElapsedTime[])),
        };
    });
}

/*
 * with waiting time until first reaction individual duration slot
 */

export interface WithWaitingTimeUntilFirstReactionIndividualDurationSlot<E extends Errorless | PotentiallyErroneous> {
    readonly waitingTimeUntilFirstReactionIndividualDurationSlot: E extends Errorless
        ? IndividualDurationSlot
        : IndividualDurationSlotOrError;
}

export function isWithWaitingTimeUntilFirstReactionIndividualDurationSlotErrorless<
    T extends WithWaitingTimeUntilFirstReactionIndividualDurationSlot<PotentiallyErroneous>
>(row: T): row is T & WithWaitingTimeUntilFirstReactionIndividualDurationSlot<Errorless> {
    return isIndividualDurationSlot(row.waitingTimeUntilFirstReactionIndividualDurationSlot);
}

export function withWaitingTimeUntilFirstReactionIndividualDurationSlot<
    T extends WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>
>(
    row: T,
    intervals: IndividualDurationInterval[]
): T & WithWaitingTimeUntilFirstReactionIndividualDurationSlot<PotentiallyErroneous> {
    return {
        ...row,
        waitingTimeUntilFirstReactionIndividualDurationSlot: !isElapsedTime(row.waitingTimeUntilFirstReaction)
            ? INDIVIDUAL_DURATION_SLOT_ELAPSED_TIME_ERRONEOUS_ERROR
            : row.waitingTimeUntilFirstReaction !== null
            ? getIndividualDurationIntervalKeyByDuration(row.waitingTimeUntilFirstReaction, intervals) ??
              INDIVIDUAL_DURATION_SLOT_MISSING_ERROR
            : null,
    };
}

/*
 * with waiting time until first reaction as perceived externally
 */

export interface WithWaitingTimeUntilFirstReactionAsPerceivedExternally<E extends Errorless | PotentiallyErroneous> {
    readonly waitingTimeUntilFirstAssignmentAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
    readonly waitingTimeUntilFirstReactionAsPerceivedExternally: E extends Errorless ? ElapsedTime : ElapsedTimeOrError;
    readonly waitingTimeUntilFirstOutgoingCallAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
    readonly waitingTimeUntilFirstOutgoingEmailAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
    readonly waitingTimeUntilFirstOutgoingSmsAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
    readonly waitingTimeUntilFirstOutgoingWhatsAppAsPerceivedExternally: E extends Errorless
        ? ElapsedTime
        : ElapsedTimeOrError;
}

export function isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless<
    T extends WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>
>(row: T): row is T & WithWaitingTimeUntilFirstReactionAsPerceivedExternally<Errorless> {
    return (
        isElapsedTime(row.waitingTimeUntilFirstAssignmentAsPerceivedExternally) &&
        isElapsedTime(row.waitingTimeUntilFirstReactionAsPerceivedExternally)
    );
}

export function withWaitingTimeUntilFirstReactionAsPerceivedExternally<
    T extends { dealerId: string; created: Date; activitySummary: IssueActivitySummary | null }
>(
    rows: readonly T[],
    getOfficeHoursIdFn: getOfficeHoursId
): readonly (T & WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>)[] {
    const cache: OfficeHoursCache = new Map<string, Map<number, ReportingOfficeHoursDay>>();
    const reactionTimeOfficeHoursByDealerId: Map<string, OfficeHours | null> = getOfficeHoursByDealerId(
        rows,
        getOfficeHoursIdFn
    );

    return rows.map((row) => {
        const reactionTimeOfficeHours = reactionTimeOfficeHoursByDealerId.get(row.dealerId) ?? null;

        const getWaitingTimeSinceFn = (activity: Date | null | undefined): ElapsedTimeOrError =>
            getElapsedTimeInOfficeHours(row.created, activity, reactionTimeOfficeHours, cache);

        const result: T & WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous> = {
            ...row,
            waitingTimeUntilFirstAssignmentAsPerceivedExternally: getWaitingTimeSinceFn(
                row.activitySummary?.firstAssigned
            ),
            waitingTimeUntilFirstReactionAsPerceivedExternally: UNDETERMINED_ERROR,
            waitingTimeUntilFirstOutgoingCallAsPerceivedExternally: getWaitingTimeSinceFn(
                row.activitySummary?.firstOutgoing?.call
            ),
            waitingTimeUntilFirstOutgoingEmailAsPerceivedExternally: getWaitingTimeSinceFn(
                row.activitySummary?.firstOutgoing?.email
            ),
            waitingTimeUntilFirstOutgoingSmsAsPerceivedExternally: getWaitingTimeSinceFn(
                row.activitySummary?.firstOutgoing?.sms
            ),
            waitingTimeUntilFirstOutgoingWhatsAppAsPerceivedExternally: getWaitingTimeSinceFn(
                row.activitySummary?.firstOutgoing?.whatsAppMessage
            ),
        };

        const waitingTimes = [
            result.waitingTimeUntilFirstOutgoingCallAsPerceivedExternally,
            result.waitingTimeUntilFirstOutgoingEmailAsPerceivedExternally,
            result.waitingTimeUntilFirstOutgoingSmsAsPerceivedExternally,
            result.waitingTimeUntilFirstOutgoingWhatsAppAsPerceivedExternally,
        ];

        return {
            ...result,
            waitingTimeUntilFirstReactionAsPerceivedExternally:
                waitingTimes.find((elapsedTime) => !isElapsedTime(elapsedTime)) ??
                min(...(waitingTimes as ElapsedTime[])),
        };
    });
}

/*
 * with waiting time until first reaction as perceived externally individual duration slot
 */

export interface WithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<
    E extends Errorless | PotentiallyErroneous
> {
    readonly waitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot: E extends Errorless
        ? IndividualDurationSlot
        : IndividualDurationSlotOrError;
}

export function isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlotErrorless<
    T extends WithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<PotentiallyErroneous>
>(row: T): row is T & WithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<Errorless> {
    return isIndividualDurationSlot(row.waitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot);
}

export function withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<
    T extends WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>
>(
    row: T,
    intervals: IndividualDurationInterval[]
): T & WithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot<PotentiallyErroneous> {
    return {
        ...row,
        waitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot: !isElapsedTime(
            row.waitingTimeUntilFirstReactionAsPerceivedExternally
        )
            ? INDIVIDUAL_DURATION_SLOT_ELAPSED_TIME_ERRONEOUS_ERROR
            : row.waitingTimeUntilFirstReactionAsPerceivedExternally !== null
            ? getIndividualDurationIntervalKeyByDuration(
                  row.waitingTimeUntilFirstReactionAsPerceivedExternally,
                  intervals
              ) ?? INDIVIDUAL_DURATION_SLOT_MISSING_ERROR
            : null,
    };
}

/*
 * misc
 */

export interface MapIssueRowKeyToLabelContext {
    readonly ongoingTimeInterval?: OngoingTimeInterval;
    readonly processingTimeSlots?: readonly IndividualDurationInterval[];
    readonly recurringTimeInterval?: RecurringTimeInterval;
    readonly waitingTimeUntilFirstReactionSlots?: readonly IndividualDurationInterval[];
}

export function mapIssueRowKeyToLabel(key: RowKey, groupBy: string, context?: MapIssueRowKeyToLabelContext): RowLabel {
    const { ongoingTimeInterval, processingTimeSlots, recurringTimeInterval, waitingTimeUntilFirstReactionSlots } =
        context ?? {};

    if (groupBy === "assigneeId") {
        if (key === null) {
            return { label: $t("Ohne Zuteilung") as string };
        } else if (typeof key === "string") {
            const user = usersStore.getUserById(key);

            if (!user) {
                return { label: $t("Gelöschter Benutzer") as string };
            }

            return { label: getFullName(user) };
        }
    } else if (groupBy === "closer") {
        if (key === null) {
            return { label: $t("Ungeschlossen") as string };
        } else if (isSameMarker(key, EXTERNAL_AGENT_MARKER)) {
            return { label: $t("Externer Agent") as string };
        } else if (isSameMarker(key, SYSTEM_MARKER)) {
            return { label: $t("System") as string };
        } else if (typeof key === "string") {
            const user = usersStore.getUserById(key);

            if (!user) {
                return { label: $t("Gelöschter Benutzer") as string };
            }

            return { label: getFullName(user) };
        }
    } else if (groupBy === "creatorActorType") {
        if (isSameMarker(key, EXTERNAL_AGENT_MARKER)) {
            return { label: $t("Externer Agent") as string };
        } else if (isSameMarker(key, SYSTEM_MARKER)) {
            return { label: $t("System") as string };
        } else if (isSameMarker(key, USER_MARKER)) {
            return { label: $t("Benutzer") as string };
        }
    } else if (groupBy === "createdOngoingTimeSlot" && typeof key === "string" && ongoingTimeInterval) {
        return { label: getOngoingTimeSlotLabelByKey(key, ongoingTimeInterval, userSession.timeZone, "L") || "" };
    } else if (groupBy === "createdRecurringTimeSlot" && typeof key === "number" && recurringTimeInterval) {
        return { label: getRecurringTimeSlotLabel(key, recurringTimeInterval) || "" };
    } else if (groupBy === "dealerId") {
        if (key === null) {
            return { label: $t("Ohne Standort") as string };
        } else if (typeof key === "string") {
            return { label: dealersStore.dealerById(key)?.name || ($t("Unbekannter Standort") as string) };
        }
    } else if (groupBy === "processingTimeIndividualDurationSlot") {
        if (key === null) {
            return { label: $t("Ungeschlossen") as string };
        } else if (isSameError(key, INDIVIDUAL_DURATION_SLOT_MISSING_ERROR) || !processingTimeSlots) {
            return { label: $t("Unbekannte Bearbeitungsdauer") as string };
        } else if (typeof key === "string") {
            return {
                label:
                    getIndividualDurationIntervalLabelByKey(key, processingTimeSlots) ??
                    ($t("Unbekannte Bearbeitungsdauer") as string),
            };
        }
    } else if (groupBy === "sentimentType" && typeof key === "string") {
        return { label: $t(`enum.SentimentType.${key}`) as string };
    } else if (
        groupBy === "assigneeWaitingTimeUntilFirstReactionIndividualDurationSlot" ||
        groupBy === "assigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot" ||
        groupBy === "waitingTimeUntilFirstReactionIndividualDurationSlot" ||
        groupBy === "waitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot"
    ) {
        if (key === null) {
            return { label: $t("Ohne Erstreaktion") as string };
        } else if (isSameError(key, INDIVIDUAL_DURATION_SLOT_MISSING_ERROR) || !waitingTimeUntilFirstReactionSlots) {
            return { label: $t("Unbekannte Reaktionszeit") as string };
        } else if (typeof key === "string") {
            return {
                label:
                    getIndividualDurationIntervalLabelByKey(key, waitingTimeUntilFirstReactionSlots) ??
                    ($t("Unbekannte Reaktionszeit") as string),
            };
        }
    }

    if (isError(key)) {
        return { label: $t("Ungültiger Wert") as string };
    }

    return { label: $t("Unbekannt") as string };
}

export interface GetIssueRowDefaultKeysContext {
    readonly processingTimeSlots?: readonly IndividualDurationInterval[];
    readonly visibleDealerIds?: readonly string[];
    readonly waitingTimeUntilFirstReactionSlots?: readonly IndividualDurationInterval[];
}

export function getIssueRowDefaultKeys(groupBy: string, context?: GetIssueRowDefaultKeysContext): RowKey[] {
    const { processingTimeSlots, visibleDealerIds, waitingTimeUntilFirstReactionSlots } = context ?? {};

    if (groupBy === "assigneeId" && visibleDealerIds) {
        return usersStore.users.filter((u) => visibleDealerIds?.includes(u.mainDealerId)).map((u) => u.id);
    } else if (groupBy === "closer" && visibleDealerIds) {
        return [
            EXTERNAL_AGENT_MARKER,
            SYSTEM_MARKER,
            ...usersStore.users.filter((u) => visibleDealerIds?.includes(u.mainDealerId)).map((u) => u.id),
        ];
    } else if (groupBy === "creatorActorType") {
        return [EXTERNAL_AGENT_MARKER, SYSTEM_MARKER, USER_MARKER];
    } else if (groupBy === "createdOngoingTimeSlot") {
        return [];
    } else if (groupBy === "createdRecurringTimeSlot") {
        return [];
    } else if (groupBy === "dealerId" && visibleDealerIds) {
        return dealersStore.dealers.map((u) => u.id).filter((id) => visibleDealerIds?.includes(id));
    } else if (groupBy === "processingTimeIndividualDurationSlot") {
        return (processingTimeSlots || []).map((s) => s.key);
    } else if (groupBy === "sentimentType") {
        return ["UNKNOWN", "POSITIVE", "NEUTRAL", "NEGATIVE"];
    } else if (
        groupBy === "assigneeWaitingTimeUntilFirstReactionIndividualDurationSlot" ||
        groupBy === "assigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot" ||
        groupBy === "waitingTimeUntilFirstReactionIndividualDurationSlot" ||
        groupBy === "waitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot"
    ) {
        return (waitingTimeUntilFirstReactionSlots || []).map((s) => s.key);
    }

    return [];
}
