import { getOngoingTimeSlotKeys, OngoingTimeInterval } from "./timeInterval";
import { userSession } from "@/store/userSession";
import { toDateObject } from "@/util/dateTimeUtils";

export function getTimeSeriesDefaultKeys<T>(
    rows: readonly T[],
    getDate: (row: T) => Date | null,
    from: string | null,
    to: string | null,
    ongoingTimeInterval: OngoingTimeInterval,
    now: Date | null,
    timeZone?: string
) {
    const earliestTime = rows.reduce((prev, cur) => Math.min(prev, getDate(cur)?.getTime() ?? Infinity), Infinity);
    const latestTime = rows.reduce((prev, cur) => Math.max(prev, getDate(cur)?.getTime() ?? -Infinity), -Infinity);

    const start = from
        ? toDateObject(timeZone || userSession.timeZone, from)
        : earliestTime !== Infinity
        ? new Date(earliestTime)
        : now;

    const end = to
        ? toDateObject(timeZone || userSession.timeZone, to, 1)
        : latestTime !== -Infinity
        ? new Date(latestTime)
        : now;

    if (!start || !end || start.getTime() > end.getTime()) {
        return [];
    }

    return getOngoingTimeSlotKeys(start, end, ongoingTimeInterval, timeZone || userSession.timeZone, !to);
}
