import { CaseRowSearchRequest } from "@/api/reporting";

export const EMPTY_CASE_ROW_SEARCH_REQUEST: CaseRowSearchRequest = {
    dealerIds: [],
    status: [],
    outcomeReasonIds: [],
    assigneeIds: [],
    escalationGroupReceiverIds: [],
    createdFrom: null,
    createdTo: null,
    closedFrom: null,
    closedTo: null,
    postponedUntilFrom: null,
    postponedUntilTo: null,
    includeFirstAssigned: false,
    includeFirstIncomingActivities: false,
    includeFirstOutgoingActivities: false,
    includeLastIncomingActivities: false,
    includeLastOutgoingActivities: false,
    includeAssignees: false,
    includeAssigneeFirstAssigned: false,
    includeAssigneeFirstOutgoingActivities: false,
    includeAssigneeLastOutgoingActivities: false,
    includeEscalationGroupReceivers: false,
};
