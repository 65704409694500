
import { downloadOrOpenFile } from "@/app/fileUtils";
import { now } from "@/store/now";
import Vue from "vue";

const UTF8_BOM = "\ufeff";

export default Vue.extend({
    props: {
        data: {
            type: Array as () => string[][],
            required: true,
        },
        filename: {
            type: String,
            required: false,
        },
        hasHeader: {
            type: Boolean,
            required: false,
        },
        iconClass: {
            type: String,
            required: false,
        },
        separator: {
            type: String,
            default: ";",
        },
    },

    computed: {
        csv(): string {
            const content = this.data
                .map((values) => values.map((v) => `"${v.replace(/"/g, '""')}"`))
                .map((values) => values.join(this.separator))
                .join("\r\n");

            return UTF8_BOM + content;
        },
    },

    methods: {
        download() {
            const filename = this.filename || `${now().getTime()}.csv`;

            let mimeType = "text/csv;charset=UTF-8";

            if (this.hasHeader !== undefined) {
                const parameterValue = this.hasHeader ? "present" : "absent";

                mimeType += `;header=${parameterValue}`;
            }

            const fileOptions: any = { type: mimeType, encoding: "UTF-8" };

            const file = new File([this.csv], filename, fileOptions);

            downloadOrOpenFile(file, true);
        },
    },
});
