
import ReportingDashboardTile from "./ReportingDashboardTile.vue";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import ReportingTimeSeriesChart from "@/app/pages/reporting/charts/ReportingTimeSeriesChart.vue";
import { ReportingChartComponent } from "@/app/pages/reporting/charts/reportingChart";
import { ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { downloadChartAsPng, downloadChartAsSvg, getFileBasename } from "@/app/pages/reporting/downloadUtils";
import ReportingTable from "@/app/pages/reporting/table/ReportingTable.vue";
import { getCsvData, ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

export default Vue.extend({
    props: {
        chart: {
            type: Object as () => ReportingTimeSeriesChartData | null,
            required: false,
            default: null,
        },
        chartFillArea: {
            type: Boolean,
            default: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        chartHideLegend: {
            type: Boolean,
            default: false,
        },
        chartHideValueAxis: {
            type: Boolean,
            default: false,
        },
        chartPercentageMax: {
            type: Number,
            required: false,
        },
        chartStacked: {
            type: Boolean,
            default: false,
        },
        filenamePrefix: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        noChart: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        table: {
            type: Object as () => ReportingTableData | null,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            tableVisible: this.noChart,
        };
    },

    computed: {
        csvData(): string[][] {
            if (!this.hasTable) {
                return [];
            }

            return getCsvData(this.table!.headerGroups, this.table!.items, undefined, this.table?.groupByHeaderText);
        },

        csvFilename(): string | undefined {
            return this.fileBasename ? `${this.fileBasename}.csv` : undefined;
        },

        fileBasename(): string | null {
            return getFileBasename(this.filenamePrefix, this.title, this.subtitle);
        },

        hasTable(): boolean {
            return !!(this.table?.headerGroups ?? []).length && !!(this.table?.items ?? []).length;
        },
    },

    methods: {
        downloadAsPng() {
            downloadChartAsPng((this.$refs.chart as unknown) as ReportingChartComponent, this.fileBasename ?? null);
        },

        downloadAsSvg() {
            downloadChartAsSvg((this.$refs.chart as unknown) as ReportingChartComponent, this.fileBasename ?? null);
        },
    },

    components: {
        CsvDownloadIcon,
        ReportingDashboardTile,
        ReportingTable,
        ReportingTimeSeriesChart,
    },
});
