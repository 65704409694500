import { OpportunityRowSearchRequest } from "@/api/reporting";

export const EMPTY_OPPORTUNITY_ROW_SEARCH_REQUEST: OpportunityRowSearchRequest = {
    dealerIds: [],
    sourceIds: [],
    status: [],
    outcomeReasonIds: [],
    assigneeIds: [],
    opportunityTeamReceiverIds: [],
    createdFrom: null,
    createdTo: null,
    closedFrom: null,
    closedTo: null,
    postponedUntilFrom: null,
    postponedUntilTo: null,
    includeFirstAssigned: false,
    includeFirstIncomingActivities: false,
    includeFirstOutgoingActivities: false,
    includeLastIncomingActivities: false,
    includeLastOutgoingActivities: false,
    includeAssignees: false,
    includeAssigneeFirstAssigned: false,
    includeAssigneeFirstOutgoingActivities: false,
    includeAssigneeLastOutgoingActivities: false,
    includeOpportunityTeamReceivers: false,
};
