
import ExternalBdcReportPage from "./ExternalBdcReportPage.vue";
import { getExternalBdcCdrRowDefaultKeys, mapExternalBdcCdrRowKeyToRowLabel } from "./externalBdcCdrRowUtils";
import { ExternalBdcReportPageResult, ExternalBdcReportPageRow } from "./externalBdcReportPage";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate, sum } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import {
    getSortByOptions,
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnEntries,
    ReportingTableItemColumnGroup,
    ReportingTableSortByOptions,
    ReportingTableSortByOptionValue,
    sort,
} from "@/app/pages/reporting/table/reportingTable";
import { userSession } from "@/store/userSession";
import Vue from "vue";

type ComputedExternalBdcCdrRow = ExternalBdcReportPageRow;

type ComputedExternalBdcCdrRowGroupBy = keyof Pick<ComputedExternalBdcCdrRow, "dealerId">;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

type ReportingTableHeaderGroupWithColumnGroupComputer = ReportingTableHeaderGroup & {
    readonly toColumnGroup: (
        rowGroupRows: readonly ComputedExternalBdcCdrRow[],
        totalRows: readonly ComputedExternalBdcCdrRow[]
    ) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        return {
            evaluationType: EvaluationType.ABSOLUTE as EvaluationType,
            EvaluationType,
            groupBy: "dealerId" as ComputedExternalBdcCdrRowGroupBy,
            result: null as ExternalBdcReportPageResult | null,
            sortBy: {
                groupIndex: 0,
                columnIndex: 0,
                direction: "DESC",
            } as ReportingTableSortByOptionValue,
        };
    },

    computed: {
        defaultKeys(): RowKey[] {
            return getExternalBdcCdrRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedExternalBdcCdrRow>[] {
            const rowGroups = groupRowsBy(this.result?.rows ?? [], (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapExternalBdcCdrRowKeyToRowLabel(
                    rowGroup.key,
                    this.groupBy,
                    this.result?.mapKeyToLabelContext
                );

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        sortByOptions(): ReportingTableSortByOptions {
            return getSortByOptions(this.tableHeaderGroups);
        },

        table(): ReportingTableData | null {
            if (!this.tableData) {
                return null;
            }

            return {
                ...this.tableData,
                items: sort(this.tableData.items, this.sortBy),
            };
        },

        tableData(): ReportingTableData | null {
            const allRows = this.result?.rows ?? [];

            if (!allRows.length) {
                return null;
            }

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => ({
                    title: rowGroup.title,
                    subtitle: rowGroup.subtitle,
                    groups: this.tableHeaderGroups.map((headerGroup) =>
                        headerGroup.toColumnGroup(rowGroup.rows, allRows)
                    ),
                })),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) => headerGroup.toColumnGroup(allRows, allRows)),
                },
                groupByHeaderText: this.$t("Standort") as string,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer[] {
            return [
                {
                    text: null,
                    headers: [{ text: this.$t("Angenommen") as string }],
                    toColumnGroup: (rowGroupRows, allRows) => ({
                        columns: [{ entries: this.toCountShareValueEntries(rowGroupRows, allRows) }],
                    }),
                },
                {
                    text: null,
                    headers: [{ text: this.$t("Abgerechnete Gesprächsminuten") as string }],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [{ entries: [{ value: sum(rowGroupRows.map((r) => r.billedMinutes)) }] }],
                    }),
                },
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        toCountShareValueEntries(
            countRows: readonly ComputedExternalBdcCdrRow[],
            totalRows: readonly ComputedExternalBdcCdrRow[]
        ): ReportingTableItemColumnEntries {
            const absoluteValue = { value: countRows.length };
            const percentageValue = {
                value: rate(countRows.length, totalRows.length),
                isPercentage: true,
            };

            return this.evaluationType === EvaluationType.PERCENTAGE
                ? [percentageValue, absoluteValue]
                : [absoluteValue, percentageValue];
        },
    },

    components: {
        ExternalBdcReportPage,
        ReportTableCard,
    },
});
