import { getSeriesValuesMatrixMaxValue, ReportingChartColorPalette } from "./reportingChart";

export interface ReportingLineChartData {
    readonly title: string;
    readonly subtitle?: string;
    readonly categories: string[];
    readonly series: ReportingLineChartSeries[];
    readonly seriesDataTooltipHeader?: string;
    readonly seriesAdditionalTooltipDataTooltipHeaders?: string[];
}

export interface ReportingLineChartSeries {
    readonly id: string;
    readonly name: string;
    readonly tooltip?: string;
    readonly selected?: boolean;
    readonly data: ReportingLineChartSeriesData;
    readonly color?: string;
    readonly colorPalette?: ReportingChartColorPalette;
    readonly additionalTooltipData?: ReportingLineChartSeriesData[];
}

export interface ReportingLineChartSeriesData {
    readonly values: ReportingLineChartSeriesValue[];
    readonly isPercentage?: boolean;
    readonly formatter?: ReportingLineChartSeriesValueFormatter;
}

export interface ReportingLineChartSeriesValue {
    readonly value: number | undefined;
    readonly onClick?: () => Promise<void> | void;
}

export type ReportingLineChartSeriesValueFormatter = (
    value: number | undefined,
    isPercentage: boolean,
    context: "LABEL" | "TOOLTIP"
) => string | null;

export function isNonZeroSeries(series: ReportingLineChartSeries): boolean {
    return series.data.values.some((v) => (v.value ?? 0) !== 0);
}

export function hasNonZeroSeries(chart: ReportingLineChartData): boolean {
    return chart.series.some(isNonZeroSeries);
}

export function getSeriesMaxValue(series: ReportingLineChartSeries[], stacked: boolean): number | undefined {
    if (!series.length) {
        return undefined;
    }

    const indexes = Array.from(series[0].data.values.keys());

    return getSeriesValuesMatrixMaxValue(
        indexes.map((index) => series.map((s) => s.data.values[index].value)),
        stacked
    );
}
