
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import { mapCaseRowKeyToRowLabel, WithCaseTypeGroup } from "@/app/pages/reporting/cases/caseRowUtils";
import { durationFormatter, getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { ISSUE_STATUS_ASSIGNED_COLOR } from "@/app/pages/reporting/colors";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import {
    Errorless,
    isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless,
    PotentiallyErroneous,
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { avg } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedCaseRow = CaseRow & WithCaseTypeGroup & WithWaitingTimeUntilFirstReactionAsPerceivedExternally<Errorless>;

type ComputedCaseRowGroupBy = keyof Pick<ComputedCaseRow, "dealerId" | "channel" | "caseType" | "caseTypeGroup">;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "DEALER" | "CHANNEL" | "CASE_TYPE" | "CASE_TYPE_GROUP",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => (CaseRow &
                WithCaseTypeGroup &
                WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>)[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => {
                    const waitingTimeUntilFirstReactionAsPerceivedExternallyRows = rowGroup.rows.filter(
                        (r) => r.waitingTimeUntilFirstReactionAsPerceivedExternally !== null
                    );

                    const waitingTimeUntilFirstReactionAsPerceivedExternallyAvg = avg(
                        waitingTimeUntilFirstReactionAsPerceivedExternallyRows.map(
                            (r) => r.waitingTimeUntilFirstReactionAsPerceivedExternally
                        )
                    );

                    const waitingTimeUntilFirstAssignmentAsPerceivedExternallyRows = waitingTimeUntilFirstReactionAsPerceivedExternallyRows.filter(
                        (r) => r.waitingTimeUntilFirstAssignmentAsPerceivedExternally !== null
                    );

                    const waitingTimeUntilFirstAssignmentAsPerceivedExternallyAvg = avg(
                        waitingTimeUntilFirstAssignmentAsPerceivedExternallyRows.map(
                            (r) => r.waitingTimeUntilFirstAssignmentAsPerceivedExternally
                        )
                    );

                    return {
                        categoryId: rowGroup.key,
                        name: rowGroup.title,
                        description: rowGroup.subtitle,
                        waitingTimeUntilFirstReactionAsPerceivedExternallyRows,
                        waitingTimeUntilFirstAssignmentAsPerceivedExternallyRows,
                        waitingTimeUntilFirstReactionAsPerceivedExternallyAvg,
                        waitingTimeUntilFirstAssignmentAsPerceivedExternallyAvg,
                        sortValue: waitingTimeUntilFirstReactionAsPerceivedExternallyAvg,
                    };
                })
                .filter((c) => c.sortValue !== undefined || this.defaultGroupIds.includes(c.categoryId))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "first-assigned-time",
                        name: this.$t("Dauer von Erstellung bis zur Zuteilung (Durchschnitt)") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.waitingTimeUntilFirstAssignmentAsPerceivedExternallyAvg,
                                onClick: this.showBottomSheetOnClick(
                                    c.waitingTimeUntilFirstAssignmentAsPerceivedExternallyRows.map((r) => r.id)
                                ),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                        color: ISSUE_STATUS_ASSIGNED_COLOR,
                    },
                    {
                        id: "waiting-time-until-first-reaction-as-perceived-externally",
                        name: this.$t("Dauer von Erstellung bis zur ersten Kontaktaufnahme (Durchschnitt)") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.waitingTimeUntilFirstReactionAsPerceivedExternallyAvg,
                                onClick: this.showBottomSheetOnClick(
                                    c.waitingTimeUntilFirstReactionAsPerceivedExternallyRows.map((r) => r.id)
                                ),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        groupByField(): ComputedCaseRowGroupBy | null {
            if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "CHANNEL") {
                return "channel";
            } else if (this.groupBy === "CASE_TYPE") {
                return "caseType";
            } else if (this.groupBy === "CASE_TYPE_GROUP") {
                return "caseTypeGroup";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string, ComputedCaseRow>[] {
            const filteredRows = this.rows.filter(isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless);

            const rowGroups = this.groupByField ? groupRowsBy(filteredRows, (r) => r[this.groupByField!]) : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapCaseRowKeyToRowLabel(rowGroup.key, this.groupByField!, {});

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "CHANNEL"
                        ? (this.$t("Kanal") as string)
                        : this.groupBy === "CASE_TYPE"
                        ? (this.$t("Fall-Typ") as string)
                        : this.groupBy === "CASE_TYPE_GROUP"
                        ? (this.$t("Fall-Kategorie") as string)
                        : undefined,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardBarTile,
    },
});
