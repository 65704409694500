
import OpportunityReportPage from "./OpportunityReportPage.vue";
import { ExtendedOpportunityRow, OpportunityReportPageResult } from "./opportunityReportPage";
import { OpportunityStatus } from "@/api/opportunities";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import ReportingTimeSeriesChartCard from "@/app/pages/reporting/ReportingTimeSeriesChartCard.vue";
import {
    hasNonZeroSeries,
    isNonZeroSeries,
    ReportingTimeSeriesChartData,
    ReportingTimeSeriesChartSeries,
} from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { WithCreatedOngoingTimeSlot } from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { getOngoingTimeSlotLabel, OngoingTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { now } from "@/store/now";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { userSession } from "@/store/userSession";
import { getWeek } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type PrecomputedProperties = WithCreatedOngoingTimeSlot;

type ComputedOpportunityRow = ExtendedOpportunityRow<PrecomputedProperties>;

export default Vue.extend({
    data() {
        const ts = now();

        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
            ongoingTimeInterval: OngoingTimeInterval.WEEK as OngoingTimeInterval,
            OngoingTimeInterval,
            opportunitiesToConsider: "ALL" as "ALL" | "WON",
            result: null as OpportunityReportPageResult<PrecomputedProperties> | null,
            timeRange: {
                from: getWeek(ts, -6, userSession.timeZone, userSession.locale).begin,
                to: getWeek(ts, 0, userSession.timeZone, userSession.locale).end,
            } as TimeRange,
        };
    },

    computed: {
        chart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const chart: ReportingTimeSeriesChartData = {
                title: this.$t("Quellen") as string,
                series: this.topSourceIds.map((sourceId) => ({
                    id: `source-${sourceId}`,
                    name:
                        opportunitySourcesStore.getOpportunitySourceById(sourceId)?.name ||
                        (this.$t("Unbekannte Quelle") as string),
                    data: {
                        values: this.rowGroups.map((rowGroup) => {
                            const rows = rowGroup.rows.filter(
                                (r) => this.opportunitiesToConsiderPredicate(r) && r.sourceId === sourceId
                            );

                            return {
                                date: new Date(rowGroup.key as string),
                                value: rows.length,
                                onClick: this.showBottomSheetOnClick(rows.map((r) => r.id)),
                            };
                        }),
                    },
                })),
                dateFormatter: this.dateFormatter,
            };

            const nonTopSourceIds = opportunitySourcesStore.opportunitySources
                .map((s) => s.id)
                .filter((nonTopSourceId) => !this.topSourceIds.includes(nonTopSourceId));

            if (nonTopSourceIds.length) {
                const nonTopSourcesSeries: ReportingTimeSeriesChartSeries = {
                    id: "non-top-sources",
                    name: this.$t("Sonstige Quellen") as string,
                    data: {
                        values: this.rowGroups.map((rowGroup) => {
                            const rows = rowGroup.rows.filter(
                                (r) => this.opportunitiesToConsiderPredicate(r) && nonTopSourceIds.includes(r.sourceId)
                            );

                            return {
                                date: new Date(rowGroup.key as string),
                                value: rows.length,
                                onClick: this.showBottomSheetOnClick(rows.map((r) => r.id)),
                            };
                        }),
                    },
                };

                if (isNonZeroSeries(nonTopSourcesSeries)) {
                    chart.series.push(nonTopSourcesSeries);
                }
            }

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        defaultKeys(): string[] {
            return getTimeSeriesDefaultKeys(
                this.result?.rows ?? [],
                (r: OpportunityRow) => r.created,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null
            );
        },

        opportunitiesToConsiderOptions(): SelectOption[] {
            return [
                {
                    text: this.$t("Alle Verkaufschancen"),
                    value: "ALL",
                },
                {
                    text: this.$t("Gewonnene Verkaufschancen"),
                    value: "WON",
                },
            ];
        },

        opportunitiesToConsiderPredicate(): (r: ComputedOpportunityRow) => boolean {
            if (this.opportunitiesToConsider === "WON") {
                return (r) => r.opportunityStatus === OpportunityStatus.CLOSED && !!r.outcome;
            } else {
                return () => true;
            }
        },

        rowGroups(): RowGroup<string, ComputedOpportunityRow>[] {
            const rowGroups = groupRowsBy(this.result?.rows ?? [], (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        topSourceIds(): string[] {
            const rowGroups = groupRowsBy(this.result?.rows ?? [], (r) => r.sourceId);

            const best = rowGroups
                .map((rowGroup) => ({
                    sourceId: rowGroup.key,
                    value: rowGroup.rows.filter(this.opportunitiesToConsiderPredicate).length,
                }))
                .sort((a, b) => b.value - a.value);

            return best.map((conversion) => conversion.sourceId).slice(0, 5);
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },

        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        OpportunityReportPage,
        ReportingTimeSeriesChartCard,
    },
});
