
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { durationFormatter, getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import {
    Errorless,
    isWithWaitingTimeUntilFirstReactionErrorless,
    PotentiallyErroneous,
    WithWaitingTimeUntilFirstReaction,
} from "@/app/pages/reporting/issueRowUtils";
import { mapOpportunityRowKeyToRowLabel } from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { avg, median } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedOpportunityRow = OpportunityRow & WithWaitingTimeUntilFirstReaction<Errorless>;

type ComputedOpportunityRowGroupBy = keyof Pick<ComputedOpportunityRow, "dealerId" | "channel" | "sourceId">;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "DEALER" | "CHANNEL" | "SOURCE",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => (OpportunityRow & WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>)[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    firstReactionRows: rowGroup.rows,
                    waitingTimeUntilFirstReactionAvg: avg(rowGroup.rows.map((r) => r.waitingTimeUntilFirstReaction)),
                    waitingTimeUntilFirstReactionMedian: median(
                        rowGroup.rows.map((r) => r.waitingTimeUntilFirstReaction)
                    ),
                }))
                .map((c) => ({
                    ...c,
                    sortValue: c.waitingTimeUntilFirstReactionAvg,
                }))
                .filter((c) => c.sortValue !== undefined || this.defaultGroupIds.includes(c.categoryId))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "waiting-time-until-first-reaction-median",
                        name: this.$t("Median") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.waitingTimeUntilFirstReactionMedian,
                                onClick: this.showBottomSheetOnClick(c.firstReactionRows.map((r) => r.id)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                    {
                        id: "waiting-time-until-first-reaction-avg",
                        name: this.$t("Durchschnitt") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.waitingTimeUntilFirstReactionAvg,
                                onClick: this.showBottomSheetOnClick(c.firstReactionRows.map((r) => r.id)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        groupByField(): ComputedOpportunityRowGroupBy | null {
            if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "CHANNEL") {
                return "channel";
            } else if (this.groupBy === "SOURCE") {
                return "sourceId";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string, ComputedOpportunityRow>[] {
            const filteredRows = this.rows
                .filter(isWithWaitingTimeUntilFirstReactionErrorless)
                .filter((r) => r.waitingTimeUntilFirstReaction !== null);

            const rowGroups = this.groupByField ? groupRowsBy(filteredRows, (r) => r[this.groupByField!]) : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapOpportunityRowKeyToRowLabel(rowGroup.key, this.groupByField!, {});

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "CHANNEL"
                        ? (this.$t("Kanal") as string)
                        : this.groupBy === "SOURCE"
                        ? (this.$t("Quelle") as string)
                        : undefined,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardBarTile,
    },
});
