import { CaseChannel } from "@/api/cases";
import { CaseRowSearchRequest } from "@/api/reporting";
import { DateRange } from "@/app/components/dateRangePicker";
import { EMPTY_CASE_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/cases/caseRowSearchUtils";
import {
    getEndOfRelativeTimeSlot,
    getStartOfRelativeTimeSlot,
    RelativeTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

@reactive
export class CaseDashboardState {
    private searchRequest_: CaseRowSearchRequest = EMPTY_CASE_ROW_SEARCH_REQUEST;
    public caseTypes: string[] = [];
    public channels: CaseChannel[] = [];

    constructor() {
        const ts = now();

        this.createdRange = {
            from: getDate(getStartOfRelativeTimeSlot(ts, RelativeTimeInterval.LAST_SEVEN_DAYS)!, userSession.timeZone),
            to: getDate(getEndOfRelativeTimeSlot(ts, RelativeTimeInterval.LAST_SEVEN_DAYS)!, userSession.timeZone),
        };
    }

    get createdRange(): DateRange {
        return {
            from: getDate(this.searchRequest_.createdFrom!, userSession.timeZone),
            to: getDate(this.searchRequest_.createdTo!, userSession.timeZone, -1),
        };
    }

    set createdRange(dateRange: DateRange) {
        this.searchRequest_ = {
            ...this.searchRequest_,
            createdFrom: toDateObject(userSession.timeZone, dateRange.from),
            createdTo: toDateObject(userSession.timeZone, dateRange.to, 1),
        };
    }

    get dealerIds(): string[] {
        return this.searchRequest_.dealerIds;
    }

    set dealerIds(dealerIds: string[]) {
        this.searchRequest_ = { ...this.searchRequest_, dealerIds };
    }

    get searchRequest(): CaseRowSearchRequest {
        return { ...this.searchRequest_ };
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest_);
    }
}

export const caseDashboardState = new CaseDashboardState();
