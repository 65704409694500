
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import ReportingDashboardTile from "@/app/pages/reporting/dashboard/ReportingDashboardTile.vue";
import {
    isWithWaitingTimeUntilFirstReactionErrorless,
    PotentiallyErroneous,
    WithWaitingTimeUntilFirstReaction,
} from "@/app/pages/reporting/issueRowUtils";
import { avg, median, renderHumanizedDuration } from "@/app/pages/reporting/reportingUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        rows: {
            type: Array as () => (CaseRow & WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>)[],
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetVisible: false,
        };
    },

    computed: {
        renderedAvg(): string | null {
            if (!this.waitingTimeUntilFirstReaction.length) {
                return null;
            }

            const rendered = renderHumanizedDuration(avg(this.waitingTimeUntilFirstReaction), "S");

            return this.$t(`ø {0}`, [rendered]) as string;
        },

        renderedMedian(): string | null {
            if (!this.waitingTimeUntilFirstReaction.length) {
                return null;
            }

            const rendered = renderHumanizedDuration(median(this.waitingTimeUntilFirstReaction), "S");

            return this.$t(`Median {0}`, [rendered]) as string;
        },

        waitingTimeUntilFirstReaction(): number[] {
            return this.rows
                .filter(isWithWaitingTimeUntilFirstReactionErrorless)
                .filter((r) => r.waitingTimeUntilFirstReaction !== null)
                .map((r) => r.waitingTimeUntilFirstReaction!)
                .sort((a, b) => b - a);
        },

        waitingTimeUntilFirstReactionCaseIds(): string[] {
            return this.rows
                .filter(isWithWaitingTimeUntilFirstReactionErrorless)
                .filter((r) => r.waitingTimeUntilFirstReaction !== null)
                .sort((a, b) => b.waitingTimeUntilFirstReaction! - a.waitingTimeUntilFirstReaction!)
                .map((r) => r.id);
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardTile,
    },
});
