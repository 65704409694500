
import IncomingCallReportPage from "./IncomingCallReportPage.vue";
import { ExtendedIncomingCallRow, IncomingCallReportPageResult } from "./incomingCallReportPage";
import {
    getIncomingCallRowDefaultKeys,
    mapIncomingCallRowKeyToRowLabel,
    splitWithIntermediateInternalIncomingPhoneNumberId,
    WithIntermediateInternalIncomingPhoneNumberId,
    withLastIntermediateInternalIncomingPhoneNumberId,
} from "./incomingCallRowUtils";
import { IssueType } from "@/api/reporting";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { median, rate, renderDuration, sum } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import {
    getSortByOptions,
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnEntries,
    ReportingTableItemColumnGroup,
    ReportingTableSortByOptions,
    ReportingTableSortByOptionValue,
    sort,
} from "@/app/pages/reporting/table/reportingTable";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type ComputedIncomingCallRow = ExtendedIncomingCallRow & WithIntermediateInternalIncomingPhoneNumberId;

type ComputedIncomingCallRowGroupBy = keyof Pick<
    ComputedIncomingCallRow,
    "acceptorUserId" | "dealerId" | "intermediateInternalIncomingPhoneNumberId" | "issueType"
>;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedIncomingCallRowGroupBy;
}

type ReportingTableHeaderGroupWithColumnGroupComputer = ReportingTableHeaderGroup & {
    readonly toColumnGroup: (
        rowGroupRows: readonly ComputedIncomingCallRow[],
        totalRows: readonly ComputedIncomingCallRow[]
    ) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        return {
            evaluationType: EvaluationType.ABSOLUTE as EvaluationType,
            EvaluationType,
            groupBy: "dealerId" as ComputedIncomingCallRowGroupBy,
            result: null as IncomingCallReportPageResult | null,
            sortBy: {
                groupIndex: 0,
                columnIndex: 0,
                direction: "DESC",
            } as ReportingTableSortByOptionValue,
        };
    },

    computed: {
        computedIncomingCallRows(): readonly ComputedIncomingCallRow[] {
            return (this.result?.rows ?? []).map(withLastIntermediateInternalIncomingPhoneNumberId);
        },

        defaultKeys(): RowKey[] {
            return getIncomingCallRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "acceptorUserId",
                    text: this.$t("Annehmer"),
                },
                {
                    value: "issueType",
                    text: this.$t("Art des Vorgangs"),
                },
                {
                    value: "intermediateInternalIncomingPhoneNumberId",
                    text: this.$t("Eingehende Rufnummer"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
            ];
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedIncomingCallRow>[] {
            let computedRows = this.computedIncomingCallRows;

            if (this.groupBy !== "acceptorUserId") {
                computedRows = computedRows
                    .map(splitWithIntermediateInternalIncomingPhoneNumberId)
                    .reduce((prev, cur) => prev.concat(cur), []);
            }

            const rowGroups = groupRowsBy(computedRows, (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys).filter(
                (rowGroup) => rowGroup.key !== null || this.groupBy !== "acceptorUserId"
            );

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapIncomingCallRowKeyToRowLabel(
                    rowGroup.key,
                    this.groupBy,
                    this.result?.mapKeyToLabelContext
                );

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        sortByOptions(): ReportingTableSortByOptions {
            return getSortByOptions(this.tableHeaderGroups);
        },

        table(): ReportingTableData | null {
            if (!this.tableData) {
                return null;
            }

            return {
                ...this.tableData,
                items: sort(this.tableData.items, this.sortBy),
            };
        },

        tableData(): ReportingTableData | null {
            const allRows = this.rowGroups.map((g) => g.rows).reduce((prev, cur) => prev.concat(cur), []);

            if (!allRows.length) {
                return null;
            }

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => ({
                    title: rowGroup.title,
                    subtitle: rowGroup.subtitle,
                    groups: this.tableHeaderGroups.map((headerGroup) =>
                        headerGroup.toColumnGroup(rowGroup.rows, allRows)
                    ),
                })),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) => headerGroup.toColumnGroup(allRows, allRows)),
                },
                groupByHeaderText: this.groupByOptions.find((o) => o.value === this.groupBy)?.text as
                    | string
                    | undefined,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer[] {
            const groups: ReportingTableHeaderGroupWithColumnGroupComputer[] = [
                {
                    text: null,
                    headers: [{ text: this.$t("Gesamt") as string }],
                    toColumnGroup: (rowGroupRows, allRows) => ({
                        columns: [{ entries: this.toCountShareValueEntries(rowGroupRows, allRows) }],
                    }),
                },
                {
                    text: this.$t("Ursprung") as string,
                    headers: [
                        {
                            text: this.$t("Extern") as string,
                            tooltip: this.$t(
                                "Der Anruf kam von außerhalb des Systems (z.B. per Weiterleitung durch die Telefonanlage im Standort oder per Direktanruf)."
                            ) as string,
                        },
                        {
                            text: this.$t("Intern") as string,
                            tooltip: this.$t(
                                "Der Anruf wurde von einer anderen Call-Tracking-Nummer weitergeleitet."
                            ) as string,
                        },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: this.toCountShareValueEntries(
                                    rowGroupRows.filter(
                                        (r) => r.isCallBeganAtThisIntermediateInternalIncomingPhoneNumberId
                                    ),
                                    rowGroupRows
                                ),
                            },
                            {
                                entries: this.toCountShareValueEntries(
                                    rowGroupRows.filter(
                                        (r) => !r.isCallBeganAtThisIntermediateInternalIncomingPhoneNumberId
                                    ),
                                    rowGroupRows
                                ),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Status") as string,
                    headers: [
                        {
                            text: this.$t("Angenommen") as string,
                            tooltip: this.$t(
                                "Der Anruf endete hier und wurde durch einen Benutzer angenommen."
                            ) as string,
                        },
                        {
                            text: this.$t("Nicht angenommen") as string,
                            tooltip: this.$t("Der Anruf endete hier und wurde nicht angenommen.") as string,
                        },
                        {
                            text: this.$t("Weitergeleitet") as string,
                            tooltip: this.$t(
                                "Der Anruf wurde nicht angenommen und an eine andere Call-Tracking-Nummer, an das externe BDC oder an eine benutzerdefinierte Rufnummer weitergeleitet."
                            ) as string,
                        },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: this.toCountShareValueEntries(
                                    rowGroupRows.filter(
                                        (r) =>
                                            r.isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId &&
                                            r.duration !== null
                                    ),
                                    rowGroupRows
                                ),
                            },
                            {
                                entries: this.toCountShareValueEntries(
                                    rowGroupRows.filter(
                                        (r) =>
                                            r.isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId &&
                                            r.duration === null
                                    ),
                                    rowGroupRows
                                ),
                            },
                            {
                                entries: this.toCountShareValueEntries(
                                    rowGroupRows.filter(
                                        (r) => !r.isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId
                                    ),
                                    rowGroupRows
                                ),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Gesamtwartezeit (Median)") as string,
                    tooltip: this.$t(
                        "Die Gesamtwartezeit (d.h. die kumulierte Wartezeit über alle Weiterleitungen hinweg) geht vollständig bei der Call-Tracking-Nummer ein, bei der der Anruf endete."
                    ) as string,
                    headers: [
                        {
                            text: this.$t("Angenommen") as string,
                            tooltip: this.$t(
                                "Der Anruf endete hier und wurde durch einen Benutzer angenommen."
                            ) as string,
                        },
                        {
                            text: this.$t("Nicht angenommen") as string,
                            tooltip: this.$t("Der Anruf endete hier und wurde nicht angenommen.") as string,
                        },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: [
                                    {
                                        value: median(
                                            rowGroupRows
                                                .filter(
                                                    (r) =>
                                                        r.isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId &&
                                                        r.duration !== null
                                                )
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "L"),
                                    },
                                ],
                            },
                            {
                                entries: [
                                    {
                                        value: median(
                                            rowGroupRows
                                                .filter(
                                                    (r) =>
                                                        r.isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId &&
                                                        r.duration === null
                                                )
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "L"),
                                    },
                                ],
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Gesprächsdauer") as string,
                    tooltip: this.$t(
                        "Die Gesprächsdauer geht vollständig bei der Call-Tracking-Nummer ein, bei der der Anruf endete."
                    ) as string,
                    headers: [
                        { text: this.$t("Gesamt (Summe)") as string },
                        { text: this.$t("Einzelgespräch (Median)") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: [
                                    {
                                        value: sum(
                                            rowGroupRows
                                                .filter(
                                                    (r) => r.isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId
                                                )
                                                .map((r) => r.duration)
                                        ),
                                        formatter: (value) => renderDuration(value, "L"),
                                    },
                                ],
                            },
                            {
                                entries: [
                                    {
                                        value: median(
                                            rowGroupRows
                                                .filter(
                                                    (r) => r.isCallEndedAtThisIntermediateInternalIncomingPhoneNumberId
                                                )
                                                .map((r) => r.duration)
                                        ),
                                        formatter: (value) => renderDuration(value, "L"),
                                    },
                                ],
                            },
                        ],
                    }),
                },
            ];

            if (this.groupBy !== "issueType") {
                groups.push({
                    text: this.$t("Art des Vorgangs") as string,
                    headers: (Object.keys(IssueType) as IssueType[]).map((issueType) => ({
                        text: this.$t(`enum.IssueType.${issueType}`) as string,
                    })),
                    toColumnGroup: (rowGroupRows) => ({
                        columns: (Object.keys(IssueType) as IssueType[]).map((issueType) => ({
                            entries: this.toCountShareValueEntries(
                                rowGroupRows.filter((r) => r.issueType === issueType),
                                rowGroupRows
                            ),
                        })),
                    }),
                });
            }

            return groups;
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        toCountShareValueEntries(
            countRows: readonly ComputedIncomingCallRow[],
            totalRows: readonly ComputedIncomingCallRow[]
        ): ReportingTableItemColumnEntries {
            const absoluteValue = { value: countRows.length };
            const percentageValue = {
                value: rate(countRows.length, totalRows.length),
                isPercentage: true,
            };

            return this.evaluationType === EvaluationType.PERCENTAGE
                ? [percentageValue, absoluteValue]
                : [absoluteValue, percentageValue];
        },
    },

    components: {
        IncomingCallReportPage,
        ReportTableCard,
    },
});
