
import { caseDashboardState } from "./caseDashboard";
import CaseChannelOfFirstReactionByAssigneeTile from "./cases/CaseChannelOfFirstReactionByAssigneeTile.vue";
import CaseChannelOfFirstReactionByGroupTile from "./cases/CaseChannelOfFirstReactionByGroupTile.vue";
import CaseClosedRateTile from "./cases/CaseClosedRateTile.vue";
import CaseCountTile from "./cases/CaseCountTile.vue";
import CaseSentimentByGroupTile from "./cases/CaseSentimentByGroupTile.vue";
import CaseSentimentTile from "./cases/CaseSentimentTile.vue";
import CaseSentimentTimeSeriesTile from "./cases/CaseSentimentTimeSeriesTile.vue";
import CaseStatusByGroupTile from "./cases/CaseStatusByGroupTile.vue";
import CaseStatusTile from "./cases/CaseStatusTile.vue";
import CaseTypeGroupByGroupTile from "./cases/CaseTypeGroupByGroupTile.vue";
import CaseWaitingTimeUntilFirstReactionAsPerceivedExternallyByAssigneeTile from "./cases/CaseWaitingTimeUntilFirstReactionAsPerceivedExternallyByAssigneeTile.vue";
import CaseWaitingTimeUntilFirstReactionAsPerceivedExternallyByGroupTile from "./cases/CaseWaitingTimeUntilFirstReactionAsPerceivedExternallyByGroupTile.vue";
import CaseWaitingTimeUntilFirstReactionAsPerceivedExternallyTile from "./cases/CaseWaitingTimeUntilFirstReactionAsPerceivedExternallyTile.vue";
import CaseWaitingTimeUntilFirstReactionByAssigneeTile from "./cases/CaseWaitingTimeUntilFirstReactionByAssigneeTile.vue";
import CaseWaitingTimeUntilFirstReactionByGroupTile from "./cases/CaseWaitingTimeUntilFirstReactionByGroupTile.vue";
import CaseWaitingTimeUntilFirstReactionTile from "./cases/CaseWaitingTimeUntilFirstReactionTile.vue";
import OutgoingActivityCountByGroupTile from "./compound/OutgoingActivityCountByGroupTile.vue";
import IncomingCallCountByGroupTile from "./incomingcalls/IncomingCallCountByGroupTile.vue";
import IncomingCallCountTimeSeriesTile from "./incomingcalls/IncomingCallCountTimeSeriesTile.vue";
import OutgoingCallCountTimeSeriesTile from "./outgoingcalls/OutgoingCallCountTimeSeriesTile.vue";
import OutgoingCallStatusByGroupTile from "./outgoingcalls/OutgoingCallStatusByGroupTile.vue";
import OutgoingEmailCountByGroupTile from "./outgoingemails/OutgoingEmailCountByGroupTile.vue";
import OutgoingEmailCountTimeSeriesTile from "./outgoingemails/OutgoingEmailCountTimeSeriesTile.vue";
import { CaseChannel } from "@/api/cases";
import {
    CaseRow,
    IncomingCallRow,
    IssueType,
    OutgoingCallRow,
    OutgoingEmailRow,
    OutgoingSmsRow,
    OutgoingWhatsAppMessageRow,
    reportingApi,
} from "@/api/reporting";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import DealerPicker from "@/app/pages/DealerPicker.vue";
import { withIssueType, WithIssueType } from "@/app/pages/reporting/activityRowUtils";
import { EMPTY_CASE_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/cases/caseRowSearchUtils";
import {
    filterConsiderCaseInReports,
    withCaseStatus,
    WithCaseStatus,
    withCaseTypeGroup,
    WithCaseTypeGroup,
    withSentimentType,
    withWaitingTimeUntilFirstReaction,
    withWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "@/app/pages/reporting/cases/caseRowUtils";
import { EMPTY_INCOMING_CALL_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/incomingcalls/incomingCallRowSearchUtils";
import {
    PotentiallyErroneous,
    withCloser,
    WithCloser,
    withCreatorActorType,
    WithCreatorActorType,
    withFirstAssignedTime,
    WithFirstAssignedTime,
    withLastCommunicationDate,
    WithSentimentType,
    WithWaitingTimeUntilFirstReaction,
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "@/app/pages/reporting/issueRowUtils";
import { EMPTY_OUTGOING_CALL_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/outgoingcalls/outgoingCallRowSearchUtils";
import { EMPTY_OUTGOING_EMAIL_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/outgoingemails/outgoingEmailRowSearchUtils";
import { EMPTY_OUTGOING_SMS_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/outgoingsms/outgoingSmsRowSearchUtils";
import { EMPTY_OUTGOING_WHATSAPP_MESSAGE_ROW_SEARCH_REQUEST } from "@/app/pages/reporting/outgoingwhatsappmessages/outgoingWhatsAppMessageRowSearchUtils";
import { canReportAllDealers, getReportableDealerIds } from "@/app/pages/reporting/reportingPermissionUtils";
import {
    getEndOfRelativeTimeSlot,
    getOngoingTimeSlotKeys,
    getStartOfRelativeTimeSlot,
    OngoingTimeInterval,
    RelativeTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { caseTypesStore } from "@/store/caseTypes";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatLocalDate, getDate, toDateObject } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

type ComputedCaseRow = CaseRow &
    WithCaseTypeGroup &
    WithCloser &
    WithCreatorActorType &
    WithFirstAssignedTime<PotentiallyErroneous> &
    WithCaseStatus &
    WithSentimentType &
    WithWaitingTimeUntilFirstReaction<PotentiallyErroneous> &
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>;

type ComputedIncomingCallRow = IncomingCallRow & WithIssueType;
type ComputedOutgoingCallRow = OutgoingCallRow & WithIssueType;
type ComputedOutgoingEmailRow = OutgoingEmailRow & WithIssueType;
type ComputedOutgoingSmsRow = OutgoingSmsRow & WithIssueType;
type ComputedOutgoingWhatsAppMessageRow = OutgoingWhatsAppMessageRow & WithIssueType;

interface QuickFilter {
    readonly label: TranslateResult;
    readonly from: string;
    readonly to: string;
}

export default Vue.extend({
    data() {
        return {
            CaseChannel,
            caseDashboardState,
            caseRows: [] as readonly CaseRow[],
            incomingCallRows: [] as readonly IncomingCallRow[],
            loading: false,
            loadLimiter: new ActionLimiter(true),
            nowAtLoadRows: now() as Date,
            outgoingCallRows: [] as readonly OutgoingCallRow[],
            outgoingEmailRows: [] as readonly OutgoingEmailRow[],
            outgoingSmsRows: [] as readonly OutgoingSmsRow[],
            outgoingWhatsAppMessageRows: [] as readonly OutgoingWhatsAppMessageRow[],
            searchId: 0,
        };
    },

    computed: {
        canReportAllDealers(): boolean {
            return canReportAllDealers();
        },

        casesWithoutEscalationGroupReceivers(): readonly ComputedCaseRow[] {
            return this.filteredCases.filter((r) => !r.escalationGroupReceivers.length);
        },

        computedCaseRows(): readonly ComputedCaseRow[] {
            const computedRows = [...this.caseRows]
                .map(withLastCommunicationDate)
                .sort(
                    (a, b) =>
                        (b.lastCommunicationDate?.getTime() ?? 0) - (a.lastCommunicationDate?.getTime() ?? 0) ||
                        b.created.getTime() - a.created.getTime()
                )
                .map(withCaseTypeGroup)
                .map(withCloser)
                .map(withCreatorActorType)
                .map(withFirstAssignedTime)
                .map(withCaseStatus)
                .map(withSentimentType);

            return [computedRows]
                .map((rows) => withWaitingTimeUntilFirstReaction(rows))
                .map((rows) => withWaitingTimeUntilFirstReactionAsPerceivedExternally(rows))
                .pop()!;
        },

        filteredCases(): readonly ComputedCaseRow[] {
            return this.computedCaseRows
                .filter(filterConsiderCaseInReports)
                .filter((row) => this.reportedCaseTypes.includes(row.caseType))
                .filter((row) => this.reportedChannels.includes(row.channel));
        },

        filteredCaseIds(): string[] {
            return this.filteredCases.map((r) => r.id);
        },

        filteredIncomingCallRows(): readonly ComputedIncomingCallRow[] {
            return this.incomingCallRows
                .filter((row) => row.caseId && this.filteredCaseIds.includes(row.caseId))
                .map(withIssueType);
        },

        filteredOutgoingCallRows(): readonly ComputedOutgoingCallRow[] {
            return this.outgoingCallRows
                .filter((row) => row.caseId && this.filteredCaseIds.includes(row.caseId))
                .map(withIssueType);
        },

        filteredOutgoingEmailRows(): readonly ComputedOutgoingEmailRow[] {
            return this.outgoingEmailRows
                .filter((row) => row.caseId && this.filteredCaseIds.includes(row.caseId))
                .map(withIssueType);
        },

        filteredOutgoingSmsRows(): readonly ComputedOutgoingSmsRow[] {
            return this.outgoingSmsRows
                .filter((row) => row.caseId && this.filteredCaseIds.includes(row.caseId))
                .map(withIssueType);
        },

        filteredOutgoingWhatsAppMessageRows(): readonly ComputedOutgoingWhatsAppMessageRow[] {
            return this.outgoingWhatsAppMessageRows
                .filter((row) => row.caseId && this.filteredCaseIds.includes(row.caseId))
                .map(withIssueType);
        },

        quickFilters(): QuickFilter[] {
            const ts = this.nowAtLoadRows;

            return [
                { label: this.$t("Heute"), interval: RelativeTimeInterval.TODAY },
                { label: this.$t("Letzten 7 Tage"), interval: RelativeTimeInterval.LAST_SEVEN_DAYS },
                { label: this.$t("Aktuelle Woche"), interval: RelativeTimeInterval.CURRENT_WEEK },
                { label: this.$t("Letzte Woche"), interval: RelativeTimeInterval.PREVIOUS_WEEK },
                { label: this.$t("Aktueller Monat"), interval: RelativeTimeInterval.CURRENT_MONTH },
                { label: this.$t("Letzter Monat"), interval: RelativeTimeInterval.PREVIOUS_MONTH },
                { label: this.$t("Aktuelles Quartal"), interval: RelativeTimeInterval.CURRENT_QUARTER },
                { label: this.$t("Letztes Quartal"), interval: RelativeTimeInterval.PREVIOUS_QUARTER },
            ].map((i) => ({
                label: i.label,
                from: getDate(getStartOfRelativeTimeSlot(ts, i.interval)!, this.timeZone),
                to: getDate(getEndOfRelativeTimeSlot(ts, i.interval)!, this.timeZone),
            }));
        },

        reportableDealerIds(): string[] {
            return getReportableDealerIds();
        },

        reportedCaseTypes(): string[] {
            return caseTypesStore.caseTypeGroups
                .map((caseTypeGroup) => caseTypeGroup.caseTypes)
                .reduce((prev, cur) => prev.concat(cur), [])
                .filter(
                    (caseType) =>
                        !this.caseDashboardState.caseTypes.length ||
                        this.caseDashboardState.caseTypes.includes(caseType)
                );
        },

        reportedChannels(): CaseChannel[] {
            return (Object.keys(CaseChannel) as CaseChannel[]).filter(
                (channel) =>
                    !this.caseDashboardState.channels.length || this.caseDashboardState.channels.includes(channel)
            );
        },

        timeSeriesFrom(): Date {
            return toDateObject(this.timeZone, this.caseDashboardState.createdRange.from);
        },

        timeSeriesNumberOfDays(): number {
            return getOngoingTimeSlotKeys(
                this.timeSeriesFrom,
                this.timeSeriesTo,
                OngoingTimeInterval.DATE,
                this.timeZone,
                true
            ).length;
        },

        timeSeriesNumberOfWeeks(): number {
            return getOngoingTimeSlotKeys(
                this.timeSeriesFrom,
                this.timeSeriesTo,
                OngoingTimeInterval.WEEK,
                this.timeZone,
                true
            ).length;
        },

        timeSeriesOngoingTimeIntervalSlim(): OngoingTimeInterval {
            if (this.timeSeriesNumberOfDays <= 1) {
                return OngoingTimeInterval.SIXTY_MINUTES;
            } else if (this.timeSeriesNumberOfDays <= 14) {
                return OngoingTimeInterval.DATE;
            } else if (this.timeSeriesNumberOfWeeks <= 14) {
                return OngoingTimeInterval.WEEK;
            } else {
                return OngoingTimeInterval.MONTH;
            }
        },

        timeSeriesOngoingTimeIntervalWide(): OngoingTimeInterval {
            if (this.timeSeriesNumberOfDays <= 1) {
                return OngoingTimeInterval.THIRTY_MINUTES;
            } else if (this.timeSeriesNumberOfDays <= 31) {
                return OngoingTimeInterval.DATE;
            } else if (this.timeSeriesNumberOfWeeks <= 26) {
                return OngoingTimeInterval.WEEK;
            } else {
                return OngoingTimeInterval.MONTH;
            }
        },

        timeSeriesTo(): Date {
            const timeSeriesTo = toDateObject(
                this.timeZone,
                this.caseDashboardState.createdRange.to,
                0,
                "23:59:59.999"
            );

            return timeSeriesTo.getTime() <= this.nowAtLoadRows.getTime() ? timeSeriesTo : this.nowAtLoadRows;
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        uncontactedCases(): readonly ComputedCaseRow[] {
            return this.filteredCases.filter((r) => r.waitingTimeUntilFirstReactionAsPerceivedExternally === null);
        },
    },

    methods: {
        getLocalDate(date: string): string {
            return formatLocalDate(date, userSession.locale, "L");
        },

        isTimeRangeSelected(quickFilter: QuickFilter) {
            return (
                this.caseDashboardState.createdRange.from === quickFilter.from &&
                this.caseDashboardState.createdRange.to === quickFilter.to
            );
        },

        async loadRows() {
            this.nowAtLoadRows = now();
            this.caseRows = [];
            this.incomingCallRows = [];
            this.outgoingCallRows = [];
            this.outgoingEmailRows = [];
            this.outgoingSmsRows = [];
            this.outgoingWhatsAppMessageRows = [];
            this.loading = true;
            const searchId = ++this.searchId;

            await this.loadLimiter.execute(async () => {
                try {
                    const createdFrom = this.caseDashboardState.searchRequest.createdFrom;
                    const createdTo = this.caseDashboardState.searchRequest.createdTo;

                    const [
                        caseRows,
                        incomingCallRows,
                        outgoingCallRows,
                        outgoingEmailRows,
                        outgoingSmsRows,
                        outgoingWhatsAppMessageRows,
                    ] = await Promise.all([
                        reportingApi.caseRows({
                            ...EMPTY_CASE_ROW_SEARCH_REQUEST,
                            dealerIds: this.caseDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                            includeFirstAssigned: true,
                            includeFirstOutgoingActivities: true,
                            includeLastIncomingActivities: true,
                            includeLastOutgoingActivities: true,
                            includeAssignees: true,
                            includeAssigneeFirstAssigned: true,
                            includeAssigneeFirstOutgoingActivities: true,
                            includeEscalationGroupReceivers: true,
                        }),
                        reportingApi.incomingCallRows({
                            ...EMPTY_INCOMING_CALL_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.CASE],
                            dealerIds: this.caseDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                        reportingApi.outgoingCallRows({
                            ...EMPTY_OUTGOING_CALL_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.CASE],
                            dealerIds: this.caseDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                        reportingApi.outgoingEmailRows({
                            ...EMPTY_OUTGOING_EMAIL_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.CASE],
                            dealerIds: this.caseDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                        reportingApi.outgoingSmsRows({
                            ...EMPTY_OUTGOING_SMS_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.CASE],
                            dealerIds: this.caseDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                        reportingApi.outgoingWhatsAppMessageRows({
                            ...EMPTY_OUTGOING_WHATSAPP_MESSAGE_ROW_SEARCH_REQUEST,
                            issueTypes: [IssueType.CASE],
                            dealerIds: this.caseDashboardState.dealerIds,
                            createdFrom,
                            createdTo,
                        }),
                    ]);

                    if (searchId === this.searchId) {
                        this.caseRows = Object.freeze(caseRows);
                        this.incomingCallRows = Object.freeze(incomingCallRows);
                        this.outgoingCallRows = Object.freeze(outgoingCallRows as OutgoingCallRow[]);
                        this.outgoingEmailRows = Object.freeze(outgoingEmailRows as OutgoingEmailRow[]);
                        this.outgoingSmsRows = Object.freeze(outgoingSmsRows as OutgoingSmsRow[]);
                        this.outgoingWhatsAppMessageRows = Object.freeze(
                            outgoingWhatsAppMessageRows as OutgoingWhatsAppMessageRow[]
                        );
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loading = false;
                    }
                }
            });
        },

        refresh() {
            this.caseDashboardState.refresh();
        },

        selectTimeRange(quickFilter: QuickFilter): void {
            this.caseDashboardState.createdRange = { from: quickFilter.from, to: quickFilter.to };
        },
    },

    watch: {
        "caseDashboardState.searchRequest": {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadRows();
    },

    components: {
        CaseChannelOfFirstReactionByAssigneeTile,
        CaseChannelOfFirstReactionByGroupTile,
        CaseClosedRateTile,
        CaseCountTile,
        CaseSentimentByGroupTile,
        CaseSentimentTile,
        CaseSentimentTimeSeriesTile,
        CaseStatusByGroupTile,
        CaseStatusTile,
        CaseTypeGroupByGroupTile,
        CaseTypePicker,
        CaseWaitingTimeUntilFirstReactionAsPerceivedExternallyByAssigneeTile,
        CaseWaitingTimeUntilFirstReactionAsPerceivedExternallyByGroupTile,
        CaseWaitingTimeUntilFirstReactionAsPerceivedExternallyTile,
        CaseWaitingTimeUntilFirstReactionByAssigneeTile,
        CaseWaitingTimeUntilFirstReactionByGroupTile,
        CaseWaitingTimeUntilFirstReactionTile,
        DateRangePicker,
        DealerPicker,
        EnumField,
        IncomingCallCountByGroupTile,
        IncomingCallCountTimeSeriesTile,
        OutgoingActivityCountByGroupTile,
        OutgoingCallStatusByGroupTile,
        OutgoingCallCountTimeSeriesTile,
        OutgoingEmailCountByGroupTile,
        OutgoingEmailCountTimeSeriesTile,
    },
});
