
import CaseReportPage from "./CaseReportPage.vue";
import { CaseReportPageResult, ExtendedCaseRow } from "./caseReportPage";
import {
    getCaseRowDefaultKeys,
    mapCaseRowKeyToRowLabel,
    withAssigneeWaitingTimeUntilFirstReaction,
    WithCaseTypeGroup,
} from "./caseRowUtils";
import { CaseStatus } from "@/api/cases";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import ReportingBarChartCard from "@/app/pages/reporting/ReportingBarChartCard.vue";
import ReportingPunchCardChartCard from "@/app/pages/reporting/ReportingPunchCardChartCard.vue";
import {
    BarChartSeriesOptions,
    durationFormatter,
    getPunchCardChartForHeaderGroup,
    getReportingBarChartForHeaderGroup,
    PunchCardChartSeriesOptions,
} from "@/app/pages/reporting/chartUtils";
import { ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { ReportingPunchCardChartData } from "@/app/pages/reporting/charts/reportingPunchCardChart";
import { getCaseStatusColor, ISSUE_STATUS_TOTAL_COLOR } from "@/app/pages/reporting/colors";
import {
    ElapsedTime,
    IndividualDurationSlotOrError,
    isWithAssigneeWaitingTimeUntilFirstReactionErrorless,
    isWithWaitingTimeUntilFirstReactionErrorless,
    PotentiallyErroneous,
    splitWithAssignee,
    WAITING_TIME_UNTIL_FIRST_REACTION_INDIVIDUAL_DURATION_SLOTS,
    WithAssignee,
    withAssigneeSetToNull,
    WithAssigneeWaitingTimeUntilFirstReaction,
    withAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot,
    WithAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot,
    withAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlotSetToUndetermined,
    withAssigneeWaitingTimeUntilFirstReactionSetToUndetermined,
    WithCloser,
    WithCreatorActorType,
    withIsAssigneeFirstReactingAssignee,
    WithSentimentType,
    WithWaitingTimeUntilFirstReaction,
    WithWaitingTimeUntilFirstReactionIndividualDurationSlot,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { median, rate, renderDuration } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import {
    getSortByOptions,
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnEntries,
    ReportingTableItemColumnGroup,
    ReportingTableSortByOptions,
    ReportingTableSortByOptionValue,
    sort,
} from "@/app/pages/reporting/table/reportingTable";
import { getIndividualDurationIntervalLabel, IndividualDurationInterval } from "@/app/pages/reporting/timeInterval";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type PrecomputedProperties = WithCaseTypeGroup &
    WithCloser &
    WithCreatorActorType &
    WithSentimentType &
    WithWaitingTimeUntilFirstReaction<PotentiallyErroneous> &
    WithWaitingTimeUntilFirstReactionIndividualDurationSlot<PotentiallyErroneous>;

interface WithReportWaitTimeUntilFirstReaction {
    readonly reportWaitTimeUntilFirstReaction: ElapsedTime;
    readonly reportWaitTimeUntilFirstReactionIndividualDurationSlot: IndividualDurationSlotOrError;
    readonly reportIsRelevantForWithFirstReaction: boolean;
}

type ComputedCaseRow = ExtendedCaseRow<PrecomputedProperties> &
    WithAssignee &
    WithAssigneeWaitingTimeUntilFirstReaction<PotentiallyErroneous> &
    WithAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot<PotentiallyErroneous> &
    WithReportWaitTimeUntilFirstReaction;

type ComputedCaseRowGroupBy = keyof Pick<
    ComputedCaseRow,
    | "assigneeId"
    | "caseStatus"
    | "caseType"
    | "caseTypeGroup"
    | "channel"
    | "closer"
    | "creatorActorType"
    | "dealerId"
    | "sentimentType"
>;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedCaseRowGroupBy;
}

type ReportingTableHeaderGroupWithColumnGroupComputer<Options> = ReportingTableHeaderGroup<Options> & {
    readonly toColumnGroup: (
        rowGroupRows: readonly ComputedCaseRow[],
        withFirstReactionRows: readonly ComputedCaseRow[],
        withoutFirstReactionRows: readonly ComputedCaseRow[],
        totalRows: readonly ComputedCaseRow[]
    ) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
            evaluationType: EvaluationType.ABSOLUTE as EvaluationType,
            EvaluationType,
            groupBy: "channel" as ComputedCaseRowGroupBy,
            result: null as CaseReportPageResult<PrecomputedProperties> | null,
            sortBy: {
                groupIndex: 0,
                columnIndex: 0,
                direction: "DESC",
            } as ReportingTableSortByOptionValue,
        };
    },

    computed: {
        defaultKeys(): RowKey[] {
            return getCaseRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        filteredComputedCaseRows(): readonly ComputedCaseRow[] {
            let filteredRows = (this.result?.rows ?? [])
                .filter((row) => this.groupBy !== "closer" || row.caseStatus === CaseStatus.CLOSED)
                .map(withAssigneeSetToNull)
                .map(withAssigneeWaitingTimeUntilFirstReactionSetToUndetermined)
                .map(withAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlotSetToUndetermined);

            if (this.groupBy === "assigneeId") {
                filteredRows = [filteredRows]
                    .map((rows) => splitWithAssignee(rows))
                    .map((rows) => withAssigneeWaitingTimeUntilFirstReaction(rows))
                    .pop()!
                    .map((row) =>
                        withAssigneeWaitingTimeUntilFirstReactionIndividualDurationSlot(
                            row,
                            this.waitingTimeUntilFirstReactionSlots
                        )
                    );
            }

            return filteredRows
                .filter((r) =>
                    this.groupBy === "assigneeId"
                        ? isWithAssigneeWaitingTimeUntilFirstReactionErrorless(r)
                        : isWithWaitingTimeUntilFirstReactionErrorless(r)
                )
                .map((r) => {
                    if (this.groupBy === "assigneeId") {
                        return {
                            ...r,
                            reportWaitTimeUntilFirstReaction: r.assigneeWaitingTimeUntilFirstReaction as ElapsedTime,
                            reportWaitTimeUntilFirstReactionIndividualDurationSlot:
                                r.assigneeWaitingTimeUntilFirstReactionIndividualDurationSlot,
                            reportIsRelevantForWithFirstReaction: withIsAssigneeFirstReactingAssignee(r)
                                .isAssigneeFirstReactingAssignee,
                        };
                    } else {
                        return {
                            ...r,
                            reportWaitTimeUntilFirstReaction: r.waitingTimeUntilFirstReaction as ElapsedTime,
                            reportWaitTimeUntilFirstReactionIndividualDurationSlot:
                                r.waitingTimeUntilFirstReactionIndividualDurationSlot,
                            reportIsRelevantForWithFirstReaction: true,
                        };
                    }
                });
        },

        firstReactionTimeChart(): ReportingPunchCardChartData | null {
            return getPunchCardChartForHeaderGroup(this.table, 4);
        },

        firstReactionTimeMedianChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 3);
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "creatorActorType",
                    text: this.$t("Art des Erstellers"),
                },
                {
                    value: "assigneeId",
                    text: this.$t("Bearbeiter"),
                },
                {
                    value: "caseTypeGroup",
                    text: this.$t("Fall-Kategorie"),
                },
                {
                    value: "caseType",
                    text: this.$t("Fall-Typ"),
                },
                {
                    value: "channel",
                    text: this.$t("Kanal"),
                },
                {
                    value: "closer",
                    text: this.$t("Schließer"),
                },
                {
                    value: "caseStatus",
                    text: this.$t("Status"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
                {
                    value: "sentimentType",
                    text: this.$t("Stimmung"),
                },
            ];
        },

        groupByText(): string | null {
            return (this.groupByOptions.find((o) => o.value === this.groupBy)?.text ?? null) as string | null;
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedCaseRow>[] {
            const rowGroups = groupRowsBy(this.filteredComputedCaseRows, (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapCaseRowKeyToRowLabel(rowGroup.key, this.groupBy, {
                    ...(this.result?.mapKeyToLabelContext ?? {}),
                    waitingTimeUntilFirstReactionSlots: this.waitingTimeUntilFirstReactionSlots,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        sortByOptions(): ReportingTableSortByOptions {
            return getSortByOptions(this.tableHeaderGroups);
        },

        table(): ReportingTableData<BarChartSeriesOptions | PunchCardChartSeriesOptions> | null {
            if (!this.tableData) {
                return null;
            }

            return {
                ...this.tableData,
                items: sort(this.tableData.items, this.sortBy),
            };
        },

        tableData(): ReportingTableData<BarChartSeriesOptions | PunchCardChartSeriesOptions> | null {
            const allRows = this.filteredComputedCaseRows;

            if (!allRows.length) {
                return null;
            }

            const allWithFirstReactionRows = allRows.filter(
                (r) => r.reportIsRelevantForWithFirstReaction && r.reportWaitTimeUntilFirstReaction !== null
            );
            const allWithoutFirstReactionRows = allRows.filter((r) => r.reportWaitTimeUntilFirstReaction === null);

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => {
                    const withFirstReactionRows = rowGroup.rows.filter(
                        (r) => r.reportIsRelevantForWithFirstReaction && r.reportWaitTimeUntilFirstReaction !== null
                    );
                    const withoutFirstReactionRows = rowGroup.rows.filter(
                        (r) => r.reportWaitTimeUntilFirstReaction === null
                    );

                    return {
                        title: rowGroup.title,
                        subtitle: rowGroup.subtitle,
                        groups: this.tableHeaderGroups.map((headerGroup) =>
                            headerGroup.toColumnGroup(
                                rowGroup.rows,
                                withFirstReactionRows,
                                withoutFirstReactionRows,
                                allRows
                            )
                        ),
                    };
                }),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) =>
                        headerGroup.toColumnGroup(
                            allRows,
                            allWithFirstReactionRows,
                            allWithoutFirstReactionRows,
                            allRows
                        )
                    ),
                },
                groupByHeaderText: this.groupByText ?? undefined,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer<BarChartSeriesOptions>[] {
            const caseStatuses = Object.keys(CaseStatus) as CaseStatus[];

            return [
                {
                    text: null,
                    headers: [{ text: this.$t("Gesamt") as string }],
                    toColumnGroup: (rowGroupRows, _, __, allRows) => ({
                        columns: [{ entries: this.toCountShareValueEntries(rowGroupRows, allRows) }],
                    }),
                },
                {
                    text: this.$t("Gesamt") as string,
                    headers: [
                        { text: this.$t("Ohne Erstreaktion") as string },
                        { text: this.$t("Mit Erstreaktion") as string },
                    ],
                    toColumnGroup: (rowGroupRows, withFirstReactionRows, withoutFirstReactionRows) => ({
                        columns: [
                            {
                                entries: this.toCountShareValueEntries(withoutFirstReactionRows, rowGroupRows),
                            },
                            {
                                entries: this.toCountShareValueEntries(withFirstReactionRows, rowGroupRows),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Ohne Erstreaktion") as string,
                    headers: caseStatuses.map((caseStatus) => ({
                        text: this.$t(`enum.CaseStatus.${caseStatus}`) as string,
                    })),
                    toColumnGroup: (_, __, withoutFirstReactionRows) => ({
                        columns: caseStatuses.map((caseStatus) => ({
                            entries: this.toCountShareValueEntries(
                                withoutFirstReactionRows.filter((r) => r.caseStatus === caseStatus),
                                withoutFirstReactionRows
                            ),
                        })),
                    }),
                },
                {
                    text: this.$t("Reaktionszeit der Erstreaktion (Median)") as string,
                    headers: [
                        {
                            text: this.$t("Gesamt") as string,
                            options: {
                                color: ISSUE_STATUS_TOTAL_COLOR,
                                formatter: (value, __, context) => durationFormatter(value, context),
                            } as BarChartSeriesOptions,
                        },
                        ...caseStatuses.map((caseStatus) => ({
                            text: this.$t(`enum.CaseStatus.${caseStatus}`) as string,
                            options: {
                                color: getCaseStatusColor(caseStatus),
                                formatter: (value, __, context) => durationFormatter(value, context),
                            } as BarChartSeriesOptions,
                        })),
                    ],
                    toColumnGroup: (_, withFirstReactionRows) => ({
                        columns: [
                            {
                                entries: this.toMedianDurationEntries(
                                    withFirstReactionRows,
                                    (r) => r.reportWaitTimeUntilFirstReaction
                                ),
                            },
                            ...caseStatuses.map((caseStatus) => ({
                                entries: this.toMedianDurationEntries(
                                    withFirstReactionRows.filter((r) => r.caseStatus === caseStatus),
                                    (r) => r.reportWaitTimeUntilFirstReaction
                                ),
                            })),
                        ],
                    }),
                },
                {
                    text: this.$t("Reaktionszeit der Erstreaktion") as string,
                    headers: this.waitingTimeUntilFirstReactionSlots.map((slot) => ({
                        text: getIndividualDurationIntervalLabel(slot),
                    })),
                    toColumnGroup: (_, withFirstReactionRows) => ({
                        columns: this.waitingTimeUntilFirstReactionSlots.map((slot) => ({
                            entries: this.toCountShareValueEntries(
                                withFirstReactionRows.filter(
                                    (r) => r.reportWaitTimeUntilFirstReactionIndividualDurationSlot === slot.key
                                ),
                                withFirstReactionRows
                            ),
                        })),
                    }),
                },
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        waitingTimeUntilFirstReactionSlots(): IndividualDurationInterval[] {
            return [...WAITING_TIME_UNTIL_FIRST_REACTION_INDIVIDUAL_DURATION_SLOTS];
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },

        toCountShareValueEntries(
            countRows: readonly ComputedCaseRow[],
            totalRows: readonly ComputedCaseRow[]
        ): ReportingTableItemColumnEntries {
            const absoluteValue = { value: countRows.length };
            const percentageValue = {
                value: rate(countRows.length, totalRows.length),
                isPercentage: true,
            };

            const onClick = this.showBottomSheetOnClick(countRows.map((r) => r.id));

            return this.evaluationType === EvaluationType.PERCENTAGE
                ? [{ ...percentageValue, onClick }, absoluteValue]
                : [{ ...absoluteValue, onClick }, percentageValue];
        },

        toMedianDurationEntries(
            rows: readonly ComputedCaseRow[],
            getValue: (r: ComputedCaseRow) => ElapsedTime
        ): ReportingTableItemColumnEntries {
            const medianRows = rows.filter((r) => getValue(r) !== null);

            return [
                {
                    value: median(medianRows.map(getValue)),
                    onClick: this.showBottomSheetOnClick(medianRows.map((r) => r.id)),
                    formatter: (value) => renderDuration(value, "L"),
                },
            ];
        },
    },

    components: {
        CaseReportPage,
        CasesBottomSheet,
        ReportingBarChartCard,
        ReportingPunchCardChartCard,
        ReportTableCard,
    },
});
