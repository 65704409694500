
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import { mapCaseRowKeyToRowLabel, WithCaseStatus, WithCaseTypeGroup } from "@/app/pages/reporting/cases/caseRowUtils";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { CALL_COLOR, EMAIL_COLOR, SMS_COLOR, WHATSAPP_MESSAGE_COLOR } from "@/app/pages/reporting/colors";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import {
    ElapsedTime,
    Errorless,
    isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless,
    PotentiallyErroneous,
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedCaseRow = CaseRow &
    WithCaseStatus &
    WithCaseTypeGroup &
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally<Errorless>;

type ComputedCaseRowGroupBy = keyof Pick<ComputedCaseRow, "dealerId" | "channel" | "caseType" | "caseTypeGroup">;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "DEALER" | "CHANNEL" | "CASE_TYPE" | "CASE_TYPE_GROUP",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => (ComputedCaseRow &
                WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>)[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const isFirstOutgoingActivityPredicate = (
                r: ComputedCaseRow,
                waitingTimeUntilFirstOutgoingActivityAsPerceivedExternally: ElapsedTime
            ) =>
                r.waitingTimeUntilFirstReactionAsPerceivedExternally ===
                waitingTimeUntilFirstOutgoingActivityAsPerceivedExternally;

            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    callRows: rowGroup.rows.filter((r) =>
                        isFirstOutgoingActivityPredicate(r, r.waitingTimeUntilFirstOutgoingCallAsPerceivedExternally)
                    ),
                    emailRows: rowGroup.rows.filter((r) =>
                        isFirstOutgoingActivityPredicate(r, r.waitingTimeUntilFirstOutgoingEmailAsPerceivedExternally)
                    ),
                    smsRows: rowGroup.rows.filter((r) =>
                        isFirstOutgoingActivityPredicate(r, r.waitingTimeUntilFirstOutgoingSmsAsPerceivedExternally)
                    ),
                    whatsAppMessageRows: rowGroup.rows.filter((r) =>
                        isFirstOutgoingActivityPredicate(
                            r,
                            r.waitingTimeUntilFirstOutgoingWhatsAppAsPerceivedExternally
                        )
                    ),
                }))
                .map((c) => ({
                    ...c,
                    sortValue: c.callRows.length + c.emailRows.length + c.smsRows.length + c.whatsAppMessageRows.length,
                }))
                .filter((c) => c.sortValue || this.defaultGroupIds.includes(c.categoryId))
                .sort((a, b) => b.sortValue - a.sortValue);

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "first-reaction-by-call",
                        name: this.$t("Anruf") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.callRows.length,
                                onClick: this.showBottomSheetOnClick(c.callRows.map((r) => r.id)),
                            })),
                        },
                        color: CALL_COLOR,
                    },
                    {
                        id: "first-reaction-by-email",
                        name: this.$t("E-Mail") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.emailRows.length,
                                onClick: this.showBottomSheetOnClick(c.emailRows.map((r) => r.id)),
                            })),
                        },
                        color: EMAIL_COLOR,
                    },
                    {
                        id: "first-reaction-by-sms",
                        name: this.$t("SMS") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.smsRows.length,
                                onClick: this.showBottomSheetOnClick(c.smsRows.map((r) => r.id)),
                            })),
                        },
                        color: SMS_COLOR,
                    },
                    {
                        id: "first-reaction-by-whatsapp",
                        name: this.$t("WhatsApp-Nachricht") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.whatsAppMessageRows.length,
                                onClick: this.showBottomSheetOnClick(c.whatsAppMessageRows.map((r) => r.id)),
                            })),
                        },
                        color: WHATSAPP_MESSAGE_COLOR,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        groupByField(): ComputedCaseRowGroupBy | null {
            if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "CHANNEL") {
                return "channel";
            } else if (this.groupBy === "CASE_TYPE") {
                return "caseType";
            } else if (this.groupBy === "CASE_TYPE_GROUP") {
                return "caseTypeGroup";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string, ComputedCaseRow>[] {
            const filteredRows = this.rows
                .filter(isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless)
                .filter((r) => r.waitingTimeUntilFirstReactionAsPerceivedExternally !== null);

            const rowGroups = this.groupByField ? groupRowsBy(filteredRows, (r) => r[this.groupByField!]) : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapCaseRowKeyToRowLabel(rowGroup.key, this.groupByField!, {});

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "CHANNEL"
                        ? (this.$t("Kanal") as string)
                        : this.groupBy === "CASE_TYPE"
                        ? (this.$t("Fall-Typ") as string)
                        : this.groupBy === "CASE_TYPE_GROUP"
                        ? (this.$t("Fall-Kategorie") as string)
                        : undefined,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardBarTile,
    },
});
