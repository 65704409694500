
import { OpportunityStatus } from "@/api/opportunities";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { getReportingTableItemsByReportingDonutChartSeries } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingDonutChartData } from "@/app/pages/reporting/charts/reportingDonutChart";
import { getOpportunityStatusColor } from "@/app/pages/reporting/colors";
import ReportingDashboardDonutTile from "@/app/pages/reporting/dashboard/ReportingDashboardDonutTile.vue";
import { WithOpportunityStatus } from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedOpportunityRow = OpportunityRow & WithOpportunityStatus;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        rows: {
            type: Array as () => ComputedOpportunityRow[],
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingDonutChartData | undefined {
            const chart: ReportingDonutChartData = {
                title: "",
                series: [
                    { opportunityStatus: OpportunityStatus.CLOSED, outcome: true },
                    { opportunityStatus: OpportunityStatus.CLOSED, outcome: false },
                    { opportunityStatus: OpportunityStatus.POSTPONED, outcome: null },
                    { opportunityStatus: OpportunityStatus.ASSIGNED, outcome: null },
                    { opportunityStatus: OpportunityStatus.OPEN, outcome: null },
                ].map(({ opportunityStatus, outcome }) => {
                    const statusRows = this.rows.filter(
                        (r) => r.opportunityStatus === opportunityStatus && r.outcome === outcome
                    );

                    return {
                        id: "status-won",
                        name: (outcome === null
                            ? this.$t(`enum.OpportunityStatus.${opportunityStatus}`)
                            : outcome
                            ? this.$t("Gewonnen")
                            : this.$t("Verloren")) as string,
                        data: {
                            value: {
                                value: statusRows.length,
                                onClick: this.showBottomSheetOnClick(statusRows.map((r) => r.id)),
                            },
                        },
                        color: getOpportunityStatusColor(opportunityStatus, outcome),
                    };
                }),
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }
            return {
                headerGroups: [
                    {
                        text: this.$t("Verkaufschancen") as string,
                        headers: [{ text: this.$t("Verkaufschancen") as string }],
                    },
                ],
                items: getReportingTableItemsByReportingDonutChartSeries(this.chart.series),
                groupByHeaderText: this.$t("Status") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardDonutTile,
    },
});
