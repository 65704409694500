
import {
    ExtendedOpportunityRow,
    OpportunityReportPageFilter,
    OpportunityReportPageResult,
} from "./opportunityReportPage";
import { EMPTY_OPPORTUNITY_ROW_SEARCH_REQUEST } from "./opportunityRowSearchUtils";
import {
    filterConsiderOpportunityInReports,
    withOpportunityStatus,
    withSentimentType,
    withWaitingTimeUntilFirstReaction,
    withWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "./opportunityRowUtils";
import { OpportunityChannel } from "@/api/opportunities";
import { OpportunityRow, reportingApi } from "@/api/reporting";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import DealerPicker from "@/app/pages/DealerPicker.vue";
import ReportPage from "@/app/pages/reporting/ReportPage.vue";
import {
    PotentiallyErroneous,
    withCloser,
    withCreatedOngoingTimeSlot,
    withCreatedRecurringTimeSlot,
    withCreatorActorType,
    withFirstAssignedTime,
    withProcessingTime,
    WithProcessingTime,
    withProcessingTimeIndividualDurationSlot,
    WithWaitingTimeUntilFirstReaction,
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally,
    withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot,
    withWaitingTimeUntilFirstReactionIndividualDurationSlot,
} from "@/app/pages/reporting/issueRowUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { canReportAllDealers, getReportableDealerIds } from "@/app/pages/reporting/reportingPermissionUtils";
import {
    IndividualDurationInterval,
    OngoingTimeInterval,
    RecurringTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { opportunityTeamsStore } from "@/store/opportunityTeams";
import { userSession } from "@/store/userSession";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        evaluationType: {
            type: Number,
            required: false,
        },
        evaluationTypeOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        groupBy: {
            type: String,
            required: false,
        },
        groupByOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        loadAssigneeFirstAssigned: {
            type: Boolean,
            default: false,
        },
        loadAssigneeFirstOutgoingActivities: {
            type: Boolean,
            default: false,
        },
        loadAssigneeLastOutgoingActivities: {
            type: Boolean,
            default: false,
        },
        loadAssignees: {
            type: Boolean,
            default: false,
        },
        loadFirstAssigned: {
            type: Boolean,
            default: false,
        },
        loadFirstIncomingActivities: {
            type: Boolean,
            default: false,
        },
        loadFirstOutgoingActivities: {
            type: Boolean,
            default: false,
        },
        loadLastIncomingActivities: {
            type: Boolean,
            default: false,
        },
        loadLastOutgoingActivities: {
            type: Boolean,
            default: false,
        },
        loadOpportunityTeamReceivers: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        noData: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: Object,
            required: false,
        },
        sortByOptions: {
            type: Array as () => SelectOptions,
            required: false,
            default: () => [],
        },
        timeInterval: {
            type: String as () => OngoingTimeInterval | RecurringTimeInterval,
            required: false,
        },
        timeIntervals: {
            type: Array as () => OngoingTimeInterval[] | RecurringTimeInterval[],
            required: false,
            default: () => [],
        },
        timeRange: {
            type: Object as () => TimeRange,
            required: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
        withCloser: {
            type: Boolean,
            default: false,
        },
        withCreatedOngoingTimeSlot: {
            type: String as () => OngoingTimeInterval | null,
            default: null,
        },
        withCreatedRecurringTimeSlot: {
            type: String as () => RecurringTimeInterval | null,
            default: null,
        },
        withCreatorActorType: {
            type: Boolean,
            default: false,
        },
        withFirstAssignedTime: {
            type: Boolean,
            default: false,
        },
        withProcessingTime: {
            type: Boolean,
            default: false,
        },
        withProcessingTimeIndividualDurationSlot: {
            type: Array as () => IndividualDurationInterval[],
            default: null,
        },
        withSentimentType: {
            type: Boolean,
            default: false,
        },
        withWaitingTimeUntilFirstReaction: {
            type: Boolean,
            default: false,
        },
        withWaitingTimeUntilFirstReactionAsPerceivedExternally: {
            type: Boolean,
            default: false,
        },
        withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot: {
            type: Array as () => IndividualDurationInterval[],
            default: null,
        },
        withWaitingTimeUntilFirstReactionIndividualDurationSlot: {
            type: Array as () => IndividualDurationInterval[],
            default: null,
        },
    },

    data() {
        const ts = now();

        return {
            getReportableDealerIds,
            loadFilter: {
                dealerIds: [] as string[],
                createdRange: {
                    from: this.timeRange ? this.timeRange.from : getDate(ts, this.timeZone, -6),
                    to: this.timeRange ? this.timeRange.to : getDate(ts, this.timeZone),
                } as DateRange,
            },
            loadingRows: false,
            loadLimiter: new ActionLimiter(true),
            localFilter: {
                channels: [] as OpportunityChannel[],
                sourceIds: [] as string[],
                opportunityTeamReceiverIds: [] as string[],
            },
            nowAtLoadRows: null as Date | null,
            OpportunityChannel,
            rows: [] as readonly OpportunityRow[],
            searchId: 0,
        };
    },

    computed: {
        canReportAllDealers(): boolean {
            return canReportAllDealers();
        },

        computedRows(): readonly ExtendedOpportunityRow[] {
            return [this.rows]
                .map((rows) =>
                    this.isWithWaitingTimeUntilFirstReaction ? withWaitingTimeUntilFirstReaction(rows) : rows
                )
                .map((rows) =>
                    this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternally
                        ? withWaitingTimeUntilFirstReactionAsPerceivedExternally(rows)
                        : rows
                )
                .pop()!
                .map(withOpportunityStatus)
                .map((r) => (this.withCloser ? withCloser(r) : r))
                .map((r) => (this.withCreatorActorType ? withCreatorActorType(r) : r))
                .map((r) =>
                    this.withCreatedOngoingTimeSlot ? withCreatedOngoingTimeSlot(r, this.withCreatedOngoingTimeSlot) : r
                )
                .map((r) =>
                    this.withCreatedRecurringTimeSlot
                        ? withCreatedRecurringTimeSlot(r, this.withCreatedRecurringTimeSlot)
                        : r
                )
                .map((r) => (this.withFirstAssignedTime ? withFirstAssignedTime(r) : r))
                .map((r) => (this.isWithProcessingTime ? withProcessingTime(r, this.nowAtLoadRows!) : r))
                .map((r) =>
                    this.isWithProcessingTimeIndividualDurationSlot
                        ? withProcessingTimeIndividualDurationSlot(
                              r as ExtendedOpportunityRow & WithProcessingTime<PotentiallyErroneous>,
                              this.withProcessingTimeIndividualDurationSlot
                          )
                        : r
                )
                .map((r) => (this.withSentimentType ? withSentimentType(r) : r))
                .map((r) =>
                    this.isWithWaitingTimeUntilFirstReactionIndividualDurationSlot
                        ? withWaitingTimeUntilFirstReactionIndividualDurationSlot(
                              r as ExtendedOpportunityRow & WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>,
                              this.withWaitingTimeUntilFirstReactionIndividualDurationSlot
                          )
                        : r
                )
                .map((r) =>
                    this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot
                        ? withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot(
                              r as ExtendedOpportunityRow &
                                  WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>,
                              this.withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot
                          )
                        : r
                );
        },

        currentDate(): string | null {
            return this.nowAtLoadRows ? getDate(this.nowAtLoadRows, this.timeZone) : null;
        },

        filter(): OpportunityReportPageFilter {
            return {
                ...{ ...this.loadFilter, createdRange: undefined },
                createdFrom: this.loadFilter.createdRange.from,
                createdTo: this.loadFilter.createdRange.to,
                ...this.localFilter,
            };
        },

        filteredRows(): readonly ExtendedOpportunityRow[] {
            return this.computedRows
                .filter(filterConsiderOpportunityInReports)
                .filter((r) => !this.localFilter.channels.length || this.localFilter.channels.includes(r.channel))
                .filter(
                    (row) => !this.localFilter.sourceIds.length || this.localFilter.sourceIds.includes(row.sourceId)
                )
                .filter(
                    (row) =>
                        !this.localFilter.opportunityTeamReceiverIds.length ||
                        row.opportunityTeamReceivers.some((id) =>
                            this.localFilter.opportunityTeamReceiverIds.includes(id)
                        )
                );
        },

        isLoadFirstAssigned(): boolean {
            return (
                this.loadFirstAssigned ||
                this.withFirstAssignedTime ||
                this.isWithWaitingTimeUntilFirstReaction ||
                this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternally
            );
        },

        isLoadFirstOutgoingActivities(): boolean {
            return (
                this.loadFirstOutgoingActivities ||
                this.isWithWaitingTimeUntilFirstReaction ||
                this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternally
            );
        },

        isWithProcessingTime(): boolean {
            return this.withProcessingTime || this.isWithProcessingTimeIndividualDurationSlot;
        },

        isWithProcessingTimeIndividualDurationSlot(): boolean {
            return (this.withProcessingTimeIndividualDurationSlot ?? null) !== null;
        },

        isWithWaitingTimeUntilFirstReaction(): boolean {
            return (
                this.withWaitingTimeUntilFirstReaction || this.isWithWaitingTimeUntilFirstReactionIndividualDurationSlot
            );
        },

        isWithWaitingTimeUntilFirstReactionAsPerceivedExternally(): boolean {
            return (
                this.withWaitingTimeUntilFirstReactionAsPerceivedExternally ||
                this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot
            );
        },

        isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot(): boolean {
            return (this.withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot ?? null) !== null;
        },

        isWithWaitingTimeUntilFirstReactionIndividualDurationSlot(): boolean {
            return (this.withWaitingTimeUntilFirstReactionIndividualDurationSlot ?? null) !== null;
        },

        opportunityTeamOptions(): SelectOption[] {
            return opportunityTeamsStore.opportunityTeams.map((t) => ({
                value: t.id,
                text: `${t.name} (${dealersStore.dealerById(t.dealerId)!.name})`,
            }));
        },

        reportableDealerIds(): string[] {
            return getReportableDealerIds();
        },

        reportedChannels(): OpportunityChannel[] {
            return (Object.keys(OpportunityChannel) as OpportunityChannel[]).filter(
                (channel) => !this.localFilter.channels.length || this.localFilter.channels.includes(channel)
            );
        },

        reportedDealerIds(): string[] {
            return dealersStore.dealers
                .filter((d) => !this.loadFilter.dealerIds.length || this.loadFilter.dealerIds.includes(d.id))
                .sort((a, b) => a.name.localeCompare(b.name, userSession.locale))
                .map((d) => d.id);
        },

        reportedOpportunityTeamReceiverIds(): string[] {
            return opportunityTeamsStore.opportunityTeams
                .filter(
                    (t) =>
                        !this.localFilter.opportunityTeamReceiverIds.length ||
                        this.localFilter.opportunityTeamReceiverIds.includes(t.id)
                )
                .map((t) => t.id);
        },

        reportedSourceIds(): string[] {
            return opportunitySourcesStore.opportunitySources
                .filter(
                    (source) => !this.localFilter.sourceIds.length || this.localFilter.sourceIds.includes(source.id)
                )
                .map((source) => source.id);
        },

        sourceOptions(): SelectOption[] {
            return opportunitySourcesStore.opportunitySources.map((s) => ({ value: s.id, text: s.name }));
        },
    },

    methods: {
        async loadRows() {
            this.nowAtLoadRows = now();
            this.rows = [];
            const searchId = ++this.searchId;
            this.loadingRows = true;

            await this.loadLimiter.execute(async () => {
                try {
                    const rows = await reportingApi.opportunityRows({
                        ...EMPTY_OPPORTUNITY_ROW_SEARCH_REQUEST,
                        dealerIds: this.loadFilter.dealerIds,
                        createdFrom: toDateObject(this.timeZone, this.loadFilter.createdRange.from),
                        createdTo: toDateObject(this.timeZone, this.loadFilter.createdRange.to, 1),
                        includeAssigneeFirstAssigned: this.loadAssigneeFirstAssigned,
                        includeAssigneeFirstOutgoingActivities: this.loadAssigneeFirstOutgoingActivities,
                        includeAssigneeLastOutgoingActivities: this.loadAssigneeLastOutgoingActivities,
                        includeAssignees: this.loadAssignees,
                        includeFirstAssigned: this.isLoadFirstAssigned,
                        includeFirstIncomingActivities: this.loadFirstIncomingActivities,
                        includeFirstOutgoingActivities: this.isLoadFirstOutgoingActivities,
                        includeLastIncomingActivities: this.loadLastIncomingActivities,
                        includeLastOutgoingActivities: this.loadLastOutgoingActivities,
                        includeOpportunityTeamReceivers: this.loadOpportunityTeamReceivers,
                    });

                    const sortedRows = rows.sort((a, b) => b.created.getTime() - a.created.getTime());

                    if (searchId === this.searchId) {
                        this.rows = Object.freeze(sortedRows);
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loadingRows = false;
                    }
                }
            });
        },

        updateTimeRange(timeRange: TimeRange): void {
            this.loadFilter.createdRange = { from: timeRange.from, to: timeRange.to };
        },
    },

    watch: {
        filteredRows() {
            this.$emit("loaded", {
                filter: { ...this.filter },
                rows: [...this.filteredRows],
                nowAtLoadRows: this.nowAtLoadRows,
                defaultKeysContext: {
                    processingTimeSlots: this.withProcessingTimeIndividualDurationSlot ?? [],
                    visibleChannels: this.reportedChannels,
                    visibleDealerIds: this.reportedDealerIds,
                    visibleOpportunityTeamReceiverIds: this.reportedOpportunityTeamReceiverIds,
                    visibleSourceIds: this.reportedSourceIds,
                    waitingTimeUntilFirstReactionSlots:
                        this.withWaitingTimeUntilFirstReactionIndividualDurationSlot ??
                        this.withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot ??
                        [],
                },
                mapKeyToLabelContext: {
                    processingTimeSlots: this.withProcessingTimeIndividualDurationSlot ?? [],
                    waitingTimeUntilFirstReactionSlots:
                        this.withWaitingTimeUntilFirstReactionIndividualDurationSlot ??
                        this.withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot ??
                        [],
                },
            } as OpportunityReportPageResult);
        },

        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadRows();
    },

    components: {
        DateRangePicker,
        DealerPicker,
        EnumField,
        ReportPage,
    },
});
