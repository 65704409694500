
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import {
    withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally,
    WithCaseStatus,
} from "@/app/pages/reporting/cases/caseRowUtils";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { CALL_COLOR, EMAIL_COLOR, SMS_COLOR, WHATSAPP_MESSAGE_COLOR } from "@/app/pages/reporting/colors";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import {
    ElapsedTime,
    Errorless,
    isWithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless,
    isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless,
    PotentiallyErroneous,
    splitWithAssignee,
    WithAssignee,
    WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally,
    withIsAssigneeFirstReactingAssignee,
    WithIsAssigneeFirstReactingAssignee,
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "@/app/pages/reporting/issueRowUtils";
import { groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";
import Vue from "vue";

type ComputedCaseRow = CaseRow &
    WithAssignee &
    WithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally<Errorless> &
    WithCaseStatus &
    WithIsAssigneeFirstReactingAssignee &
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally<Errorless>;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => (CaseRow &
                WithCaseStatus &
                WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>)[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const isFirstOutgoingActivityPredicate = (
                r: ComputedCaseRow,
                assigneeWaitingTimeUntilFirstOutgoingActivityAsPerceivedExternally: ElapsedTime
            ) =>
                r.waitingTimeUntilFirstReactionAsPerceivedExternally ===
                assigneeWaitingTimeUntilFirstOutgoingActivityAsPerceivedExternally;

            const topCategories = this.rowGroups
                .map((rowGroup) => {
                    const firstReactingAssigneeRows = rowGroup.rows.filter((r) => r.isAssigneeFirstReactingAssignee);

                    return {
                        assigneeId: rowGroup.key!,
                        name: rowGroup.title,
                        description: rowGroup.subtitle,
                        callRows: firstReactingAssigneeRows.filter((r) =>
                            isFirstOutgoingActivityPredicate(
                                r,
                                r.assigneeWaitingTimeUntilFirstOutgoingCallAsPerceivedExternally
                            )
                        ),
                        emailRows: firstReactingAssigneeRows.filter((r) =>
                            isFirstOutgoingActivityPredicate(
                                r,
                                r.assigneeWaitingTimeUntilFirstOutgoingEmailAsPerceivedExternally
                            )
                        ),
                        smsRows: firstReactingAssigneeRows.filter((r) =>
                            isFirstOutgoingActivityPredicate(
                                r,
                                r.assigneeWaitingTimeUntilFirstOutgoingSmsAsPerceivedExternally
                            )
                        ),
                        whatsAppMessageRows: firstReactingAssigneeRows.filter((r) =>
                            isFirstOutgoingActivityPredicate(
                                r,
                                r.assigneeWaitingTimeUntilFirstOutgoingWhatsAppAsPerceivedExternally
                            )
                        ),
                    };
                })
                .map((c) => ({
                    ...c,
                    sortValue: c.callRows.length + c.emailRows.length + c.smsRows.length + c.whatsAppMessageRows.length,
                }))
                .filter((c) => c.sortValue)
                .sort((a, b) => b.sortValue - a.sortValue);

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "first-reaction-by-call",
                        name: this.$t("Anruf") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.callRows.length,
                                onClick: this.showBottomSheetOnClick(c.callRows.map((r) => r.id)),
                            })),
                        },
                        color: CALL_COLOR,
                    },
                    {
                        id: "first-reaction-by-email",
                        name: this.$t("E-Mail") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.emailRows.length,
                                onClick: this.showBottomSheetOnClick(c.emailRows.map((r) => r.id)),
                            })),
                        },
                        color: EMAIL_COLOR,
                    },
                    {
                        id: "first-reaction-by-sms",
                        name: this.$t("SMS") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.smsRows.length,
                                onClick: this.showBottomSheetOnClick(c.smsRows.map((r) => r.id)),
                            })),
                        },
                        color: SMS_COLOR,
                    },
                    {
                        id: "first-reaction-by-whatsapp",
                        name: this.$t("WhatsApp-Nachricht") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.whatsAppMessageRows.length,
                                onClick: this.showBottomSheetOnClick(c.whatsAppMessageRows.map((r) => r.id)),
                            })),
                        },
                        color: WHATSAPP_MESSAGE_COLOR,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        rowGroups(): readonly TitledRowGroup<string | null, ComputedCaseRow>[] {
            const computedRows = [this.rows]
                .map(splitWithAssignee)
                .map((rows) => rows.filter((r) => r.assigneeId))
                .map(withAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternally)
                .pop()!
                .filter(isWithAssigneeWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless)
                .filter(isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyErrorless)
                .filter((r) => r.waitingTimeUntilFirstReactionAsPerceivedExternally !== null)
                .map(withIsAssigneeFirstReactingAssignee);

            return groupRowsBy(computedRows, (r) => r.assigneeId).map((rowGroup) => {
                const user = rowGroup.key ? usersStore.getUserById(rowGroup.key) : null;

                return {
                    ...rowGroup,
                    title: getFullName(user) || (this.$t("Unbekannter Benutzer") as string),
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText: this.$t("Bearbeiter") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardBarTile,
    },
});
