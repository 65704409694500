
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import ReportingDashboardTile from "@/app/pages/reporting/dashboard/ReportingDashboardTile.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        rows: {
            type: Array as () => CaseRow[],
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        showBottomSheetOnClick(): (() => void) | undefined {
            return () => {
                this.bottomSheetCaseIds = this.rows.map((r) => r.id);
                this.bottomSheetVisible = true;
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },
    },

    components: {
        CasesBottomSheet,
        ReportingDashboardTile,
    },
});
