var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('opportunity-report-page',{attrs:{"no-data":!_vm.chart,"time-interval":_vm.ongoingTimeInterval,"time-intervals":[
        _vm.OngoingTimeInterval.THIRTY_MINUTES,
        _vm.OngoingTimeInterval.SIXTY_MINUTES,
        _vm.OngoingTimeInterval.DATE,
        _vm.OngoingTimeInterval.WEEK,
        _vm.OngoingTimeInterval.MONTH,
        _vm.OngoingTimeInterval.QUARTER,
        _vm.OngoingTimeInterval.YEAR,
    ],"time-range":_vm.timeRange,"time-zone":_vm.timeZone,"with-created-ongoing-time-slot":_vm.ongoingTimeInterval},on:{"update:timeInterval":function($event){_vm.ongoingTimeInterval=$event},"update:time-interval":function($event){_vm.ongoingTimeInterval=$event},"loaded":(v) => (_vm.result = v)},scopedSlots:_vm._u([{key:"settings",fn:function(){return [_c('v-card',{staticClass:"mb-2",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pb-0 pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"overline"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Verkaufschancen"))+" ")])])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","dense":"","hide-details":"auto","id":_vm.$id(),"items":_vm.opportunitiesToConsiderOptions,"outlined":""},model:{value:(_vm.opportunitiesToConsider),callback:function ($$v) {_vm.opportunitiesToConsider=$$v},expression:"opportunitiesToConsider"}})],1)],1)],1)],1)]},proxy:true}])},[(_vm.chart)?_c('reporting-time-series-chart-card',{attrs:{"chart":_vm.chart,"group-by-header-text":_vm.$t('Zeitraum')}}):_vm._e(),(_vm.bottomSheetVisible)?_c('opportunities-bottom-sheet',{attrs:{"opportunity-ids":_vm.bottomSheetOpportunityIds},on:{"close":_vm.hideBottomSheet}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }