
import { CaseReportPageFilter, CaseReportPageResult, ExtendedCaseRow } from "./caseReportPage";
import { EMPTY_CASE_ROW_SEARCH_REQUEST } from "./caseRowSearchUtils";
import {
    filterConsiderCaseInReports,
    withCaseStatus,
    withCaseTypeGroup,
    withSentimentType,
    withWaitingTimeUntilFirstReaction,
    withWaitingTimeUntilFirstReactionAsPerceivedExternally,
} from "./caseRowUtils";
import { CaseChannel } from "@/api/cases";
import { CaseRow, reportingApi } from "@/api/reporting";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import CaseTypePicker from "@/app/pages/CaseTypePicker.vue";
import DealerPicker from "@/app/pages/DealerPicker.vue";
import ReportPage from "@/app/pages/reporting/ReportPage.vue";
import {
    PotentiallyErroneous,
    withCloser,
    withCreatedOngoingTimeSlot,
    withCreatedRecurringTimeSlot,
    withCreatorActorType,
    withFirstAssignedTime,
    WithProcessingTime,
    withProcessingTime,
    withProcessingTimeIndividualDurationSlot,
    WithWaitingTimeUntilFirstReaction,
    WithWaitingTimeUntilFirstReactionAsPerceivedExternally,
    withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot,
    withWaitingTimeUntilFirstReactionIndividualDurationSlot,
} from "@/app/pages/reporting/issueRowUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { canReportAllDealers, getReportableDealerIds } from "@/app/pages/reporting/reportingPermissionUtils";
import {
    IndividualDurationInterval,
    OngoingTimeInterval,
    RecurringTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { caseTypesStore } from "@/store/caseTypes";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        evaluationType: {
            type: Number,
            required: false,
        },
        evaluationTypeOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        groupBy: {
            type: String,
            required: false,
        },
        groupByOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        loadAssigneeFirstAssigned: {
            type: Boolean,
            default: false,
        },
        loadAssigneeFirstOutgoingActivities: {
            type: Boolean,
            default: false,
        },
        loadAssigneeLastOutgoingActivities: {
            type: Boolean,
            default: false,
        },
        loadAssignees: {
            type: Boolean,
            default: false,
        },
        loadEscalationGroupReceivers: {
            type: Boolean,
            default: false,
        },
        loadFirstAssigned: {
            type: Boolean,
            default: false,
        },
        loadFirstIncomingActivities: {
            type: Boolean,
            default: false,
        },
        loadFirstOutgoingActivities: {
            type: Boolean,
            default: false,
        },
        loadLastIncomingActivities: {
            type: Boolean,
            default: false,
        },
        loadLastOutgoingActivities: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        noData: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: Object,
            required: false,
        },
        sortByOptions: {
            type: Array as () => SelectOptions,
            required: false,
            default: () => [],
        },
        timeInterval: {
            type: String as () => OngoingTimeInterval | RecurringTimeInterval,
            required: false,
        },
        timeIntervals: {
            type: Array as () => OngoingTimeInterval[] | RecurringTimeInterval[],
            required: false,
            default: () => [],
        },
        timeRange: {
            type: Object as () => TimeRange,
            required: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
        withCaseTypeGroup: {
            type: Boolean,
            default: false,
        },
        withCloser: {
            type: Boolean,
            default: false,
        },
        withCreatedOngoingTimeSlot: {
            type: String as () => OngoingTimeInterval | null,
            default: null,
        },
        withCreatedRecurringTimeSlot: {
            type: String as () => RecurringTimeInterval | null,
            default: null,
        },
        withCreatorActorType: {
            type: Boolean,
            default: false,
        },
        withFirstAssignedTime: {
            type: Boolean,
            default: false,
        },
        withProcessingTime: {
            type: Boolean,
            default: false,
        },
        withProcessingTimeIndividualDurationSlot: {
            type: Array as () => IndividualDurationInterval[],
            default: null,
        },
        withSentimentType: {
            type: Boolean,
            default: false,
        },
        withWaitingTimeUntilFirstReaction: {
            type: Boolean,
            default: false,
        },
        withWaitingTimeUntilFirstReactionAsPerceivedExternally: {
            type: Boolean,
            default: false,
        },
        withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot: {
            type: Array as () => IndividualDurationInterval[],
            default: null,
        },
        withWaitingTimeUntilFirstReactionIndividualDurationSlot: {
            type: Array as () => IndividualDurationInterval[],
            default: null,
        },
    },

    data() {
        const ts = now();

        return {
            CaseChannel,
            loadFilter: {
                dealerIds: [] as string[],
                createdRange: {
                    from: this.timeRange ? this.timeRange.from : getDate(ts, this.timeZone, -6),
                    to: this.timeRange ? this.timeRange.to : getDate(ts, this.timeZone),
                } as DateRange,
            },
            loadingRows: false,
            loadLimiter: new ActionLimiter(true),
            localFilter: {
                caseTypes: [] as string[],
                channels: [] as CaseChannel[],
            },
            nowAtLoadRows: null as Date | null,
            rows: [] as readonly CaseRow[],
            searchId: 0,
        };
    },

    computed: {
        canReportAllDealers(): boolean {
            return canReportAllDealers();
        },

        computedRows(): readonly ExtendedCaseRow[] {
            return [this.rows]
                .map((rows) =>
                    this.isWithWaitingTimeUntilFirstReaction ? withWaitingTimeUntilFirstReaction(rows) : rows
                )
                .map((rows) =>
                    this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternally
                        ? withWaitingTimeUntilFirstReactionAsPerceivedExternally(rows)
                        : rows
                )
                .pop()!
                .map(withCaseStatus)
                .map((r) => (this.withCaseTypeGroup ? withCaseTypeGroup(r) : r))
                .map((r) => (this.withCloser ? withCloser(r) : r))
                .map((r) => (this.withCreatorActorType ? withCreatorActorType(r) : r))
                .map((r) =>
                    this.withCreatedOngoingTimeSlot ? withCreatedOngoingTimeSlot(r, this.withCreatedOngoingTimeSlot) : r
                )
                .map((r) =>
                    this.withCreatedRecurringTimeSlot
                        ? withCreatedRecurringTimeSlot(r, this.withCreatedRecurringTimeSlot)
                        : r
                )
                .map((r) => (this.withFirstAssignedTime ? withFirstAssignedTime(r) : r))
                .map((r) => (this.isWithProcessingTime ? withProcessingTime(r, this.nowAtLoadRows!) : r))
                .map((r) =>
                    this.isWithProcessingTimeIndividualDurationSlot
                        ? withProcessingTimeIndividualDurationSlot(
                              r as ExtendedCaseRow & WithProcessingTime<PotentiallyErroneous>,
                              this.withProcessingTimeIndividualDurationSlot
                          )
                        : r
                )
                .map((r) => (this.withSentimentType ? withSentimentType(r) : r))
                .map((r) =>
                    this.isWithWaitingTimeUntilFirstReactionIndividualDurationSlot
                        ? withWaitingTimeUntilFirstReactionIndividualDurationSlot(
                              r as ExtendedCaseRow & WithWaitingTimeUntilFirstReaction<PotentiallyErroneous>,
                              this.withWaitingTimeUntilFirstReactionIndividualDurationSlot
                          )
                        : r
                )
                .map((r) =>
                    this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot
                        ? withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot(
                              r as ExtendedCaseRow &
                                  WithWaitingTimeUntilFirstReactionAsPerceivedExternally<PotentiallyErroneous>,
                              this.withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot
                          )
                        : r
                );
        },

        currentDate(): string | null {
            return this.nowAtLoadRows ? getDate(this.nowAtLoadRows, this.timeZone) : null;
        },

        filter(): CaseReportPageFilter {
            return {
                ...{ ...this.loadFilter, createdRange: undefined },
                createdFrom: this.loadFilter.createdRange.from,
                createdTo: this.loadFilter.createdRange.to,
                ...this.localFilter,
            };
        },

        filteredRows(): readonly ExtendedCaseRow[] {
            return this.computedRows
                .filter(filterConsiderCaseInReports)
                .filter((r) => !this.localFilter.caseTypes.length || this.localFilter.caseTypes.includes(r.caseType))
                .filter((r) => !this.localFilter.channels.length || this.localFilter.channels.includes(r.channel));
        },

        isLoadFirstAssigned(): boolean {
            return (
                this.loadFirstAssigned ||
                this.withFirstAssignedTime ||
                this.isWithWaitingTimeUntilFirstReaction ||
                this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternally
            );
        },

        isLoadFirstOutgoingActivities(): boolean {
            return (
                this.loadFirstOutgoingActivities ||
                this.isWithWaitingTimeUntilFirstReaction ||
                this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternally
            );
        },

        isWithProcessingTime(): boolean {
            return this.withProcessingTime || this.isWithProcessingTimeIndividualDurationSlot;
        },

        isWithProcessingTimeIndividualDurationSlot(): boolean {
            return (this.withProcessingTimeIndividualDurationSlot ?? null) !== null;
        },

        isWithWaitingTimeUntilFirstReaction(): boolean {
            return (
                this.withWaitingTimeUntilFirstReaction || this.isWithWaitingTimeUntilFirstReactionIndividualDurationSlot
            );
        },

        isWithWaitingTimeUntilFirstReactionAsPerceivedExternally(): boolean {
            return (
                this.withWaitingTimeUntilFirstReactionAsPerceivedExternally ||
                this.isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot
            );
        },

        isWithWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot(): boolean {
            return (this.withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot ?? null) !== null;
        },

        isWithWaitingTimeUntilFirstReactionIndividualDurationSlot(): boolean {
            return (this.withWaitingTimeUntilFirstReactionIndividualDurationSlot ?? null) !== null;
        },

        reportableDealerIds(): string[] {
            return getReportableDealerIds();
        },

        reportedCaseTypes(): string[] {
            return caseTypesStore.caseTypeGroups
                .map((caseTypeGroup) => caseTypeGroup.caseTypes)
                .reduce((prev, cur) => prev.concat(cur), [])
                .filter(
                    (caseType) => !this.localFilter.caseTypes.length || this.localFilter.caseTypes.includes(caseType)
                );
        },

        reportedChannels(): CaseChannel[] {
            return (Object.keys(CaseChannel) as CaseChannel[]).filter(
                (channel) => !this.localFilter.channels.length || this.localFilter.channels.includes(channel)
            );
        },

        reportedDealerIds(): string[] {
            return dealersStore.dealers
                .filter((d) => !this.loadFilter.dealerIds.length || this.loadFilter.dealerIds.includes(d.id))
                .sort((a, b) => a.name.localeCompare(b.name, userSession.locale))
                .map((d) => d.id);
        },
    },

    methods: {
        async loadRows() {
            this.nowAtLoadRows = now();
            this.rows = [];
            const searchId = ++this.searchId;
            this.loadingRows = true;

            await this.loadLimiter.execute(async () => {
                try {
                    const rows = await reportingApi.caseRows({
                        ...EMPTY_CASE_ROW_SEARCH_REQUEST,
                        dealerIds: this.loadFilter.dealerIds,
                        createdFrom: toDateObject(this.timeZone, this.loadFilter.createdRange.from),
                        createdTo: toDateObject(this.timeZone, this.loadFilter.createdRange.to, 1),
                        includeAssigneeFirstAssigned: this.loadAssigneeFirstAssigned,
                        includeAssigneeFirstOutgoingActivities: this.loadAssigneeFirstOutgoingActivities,
                        includeAssigneeLastOutgoingActivities: this.loadAssigneeLastOutgoingActivities,
                        includeAssignees: this.loadAssignees,
                        includeEscalationGroupReceivers: this.loadEscalationGroupReceivers,
                        includeFirstAssigned: this.isLoadFirstAssigned,
                        includeFirstIncomingActivities: this.loadFirstIncomingActivities,
                        includeFirstOutgoingActivities: this.isLoadFirstOutgoingActivities,
                        includeLastIncomingActivities: this.loadLastIncomingActivities,
                        includeLastOutgoingActivities: this.loadLastOutgoingActivities,
                    });

                    const sortedRows = rows.sort((a, b) => b.created.getTime() - a.created.getTime());

                    if (searchId === this.searchId) {
                        this.rows = Object.freeze(sortedRows);
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loadingRows = false;
                    }
                }
            });
        },

        updateTimeRange(timeRange: TimeRange): void {
            this.loadFilter.createdRange = { from: timeRange.from, to: timeRange.to };
        },
    },

    watch: {
        filteredRows() {
            this.$emit("loaded", {
                filter: { ...this.filter },
                rows: [...this.filteredRows],
                nowAtLoadRows: this.nowAtLoadRows,
                defaultKeysContext: {
                    processingTimeSlots: this.withProcessingTimeIndividualDurationSlot ?? [],
                    visibleCaseTypes: this.reportedCaseTypes,
                    visibleChannels: this.reportedChannels,
                    visibleDealerIds: this.reportedDealerIds,
                    waitingTimeUntilFirstReactionSlots:
                        this.withWaitingTimeUntilFirstReactionIndividualDurationSlot ??
                        this.withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot ??
                        [],
                },
                mapKeyToLabelContext: {
                    processingTimeSlots: this.withProcessingTimeIndividualDurationSlot ?? [],
                    waitingTimeUntilFirstReactionSlots:
                        this.withWaitingTimeUntilFirstReactionIndividualDurationSlot ??
                        this.withWaitingTimeUntilFirstReactionAsPerceivedExternallyIndividualDurationSlot ??
                        [],
                },
            } as CaseReportPageResult);
        },

        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadRows();
    },

    components: {
        CaseTypePicker,
        DateRangePicker,
        DealerPicker,
        EnumField,
        ReportPage,
    },
});
