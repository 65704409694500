
import { EChartsMouseEvent, formatAxisCategoryLabel } from "./eChartsUtils";
import { renderTooltip, ReportingChartTooltipCache } from "./reportingChartTooltip";
import {
    getSeriesMaxValue,
    ReportingPunchCardChartCategory,
    ReportingPunchCardChartHeatRange,
    ReportingPunchCardChartSeries,
    ReportingPunchCardChartSeriesData,
    ReportingPunchCardChartSeriesDataPoint,
    ReportingPunchCardChartSeriesValueFormatter,
} from "./reportingPunchCardChart";
import {
    DefaultLabelFormatterCallbackParams,
    EChartsOption,
    ScatterSeriesOption,
    XAXisComponentOption as XAXisOption,
    YAXisComponentOption as YAXisOption,
} from "echarts";
import { DataZoomComponentOption } from "echarts/types/dist/echarts";
import Vue from "vue";

interface ScatterSeriesOptionDataValue {
    readonly value: number | undefined;
    readonly isPercentage: boolean;
    readonly heat: number | undefined;
    readonly onClick?: () => Promise<void> | void;
    readonly formatter?: ReportingPunchCardChartSeriesValueFormatter;
}

export default Vue.extend({
    props: {
        categories: {
            type: Array as () => ReportingPunchCardChartCategory[],
            required: true,
        },
        categoryAxisLabelTrimLength: {
            type: Number,
            default: 0,
        },
        categoryAxisLabelWrap: {
            type: Boolean,
            default: true,
        },
        categoryAxisScroll: {
            type: Boolean,
            default: false,
        },
        heatRangePieces: {
            type: Array as () => ReportingPunchCardChartHeatRange[],
            default: () => [],
        },
        height: {
            type: Number,
            required: false,
        },
        hideHeatRange: {
            type: Boolean,
            default: false,
        },
        max: {
            type: Number,
            required: false,
        },
        series: {
            type: Array as () => ReportingPunchCardChartSeries[],
            required: true,
        },
        seriesAdditionalTooltipDataTooltipHeaders: {
            type: Array as () => string[],
            required: false,
        },
        seriesAxisLabelTrimLength: {
            type: Number,
            default: 0,
        },
        seriesAxisLabelWrap: {
            type: Boolean,
            default: true,
        },
        seriesAxisScroll: {
            type: Boolean,
            default: false,
        },
        seriesAxisScrollWindowSize: {
            type: Number,
            required: false,
        },
        seriesDataTooltipHeader: {
            type: String,
            required: false,
        },
        seriesColor: {
            type: String,
            default: "#546E7A",
        },
        symbolSize: {
            type: Number,
            default: 40,
        },
    },

    data() {
        return {
            tooltipCache: {} as ReportingChartTooltipCache,
            width: 0,
        };
    },

    computed: {
        categoryAxisLabelLinesMax(): number {
            const formatter = this.categoryAxisOption.axisLabel?.formatter as
                | ((category: string) => string | null)
                | undefined;

            return ((this.categoryAxisOption.data as string[]) ?? [])
                .map((category) => (formatter ? formatter(category) : null))
                .map((label) => label ?? "")
                .map((label) => label.split("\n").length)
                .reduce((max, occurrences) => Math.max(max, occurrences), 0);
        },

        categoryAxisOption(): Extract<XAXisOption, { type?: "category" }> {
            return {
                id: "category-axis",
                type: "category",
                data: this.categories.map((c) => c.name),
                position: "top",
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                    interval: () => true,
                },
                axisLabel: {
                    formatter: (value: string | number) =>
                        formatAxisCategoryLabel(
                            value as string,
                            this.categoryAxisLabelTrimLength,
                            this.categoryAxisLabelWrap
                        ),
                    interval: () => true,
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "11px",
                },
            };
        },

        categoryAxisScrollFullChartWidthFactor(): number {
            const dataZoom = (this.eChartsOption.dataZoom ?? []) as DataZoomComponentOption[];

            if (!this.showCategoryAxisSlider || !dataZoom.some((d) => d.xAxisId === this.categoryAxisOption.id)) {
                return 1;
            }

            return this.categories.length / this.categoryAxisScrollWindowSize;
        },

        categoryAxisScrollWindowSize(): number {
            const horizontalMargin = 100;
            const punchMargin = 40;
            const punchWidthAndMargin = punchMargin + this.symbolSize;

            const gridWidth = Math.max(0, this.width - horizontalMargin);

            return Math.max(2, Math.ceil(gridWidth / punchWidthAndMargin));
        },

        eChartHeight(): number {
            if (this.height) {
                return this.height;
            } else {
                const sliderAndRangeHeight = this.showCategoryAxisSlider
                    ? this.heatRangeVisible
                        ? 50
                        : 30
                    : this.heatRangeVisible
                    ? 30
                    : 0;

                const windowSize = this.showSeriesAxisSlider ? this.seriesAxisScrollWindowSize : this.series.length;

                const categoryHeaderMargin = 10;
                const categoryHeaderHeight = categoryHeaderMargin + 12 * this.categoryAxisLabelLinesMax;

                const seriesMargin = 1;

                return (
                    categoryHeaderHeight + sliderAndRangeHeight + windowSize * Math.ceil(this.symbolSize + seriesMargin)
                );
            }
        },

        eChartsOption(): EChartsOption {
            const options: EChartsOption = {
                animation: false,
                grid: {
                    top: "0px",
                    left: "0px",
                    right: "20px",
                    bottom: this.showCategoryAxisSlider
                        ? this.heatRangeVisible
                            ? "50px"
                            : "30px"
                        : this.heatRangeVisible
                        ? "30px"
                        : "0px",
                    containLabel: true,
                },
                xAxis: this.categoryAxisOption,
                yAxis: this.seriesAxisOption,
                tooltip: {
                    trigger: "axis",
                    formatter: (params: any) => {
                        return this.renderTooltip(params as DefaultLabelFormatterCallbackParams[]) ?? "";
                    },
                },
                axisPointer: {
                    type: "none",
                },
                legend: {
                    show: false,
                },
                series: this.eChartSeries,
            };

            if (this.heatRangePieces.length) {
                options.visualMap = {
                    type: "piecewise",
                    show: this.heatRangeVisible,
                    dimension: 3,
                    pieces: this.heatRangePieces.map((piece) => ({
                        label: piece.text,
                        min: piece.min,
                        max: piece.max,
                        gt: piece.greaterThan,
                        lt: piece.lessThan,
                    })),
                    orient: "horizontal",
                    left: "center",
                    bottom: "0px",
                    inRange: {
                        color: this.heatRangePieces.map((piece) => piece.color),
                    },
                };
            }

            if (this.showCategoryAxisSlider || this.showSeriesAxisSlider) {
                options.dataZoom = [];

                if (this.showCategoryAxisSlider) {
                    options.dataZoom.push({
                        type: "slider",
                        xAxisId: this.categoryAxisOption.id as string,
                        dataBackground: {
                            lineStyle: { opacity: 0, width: 0 },
                            areaStyle: { opacity: 0 },
                        },
                        selectedDataBackground: {
                            lineStyle: { opacity: 0, width: 0 },
                            areaStyle: { opacity: 0 },
                        },
                        handleStyle: { opacity: 0 },
                        labelFormatter: "",
                        filterMode: "none",
                        startValue: 0,
                        endValue: this.categoryAxisScrollWindowSize - 1,
                        zoomLock: true,
                        rangeMode: ["value", "value"],
                        brushSelect: false,
                        height: 15,
                        bottom: this.heatRangeVisible ? 30 : 5,
                    });
                }

                if (this.showSeriesAxisSlider) {
                    options.dataZoom.push({
                        type: "slider",
                        yAxisId: this.seriesAxisOption.id as string,
                        dataBackground: {
                            lineStyle: { opacity: 0, width: 0 },
                            areaStyle: { opacity: 0 },
                        },
                        selectedDataBackground: {
                            lineStyle: { opacity: 0, width: 0 },
                            areaStyle: { opacity: 0 },
                        },
                        handleStyle: { opacity: 0 },
                        labelFormatter: "",
                        filterMode: "none",
                        startValue: 0,
                        endValue: this.categoryAxisScrollWindowSize - 1,
                        zoomLock: true,
                        rangeMode: ["value", "value"],
                        brushSelect: false,
                        width: 15,
                        right: 5,
                    });
                }
            }

            return options;
        },

        eChartSeries(): ScatterSeriesOption[] {
            return this.series.map((series: ReportingPunchCardChartSeries, seriesIndex) => {
                return {
                    name: series.name,
                    type: "scatter",
                    cursor: series.data.values.some((v) => v.onClick) ? "pointer" : "default",
                    data: series.data.values.map((v, categoryIndex) => {
                        const value = this.getScatterSeriesOptionDataValue(v, series.data);

                        return [
                            categoryIndex,
                            seriesIndex,
                            {
                                ...value,
                                additionalTooltipValues: (series.additionalTooltipData ?? []).map((adtData) =>
                                    this.getScatterSeriesOptionDataValue(adtData.values[categoryIndex], adtData)
                                ),
                            } as any,
                            value.heat,
                        ];
                    }),
                    symbol: "square",
                    symbolSize: (dataPoint: any) => {
                        const value = dataPoint[2].value as number | null;
                        const maxValue = this.max ?? this.seriesMaxValue;

                        if (!value || !maxValue) {
                            return 0;
                        }

                        const sizeFactor = value / maxValue;

                        return Math.ceil(Math.sqrt(this.symbolSize * this.symbolSize * sizeFactor));
                    },
                    emphasis: {
                        scale: false,
                    },
                    color: this.seriesColor,
                };
            });
        },

        heatRangeVisible(): boolean {
            return !!this.heatRangePieces.length && !this.hideHeatRange;
        },

        seriesAxisOption(): YAXisOption {
            return {
                id: "series-axis",
                type: "category",
                data: this.series.map((s) => s.name),
                inverse: true,
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    formatter: (value: string | number) =>
                        formatAxisCategoryLabel(
                            value as string,
                            this.seriesAxisLabelTrimLength,
                            this.seriesAxisLabelWrap
                        ),
                    interval: () => true,
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "11px",
                },
            };
        },

        seriesAxisScrollFullChartHeightFactor(): number {
            const dataZoom = (this.eChartsOption.dataZoom ?? []) as DataZoomComponentOption[];

            if (!this.showCategoryAxisSlider || !dataZoom.some((d) => d.yAxisId === this.seriesAxisOption.id)) {
                return 1;
            }

            return this.series.length / this.seriesAxisScrollWindowSize;
        },

        seriesMaxValue(): number | undefined {
            return getSeriesMaxValue(this.series);
        },

        showCategoryAxisSlider(): boolean {
            return this.categoryAxisScroll && this.categoryAxisScrollWindowSize < this.categories.length;
        },

        showSeriesAxisSlider(): boolean {
            return this.seriesAxisScroll && this.seriesAxisScrollWindowSize < this.series.length;
        },
    },

    methods: {
        getChartAsPngDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsPngDataUrl();
        },

        getChartAsSvgDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsSvgDataUrl();
        },

        getScatterSeriesOptionDataValue(
            v: ReportingPunchCardChartSeriesDataPoint,
            data: ReportingPunchCardChartSeriesData
        ): ScatterSeriesOptionDataValue {
            const roundFactor = data.isPercentage ? 10000 : 100;

            return {
                value: v.value !== undefined ? Math.round(roundFactor * v.value) / roundFactor : undefined,
                isPercentage: !!data.isPercentage,
                heat: v.heat !== undefined ? v.heat : undefined,
                onClick: v.onClick,
                formatter: data.formatter,
            };
        },

        async onValueClick(e: EChartsMouseEvent<ScatterSeriesOptionDataValue[]>) {
            if (e.componentType !== "series" || e.seriesType !== "scatter") {
                return;
            }

            if (e.data.length < 3 || !e.data[2].onClick) {
                return;
            }

            await e.data[2].onClick();
        },

        renderTooltip(series: DefaultLabelFormatterCallbackParams[]): string | null {
            if (!series.length) {
                return null;
            }

            const tooltipSeries = series.map((s) => {
                const dataValue = (s.data as any)[2] as ScatterSeriesOptionDataValue;
                const additionalTooltipValues = (s.data as any)[2]
                    .additionalTooltipValues as ScatterSeriesOptionDataValue[];

                return {
                    name: s.seriesName!,
                    marker: s.marker! as string,
                    value: {
                        value: dataValue.value,
                        isPercentage: dataValue.isPercentage,
                        formatter: (value: number | undefined, isPercentage: boolean) =>
                            dataValue.formatter ? dataValue.formatter(value, isPercentage, "TOOLTIP") : null,
                    },
                    additionalTooltipValues: additionalTooltipValues.map((adtValue) => ({
                        value: adtValue.value,
                        isPercentage: dataValue.isPercentage,
                        formatter: (value: number | undefined, isPercentage: boolean) =>
                            adtValue.formatter ? adtValue.formatter(value, isPercentage, "TOOLTIP") : null,
                    })),
                    group: null,
                };
            });

            const category = this.categories[(series[0].data as any)[0]];

            return renderTooltip(
                {
                    additionalValueHeaders: this.seriesAdditionalTooltipDataTooltipHeaders,
                    series: tooltipSeries,
                    title: category.name,
                    subtitle: category.description,
                    valueHeader: this.seriesDataTooltipHeader,
                },
                this.tooltipCache,
                this.$i18n
            );
        },

        resize(width: number) {
            this.width = width;
        },
    },

    components: {
        LazyEChart: () => import("./LazyEChart.vue"),
    },
});
