import { Permission } from "@/api/userSession";
import { dealersStore } from "@/store/dealers";
import { userSession } from "@/store/userSession";

export function canReportAllDealers(isExternalBdcReport?: boolean): boolean {
    return (
        userSession.hasPermission(Permission.VIEW_ALL_REPORTS) ||
        (!!isExternalBdcReport && userSession.hasPermission(Permission.VIEW_ALL_EXTERNAL_BDC_REPORTS))
    );
}

export function getReportableDealerIds(isExternalBdcReport?: boolean): string[] {
    if (!canReportAllDealers(isExternalBdcReport)) {
        return userSession.profile?.dealerIds || [];
    }

    return dealersStore.dealers.map((d) => d.id);
}
