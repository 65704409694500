var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('opportunity-report-page',{attrs:{"no-data":!_vm.chart,"time-interval":_vm.ongoingTimeInterval,"time-intervals":[
        _vm.OngoingTimeInterval.THIRTY_MINUTES,
        _vm.OngoingTimeInterval.SIXTY_MINUTES,
        _vm.OngoingTimeInterval.DATE,
        _vm.OngoingTimeInterval.WEEK,
        _vm.OngoingTimeInterval.MONTH,
        _vm.OngoingTimeInterval.QUARTER,
        _vm.OngoingTimeInterval.YEAR,
    ],"time-range":_vm.timeRange,"time-zone":_vm.timeZone,"with-created-ongoing-time-slot":_vm.ongoingTimeInterval},on:{"update:timeInterval":function($event){_vm.ongoingTimeInterval=$event},"update:time-interval":function($event){_vm.ongoingTimeInterval=$event},"loaded":(v) => (_vm.result = v)}},[(_vm.chart)?_c('reporting-time-series-chart-card',{attrs:{"chart":_vm.chart,"chart-fill-area":"","chart-percentage-max":1,"group-by-header-text":_vm.$t('Zeitraum')}}):_vm._e(),(_vm.bottomSheetVisible)?_c('opportunities-bottom-sheet',{attrs:{"opportunity-ids":_vm.bottomSheetOpportunityIds},on:{"close":_vm.hideBottomSheet}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }