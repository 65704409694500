var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('case-report-page',{attrs:{"evaluation-type":_vm.evaluationType,"evaluation-type-options":_vm.evaluationTypeOptions,"group-by":_vm.groupBy,"group-by-options":_vm.groupByOptions,"load-assignees":"","load-escalation-group-receivers":"","no-data":!_vm.chart,"time-interval":_vm.recurringTimeInterval,"time-intervals":[
        _vm.RecurringTimeInterval.HOUR_OF_DAY,
        _vm.RecurringTimeInterval.DAY_OF_WEEK,
        _vm.RecurringTimeInterval.DAY_OF_MONTH,
        _vm.RecurringTimeInterval.MONTH_OF_YEAR,
        _vm.RecurringTimeInterval.QUARTER_OF_YEAR,
    ],"time-zone":_vm.timeZone,"with-case-type-group":"","with-closer":"","with-created-recurring-time-slot":_vm.recurringTimeInterval,"with-creator-actor-type":"","with-processing-time-individual-duration-slot":_vm.processingTimeSlots,"with-sentiment-type":""},on:{"update:evaluationType":function($event){_vm.evaluationType=$event},"update:evaluation-type":function($event){_vm.evaluationType=$event},"update:groupBy":function($event){_vm.groupBy=$event},"update:group-by":function($event){_vm.groupBy=$event},"update:timeInterval":function($event){_vm.recurringTimeInterval=$event},"update:time-interval":function($event){_vm.recurringTimeInterval=$event},"loaded":(v) => (_vm.result = v)}},[(_vm.chart)?_c('reporting-line-chart-card',{attrs:{"chart":_vm.chart,"group-by-header-text":_vm.groupByText,"table":_vm.table}}):_vm._e(),(_vm.bottomSheetVisible)?_c('cases-bottom-sheet',{attrs:{"case-ids":_vm.bottomSheetCaseIds},on:{"close":_vm.hideBottomSheet}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }