
import { withBeginOngoingTimeSlot } from "./externalBdcCdrRowUtils";
import {
    ExternalBdcReportPageFilter,
    ExternalBdcReportPageResult,
    ExternalBdcReportPageRow,
} from "./externalBdcReportPage";
import { ExternalBdcCdrRow, ExternalBdcCdrRowExtension, ExternalBdcRevenue, reportingApi } from "@/api/reporting";
import { Permission } from "@/api/userSession";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import DealerPicker from "@/app/pages/DealerPicker.vue";
import ReportPage from "@/app/pages/reporting/ReportPage.vue";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { canReportAllDealers, getReportableDealerIds } from "@/app/pages/reporting/reportingPermissionUtils";
import { OngoingTimeInterval, RecurringTimeInterval } from "@/app/pages/reporting/timeInterval";
import { dealersStore } from "@/store/dealers";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        evaluationType: {
            type: Number,
            required: false,
        },
        evaluationTypeOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadRevenues: {
            type: Boolean,
            default: false,
        },
        noData: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: Object,
            required: false,
        },
        sortByOptions: {
            type: Array as () => SelectOptions,
            required: false,
            default: () => [],
        },
        timeInterval: {
            type: String as () => OngoingTimeInterval | RecurringTimeInterval,
            required: false,
        },
        timeIntervals: {
            type: Array as () => OngoingTimeInterval[] | RecurringTimeInterval[],
            required: false,
            default: () => [],
        },
        timeRange: {
            type: Object as () => TimeRange,
            required: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
        withBeginOngoingTimeSlot: {
            type: String as () => OngoingTimeInterval | null,
            default: null,
        },
        withExtension: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        const ts = now();

        return {
            loadFilter: {
                dealerIds: [] as string[],
                createdRange: {
                    from: this.timeRange ? this.timeRange.from : getDate(ts, this.timeZone, -6),
                    to: this.timeRange ? this.timeRange.to : getDate(ts, this.timeZone),
                } as DateRange,
            },
            loadingRows: false,
            loadLimiter: new ActionLimiter(true),
            localFilter: {
                callees: [] as string[],
            },
            nowAtLoadRows: null as Date | null,
            revenues: [] as readonly ExternalBdcRevenue[],
            rows: [] as readonly ExternalBdcCdrRow[],
            searchId: 0,
        };
    },

    computed: {
        calleeOptions(): SelectOption[] {
            return this.rows
                .map((r) => r)
                .sort((a, b) => a.callee.localeCompare(b.callee, userSession.locale))
                .filter((row, index, array) => !index || array[index - 1].callee !== row.callee)
                .map((row) => {
                    const formattedCallee = parseAndFormatNumber(row.callee, "INTERNATIONAL") ?? row.callee;
                    const dealer = dealersStore.dealerById(row.dealerId);

                    return {
                        value: row.callee,
                        text:
                            !!formattedCallee && !!dealer
                                ? `${formattedCallee} (${dealer.name})`
                                : formattedCallee ?? dealer?.name ?? "",
                    };
                });
        },

        canReportAllDealers(): boolean {
            return canReportAllDealers(true);
        },

        canViewRevenue(): boolean {
            return !userSession.isCtUser() || userSession.hasPermission(Permission.MANAGE_BILLING);
        },

        computedRows(): readonly ExternalBdcReportPageRow[] {
            return this.rows.map((r) =>
                this.withBeginOngoingTimeSlot ? withBeginOngoingTimeSlot(r, this.withBeginOngoingTimeSlot) : r
            );
        },

        currentDate(): string | null {
            return this.nowAtLoadRows ? getDate(this.nowAtLoadRows, this.timeZone) : null;
        },

        filter(): ExternalBdcReportPageFilter {
            return {
                ...{ ...this.loadFilter, createdRange: undefined },
                createdFrom: this.loadFilter.createdRange.from,
                createdTo: this.loadFilter.createdRange.to,
                ...this.localFilter,
            };
        },

        filteredRows(): readonly ExternalBdcReportPageRow[] {
            return (this.computedRows as ExternalBdcReportPageRow<unknown, true>[])
                .filter((r) => this.withExtension || (r as ExternalBdcCdrRowExtension).billedMinutes !== null)
                .filter((r) => !this.localFilter.callees.length || this.localFilter.callees.includes(r.callee));
        },

        reportableDealerIds(): string[] {
            return getReportableDealerIds(true);
        },
    },

    methods: {
        async loadRows() {
            this.nowAtLoadRows = now();
            this.rows = [];
            const searchId = ++this.searchId;

            if (!(this.$refs.filterForm as any).validate()) {
                return;
            }

            this.loadingRows = true;

            await this.loadLimiter.execute(async () => {
                try {
                    const createdFrom = toDateObject(this.timeZone, this.loadFilter.createdRange.from);
                    const createdTo = toDateObject(this.timeZone, this.loadFilter.createdRange.to, 1);

                    const [revenues, rows] = await Promise.all([
                        this.canViewRevenue && this.loadRevenues
                            ? reportingApi.externalBdcRevenues(this.loadFilter.dealerIds, createdFrom, createdTo)
                            : Promise.resolve([]),
                        reportingApi.externalBdcCdrRows(
                            this.loadFilter.dealerIds,
                            createdFrom,
                            createdTo,
                            this.withExtension
                        ),
                    ]);

                    const sortedRows = rows.sort((a, b) => b.begin.getTime() - a.begin.getTime());

                    if (searchId === this.searchId) {
                        this.revenues = Object.freeze(revenues);
                        this.rows = Object.freeze(sortedRows);
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loadingRows = false;
                    }
                }
            });
        },

        updateTimeRange(timeRange: TimeRange): void {
            this.loadFilter.createdRange = { from: timeRange.from, to: timeRange.to };
        },
    },

    watch: {
        filteredRows() {
            this.$emit("loaded", {
                filter: { ...this.filter },
                canViewRevenue: this.canViewRevenue,
                revenues: Object.freeze(this.revenues),
                rows: Object.freeze(this.filteredRows),
                nowAtLoadRows: this.nowAtLoadRows,
                defaultKeysContext: {
                    visibleDealerIds: this.filter.dealerIds.length
                        ? this.filter.dealerIds
                        : dealersStore.dealers.map((d) => d.id),
                },
                mapKeyToLabelContext: {},
            } as ExternalBdcReportPageResult);
        },

        loadFilter: {
            deep: true,
            async handler() {
                try {
                    // wait for form validation
                    await this.$nextTick();

                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadRows();
    },

    components: {
        DateRangePicker,
        DealerPicker,
        ReportPage,
    },
});
