
import {
    ExtendedOutgoingCallRow,
    OutgoingCallReportPageFilter,
    OutgoingCallReportPageResult,
} from "./outgoingCallReportPage";
import { EMPTY_OUTGOING_CALL_ROW_SEARCH_REQUEST } from "./outgoingCallRowSearchUtils";
import { IssueType, OutgoingCallRow, reportingApi } from "@/api/reporting";
import DAutocomplete from "@/app/components/DAutocomplete.vue";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import DealerPicker from "@/app/pages/DealerPicker.vue";
import ReportPage from "@/app/pages/reporting/ReportPage.vue";
import { withIssueType } from "@/app/pages/reporting/activityRowUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { canReportAllDealers, getReportableDealerIds } from "@/app/pages/reporting/reportingPermissionUtils";
import { OngoingTimeInterval, RecurringTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getFullName } from "@/app/userUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { usersStore } from "@/store/users";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        noData: {
            type: Boolean,
            default: false,
        },
        timeInterval: {
            type: String as () => OngoingTimeInterval | RecurringTimeInterval,
            required: false,
        },
        timeIntervals: {
            type: Array as () => OngoingTimeInterval[] | RecurringTimeInterval[],
            required: false,
            default: () => [],
        },
        timeRange: {
            type: Object as () => TimeRange,
            required: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
    },

    data() {
        const ts = now();

        return {
            IssueType,
            loadFilter: {
                dealerIds: [] as string[],
                createdRange: {
                    from: this.timeRange ? this.timeRange.from : getDate(ts, this.timeZone, -6),
                    to: this.timeRange ? this.timeRange.to : getDate(ts, this.timeZone),
                } as DateRange,
            },
            loadingRows: false,
            loadLimiter: new ActionLimiter(true),
            localFilter: {
                issueTypes: [] as IssueType[],
                initiatorUserIds: [] as string[],
            },
            nowAtLoadRows: null as Date | null,
            rows: [] as readonly OutgoingCallRow[],
            searchId: 0,
        };
    },

    computed: {
        canReportAllDealers(): boolean {
            return canReportAllDealers();
        },

        currentDate(): string | null {
            return this.nowAtLoadRows ? getDate(this.nowAtLoadRows, this.timeZone) : null;
        },

        filter(): OutgoingCallReportPageFilter {
            return {
                ...{ ...this.loadFilter, createdRange: undefined },
                createdFrom: this.loadFilter.createdRange.from,
                createdTo: this.loadFilter.createdRange.to,
                ...this.localFilter,
            };
        },

        filteredRows(): readonly ExtendedOutgoingCallRow[] {
            return this.rows
                .map(withIssueType)
                .filter(
                    (row) => !this.localFilter.issueTypes.length || this.localFilter.issueTypes.includes(row.issueType)
                )
                .filter(
                    (row) =>
                        !this.localFilter.initiatorUserIds.length ||
                        this.localFilter.initiatorUserIds.includes(row.initiatorUserId)
                );
        },

        initiatorUserOptions(): SelectOption[] {
            return usersStore.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        reportableDealerIds(): string[] {
            return getReportableDealerIds();
        },
    },

    methods: {
        async loadRows() {
            this.nowAtLoadRows = now();
            this.rows = [];
            const searchId = ++this.searchId;
            this.loadingRows = true;

            await this.loadLimiter.execute(async () => {
                try {
                    const createdFrom = toDateObject(this.timeZone, this.loadFilter.createdRange.from);
                    const createdTo = toDateObject(this.timeZone, this.loadFilter.createdRange.to, 1);

                    const rows = await reportingApi.outgoingCallRows({
                        ...EMPTY_OUTGOING_CALL_ROW_SEARCH_REQUEST,
                        dealerIds: this.loadFilter.dealerIds,
                        createdFrom,
                        createdTo,
                    });

                    const sortedRows = rows.sort((a, b) => b.created.getTime() - a.created.getTime());

                    if (searchId === this.searchId) {
                        this.rows = Object.freeze(sortedRows);
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loadingRows = false;
                    }
                }
            });
        },

        updateTimeRange(timeRange: TimeRange): void {
            this.loadFilter.createdRange = { from: timeRange.from, to: timeRange.to };
        },
    },

    watch: {
        filteredRows() {
            this.$emit("loaded", {
                filter: { ...this.filter },
                rows: [...this.filteredRows],
                nowAtLoadRows: this.nowAtLoadRows,
            } as OutgoingCallReportPageResult);
        },

        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadRows();
    },

    components: {
        DAutocomplete,
        DateRangePicker,
        DealerPicker,
        EnumField,
        ReportPage,
    },
});
