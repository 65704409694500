
import ExternalBdcReportPage from "./ExternalBdcReportPage.vue";
import { WithBeginOngoingTimeSlot } from "./externalBdcCdrRowUtils";
import { ExternalBdcReportPageResult, ExternalBdcReportPageRow } from "./externalBdcReportPage";
import { renderDefaultCurrency } from "@/app/filters";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { avg, max, median, rate, renderDuration, sum } from "@/app/pages/reporting/reportingUtils";
import { RowKey } from "@/app/pages/reporting/rowUtils";
import {
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnEntries,
    ReportingTableItemColumnGroup,
} from "@/app/pages/reporting/table/reportingTable";
import { getOngoingTimeSlotLabel, OngoingTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { userSession } from "@/store/userSession";
import { endOf, formatLocalTime, getDate, startOf, toDateObject, UnitOfTime } from "@/util/dateTimeUtils";
import Vue from "vue";

type PrecomputedProperties = WithBeginOngoingTimeSlot;

type ComputedExternalBdcCdrRow = ExternalBdcReportPageRow<PrecomputedProperties, true> & {
    readonly isInServiceTime: boolean;
};

type ReportingTableHeaderGroupWithColumnGroupComputer = ReportingTableHeaderGroup & {
    readonly toColumnGroup: (
        rowGroupRows: readonly ComputedExternalBdcCdrRow[],
        billingMonth: string | null,
        isColumnTotal: boolean
    ) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        return {
            contactRateWaitTime: 5, // only consider calls that waited at least x seconds or were accepted
            expectedContactRate: 0.95, // ratio of accepted calls
            expectedServiceLevel: 0.8, // ratio of accepted calls
            ongoingTimeInterval: OngoingTimeInterval.DATE as OngoingTimeInterval,
            OngoingTimeInterval,
            result: null as ExternalBdcReportPageResult<PrecomputedProperties, true> | null,
            serviceLevelWaitTime: 20, // only consider calls that waited at least x seconds or were accepted
            serviceTimeBegin: "07:30",
            serviceTimeEnd: "18:00",
        };
    },

    computed: {
        computedRows(): readonly ComputedExternalBdcCdrRow[] {
            return (this.result?.rows ?? []).map((r) => {
                const beginDate = getDate(r.begin, this.timeZone);

                const serviceTimeBegin = toDateObject(this.timeZone, beginDate, 0, this.serviceTimeBegin);
                const serviceTimeEnd = toDateObject(this.timeZone, beginDate, 0, this.serviceTimeEnd);

                return {
                    ...r,
                    isInServiceTime:
                        serviceTimeBegin.getTime() <= r.begin.getTime() &&
                        r.begin.getTime() <= serviceTimeEnd.getTime(),
                };
            });
        },

        defaultKeys(): RowKey[] {
            return getTimeSeriesDefaultKeys(
                this.computedRows,
                (r: ComputedExternalBdcCdrRow) => r.begin,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null,
                this.timeZone
            );
        },

        filteredRows(): readonly ComputedExternalBdcCdrRow[] {
            return this.computedRows.filter((r) => r.waited >= this.contactRateWaitTime || r.duration !== null);
        },

        locale(): string {
            return userSession.locale;
        },

        rowGroups(): readonly RowGroup<RowKey, ComputedExternalBdcCdrRow>[] {
            const rowGroups = groupRowsBy(this.filteredRows, (r) => r.beginOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        table(): ReportingTableData | null {
            const allRows = this.filteredRows;

            if (!allRows.length) {
                return null;
            }

            const from = startOf(
                toDateObject(this.timeZone, this.result!.filter.createdFrom),
                this.timeZone,
                this.locale,
                UnitOfTime.DAY
            );

            const to = endOf(
                toDateObject(this.timeZone, this.result!.filter.createdTo),
                this.timeZone,
                this.locale,
                UnitOfTime.DAY
            );

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => ({
                    title: this.dateFormatter(new Date(rowGroup.key as string), false),
                    groups: this.tableHeaderGroups.map((headerGroup) => {
                        let billingMonth: string | null = null;

                        if (this.ongoingTimeInterval === OngoingTimeInterval.MONTH) {
                            const slotDate = new Date(rowGroup.key as string);

                            const startOfMonth = startOf(slotDate, this.timeZone, this.locale, UnitOfTime.MONTH);
                            const endOfMonth = endOf(slotDate, this.timeZone, this.locale, UnitOfTime.MONTH);

                            if (
                                from.getTime() <= startOfMonth.getTime() &&
                                endOfMonth.getTime() <= to.getTime() &&
                                endOfMonth.getTime() <= this.result!.nowAtLoadRows.getTime()
                            ) {
                                billingMonth = this.getBillingMonth(slotDate);
                            }
                        }

                        return headerGroup.toColumnGroup(rowGroup.rows, billingMonth, false);
                    }),
                })),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) => headerGroup.toColumnGroup(allRows, null, true)),
                },
                groupByHeaderText: this.$t("Zeitraum") as string,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer[] {
            const headerGroups: ReportingTableHeaderGroupWithColumnGroupComputer[] = [
                {
                    text: this.$t("Contact rate") as string,
                    headers: [
                        {
                            text: `${this.expectedContactRate * 100}%`,
                        },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: this.toAcceptanceRateAndDeltaEntries(
                                    rowGroupRows.filter((r) => r.duration !== null),
                                    rowGroupRows,
                                    this.expectedContactRate
                                ),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Service level") as string,
                    headers: [
                        {
                            text: this.$t("{0} innerhalb von {1} Sek.", [
                                `${this.expectedServiceLevel * 100}%`,
                                this.serviceLevelWaitTime,
                            ]) as string,
                        },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: this.toAcceptanceRateAndDeltaEntries(
                                    rowGroupRows.filter(
                                        (r) => r.waited <= this.serviceLevelWaitTime && r.duration !== null
                                    ),
                                    rowGroupRows,
                                    this.expectedServiceLevel
                                ),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Anrufe nach Servicezeit") as string,
                    headers: [
                        {
                            text: this.$t("{0} bis {1} Uhr", [
                                this.formatLocalTime(this.serviceTimeBegin),
                                this.formatLocalTime(this.serviceTimeEnd),
                            ]) as string,
                        },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: [
                                    { value: rowGroupRows.filter((r) => r.isInServiceTime).length },
                                    { value: rowGroupRows.filter((r) => !r.isInServiceTime).length },
                                ],
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Anrufe") as string,
                    headers: [
                        { text: this.$t("Angenommen") as string },
                        { text: this.$t("Nicht angenommen") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: [
                                    {
                                        value: rowGroupRows
                                            .filter((r) => r.isInServiceTime)
                                            .filter((r) => r.duration !== null).length,
                                    },
                                    {
                                        value: rowGroupRows
                                            .filter((r) => !r.isInServiceTime)
                                            .filter((r) => r.duration !== null).length,
                                    },
                                ],
                            },
                            {
                                entries: [
                                    {
                                        value: rowGroupRows
                                            .filter((r) => r.isInServiceTime)
                                            .filter((r) => r.duration === null).length,
                                    },
                                    {
                                        value: rowGroupRows
                                            .filter((r) => !r.isInServiceTime)
                                            .filter((r) => r.duration === null).length,
                                    },
                                ],
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Gesprächsdauer") as string,
                    headers: [{ text: this.$t("Median") as string }, { text: this.$t("Durchschnitt") as string }],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: [
                                    {
                                        value: median(
                                            rowGroupRows.filter((r) => r.isInServiceTime).map((r) => r.duration)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                    {
                                        value: median(
                                            rowGroupRows.filter((r) => !r.isInServiceTime).map((r) => r.duration)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                ],
                            },
                            {
                                entries: [
                                    {
                                        value: avg(
                                            rowGroupRows.filter((r) => r.isInServiceTime).map((r) => r.duration)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                    {
                                        value: avg(
                                            rowGroupRows.filter((r) => !r.isInServiceTime).map((r) => r.duration)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                ],
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Wartezeit angenommener Anrufe") as string,
                    headers: [
                        { text: this.$t("Median") as string },
                        { text: this.$t("Durchschnitt") as string },
                        { text: this.$t("Maximum") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: [
                                    {
                                        value: median(
                                            rowGroupRows
                                                .filter((r) => r.duration !== null)
                                                .filter((r) => r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                    {
                                        value: median(
                                            rowGroupRows
                                                .filter((r) => r.duration !== null)
                                                .filter((r) => !r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                ],
                            },
                            {
                                entries: [
                                    {
                                        value: avg(
                                            rowGroupRows
                                                .filter((r) => r.duration !== null)
                                                .filter((r) => r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                    {
                                        value: avg(
                                            rowGroupRows
                                                .filter((r) => r.duration !== null)
                                                .filter((r) => !r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                ],
                            },
                            {
                                entries: [
                                    {
                                        value: max(
                                            rowGroupRows
                                                .filter((r) => r.duration !== null)
                                                .filter((r) => r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                    {
                                        value: max(
                                            rowGroupRows
                                                .filter((r) => r.duration !== null)
                                                .filter((r) => !r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                ],
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Wartezeit nicht angenommener Anrufe") as string,
                    headers: [
                        { text: this.$t("Median") as string },
                        { text: this.$t("Durchschnitt") as string },
                        { text: this.$t("Maximum") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: [
                                    {
                                        value: median(
                                            rowGroupRows
                                                .filter((r) => r.duration === null)
                                                .filter((r) => r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                    {
                                        value: median(
                                            rowGroupRows
                                                .filter((r) => r.duration === null)
                                                .filter((r) => !r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                ],
                            },
                            {
                                entries: [
                                    {
                                        value: avg(
                                            rowGroupRows
                                                .filter((r) => r.duration === null)
                                                .filter((r) => r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                    {
                                        value: avg(
                                            rowGroupRows
                                                .filter((r) => r.duration === null)
                                                .filter((r) => !r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                ],
                            },
                            {
                                entries: [
                                    {
                                        value: max(
                                            rowGroupRows
                                                .filter((r) => r.duration === null)
                                                .filter((r) => r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                    {
                                        value: max(
                                            rowGroupRows
                                                .filter((r) => r.duration === null)
                                                .filter((r) => !r.isInServiceTime)
                                                .map((r) => r.waited)
                                        ),
                                        formatter: (value) => renderDuration(value, "S"),
                                    },
                                ],
                            },
                        ],
                    }),
                },
            ];

            if (this.ongoingTimeInterval === OngoingTimeInterval.MONTH && this.result!.canViewRevenue) {
                headerGroups.push({
                    text: null,
                    headers: [
                        { text: this.$t("Abgerechnete Minuten") as string },
                        { text: this.$t("Kosten") as string },
                    ],
                    toColumnGroup: (rowGroupRows, billingMonth, isColumnTotal) => {
                        const revenue = this.result!.revenues.filter(
                            (rev) => !isColumnTotal && rev.billingMonth === billingMonth
                        )
                            .map((r) => r.fixedBdcRevenue + r.variableBdcRevenue)
                            .reduce((sum, rev) => (!sum ? rev : sum + rev), undefined as number | undefined);

                        return {
                            columns: [
                                {
                                    entries: [
                                        {
                                            value:
                                                revenue !== undefined
                                                    ? sum(rowGroupRows.map((r) => r.billedMinutes))
                                                    : undefined,
                                        },
                                    ],
                                },
                                {
                                    entries: [
                                        {
                                            value: revenue,
                                            formatter: (value) =>
                                                value !== undefined ? renderDefaultCurrency(value) : null,
                                        },
                                    ],
                                },
                            ],
                        };
                    },
                });
            }

            return headerGroups;
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },

        formatLocalTime(localTime: string): string {
            return formatLocalTime(localTime, this.locale, "S");
        },

        getBillingMonth(date: Date): string {
            return getDate(date, this.timeZone).substr(0, 7);
        },

        toAcceptanceRateAndDeltaEntries(
            countRows: readonly ComputedExternalBdcCdrRow[],
            totalRows: readonly ComputedExternalBdcCdrRow[],
            expectedRate: number
        ): ReportingTableItemColumnEntries {
            const actual = rate(countRows.length, totalRows.length);
            const delta = actual !== undefined ? actual - expectedRate : undefined;

            return [
                {
                    value: actual,
                    isPercentage: true,
                },
                {
                    value: delta,
                    isPercentage: true,
                    class: (delta ?? 0) === 0 ? undefined : delta! < 0 ? "error--text" : "success--text",
                },
            ];
        },
    },

    components: {
        ExternalBdcReportPage,
        ReportTableCard,
    },
});
