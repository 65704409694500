import { IncomingCallRowSearchRequest } from "@/api/reporting";

export const EMPTY_INCOMING_CALL_ROW_SEARCH_REQUEST: IncomingCallRowSearchRequest = Object.freeze({
    issueTypes: [],
    dealerIds: [],
    acceptorUserIds: [],
    createdFrom: null,
    createdTo: null,
    includeForwarding: false,
});
