
import { OpportunityChannel } from "@/api/opportunities";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { getReportingTableByReportingTimeSeriesChart } from "@/app/pages/reporting/chartUtils";
import { getColoredChartSeries } from "@/app/pages/reporting/charts/reportingChart";
import {
    hasNonZeroSeries,
    isNonZeroSeries,
    ReportingTimeSeriesChartData,
    ReportingTimeSeriesChartSeries,
} from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { OTHER_COLOR } from "@/app/pages/reporting/colors";
import ReportingDashboardTimeSeriesTile from "@/app/pages/reporting/dashboard/ReportingDashboardTimeSeriesTile.vue";
import { WithCreatedOngoingTimeSlot, withCreatedOngoingTimeSlot } from "@/app/pages/reporting/issueRowUtils";
import { WithOpportunityStatus } from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import {
    getOngoingTimeSlotKeys,
    getOngoingTimeSlotLabel,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { dealersStore } from "@/store/dealers";
import { opportunitySourcesStore } from "@/store/opportunitySources";
import { userSession } from "@/store/userSession";
import Vue from "vue";

type ComputedOpportunityRow = OpportunityRow & WithOpportunityStatus & WithCreatedOngoingTimeSlot;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        chartTimeInterval: {
            type: String as () => OngoingTimeInterval,
            default: OngoingTimeInterval.WEEK,
        },
        groupBy: {
            type: String as () => "DEALER" | "CHANNEL" | "SOURCE",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        maxGroupsCount: {
            type: Number,
            required: true,
        },
        rows: {
            type: Array as () => ComputedOpportunityRow[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        timeSeriesFrom: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        timeSeriesTo: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        categoryIds(): string[] {
            if (this.groupBy === "DEALER") {
                return dealersStore.dealers.map((dealer) => dealer.id);
            } else if (this.groupBy === "CHANNEL") {
                return Object.keys(OpportunityChannel);
            } else if (this.groupBy === "SOURCE") {
                return opportunitySourcesStore.opportunitySources.map((source) => source.id);
            } else {
                return [];
            }
        },

        chart(): ReportingTimeSeriesChartData | undefined {
            const series: ReportingTimeSeriesChartSeries[] = this.topCategoryIds.map((categoryId) => {
                let name: string = this.$t("Unbekannt") as string;

                if (this.groupBy === "DEALER") {
                    name = dealersStore.dealerById(categoryId)?.name || (this.$t("Unbekannter Standort") as string);
                } else if (this.groupBy === "CHANNEL") {
                    name = this.$t(`enum.OpportunityChannel.${categoryId}`) as string;
                } else if (this.groupBy === "SOURCE") {
                    name =
                        opportunitySourcesStore.getOpportunitySourceById(categoryId)?.name ||
                        (this.$t("Unbekannte Quelle") as string);
                }

                return {
                    id: `category-${categoryId}`,
                    name,
                    data: {
                        values: this.rowGroups.map((rowGroup) => {
                            const rows = rowGroup.rows.filter((r) => this.groupByKeyMapper(r) === categoryId);

                            return {
                                date: new Date(rowGroup.key),
                                value: rows.length,
                                onClick: this.showBottomSheetOnClick(rows.map((r) => r.id)),
                            };
                        }),
                    },
                    color: this.seriesColors.find((c) => c.id === categoryId)?.color,
                };
            });

            const nonTopCategoryIds = this.categoryIds.filter(
                (categoryId) => !this.topCategoryIds.includes(categoryId)
            );

            if (nonTopCategoryIds.length) {
                const nonTopCategoriesSeries: ReportingTimeSeriesChartSeries = {
                    id: "non-top-categories",
                    name: this.$t("Sonstige") as string,
                    data: {
                        values: this.rowGroups.map((rowGroup) => {
                            const rows = rowGroup.rows.filter((r) =>
                                nonTopCategoryIds.includes(this.groupByKeyMapper(r))
                            );

                            return {
                                date: new Date(rowGroup.key),
                                value: rows.length,
                                onClick: this.showBottomSheetOnClick(rows.map((r) => r.id)),
                            };
                        }),
                    },
                    color: OTHER_COLOR,
                };

                if (isNonZeroSeries(nonTopCategoriesSeries)) {
                    series.push(nonTopCategoriesSeries);
                }
            }

            const chart: ReportingTimeSeriesChartData = {
                title: "",
                series,
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        defaultKeys(): string[] {
            return getOngoingTimeSlotKeys(
                this.timeSeriesFrom,
                this.timeSeriesTo,
                this.chartTimeInterval,
                userSession.timeZone,
                true
            );
        },

        rowGroups(): RowGroup<string, ComputedOpportunityRow>[] {
            const filteredRows = this.rows
                .filter(
                    (row) =>
                        this.timeSeriesFrom.getTime() <= row.created.getTime() &&
                        row.created.getTime() <= this.timeSeriesTo.getTime()
                )
                .map((row) => withCreatedOngoingTimeSlot(row, this.chartTimeInterval));

            const rowGroups = groupRowsBy(filteredRows, (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        seriesColors() {
            if (this.groupBy === "DEALER") {
                return getColoredChartSeries(
                    dealersStore.dealers.map((dealer) => ({
                        id: dealer.id,
                        name: dealer.name,
                        color: undefined,
                    }))
                );
            } else if (this.groupBy === "CHANNEL") {
                return getColoredChartSeries(
                    Object.keys(OpportunityChannel).map((channel) => ({
                        id: channel,
                        name: this.$t(`enum.OpportunityChannel.${channel}`) as string,
                        color: undefined,
                    }))
                );
            } else if (this.groupBy === "SOURCE") {
                return getColoredChartSeries(
                    opportunitySourcesStore.opportunitySources.map((source) => ({
                        id: source.id,
                        name: source.name,
                        color: undefined,
                    }))
                );
            } else {
                return [];
            }
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingTimeSeriesChart(this.chart),
                groupByHeaderText: this.$t("Zeitraum") as string,
            };
        },

        topCategoryIds(): string[] {
            const rows = this.rowGroups.reduce(
                (rows, rowGroup) => rows.concat(rowGroup.rows),
                [] as ComputedOpportunityRow[]
            );

            return groupRowsBy(rows, this.groupByKeyMapper)
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    sortValue: rowGroup.rows.length,
                }))
                .filter((c) => c.sortValue)
                .sort((a, b) => b.sortValue - a.sortValue)
                .map((b) => b.categoryId)
                .slice(0, this.maxGroupsCount);
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.chartTimeInterval, userSession.timeZone, short ? "S" : "L") || "";
        },

        groupByKeyMapper(row: ComputedOpportunityRow): string {
            if (this.groupBy === "DEALER") {
                return row.dealerId;
            } else if (this.groupBy === "CHANNEL") {
                return row.channel;
            } else if (this.groupBy === "SOURCE") {
                return row.sourceId;
            } else {
                return "";
            }
        },

        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardTimeSeriesTile,
    },
});
