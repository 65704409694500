
import OutgoingCallReportPage from "./OutgoingCallReportPage.vue";
import { OutgoingCallReportPageResult } from "./outgoingCallReportPage";
import { OutgoingCallRow } from "@/api/reporting";
import ReportingTimeSeriesChartCard from "@/app/pages/reporting/ReportingTimeSeriesChartCard.vue";
import {
    WithCreatedOngoingTimeSlot,
    withCreatedOngoingTimeSlot,
    WithIssueType,
} from "@/app/pages/reporting/activityRowUtils";
import { durationFormatter } from "@/app/pages/reporting/chartUtils";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { hasNonZeroSeries, ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { avg, median, sum } from "@/app/pages/reporting/reportingUtils";
import { getOngoingTimeSlotLabel, OngoingTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getWeek } from "@/util/dateTimeUtils";
import Vue from "vue";

type ComputedOutgoingCallRow = OutgoingCallRow & WithCreatedOngoingTimeSlot & WithIssueType;

export default Vue.extend({
    data() {
        const ts = now();

        return {
            ongoingTimeInterval: OngoingTimeInterval.WEEK,
            OngoingTimeInterval,
            result: null as OutgoingCallReportPageResult | null,
            timeRange: {
                from: getWeek(ts, -6, userSession.timeZone, userSession.locale).begin,
                to: getWeek(ts, 0, userSession.timeZone, userSession.locale).end,
            } as TimeRange,
        };
    },

    computed: {
        acceptanceTimeSeriesChart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const chart: ReportingTimeSeriesChartData = {
                title: this.$t("Anrufvolumen") as string,
                series: [
                    {
                        id: "not-accepted",
                        name: this.$t("Nicht angenommen") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: rowGroup.rows.filter((r) => r.duration === null).length,
                            })),
                        },
                        colorPalette: ReportingChartColorPalette.NEGATIVE,
                    },
                    {
                        id: "accepted",
                        name: this.$t("Angenommen") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: rowGroup.rows.filter((r) => r.duration !== null).length,
                            })),
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                ],
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        computedOutgoingCallRows(): readonly ComputedOutgoingCallRow[] {
            return (this.result?.rows ?? []).map((row) =>
                withCreatedOngoingTimeSlot(row, this.ongoingTimeInterval, userSession.timeZone)
            );
        },

        defaultKeys(): string[] {
            return getTimeSeriesDefaultKeys(
                this.computedOutgoingCallRows,
                (r: ComputedOutgoingCallRow) => r.created,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null
            );
        },

        durationTimeSeriesChart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            return {
                title: this.$t("Gesprächsdauer") as string,
                series: [
                    {
                        id: "total",
                        name: this.$t("Gesamtdauer") as string,
                        selected: false,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: sum(rowGroup.rows.map((r) => r.duration)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                    {
                        id: "duration-median",
                        name: this.$t("Median") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: median(rowGroup.rows.map((r) => r.duration)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                    {
                        id: "duration-average",
                        name: this.$t("Durchschnitt") as string,
                        selected: false,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: avg(rowGroup.rows.map((r) => r.duration)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                ],
                dateFormatter: this.dateFormatter,
            };
        },

        rowGroups(): RowGroup<string, ComputedOutgoingCallRow>[] {
            const rowGroups = groupRowsBy(this.computedOutgoingCallRows, (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        waitTimeTimeSeriesChart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            return {
                title: this.$t("Wartezeit") as string,
                subtitle: this.$t("für nicht angenommene Anrufe") as string,
                series: [
                    {
                        id: "wait-time-median",
                        name: this.$t("Median") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: median(rowGroup.rows.filter((r) => r.duration === null).map((r) => r.waited)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                    {
                        id: "wait-time-average",
                        name: this.$t("Durchschnitt") as string,
                        selected: false,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: avg(rowGroup.rows.filter((r) => r.duration === null).map((r) => r.waited)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                    },
                ],
                dateFormatter: this.dateFormatter,
            };
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },
    },

    components: {
        OutgoingCallReportPage,
        ReportingTimeSeriesChartCard,
    },
});
