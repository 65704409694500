import { $t } from "@/app/i18n";
import { mapActivityRowKeyToLabel, MapActivityRowKeyToLabelContext } from "@/app/pages/reporting/activityRowUtils";
import { RowKey, RowLabel } from "@/app/pages/reporting/rowUtils";
import { getFullName } from "@/app/userUtils";
import { usersStore } from "@/store/users";

export type MapOutgoingCallRowKeyToLabelContext = MapActivityRowKeyToLabelContext;

export function mapOutgoingCallRowKeyToRowLabel(
    key: RowKey,
    groupBy: string,
    context?: MapOutgoingCallRowKeyToLabelContext
): RowLabel {
    if (groupBy === "initiatorUserId") {
        if (key === null) {
            return { label: $t("Unbekannter Benutzer") as string };
        } else if (typeof key === "string") {
            const user = usersStore.getUserById(key);

            if (!user) {
                return { label: $t("Gelöschter Benutzer") as string };
            }

            return { label: getFullName(user) };
        }
    }

    return mapActivityRowKeyToLabel(key, groupBy, context);
}
