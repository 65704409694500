
import { IssueType, OutgoingCallRow } from "@/api/reporting";
import {
    WithCreatedOngoingTimeSlot,
    withCreatedOngoingTimeSlot,
    WithIssueType,
} from "@/app/pages/reporting/activityRowUtils";
import { getReportingTableByReportingTimeSeriesChart } from "@/app/pages/reporting/chartUtils";
import {
    hasNonZeroSeries,
    ReportingTimeSeriesChartData,
    ReportingTimeSeriesChartSeries,
} from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { CALL_COLOR } from "@/app/pages/reporting/colors";
import ReportingDashboardTimeSeriesTile from "@/app/pages/reporting/dashboard/ReportingDashboardTimeSeriesTile.vue";
import { addMissingRowGroups, applyKeySort, count, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import {
    getOngoingTimeSlotKeys,
    getOngoingTimeSlotLabel,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { userSession } from "@/store/userSession";
import Vue from "vue";

type ComputedOutgoingCallRow = OutgoingCallRow & WithCreatedOngoingTimeSlot & WithIssueType;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        chartTimeInterval: {
            type: String as () => OngoingTimeInterval,
            default: OngoingTimeInterval.WEEK,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Array as () => (OutgoingCallRow & WithIssueType)[],
            default: () => [],
        },
        showCaseCalls: {
            type: Boolean,
            default: false,
        },
        showOpportunityCalls: {
            type: Boolean,
            default: false,
        },
        showUnknownIssueCalls: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        timeSeriesFrom: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        timeSeriesTo: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },

    computed: {
        chart(): ReportingTimeSeriesChartData | undefined {
            const series: ReportingTimeSeriesChartSeries[] = [];

            if (this.showCaseCalls) {
                const cases = count(this.rowGroups, (r) => r.issueType === IssueType.CASE);

                series.push({
                    id: "cases",
                    name: this.$t("Fälle") as string,
                    data: {
                        values: this.rowGroups.map((group, index) => ({
                            date: new Date(group.key as string),
                            value: cases[index],
                        })),
                    },
                    color: CALL_COLOR,
                });
            }

            if (this.showOpportunityCalls) {
                const opportunities = count(this.rowGroups, (r) => r.issueType === IssueType.OPPORTUNITY);

                series.push({
                    id: "opportunities",
                    name: this.$t("Verkaufschancen") as string,
                    data: {
                        values: this.rowGroups.map((group, index) => ({
                            date: new Date(group.key as string),
                            value: opportunities[index],
                        })),
                    },
                    color: CALL_COLOR,
                });
            }

            if (this.showUnknownIssueCalls) {
                const unknown = count(this.rowGroups, (r) => r.issueType === IssueType.UNKNOWN);

                series.push({
                    id: "unknown",
                    name: this.$t("Unbekannte Vorgänge") as string,
                    data: {
                        values: this.rowGroups.map((group, index) => ({
                            date: new Date(group.key as string),
                            value: unknown[index],
                        })),
                    },
                    color: CALL_COLOR,
                });
            }

            const chart: ReportingTimeSeriesChartData = {
                title: "",
                series,
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        defaultKeys(): string[] {
            return getOngoingTimeSlotKeys(
                this.timeSeriesFrom,
                this.timeSeriesTo,
                this.chartTimeInterval,
                userSession.timeZone,
                true
            );
        },

        rowGroups(): RowGroup<string, ComputedOutgoingCallRow>[] {
            const filteredRows = this.rows
                .filter(
                    (row) =>
                        this.timeSeriesFrom.getTime() <= row.created.getTime() &&
                        row.created.getTime() <= this.timeSeriesTo.getTime()
                )
                .map((row) => withCreatedOngoingTimeSlot(row, this.chartTimeInterval, userSession.timeZone));

            const rowGroups = groupRowsBy(filteredRows, (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingTimeSeriesChart(this.chart),
                groupByHeaderText: this.$t("Zeitraum") as string,
            };
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.chartTimeInterval, userSession.timeZone, short ? "S" : "L") || "";
        },
    },

    components: {
        ReportingDashboardTimeSeriesTile,
    },
});
