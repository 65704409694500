import { getSeriesValuesMatrixMaxValue } from "./reportingChart";

export interface ReportingPunchCardChartCategory {
    readonly name: string;
    readonly description?: string;
}

export interface ReportingPunchCardChartData {
    readonly title: string;
    readonly subtitle?: string;
    readonly categories: ReportingPunchCardChartCategory[];
    readonly series: ReportingPunchCardChartSeries[];
    readonly seriesDataTooltipHeader?: string;
    readonly seriesAdditionalTooltipDataTooltipHeaders?: string[];
}

export interface ReportingPunchCardChartHeatRange {
    readonly text: string;
    readonly min?: number;
    readonly max?: number;
    readonly greaterThan?: number;
    readonly lessThan?: number;
    readonly color: string;
}

export interface ReportingPunchCardChartSeries {
    readonly id: string;
    readonly name: string;
    readonly tooltip?: string;
    readonly data: ReportingPunchCardChartSeriesData;
    readonly additionalTooltipData?: ReportingPunchCardChartSeriesData[];
}

export interface ReportingPunchCardChartSeriesData {
    readonly values: ReportingPunchCardChartSeriesDataPoint[];
    readonly isPercentage?: boolean;
    readonly formatter?: ReportingPunchCardChartSeriesValueFormatter;
}

export interface ReportingPunchCardChartSeriesDataPoint {
    readonly value: number | undefined;
    readonly heat?: number;
    readonly onClick?: () => Promise<void> | void;
}

export type ReportingPunchCardChartSeriesValueFormatter = (
    value: number | undefined,
    isPercentage: boolean,
    context: "LABEL" | "TOOLTIP"
) => string | null;

export function isNonZeroSeries(series: ReportingPunchCardChartSeries): boolean {
    return series.data.values.some((v) => (v.value ?? 0) !== 0);
}

export function hasNonZeroSeries(chart: ReportingPunchCardChartData): boolean {
    return chart.series.some(isNonZeroSeries);
}

export function getSeriesMaxValue(series: ReportingPunchCardChartSeries[]): number | undefined {
    if (!series.length) {
        return undefined;
    }

    return getSeriesValuesMatrixMaxValue(
        series.map((s) => s.data.values.map((sv) => sv.value)),
        false
    );
}
