
import { OpportunityStatus } from "@/api/opportunities";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import ReportingDashboardTile from "@/app/pages/reporting/dashboard/ReportingDashboardTile.vue";
import { WithOpportunityStatus } from "@/app/pages/reporting/opportunities/opportunityRowUtils";
import { rate, renderPercentage } from "@/app/pages/reporting/reportingUtils";
import Vue from "vue";

type ComputedOpportunityRow = OpportunityRow & WithOpportunityStatus;

export default Vue.extend({
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        rows: {
            type: Array as () => ComputedOpportunityRow[],
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
            renderPercentage,
        };
    },

    computed: {
        closedRows(): ComputedOpportunityRow[] {
            return this.rows.filter((r) => r.opportunityStatus === OpportunityStatus.CLOSED);
        },

        conversionRate(): number | undefined {
            return rate(this.wonRows.length, this.closedRows.length);
        },

        showBottomSheetOnClick(): (() => void) | undefined {
            return () => {
                this.bottomSheetOpportunityIds = this.wonRows.map((r) => r.id);
                this.bottomSheetVisible = true;
            };
        },

        wonRows(): ComputedOpportunityRow[] {
            return this.rows.filter((r) => r.opportunityStatus === OpportunityStatus.CLOSED && !!r.outcome);
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardTile,
    },
});
