
import CaseReportPage from "./CaseReportPage.vue";
import { CaseReportPageResult, ExtendedCaseRow } from "./caseReportPage";
import {
    getCaseRowDefaultKeys,
    mapCaseRowKeyToRowLabel,
    splitWithCaseEscalationGroupReceiverId,
    WithCaseEscalationGroupReceiverId,
    withCaseEscalationGroupReceiverIdSetToNull,
    WithCaseTypeGroup,
} from "./caseRowUtils";
import { SentimentType } from "@/api/caseSearch";
import { CaseStatus } from "@/api/cases";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import ReportingLineChartCard from "@/app/pages/reporting/ReportingLineChartCard.vue";
import { getReportingTableByReportingLineChart } from "@/app/pages/reporting/chartUtils";
import {
    hasNonZeroSeries,
    isNonZeroSeries,
    ReportingLineChartData,
} from "@/app/pages/reporting/charts/reportingLineChart";
import { getCaseSentimentTypeColor, getCaseStatusColor, TOTAL_COLOR } from "@/app/pages/reporting/colors";
import {
    isWithProcessingTimeIndividualDurationSlotErrorless,
    PotentiallyErroneous,
    PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS,
    splitWithAssignee,
    WithAssignee,
    withAssigneeSetToNull,
    WithCloser,
    WithCreatedRecurringTimeSlot,
    WithCreatorActorType,
    WithProcessingTimeIndividualDurationSlot,
    WithSentimentType,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData, transpose } from "@/app/pages/reporting/table/reportingTable";
import {
    getRecurringTimeSlotKeys,
    getRecurringTimeSlotLabel,
    IndividualDurationInterval,
    RecurringTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { getDaysOfWeek } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type PrecomputedProperties = WithCaseTypeGroup &
    WithCloser &
    WithCreatedRecurringTimeSlot &
    WithCreatorActorType &
    WithProcessingTimeIndividualDurationSlot<PotentiallyErroneous> &
    WithSentimentType;

type ComputedCaseRow = ExtendedCaseRow<PrecomputedProperties> & WithAssignee & WithCaseEscalationGroupReceiverId;

type ComputedCaseRowGroupBy = keyof Pick<
    ComputedCaseRow,
    | "assigneeId"
    | "caseStatus"
    | "caseType"
    | "caseTypeGroup"
    | "channel"
    | "closer"
    | "creatorActorType"
    | "escalationGroupReceiverId"
    | "dealerId"
    | "processingTimeIndividualDurationSlot"
    | "sentimentType"
>;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedCaseRowGroupBy;
}

export default Vue.extend({
    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
            evaluationType: EvaluationType.PERCENTAGE,
            groupBy: "channel" as ComputedCaseRowGroupBy,
            recurringTimeInterval: RecurringTimeInterval.HOUR_OF_DAY as RecurringTimeInterval,
            RecurringTimeInterval,
            result: null as CaseReportPageResult<PrecomputedProperties> | null,
        };
    },

    computed: {
        chart(): ReportingLineChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const slots = getRecurringTimeSlotKeys(this.recurringTimeInterval);
            const rowsBySlot = new Map<number, ComputedCaseRow[]>();

            for (const slot of slots) {
                rowsBySlot.set(
                    slot,
                    this.filteredComputedCaseRows.filter((r) => r.createdRecurringTimeSlot === slot)
                );
            }

            const chart: ReportingLineChartData = {
                title: this.$t("Stoßzeiten") as string,
                categories: slots.map(
                    (slot) =>
                        getRecurringTimeSlotLabel(slot, this.recurringTimeInterval) || (this.$t("Unbekannt") as string)
                ),
                series: [
                    {
                        id: "total",
                        name: this.$t("Gesamt") as string,
                        selected: false,
                        data: {
                            values: slots.map((slot) => {
                                const slotRows = rowsBySlot.get(slot)!;

                                return {
                                    value: slotRows.length,
                                    onClick: this.showBottomSheetOnClick(slotRows.map((r) => r.id)),
                                };
                            }),
                        },
                        color: TOTAL_COLOR,
                    },
                    ...[...this.rowGroups]
                        .sort((a, b) => b.rows.length - a.rows.length)
                        .map((rowGroup, index) => ({
                            id: `group-${index}`,
                            name: rowGroup.title,
                            data: {
                                values: slots.map((slot) => {
                                    const slotRows = rowGroup.rows.filter((r) => r.createdRecurringTimeSlot === slot);

                                    return {
                                        value:
                                            this.evaluationType === EvaluationType.PERCENTAGE
                                                ? rate(slotRows.length, rowsBySlot.get(slot)!.length)
                                                : slotRows.length,
                                        onClick: this.showBottomSheetOnClick(slotRows.map((r) => r.id)),
                                    };
                                }),
                                isPercentage: this.evaluationType === EvaluationType.PERCENTAGE,
                            },
                            additionalTooltipData: [
                                {
                                    values: slots.map((slot) => {
                                        const slotRows = rowGroup.rows.filter(
                                            (r) => r.createdRecurringTimeSlot === slot
                                        );

                                        return {
                                            value:
                                                this.evaluationType === EvaluationType.PERCENTAGE
                                                    ? slotRows.length
                                                    : rate(slotRows.length, rowsBySlot.get(slot)!.length),
                                        };
                                    }),
                                    isPercentage: this.evaluationType !== EvaluationType.PERCENTAGE,
                                },
                            ],
                            color:
                                this.groupBy === "caseStatus"
                                    ? getCaseStatusColor(rowGroup.key as CaseStatus)
                                    : this.groupBy === "sentimentType"
                                    ? getCaseSentimentTypeColor(rowGroup.key as SentimentType)
                                    : undefined,
                        })),
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return {
                ...chart,
                series: chart.series.map((s) => ({
                    ...s,
                    selected: s.selected ?? isNonZeroSeries(s),
                })),
            };
        },

        daysOfWeek(): SelectOption[] {
            return getDaysOfWeek(configStore.configuration.defaultLocale, userSession.locale);
        },

        defaultKeys(): RowKey[] {
            return getCaseRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        evaluationTypeOptions(): SelectOption[] {
            return [
                {
                    text: this.$t("Absolut"),
                    value: EvaluationType.ABSOLUTE,
                },
                {
                    text: this.$t("Prozentual"),
                    value: EvaluationType.PERCENTAGE,
                },
            ];
        },

        filteredComputedCaseRows(): readonly ComputedCaseRow[] {
            let filteredRows: readonly ComputedCaseRow[] = (this.result?.rows ?? [])
                .filter((row) => this.groupBy !== "closer" || row.caseStatus === CaseStatus.CLOSED)
                .map(withAssigneeSetToNull)
                .map(withCaseEscalationGroupReceiverIdSetToNull);

            if (this.groupBy === "assigneeId") {
                filteredRows = splitWithAssignee(filteredRows);
            } else if (this.groupBy === "escalationGroupReceiverId") {
                filteredRows = splitWithCaseEscalationGroupReceiverId(filteredRows);
            }

            return filteredRows;
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "creatorActorType",
                    text: this.$t("Art des Erstellers"),
                },
                {
                    value: "assigneeId",
                    text: this.$t("Bearbeiter"),
                },
                {
                    value: "processingTimeIndividualDurationSlot",
                    text: this.$t("Bearbeitungsdauer"),
                },
                {
                    value: "escalationGroupReceiverId",
                    text: this.$t("Eskalationsgruppe"),
                },
                {
                    value: "caseTypeGroup",
                    text: this.$t("Fall-Kategorie"),
                },
                {
                    value: "caseType",
                    text: this.$t("Fall-Typ"),
                },
                {
                    value: "channel",
                    text: this.$t("Kanal"),
                },
                {
                    value: "closer",
                    text: this.$t("Schließer"),
                },
                {
                    value: "caseStatus",
                    text: this.$t("Status"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
                {
                    value: "sentimentType",
                    text: this.$t("Stimmung"),
                },
            ];
        },

        groupByText(): string | null {
            return (this.groupByOptions.find((o) => o.value === this.groupBy)?.text ?? null) as string | null;
        },

        processingTimeSlots(): IndividualDurationInterval[] {
            return [...PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS];
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedCaseRow>[] {
            const filteredRows =
                this.groupBy === "processingTimeIndividualDurationSlot"
                    ? this.filteredComputedCaseRows.filter(isWithProcessingTimeIndividualDurationSlotErrorless)
                    : this.filteredComputedCaseRows;

            const rowGroups = groupRowsBy(filteredRows, (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapCaseRowKeyToRowLabel(rowGroup.key, this.groupBy, {
                    ...(this.result?.mapKeyToLabelContext ?? {}),
                    recurringTimeInterval: this.recurringTimeInterval,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            const table = transpose(getReportingTableByReportingLineChart(this.chart));

            return {
                headerGroups: table.headerGroups,
                items: table.items.slice(1),
                totals: table.items[0],
                groupByHeaderText: this.groupByText ?? undefined,
            };
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CaseReportPage,
        CasesBottomSheet,
        ReportingLineChartCard,
    },
});
