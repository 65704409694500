
import { SentimentType } from "@/api/opportunitySearch";
import { OpportunityRow } from "@/api/reporting";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import { SENTIMENT_NEGATIVE, SENTIMENT_NEUTRAL, SENTIMENT_POSITIVE } from "@/app/pages/reporting/colors";
import ReportingDashboardTile from "@/app/pages/reporting/dashboard/ReportingDashboardTile.vue";
import { WithSentimentType } from "@/app/pages/reporting/issueRowUtils";
import { rate, renderPercentage } from "@/app/pages/reporting/reportingUtils";
import Vue from "vue";

interface Item {
    readonly type: SentimentType;
    readonly icon: string;
    readonly iconColor: string;
    readonly absolute: number;
    readonly percentage: number | undefined;
    readonly showBottomSheetOnClick: (() => void) | undefined;
}

export default Vue.extend({
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        rows: {
            type: Array as () => (OpportunityRow & WithSentimentType)[],
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
            renderPercentage,
        };
    },

    computed: {
        items(): Item[] {
            const total = this.rows.length;

            return [
                {
                    type: SentimentType.POSITIVE,
                    icon: "mdi-emoticon-excited-outline",
                    iconColor: SENTIMENT_POSITIVE,
                    rows: this.rows.filter((row) => row.sentimentType === SentimentType.POSITIVE),
                },
                {
                    type: SentimentType.NEUTRAL,
                    icon: "mdi-emoticon-happy-outline",
                    iconColor: SENTIMENT_NEUTRAL,
                    rows: this.rows.filter((row) => row.sentimentType === SentimentType.NEUTRAL),
                },
                {
                    type: SentimentType.NEGATIVE,
                    icon: "mdi-emoticon-frown-outline",
                    iconColor: SENTIMENT_NEGATIVE,
                    rows: this.rows.filter((row) => row.sentimentType === SentimentType.NEGATIVE),
                },
            ].map((item) => {
                const absolute = item.rows.length;

                return {
                    ...item,
                    absolute,
                    percentage: rate(absolute, total),
                    showBottomSheetOnClick: this.showBottomSheetOnClick(item.rows.map((r) => r.id)),
                };
            });
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        OpportunitiesBottomSheet,
        ReportingDashboardTile,
    },
});
