
import CaseReportPage from "./CaseReportPage.vue";
import { CaseReportPageResult, ExtendedCaseRow } from "./caseReportPage";
import { CaseStatus } from "@/api/cases";
import { CaseRow } from "@/api/reporting";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import ReportingTimeSeriesChartCard from "@/app/pages/reporting/ReportingTimeSeriesChartCard.vue";
import { durationFormatter } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { ISSUE_STATUS_CLOSED_COLOR } from "@/app/pages/reporting/colors";
import {
    Errorless,
    isWithProcessingTimeErrorless,
    isWithProcessingTimeIndividualDurationSlotErrorless,
    PotentiallyErroneous,
    PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS,
    WithCreatedOngoingTimeSlot,
    WithProcessingTime,
    WithProcessingTimeIndividualDurationSlot,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { median } from "@/app/pages/reporting/reportingUtils";
import { RowKey } from "@/app/pages/reporting/rowUtils";
import {
    getIndividualDurationIntervalLabelByKey,
    getOngoingTimeSlotLabel,
    IndividualDurationInterval,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getWeek } from "@/util/dateTimeUtils";
import Vue from "vue";

type PrecomputedProperties = WithCreatedOngoingTimeSlot &
    WithProcessingTime<PotentiallyErroneous> &
    WithProcessingTimeIndividualDurationSlot<PotentiallyErroneous>;

type ComputedCaseRow = ExtendedCaseRow<PrecomputedProperties> &
    WithProcessingTime<Errorless> &
    WithProcessingTimeIndividualDurationSlot<Errorless>;

export default Vue.extend({
    data() {
        const ts = now();

        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
            ongoingTimeInterval: OngoingTimeInterval.WEEK as OngoingTimeInterval,
            OngoingTimeInterval,
            result: null as CaseReportPageResult<PrecomputedProperties> | null,
            timeRange: {
                from: getWeek(ts, -6, userSession.timeZone, userSession.locale).begin,
                to: getWeek(ts, 0, userSession.timeZone, userSession.locale).end,
            } as TimeRange,
        };
    },

    computed: {
        defaultKeys(): RowKey[] {
            return getTimeSeriesDefaultKeys(
                this.result?.rows ?? [],
                (r: CaseRow) => r.created,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null
            );
        },

        processingTimeIndividualDurationSlotChart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const chart: ReportingTimeSeriesChartData = {
                title: this.$t("Bearbeitungsdauer geschlossener Fälle") as string,
                series: this.processingTimeSlots.map((s, index) => ({
                    id: `processing-time-slot-${index}`,
                    name: getIndividualDurationIntervalLabelByKey(s.key, this.processingTimeSlots)!,
                    selected: index >= 6,
                    data: {
                        values: this.rowGroups.map((rowGroup) => {
                            const slotRows = rowGroup.rows.filter(
                                (r) => r.processingTimeIndividualDurationSlot === s.key
                            );

                            return {
                                date: new Date(rowGroup.key as string),
                                value: slotRows.length,
                                onClick: this.showBottomSheetOnClick(slotRows.map((r) => r.id)),
                            };
                        }),
                    },
                })),
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        processingTimeMedianChart(): ReportingTimeSeriesChartData | null {
            if (!this.rowGroups.length) {
                return null;
            }

            const chart: ReportingTimeSeriesChartData = {
                title: this.$t("Bearbeitungsdauer (Median)") as string,
                series: [
                    {
                        id: "closed",
                        name: this.$t("Geschlossene Fälle") as string,
                        data: {
                            values: this.rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key as string),
                                value: median(rowGroup.rows.map((r) => r.processingTime)),
                                onClick: this.showBottomSheetOnClick(rowGroup.rows.map((r) => r.id)),
                            })),
                            formatter: (value, __, context) => durationFormatter(value, context),
                        },
                        color: ISSUE_STATUS_CLOSED_COLOR,
                    },
                ],
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        processingTimeSlots(): IndividualDurationInterval[] {
            return [...PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS];
        },

        rowGroups(): readonly RowGroup<RowKey, ComputedCaseRow>[] {
            const filteredRows: readonly ComputedCaseRow[] = (this.result?.rows ?? [])
                .filter(isWithProcessingTimeErrorless)
                .filter((row) => row.processingTime !== null)
                .filter(isWithProcessingTimeIndividualDurationSlotErrorless)
                .filter((row) => row.caseStatus === CaseStatus.CLOSED);

            const rowGroups = groupRowsBy(filteredRows, (r) => r.createdOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },

        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        CaseReportPage,
        CasesBottomSheet,
        ReportingTimeSeriesChartCard,
    },
});
