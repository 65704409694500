
import CaseReportPage from "./CaseReportPage.vue";
import { CaseReportPageResult, ExtendedCaseRow } from "./caseReportPage";
import {
    getCaseRowDefaultKeys,
    mapCaseRowKeyToRowLabel,
    splitWithCaseEscalationGroupReceiverId,
    WithCaseEscalationGroupReceiverId,
    withCaseEscalationGroupReceiverIdSetToNull,
    WithCaseTypeGroup,
} from "./caseRowUtils";
import { CaseStatus } from "@/api/cases";
import CasesBottomSheet from "@/app/pages/cases/CasesBottomSheet.vue";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import ReportingBarChartCard from "@/app/pages/reporting/ReportingBarChartCard.vue";
import ReportingPunchCardChartCard from "@/app/pages/reporting/ReportingPunchCardChartCard.vue";
import {
    BarChartSeriesOptions,
    durationFormatter,
    getPunchCardChartForHeaderGroup,
    getReportingBarChartForHeaderGroup,
    PunchCardChartSeriesOptions,
} from "@/app/pages/reporting/chartUtils";
import { ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { ReportingPunchCardChartData } from "@/app/pages/reporting/charts/reportingPunchCardChart";
import { getCaseStatusColor, ISSUE_STATUS_TOTAL_COLOR } from "@/app/pages/reporting/colors";
import {
    Errorless,
    isWithProcessingTimeErrorless,
    isWithProcessingTimeIndividualDurationSlotErrorless,
    PotentiallyErroneous,
    PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS,
    splitWithAssignee,
    WithAssignee,
    withAssigneeSetToNull,
    WithCloser,
    WithCreatorActorType,
    WithProcessingTime,
    WithProcessingTimeIndividualDurationSlot,
    WithSentimentType,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { median, rate, renderDuration } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import {
    getSortByOptions,
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnEntries,
    ReportingTableItemColumnGroup,
    ReportingTableSortByOptions,
    ReportingTableSortByOptionValue,
    sort,
} from "@/app/pages/reporting/table/reportingTable";
import { getIndividualDurationIntervalLabel, IndividualDurationInterval } from "@/app/pages/reporting/timeInterval";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type PrecomputedProperties = WithCaseTypeGroup &
    WithCloser &
    WithCreatorActorType &
    WithProcessingTime<PotentiallyErroneous> &
    WithProcessingTimeIndividualDurationSlot<PotentiallyErroneous> &
    WithSentimentType;

type ComputedCaseRow = ExtendedCaseRow<PrecomputedProperties> &
    WithAssignee &
    WithCaseEscalationGroupReceiverId &
    WithProcessingTime<Errorless> &
    WithProcessingTimeIndividualDurationSlot<Errorless>;

type ComputedCaseRowGroupBy = keyof Pick<
    ComputedCaseRow,
    | "assigneeId"
    | "caseStatus"
    | "caseType"
    | "caseTypeGroup"
    | "channel"
    | "closer"
    | "creatorActorType"
    | "escalationGroupReceiverId"
    | "dealerId"
    | "sentimentType"
>;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedCaseRowGroupBy;
}

type ReportingTableHeaderGroupWithColumnGroupComputer<Options> = ReportingTableHeaderGroup<Options> & {
    readonly toColumnGroup: (
        rowGroupRows: readonly ComputedCaseRow[],
        totalRows: readonly ComputedCaseRow[]
    ) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        return {
            bottomSheetCaseIds: [] as string[],
            bottomSheetVisible: false,
            evaluationType: EvaluationType.ABSOLUTE as EvaluationType,
            EvaluationType,
            groupBy: "channel" as ComputedCaseRowGroupBy,
            result: null as CaseReportPageResult<PrecomputedProperties> | null,
            sortBy: {
                groupIndex: 0,
                columnIndex: 0,
                direction: "DESC",
            } as ReportingTableSortByOptionValue,
        };
    },

    computed: {
        defaultKeys(): RowKey[] {
            return getCaseRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        filteredComputedCaseRows(): readonly ComputedCaseRow[] {
            let filteredRows: readonly ComputedCaseRow[] = (this.result?.rows ?? [])
                .filter((row) => this.groupBy !== "closer" || row.caseStatus === CaseStatus.CLOSED)
                .filter(isWithProcessingTimeErrorless)
                .filter(isWithProcessingTimeIndividualDurationSlotErrorless)
                .map(withAssigneeSetToNull)
                .map(withCaseEscalationGroupReceiverIdSetToNull);

            if (this.groupBy === "assigneeId") {
                filteredRows = splitWithAssignee(filteredRows);
            } else if (this.groupBy === "escalationGroupReceiverId") {
                filteredRows = splitWithCaseEscalationGroupReceiverId(filteredRows);
            }

            return filteredRows;
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "creatorActorType",
                    text: this.$t("Art des Erstellers"),
                },
                {
                    value: "assigneeId",
                    text: this.$t("Bearbeiter"),
                },
                {
                    value: "escalationGroupReceiverId",
                    text: this.$t("Eskalationsgruppe"),
                },
                {
                    value: "caseTypeGroup",
                    text: this.$t("Fall-Kategorie"),
                },
                {
                    value: "caseType",
                    text: this.$t("Fall-Typ"),
                },
                {
                    value: "channel",
                    text: this.$t("Kanal"),
                },
                {
                    value: "closer",
                    text: this.$t("Schließer"),
                },
                {
                    value: "caseStatus",
                    text: this.$t("Status"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
                {
                    value: "sentimentType",
                    text: this.$t("Stimmung"),
                },
            ];
        },

        groupByText(): string | null {
            return (this.groupByOptions.find((o) => o.value === this.groupBy)?.text ?? null) as string | null;
        },

        processingTimeChart(): ReportingPunchCardChartData | null {
            return getPunchCardChartForHeaderGroup(this.table, 3);
        },

        processingTimeMedianChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 2);
        },

        processingTimeSlots(): IndividualDurationInterval[] {
            return [...PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS];
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedCaseRow>[] {
            const rowGroups = groupRowsBy(this.filteredComputedCaseRows, (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapCaseRowKeyToRowLabel(rowGroup.key, this.groupBy, {
                    ...(this.result?.mapKeyToLabelContext ?? {}),
                    processingTimeSlots: this.processingTimeSlots,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        sortByOptions(): ReportingTableSortByOptions {
            return getSortByOptions(this.tableHeaderGroups);
        },

        table(): ReportingTableData<BarChartSeriesOptions | PunchCardChartSeriesOptions> | null {
            if (!this.tableData) {
                return null;
            }

            return {
                ...this.tableData,
                items: sort(this.tableData.items, this.sortBy),
            };
        },

        tableData(): ReportingTableData<BarChartSeriesOptions | PunchCardChartSeriesOptions> | null {
            const allRows = this.filteredComputedCaseRows;

            if (!allRows.length) {
                return null;
            }

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => ({
                    title: rowGroup.title,
                    subtitle: rowGroup.subtitle,
                    groups: this.tableHeaderGroups.map((headerGroup) =>
                        headerGroup.toColumnGroup(rowGroup.rows, allRows)
                    ),
                })),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) => headerGroup.toColumnGroup(allRows, allRows)),
                },
                groupByHeaderText: this.groupByText ?? undefined,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer<BarChartSeriesOptions>[] {
            const caseStatuses = Object.keys(CaseStatus) as CaseStatus[];

            return [
                {
                    text: null,
                    headers: [{ text: this.$t("Gesamt") as string }],
                    toColumnGroup: (rowGroupRows, allRows) => ({
                        columns: [{ entries: this.toCountShareValueEntries(rowGroupRows, allRows) }],
                    }),
                },
                {
                    text: this.$t("Gesamt") as string,
                    headers: caseStatuses.map((caseStatus) => ({
                        text: this.$t(`enum.CaseStatus.${caseStatus}`) as string,
                    })),
                    toColumnGroup: (rowGroupRows) => ({
                        columns: caseStatuses.map((caseStatus) => ({
                            entries: this.toCountShareValueEntries(
                                rowGroupRows.filter((r) => r.caseStatus === caseStatus),
                                rowGroupRows
                            ),
                        })),
                    }),
                },
                {
                    text: this.$t("Bearbeitungsdauer (Median)") as string,
                    headers: [
                        {
                            text: this.$t("Gesamt") as string,
                            options: {
                                color: ISSUE_STATUS_TOTAL_COLOR,
                                formatter: (value, __, context) => durationFormatter(value, context),
                            } as BarChartSeriesOptions,
                        },
                        ...caseStatuses.map((caseStatus) => ({
                            text: this.$t(`enum.CaseStatus.${caseStatus}`) as string,
                            options: {
                                color: getCaseStatusColor(caseStatus),
                                formatter: (value, __, context) => durationFormatter(value, context),
                            } as BarChartSeriesOptions,
                        })),
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: this.toMedianDurationEntries(rowGroupRows, (r) => r.processingTime),
                            },
                            ...caseStatuses.map((caseStatus) => ({
                                entries: this.toMedianDurationEntries(
                                    rowGroupRows.filter((r) => r.caseStatus === caseStatus),
                                    (r) => r.processingTime
                                ),
                            })),
                        ],
                    }),
                },
                {
                    text: this.$t("Bearbeitungsdauer") as string,
                    headers: this.processingTimeSlots.map((slot) => ({
                        text: getIndividualDurationIntervalLabel(slot),
                    })),
                    toColumnGroup: (rowGroupRows) => ({
                        columns: this.processingTimeSlots.map((slot) => ({
                            entries: this.toCountShareValueEntries(
                                rowGroupRows.filter((r) => r.processingTimeIndividualDurationSlot === slot.key),
                                rowGroupRows
                            ),
                        })),
                    }),
                },
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetCaseIds = [];
        },

        showBottomSheetOnClick(caseIds: string[]): () => void {
            return () => {
                this.bottomSheetCaseIds = [...new Set(caseIds)];
                this.bottomSheetVisible = true;
            };
        },

        toCountShareValueEntries(
            countRows: readonly ComputedCaseRow[],
            totalRows: readonly ComputedCaseRow[]
        ): ReportingTableItemColumnEntries {
            const absoluteValue = { value: countRows.length };
            const percentageValue = {
                value: rate(countRows.length, totalRows.length),
                isPercentage: true,
            };

            const onClick = this.showBottomSheetOnClick(countRows.map((r) => r.id));

            return this.evaluationType === EvaluationType.PERCENTAGE
                ? [{ ...percentageValue, onClick }, absoluteValue]
                : [{ ...absoluteValue, onClick }, percentageValue];
        },

        toMedianDurationEntries(
            rows: readonly ComputedCaseRow[],
            getValue: (r: ComputedCaseRow) => number | null
        ): ReportingTableItemColumnEntries {
            const medianRows = rows.filter((r) => getValue(r) !== null);

            return [
                {
                    value: median(medianRows.map(getValue)),
                    onClick: this.showBottomSheetOnClick(medianRows.map((r) => r.id)),
                    formatter: (value) => renderDuration(value, "L"),
                },
            ];
        },
    },

    components: {
        CaseReportPage,
        CasesBottomSheet,
        ReportingBarChartCard,
        ReportingPunchCardChartCard,
        ReportTableCard,
    },
});
