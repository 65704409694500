
import OpportunityReportPage from "./OpportunityReportPage.vue";
import { ExtendedOpportunityRow, OpportunityReportPageResult } from "./opportunityReportPage";
import {
    getOpportunityRowDefaultKeys,
    mapOpportunityRowKeyToRowLabel,
    splitWithOpportunityTeamReceiverId,
    WithOpportunityTeamReceiverId,
    withOpportunityTeamReceiverIdSetToNull,
} from "./opportunityRowUtils";
import { OpportunityStatus } from "@/api/opportunities";
import { OpportunityOutcomeReason } from "@/api/opportunityOutcomeReasons";
import OpportunitiesBottomSheet from "@/app/pages/opportunities/OpportunitiesBottomSheet.vue";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import ReportingBarChartCard from "@/app/pages/reporting/ReportingBarChartCard.vue";
import ReportingPunchCardChartCard from "@/app/pages/reporting/ReportingPunchCardChartCard.vue";
import {
    BarChartSeriesOptions,
    getPunchCardChartForHeaderGroup,
    getReportingBarChartForHeaderGroup,
} from "@/app/pages/reporting/chartUtils";
import { ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { ReportingPunchCardChartData } from "@/app/pages/reporting/charts/reportingPunchCardChart";
import {
    isWithProcessingTimeIndividualDurationSlotErrorless,
    PotentiallyErroneous,
    PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS,
    splitWithAssignee,
    WithAssignee,
    withAssigneeSetToNull,
    WithCloser,
    WithCreatorActorType,
    WithProcessingTimeIndividualDurationSlot,
    WithSentimentType,
} from "@/app/pages/reporting/issueRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import {
    getSortByOptions,
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnEntries,
    ReportingTableItemColumnGroup,
    ReportingTableSortByOptions,
    ReportingTableSortByOptionValue,
    sort,
} from "@/app/pages/reporting/table/reportingTable";
import { IndividualDurationInterval } from "@/app/pages/reporting/timeInterval";
import { opportunityOutcomeReasonsStore } from "@/store/opportunityOutcomeReasons";
import { reportingSettingsStore } from "@/store/reportingSettingsStore";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type PrecomputedProperties = WithCloser &
    WithCreatorActorType &
    WithProcessingTimeIndividualDurationSlot<PotentiallyErroneous> &
    WithSentimentType;

type ComputedOpportunityRow = ExtendedOpportunityRow<PrecomputedProperties> &
    WithAssignee &
    WithOpportunityTeamReceiverId;

type ComputedOpportunityRowGroupBy = keyof Pick<
    ComputedOpportunityRow,
    | "assigneeId"
    | "channel"
    | "closer"
    | "creatorActorType"
    | "dealerId"
    | "opportunityTeamReceiverId"
    | "processingTimeIndividualDurationSlot"
    | "sentimentType"
    | "sourceId"
>;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedOpportunityRowGroupBy;
}

type ReportingTableHeaderGroupWithColumnGroupComputer<Options> = ReportingTableHeaderGroup<Options> & {
    readonly toColumnGroup: (
        rowGroupRows: readonly ComputedOpportunityRow[],
        totalRows: readonly ComputedOpportunityRow[]
    ) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        return {
            bottomSheetOpportunityIds: [] as string[],
            bottomSheetVisible: false,
            evaluationType: EvaluationType.ABSOLUTE as EvaluationType,
            EvaluationType,
            groupBy: "sourceId" as ComputedOpportunityRowGroupBy,
            result: null as OpportunityReportPageResult<PrecomputedProperties> | null,
            sortBy: {
                groupIndex: 0,
                columnIndex: 0,
                direction: "DESC",
            } as ReportingTableSortByOptionValue,
        };
    },

    computed: {
        defaultKeys(): RowKey[] {
            if (this.groupBy === "sourceId") {
                return [];
            }

            return getOpportunityRowDefaultKeys(this.groupBy, this.result?.defaultKeysContext);
        },

        filteredComputedOpportunityRows(): readonly ComputedOpportunityRow[] {
            let filteredRows: readonly ComputedOpportunityRow[] = (this.result?.rows ?? [])
                .filter((row) => this.groupBy !== "closer" || row.opportunityStatus === OpportunityStatus.CLOSED)
                .map(withAssigneeSetToNull)
                .map(withOpportunityTeamReceiverIdSetToNull);

            if (this.groupBy === "assigneeId") {
                filteredRows = splitWithAssignee(filteredRows);
            } else if (this.groupBy === "opportunityTeamReceiverId") {
                filteredRows = splitWithOpportunityTeamReceiverId(filteredRows);
            }

            return filteredRows;
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "creatorActorType",
                    text: this.$t("Art des Erstellers"),
                },
                {
                    value: "processingTimeIndividualDurationSlot",
                    text: this.$t("Bearbeitungsdauer"),
                },
                {
                    value: "channel",
                    text: this.$t("Kanal"),
                },
                {
                    value: "sourceId",
                    text: this.$t("Quelle"),
                },
                {
                    value: "closer",
                    text: this.$t("Schließer"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
                {
                    value: "sentimentType",
                    text: this.$t("Stimmung"),
                },
                {
                    value: "assigneeId",
                    text: this.$t("Verkäufer"),
                },
                {
                    value: "opportunityTeamReceiverId",
                    text: this.$t("Verkäuferteam"),
                },
            ];
        },

        groupByText(): string | null {
            return (this.groupByOptions.find((o) => o.value === this.groupBy)?.text ?? null) as string | null;
        },

        lostOutcomeReasons(): OpportunityOutcomeReason[] {
            return opportunityOutcomeReasonsStore.opportunityOutcomeReasons
                .filter((r) => !r.won)
                .filter(
                    (r) => !reportingSettingsStore.reportingSettings.ignoreOpportunityOutcomeReasonIds.includes(r.id)
                );
        },

        lostOutcomeReasonsChart(): ReportingPunchCardChartData | null {
            return getPunchCardChartForHeaderGroup(this.table, 4);
        },

        outcomeChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 2);
        },

        processingTimeSlots(): IndividualDurationInterval[] {
            return [...PROCESSING_TIME_INDIVIDUAL_DURATION_SLOTS];
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedOpportunityRow>[] {
            const filteredRows =
                this.groupBy === "processingTimeIndividualDurationSlot"
                    ? this.filteredComputedOpportunityRows.filter(isWithProcessingTimeIndividualDurationSlotErrorless)
                    : this.filteredComputedOpportunityRows;

            const rowGroups = groupRowsBy(filteredRows, (r) => r[this.groupBy]);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys).map((rowGroup) => {
                const rowGroupLabel = mapOpportunityRowKeyToRowLabel(rowGroup.key, this.groupBy, {
                    ...(this.result?.mapKeyToLabelContext ?? {}),
                    processingTimeSlots: this.processingTimeSlots,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        sortByOptions(): ReportingTableSortByOptions {
            return getSortByOptions(this.tableHeaderGroups);
        },

        table(): ReportingTableData<BarChartSeriesOptions> | null {
            if (!this.tableData) {
                return null;
            }

            return {
                ...this.tableData,
                items: sort(this.tableData.items, this.sortBy),
            };
        },

        tableData(): ReportingTableData<BarChartSeriesOptions> | null {
            const allRows = this.filteredComputedOpportunityRows;

            if (!allRows.length) {
                return null;
            }

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => ({
                    title: rowGroup.title,
                    subtitle: rowGroup.subtitle,
                    groups: this.tableHeaderGroups.map((headerGroup) =>
                        headerGroup.toColumnGroup(rowGroup.rows, allRows)
                    ),
                })),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) => headerGroup.toColumnGroup(allRows, allRows)),
                },
                groupByHeaderText: this.groupByText ?? undefined,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer<BarChartSeriesOptions>[] {
            const opportunityStatuses = Object.keys(OpportunityStatus) as OpportunityStatus[];

            return [
                {
                    text: null,
                    headers: [{ text: this.$t("Gesamt") as string }],
                    toColumnGroup: (rowGroupRows, allRows) => ({
                        columns: [{ entries: this.toCountShareValueEntries(rowGroupRows, allRows) }],
                    }),
                },
                {
                    text: this.$t("Gesamt") as string,
                    headers: opportunityStatuses.map((opportunityStatus) => ({
                        text: this.$t(`enum.OpportunityStatus.${opportunityStatus}`) as string,
                    })),
                    toColumnGroup: (rowGroupRows) => ({
                        columns: opportunityStatuses.map((opportunityStatus) => ({
                            entries: this.toCountShareValueEntries(
                                rowGroupRows.filter((r) => r.opportunityStatus === opportunityStatus),
                                rowGroupRows
                            ),
                        })),
                    }),
                },
                {
                    text: this.$t("Resultat") as string,
                    headers: [
                        {
                            text: this.$t("Erfolgreich") as string,
                            options: { colorPalette: ReportingChartColorPalette.POSITIVE } as BarChartSeriesOptions,
                        },
                        {
                            text: this.$t("Erfolglos") as string,
                            options: { colorPalette: ReportingChartColorPalette.NEGATIVE } as BarChartSeriesOptions,
                        },
                    ],
                    toColumnGroup: (rowGroupRows) => {
                        const statusClosedRows = rowGroupRows.filter(
                            (r) => r.opportunityStatus === OpportunityStatus.CLOSED
                        );

                        return {
                            columns: [
                                {
                                    entries: this.toCountShareValueEntries(
                                        statusClosedRows.filter((r) => r.outcome),
                                        statusClosedRows
                                    ),
                                },
                                {
                                    entries: this.toCountShareValueEntries(
                                        statusClosedRows.filter((r) => !r.outcome),
                                        statusClosedRows
                                    ),
                                },
                            ],
                        };
                    },
                },
                {
                    text: this.$t("Erfolgreich (Gründe)") as string,
                    headers: [
                        ...this.wonOutcomeReasons.map((r) => ({
                            text: r.name,
                        })),
                        { text: this.$t("Kein Grund angegeben") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => {
                        const wonRows = rowGroupRows.filter(
                            (r) => r.opportunityStatus === OpportunityStatus.CLOSED && r.outcome
                        );

                        return {
                            columns: [
                                ...this.wonOutcomeReasons.map((reason) => ({
                                    entries: this.toCountShareValueEntries(
                                        wonRows.filter((r) => r.outcomeReasonId === reason.id),
                                        wonRows
                                    ),
                                })),
                                {
                                    entries: this.toCountShareValueEntries(
                                        wonRows.filter((r) => !r.outcomeReasonId),
                                        wonRows
                                    ),
                                },
                            ],
                        };
                    },
                },
                {
                    text: this.$t("Erfolglos (Gründe)") as string,
                    headers: [
                        ...this.lostOutcomeReasons.map((r) => ({
                            text: r.name,
                        })),
                        { text: this.$t("Kein Grund angegeben") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => {
                        const lostRows = rowGroupRows.filter(
                            (r) => r.opportunityStatus === OpportunityStatus.CLOSED && !r.outcome
                        );

                        return {
                            columns: [
                                ...this.lostOutcomeReasons.map((reason) => ({
                                    entries: this.toCountShareValueEntries(
                                        lostRows.filter((r) => r.outcomeReasonId === reason.id),
                                        lostRows
                                    ),
                                })),
                                {
                                    entries: this.toCountShareValueEntries(
                                        lostRows.filter((r) => !r.outcomeReasonId),
                                        lostRows
                                    ),
                                },
                            ],
                        };
                    },
                },
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        wonOutcomeReasons(): OpportunityOutcomeReason[] {
            return opportunityOutcomeReasonsStore.opportunityOutcomeReasons
                .filter((r) => r.won)
                .filter(
                    (r) => !reportingSettingsStore.reportingSettings.ignoreOpportunityOutcomeReasonIds.includes(r.id)
                );
        },

        wonOutcomeReasonsChart(): ReportingPunchCardChartData | null {
            return getPunchCardChartForHeaderGroup(this.table, 3);
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetOpportunityIds = [];
        },

        showBottomSheetOnClick(opportunityIds: string[]): () => void {
            return () => {
                this.bottomSheetOpportunityIds = [...new Set(opportunityIds)];
                this.bottomSheetVisible = true;
            };
        },

        toCountShareValueEntries(
            countRows: readonly ComputedOpportunityRow[],
            totalRows: readonly ComputedOpportunityRow[]
        ): ReportingTableItemColumnEntries {
            const absoluteValue = { value: countRows.length };
            const percentageValue = {
                value: rate(countRows.length, totalRows.length),
                isPercentage: true,
            };

            const onClick = this.showBottomSheetOnClick(countRows.map((r) => r.id));

            return this.evaluationType === EvaluationType.PERCENTAGE
                ? [{ ...percentageValue, onClick }, absoluteValue]
                : [{ ...absoluteValue, onClick }, percentageValue];
        },
    },

    components: {
        OpportunitiesBottomSheet,
        OpportunityReportPage,
        ReportingBarChartCard,
        ReportingPunchCardChartCard,
        ReportTableCard,
    },
});
