
import {
    IssueType,
    OutgoingCallRow,
    OutgoingEmailRow,
    OutgoingSmsRow,
    OutgoingWhatsAppMessageRow,
} from "@/api/reporting";
import { WithIssueType } from "@/app/pages/reporting/activityRowUtils";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { CALL_COLOR, EMAIL_COLOR, SMS_COLOR, WHATSAPP_MESSAGE_COLOR } from "@/app/pages/reporting/colors";

import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { addMissingRowGroups, count, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import { getFullName } from "@/app/userUtils";
import { dealersStore } from "@/store/dealers";
import { usersStore } from "@/store/users";
import Vue from "vue";

enum OutgoingActivityType {
    CALL,
    EMAIL,
    SMS,
    WHATSAPP,
}

interface OutgoingActivity {
    readonly type: OutgoingActivityType;
    readonly created: Date;
    readonly issueType: IssueType;
    readonly dealerId: string | null;
    readonly initiatorUserId: string;
}

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "ISSUE_TYPE" | "DEALER" | "INITIATOR",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        outgoingCallRows: {
            type: Array as () => (OutgoingCallRow & WithIssueType)[],
            default: () => [],
        },
        outgoingEmailRows: {
            type: Array as () => (OutgoingEmailRow & WithIssueType)[],
            default: () => [],
        },
        outgoingSmsRows: {
            type: Array as () => (OutgoingSmsRow & WithIssueType)[],
            default: () => [],
        },
        outgoingWhatsAppMessageRows: {
            type: Array as () => (OutgoingWhatsAppMessageRow & WithIssueType)[],
            default: () => [],
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    computed: {
        chart(): ReportingBarChartData | undefined {
            const calls = count(this.rowGroups, (r) => r.type === OutgoingActivityType.CALL);
            const emails = count(this.rowGroups, (r) => r.type === OutgoingActivityType.EMAIL);
            const sms = count(this.rowGroups, (r) => r.type === OutgoingActivityType.SMS);
            const whatsAppMessages = count(this.rowGroups, (r) => r.type === OutgoingActivityType.WHATSAPP);

            const topCategories = this.rowGroups
                .map((rowGroup, index) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    index,
                    value: rowGroup.rows.length,
                }))
                .filter((v) => v.value || (v.categoryId && this.defaultGroupIds.includes(v.categoryId)))
                .sort((a, b) => b.value - a.value);

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "call",
                        name: this.$t("Anruf") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: calls[c.index],
                            })),
                        },
                        color: CALL_COLOR,
                    },
                    {
                        id: "email",
                        name: this.$t("E-Mail") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: emails[c.index],
                            })),
                        },
                        color: EMAIL_COLOR,
                    },
                    {
                        id: "sms",
                        name: this.$t("SMS") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: sms[c.index],
                            })),
                        },
                        color: SMS_COLOR,
                    },
                    {
                        id: "whatsapp",
                        name: this.$t("WhatsApp-Nachricht") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: whatsAppMessages[c.index],
                            })),
                        },
                        color: WHATSAPP_MESSAGE_COLOR,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        rowGroups(): readonly TitledRowGroup<string | null, OutgoingActivity>[] {
            const rows: OutgoingActivity[] = [
                ...this.outgoingCallRows.map((activity) => ({
                    type: OutgoingActivityType.CALL,
                    created: activity.created,
                    issueType: activity.issueType,
                    dealerId: activity.dealerId,
                    initiatorUserId: activity.initiatorUserId,
                })),
                ...this.outgoingEmailRows.map((activity) => ({
                    type: OutgoingActivityType.EMAIL,
                    created: activity.created,
                    issueType: activity.issueType,
                    dealerId: activity.dealerId,
                    initiatorUserId: activity.initiatorUserId,
                })),
                ...this.outgoingSmsRows.map((activity) => ({
                    type: OutgoingActivityType.SMS,
                    created: activity.created,
                    issueType: activity.issueType,
                    dealerId: activity.dealerId,
                    initiatorUserId: activity.initiatorUserId,
                })),
                ...this.outgoingWhatsAppMessageRows.map((activity) => ({
                    type: OutgoingActivityType.WHATSAPP,
                    created: activity.created,
                    issueType: activity.issueType,
                    dealerId: activity.dealerId,
                    initiatorUserId: activity.initiatorUserId,
                })),
            ];

            let rowGroups: RowGroup<string | null, OutgoingActivity>[] = [];

            if (this.groupBy === "DEALER") {
                rowGroups = groupRowsBy(rows, (r) => r.dealerId);
            } else if (this.groupBy === "INITIATOR") {
                rowGroups = groupRowsBy(rows, (r) => r.initiatorUserId);
            } else if (this.groupBy === "ISSUE_TYPE") {
                rowGroups = groupRowsBy(rows, (r) => r.issueType);
            } else {
                rowGroups = [];
            }

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                let text: string | null = null;

                if (this.groupBy === "DEALER") {
                    text =
                        (rowGroup.key !== null ? dealersStore.dealerById(rowGroup.key)?.name : undefined) ||
                        (this.$t("Unbekannter Standort") as string);
                } else if (this.groupBy === "INITIATOR") {
                    const user = usersStore.getUserById(rowGroup.key!);

                    text = getFullName(user) || (this.$t("Unbekannter Benutzer") as string);
                } else if (this.groupBy === "ISSUE_TYPE") {
                    text = this.$t(`enum.IssueType.${rowGroup.key!}`) as string;
                }

                return {
                    ...rowGroup,
                    title: text || (this.$t("Unbekannt") as string),
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "INITIATOR"
                        ? (this.$t("Initiator") as string)
                        : this.groupBy === "ISSUE_TYPE"
                        ? (this.$t("Art des Vorgangs") as string)
                        : undefined,
            };
        },
    },

    components: {
        ReportingDashboardBarTile,
    },
});
